import * as R from 'ramda';

import type { Well } from 'modules/well/models/well';
import { countBoeValue } from 'modules/production/utils';

import { VarianceDrilldownTableItem } from '../models/drilldownTable';
import populateEntryWithGroupInfo from './populateEntryWithGroupInfo';

const normalizeDrilldownTable = (
  rawDrilldownTable: Record<string, any>[],
  wells: { [wellId: string]: Well },
  additionalKeys: string[],
  days: number,
): VarianceDrilldownTableItem[] => {
  const withoutGroups = rawDrilldownTable.reduce((acc, entry) => {
    const {
      gasActual,
      oilActual,
      waterActual,
      gasCapacity,
      oilCapacity,
      waterCapacity,
      gasForecast,
      oilForecast,
      waterForecast,
    } = entry;
    const data = {} as any;
    data.wellId = entry.wellId ? entry.wellId.toString() : null;
    data.well =
      entry.wellId && wells[entry.wellId] ? wells[entry.wellId].LEASE : '';
    data.gasActual = gasActual / days;
    data.oilActual = oilActual / days;
    data.waterActual = waterActual / days;
    data.boeActual = countBoeValue(data.oilActual, data.gasActual);
    data.gasCapacity = R.isNil(gasCapacity) ? 0 : gasCapacity / days;
    data.oilCapacity = R.isNil(oilCapacity) ? 0 : oilCapacity / days;
    data.waterCapacity = R.isNil(waterCapacity) ? 0 : waterCapacity / days;
    data.boeCapacity = countBoeValue(data.oilCapacity, data.gasCapacity);
    data.gasVariance = R.isNil(gasCapacity)
      ? 0
      : data.gasActual - data.gasCapacity;
    data.oilVariance = R.isNil(oilCapacity)
      ? 0
      : data.oilActual - data.oilCapacity;
    data.waterVariance = R.isNil(waterCapacity)
      ? 0
      : data.waterActual - data.waterCapacity;
    data.boeVariance = countBoeValue(data.oilVariance, data.gasVariance);
    data.gasForecast = R.isNil(gasForecast) ? null : gasForecast / days;
    data.oilForecast = R.isNil(oilForecast) ? null : oilForecast / days;
    data.waterForecast = R.isNil(waterForecast) ? null : waterForecast / days;
    data.boeForecast =
      R.isNil(oilForecast) && R.isNil(gasForecast)
        ? null
        : countBoeValue(data.oilForecast, data.gasForecast);
    data.gasCapVsExtVariance = R.isNil(gasForecast)
      ? data.gasCapacity ?? 0
      : data.gasCapacity - data.gasForecast;
    data.oilCapVsExtVariance = R.isNil(oilForecast)
      ? data.oilCapacity ?? 0
      : data.oilCapacity - data.oilForecast;
    data.waterCapVsExtVariance = R.isNil(waterForecast)
      ? data.waterCapacity ?? 0
      : data.waterCapacity - data.waterForecast;
    data.boeCapVsExtVariance = countBoeValue(
      data.oilCapVsExtVariance,
      data.gasCapVsExtVariance,
    );
    data.gasActVsExtVariance = data.gasActual - data.gasForecast;
    data.oilActVsExtVariance = data.oilActual - data.oilForecast;
    data.waterActVsExtVariance = data.waterActual - data.waterForecast;
    data.boeActVsExtVariance = countBoeValue(
      data.oilActVsExtVariance,
      data.gasActVsExtVariance,
    );
    acc.push(data);
    return acc;
  }, []);
  return withoutGroups.map(entry =>
    populateEntryWithGroupInfo(entry, additionalKeys, wells),
  );
};

export default normalizeDrilldownTable;
