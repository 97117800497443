import * as R from 'ramda';
import type { Well, WellColumnMappingItem } from 'modules/well/models/well';
import {
  CustomAttributeValue,
  FilterLayoutOptions,
} from 'modules/filterLayouts/models';

export const normalizeWells = (
  rawWells: Well[],
  columnMapping: WellColumnMappingItem[],
): { [id: string]: Well } => {
  const columnsByIndex = R.indexBy<number, Record<string, any>>(
    R.prop('columnIndex'),
    columnMapping,
  );
  const newWells = rawWells.map(rawWell => {
    return Object.keys(rawWell).reduce((acc, key) => {
      if (key === 'id') {
        acc.id = rawWell.id;

        return acc;
      }
      const columnIndex = key.split('_')[1];
      const newKeyName =
        columnsByIndex[columnIndex].wiserockBinding ||
        columnsByIndex[columnIndex].sourceName;
      acc[newKeyName] = rawWell[key];

      return acc;
    }, {} as Well);
  });

  return R.indexBy<string, Well>(R.prop('id'), newWells);
};

export const joinCustomAttributesToWells = (
  wells: Well[],
  customAttributes: FilterLayoutOptions,
  customValues: CustomAttributeValue[],
) => {
  const defaultValues = R.values(customAttributes).reduce((acc, n) => {
    acc[n.filterName] = '';
    return acc;
  }, {});

  const customData = customValues.reduce((acc, v) => {
    if (!acc[v.wellId]) acc[v.wellId] = {};
    const attr = customAttributes['custom' + v.wellCustomAttributeId];
    if (!attr) return acc;
    acc[v.wellId][attr.filterName] = v.value;
    return acc;
  }, {});

  const wellsWithCustomAttributes = wells.map(w => ({
    ...w,
    ...defaultValues,
    ...customData[w.id],
  }));

  return wellsWithCustomAttributes;
};
