import { utcDay } from 'd3-time';
import * as R from 'ramda';
import {
  all,
  put,
  putResolve,
  select,
  takeEvery,
  takeLatest,
  throttle,
  fork,
  take,
} from 'redux-saga/effects';

import { isIdNew } from 'helpers';
import {
  DELETE_LOCAL_ALLOC_ISSUE,
  CREATE_REMOTE_ALLOC_ISSUE,
} from 'modules/allocIssue/AllocIssueActions';
import { getAllAllocIssueAsArray } from 'modules/allocIssue/AllocIssueReducer';
import {
  NORMALIZE_APP_CONFIG,
  SET_APP_CONFIG,
  SET_APP_CONFIG_LOCALLY,
} from 'modules/appConfig/AppConfigActions';
import { getPermissions } from 'modules/auth/AuthReducer';
import {
  DELETE_CAPACITY_LOCALLY,
  fetchWellCapacity,
  updateRemoteCapacity,
  createRemoteCapacity,
  moveWellCapacityToBuffer,
} from 'modules/capacityChangeEvent/CapacityChangeEventActions';
import {
  hasCapacityForIssueCalc,
  getWellCapacityEventsSorted,
} from 'modules/capacityChangeEvent/CapacityChangeEventReducer';
import { VARIANCE_TRELLIS } from 'modules/chart/models/chart';
import { SWITCH_SERIES_MOD } from 'modules/chartOptions/ChartOptionsActions';
import {
  CLEAR_DRILLDOWN_TABLE,
  POPULATE_VARIANCE_DRILLDOWN_TABLE,
  POPULATE_WELL_DRILLDOWN_TABLE,
  resetSorting,
} from 'modules/drilldownTable/DrilldownTableActions';
import {
  getSortedWellDrilldownTable,
  getSortedGroupedTable,
  getSortedVarianceDrilldownTable,
  getDrilldownLoadingStatus,
  getRawWellDrilldownTable,
  getRawVarianceDrilldownTable,
} from 'modules/drilldownTable/DrilldownTableReducer';
import { getGroupChart } from 'modules/groupChart/GroupChartReducer';
import { clearAllFilters } from 'modules/filter/FilterActions';
import { fetchWellProduction } from 'modules/production/ProductionActions';
import { getDataAvailibility } from 'modules/production/ProductionReducer';
import {
  DELETE_VARIANCE_EVENT_LOCALLY,
  fetchVarianceEvents,
  CREATE_REMOTE_VARIANCE_EVENT,
} from 'modules/varianceEvent/VarianceEventActions';
import { getWellVarianceEventsSortedByDate } from 'modules/varianceEvent/VarianceEventReducer';
import {
  getWellsByCriteria,
  getWellInfo,
  getGroupsByKey,
  getGroups,
  getAllWells,
} from 'modules/well/WellReducer';
import { getGraphqlPayload } from 'store/helpers';
import { Action } from 'store/models';
import {
  OPEN_CAPACITY_CONVERSATION,
  OPEN_RIBBON_CONVERSATION,
  OPEN_VARIANCE_CONVERSATION,
} from 'modules/inboxConversation/InboxConversationActions';

import {
  CHANGE_GROUP_MODE_SUBJECT,
  DEBOUNCE_SET_NOTES_PRODUCTION_DAY,
  DISABLE_GROUP_MODE,
  ENABLE_GROUP_MODE,
  SET_CURRENT_WELL_ID,
  SET_RIGHT_PANEL_DIALOG,
  OPEN_ALLOC_ISSUE_DIALOG,
  CLOSE_CAPACITY_DIALOG,
  CLOSE_VARIANCE_DIALOG,
  CLOSE_RIBBON_EVENT_DIALOG,
  disableAdditionMode,
  disableGroupMode,
  disableNotesMode,
  disableRegionOfInterestMode,
  disableZoomInMode,
  setNotesProductionDay,
  switchTrellises,
  highlightOffEverything,
  highlightAllocIssueOff,
  highlightAllocIssueDividerOff,
  highlightCapacityOff,
  highlightCapacityDividerOff,
  highlightVarianceOff,
  highlightVarianceDividerOff,
  setCurrentWellId,
  setCurrentGroup,
  openEventConversation,
  closeEventConversation,
  unsetRightPanelDialog,
  setRightPanelDialog,
  expandRightPanelWithoutChangingDialog,
} from './UIActions';
import {
  getCurrentGroup,
  getCurrentWellId,
  getGroupMode,
  getIsRightPanelExpanded,
  getNotesProductionDay,
  getRightPanelDialog,
  getRightPanelDialogs,
  getRightPanelDialogType,
} from './UIReducer';
import { Dialog } from './models/dialog';
import {
  DIALOG_TYPE_TO_OPEN_ACTION_TYPE,
  DIALOG_TYPE_TO_CLOSE_ACTION_TYPE,
} from './constants';
import { getRibbonEvents } from 'modules/ribbon/RibbonReducer';
import { CREATE_REMOTE_RIBBON_EVENT } from 'modules/ribbon/RibbonActions';
import { RibbonEvent } from 'modules/ribbon/models';

/**
 * Initiates all ui changes, which are required on well switching
 */
function* wellSwitchSaga(): Generator<any, any, any> {
  if (window.location.pathname.includes('/dashboard')) {
    yield put(highlightOffEverything());
    yield put(unsetRightPanelDialog());
    yield put(disableAdditionMode());
    yield put(disableNotesMode());
    yield put(disableZoomInMode());
    yield put(disableRegionOfInterestMode());
  }
}

/**
 * Initiates ui reaction to Drilldown Table clearing
 */
function* clearDrilldownTableSaga(): Generator<any, any, any> {
  yield put(highlightOffEverything());
  yield put(unsetRightPanelDialog());
}

/**
 * Initiates ui reaction to removing capacity segment
 */
function* removeCapacitySaga(): Generator<any, any, any> {
  yield put(highlightCapacityOff());
  yield put(highlightCapacityDividerOff());
  yield put(unsetRightPanelDialog());
}

/**
 * Initiates ui reaction to removing event
 */
function* removeVarianceSaga(): Generator<any, any, any> {
  yield put(highlightVarianceOff());
  yield put(highlightVarianceDividerOff());
  yield put(unsetRightPanelDialog());
}

/**
 * Initiates ui reaction to removing allocation issue
 */
function* removeAllocIssueSaga(): Generator<any, any, any> {
  yield put(highlightAllocIssueOff());
  yield put(highlightAllocIssueDividerOff());
  yield put(unsetRightPanelDialog());
}

/**
 * Opens Variance Event Details Panel on capacity segment adding
 * @param {Object} action
 */
function* openDialogForNewlyAddedVarianceSaga(): Generator<any, any, any> {
  const wellId = yield select(getCurrentWellId);
  const eventsSorted = yield select(getWellVarianceEventsSortedByDate, {
    wellId,
  });
  const index = eventsSorted.findIndex(event => isIdNew(event.id));
  const id = eventsSorted[index].id;
  const data = { id, index };

  if (R.isNil(index) || index > -1) {
    yield put(setRightPanelDialog({ type: 'VarianceChangeEvent', data }));
  }
}

function* openDialogForNewlyAddedRibbonSaga(): Generator<any, any, any> {
  const ribbonEvents: { [id: string]: RibbonEvent } = yield select(
    getRibbonEvents,
  );
  const event = Object.values(ribbonEvents).find(event => isIdNew(event.id));

  if (!event) return;

  const data = { id: event.id };

  yield put(setRightPanelDialog({ type: 'RibbonEvent', data }));
}

/**
 * When newly added alloc issue is populated with id from api, saga keeps the Details Panel opened.
 * @param {Object} action
 */
function* openDialogForNewlyAddedAllocIssueSaga(
  action,
): Generator<any, any, any> {
  const currentDialog: Dialog | null = yield select(getRightPanelDialog);
  if (currentDialog?.type !== 'AllocationIssue') return;

  const newIssue = getGraphqlPayload(action);
  const index = currentDialog.data.index;
  const data = { index, id: newIssue.id };
  yield put(setRightPanelDialog({ type: 'AllocationIssue', data }));
}

/**
 * Initiates data loading for issue, when on Backlog user clicks an issue card.
 * @param {Object} action
 */
function* loadDataForIssueSaga(action: Action): Generator<any, any, any> {
  const payload = action.payload;
  const issueId = payload.id;
  const issues = yield select(getAllAllocIssueAsArray);
  const panelIssue = issues.find(issue => issue.id === issueId);

  if (panelIssue) {
    const wellId = panelIssue.wellId;
    const hasCapacity = yield select(hasCapacityForIssueCalc, { wellId });
    const hasProduction = yield select(getDataAvailibility, wellId);

    if (hasCapacity && hasProduction) return;
    yield put(fetchWellProduction({ wellId }));
    yield put(fetchWellCapacity(wellId));
    yield put(fetchVarianceEvents(wellId));
  }
}

/**
 * Sets the first well in sorted drilldown table as current well
 * if there is no current well and app is not in group mode.
 * @param {Object} action
 */
function* setCurrentWellSaga({ type }): Generator<any, any, any> {
  const groupMode = yield select(getGroupMode);
  if (groupMode.isOn) {
    return;
  }
  const pathname = window.location.pathname;
  const rawDrilldownTable = yield select(getRawWellDrilldownTable);
  const filteredDrilldownTable = yield select(
    getSortedWellDrilldownTable,
    null,
  );
  const rawVarianceDrilldownTable = yield select(getRawVarianceDrilldownTable);
  const filteredVarianceDrilldownTable = yield select(
    getSortedVarianceDrilldownTable,
  );
  const loadingStatus = yield select(getDrilldownLoadingStatus);
  const matchArr = pathname.match(/dashboard\/(.*)/);
  const routeWellId = matchArr ? matchArr[1] : '';
  const storeWellId = yield select(getCurrentWellId);
  const prevWellId = storeWellId || routeWellId;
  const wells = yield select(getAllWells);

  if (
    R.isEmpty(filteredDrilldownTable) &&
    R.isEmpty(filteredVarianceDrilldownTable) &&
    !loadingStatus.isVarianceTableFetching &&
    !loadingStatus.isWellTableFetching
  ) {
    yield putResolve(clearAllFilters());
    const fullDrilldownTable = yield select(getSortedWellDrilldownTable);
    const firstWellId = R.pathOr('', [0, 'wellId'], fullDrilldownTable);
    if (
      !prevWellId ||
      !fullDrilldownTable.some(item => item.wellId === prevWellId)
    ) {
      yield put(setCurrentWellId(firstWellId));
    } else if (type === DISABLE_GROUP_MODE) {
      yield put(setCurrentWellId(prevWellId));
    }

    return;
  }
  const firstWellId = R.pathOr('', [0, 'wellId'], filteredDrilldownTable);

  if (
    !rawDrilldownTable.some(item => item.wellId === prevWellId) &&
    !rawVarianceDrilldownTable.some(item => item.wellId === prevWellId) &&
    firstWellId
  ) {
    if (prevWellId && wells[prevWellId]) {
      yield putResolve(clearAllFilters());
    } else {
      yield put(setCurrentWellId(firstWellId));
    }
  } else if (type === DISABLE_GROUP_MODE) {
    yield put(setCurrentWellId(prevWellId));
  }
}

/**
 * Resets sorting of Drilldown Table to default config
 */
function* resetDrilldownTableSortingSaga(action): Generator<any, any, any> {
  yield putResolve(resetSorting());
}

/**
 *
 * @param {Object} action
 */

function* setCurrentGroupSaga(action: Action): Generator<any, any, any> {
  const wells = yield select(getAllWells);

  if (R.isEmpty(wells)) {
    const nestedAction = yield take([
      CHANGE_GROUP_MODE_SUBJECT,
      ENABLE_GROUP_MODE,
      POPULATE_WELL_DRILLDOWN_TABLE,
      DISABLE_GROUP_MODE,
    ]);

    if (nestedAction.type === DISABLE_GROUP_MODE) {
      return;
    }

    if (nestedAction.type !== POPULATE_WELL_DRILLDOWN_TABLE) {
      return yield setCurrentGroupSaga(nestedAction);
    }
  }

  const subject = action.payload;
  const groupedTable = yield select(getSortedGroupedTable, { subject });
  const groupMode = yield select(getGroupMode);
  const prevGroup = yield select(getCurrentGroup);
  const prevGroupChart = yield select(getGroupChart, prevGroup);

  const prevGroupIsEmpty = !prevGroup || R.isEmpty(prevGroup);

  // disable group mode if group doesn't exist
  const existingGroups = yield select(getGroups, null);
  const isGroupExist = existingGroups.some(group => group.key === subject);
  if (!isGroupExist) {
    yield put(disableGroupMode());
    return;
  }

  // set group item of current well
  if (prevGroupIsEmpty) {
    const currentWellId = yield select(getCurrentWellId);
    const currentWellInfo = yield select(getWellInfo, currentWellId);

    if (currentWellId && !R.isEmpty(currentWellInfo)) {
      const { subject } = groupMode;
      const groupItemOfCurrentWell =
        subject === 'all' ? 'all' : currentWellInfo[subject];
      yield put(
        setCurrentGroup({
          subject,
          item: groupItemOfCurrentWell,
        }),
      );

      return;
    } else if (!currentWellId && R.isEmpty(prevGroup)) {
      return;
    }
  }

  const prevGroupCriteriaIsTheSame =
    prevGroup &&
    !R.isEmpty(prevGroup) &&
    prevGroup.subject === groupMode.subject;

  const groupsByKey = yield select(getGroupsByKey, null);
  const indexOfPrevCriteria = groupsByKey[prevGroup.subject].index;
  const indexOfCurrentCriteria = groupsByKey[groupMode.subject].index;
  const prevGroupCriteriaIsIncluded =
    indexOfCurrentCriteria !== -1 &&
    indexOfPrevCriteria !== -1 &&
    indexOfCurrentCriteria < indexOfPrevCriteria;

  // ignore if there is no need to set current group or prevent current group resetting
  if (prevGroupCriteriaIsTheSame && !R.isEmpty(prevGroupChart)) {
    return;
  } else if (prevGroupCriteriaIsTheSame && R.isEmpty(prevGroupChart)) {
    // to prevent current group resetting on drill-up
    yield put(setCurrentGroup(prevGroup));

    return;
  }

  // if new group includes previous group
  if (prevGroupCriteriaIsIncluded) {
    const wellsOfPrevCriteria = yield select(
      getWellsByCriteria,
      prevGroup.subject,
      prevGroup.item,
    );
    if (
      !!wellsOfPrevCriteria[0] &&
      !!wellsOfPrevCriteria[0][groupMode.subject]
    ) {
      const newGroupItem = wellsOfPrevCriteria[0][groupMode.subject];
      yield put(
        setCurrentGroup({
          subject: groupMode.subject,
          item: newGroupItem,
        }),
      );

      return;
    }
  }
  // set the first table row as current group
  if (!R.isEmpty(groupedTable)) {
    yield put(
      setCurrentGroup({
        subject: groupMode.subject,
        item: groupedTable[0][groupMode.subject],
      }),
    );
  }
}

function* closeCapacityDialogSaga(): Generator<any, any, any> {
  const wellId = yield select(getCurrentWellId);
  const permissions = yield select(getPermissions, null);
  yield put(moveWellCapacityToBuffer(wellId));

  if (!(permissions.isAdmin || permissions.isAllowedEditCapChanges)) return;
  const { CapacityChangeEvent } = yield select(getRightPanelDialogs);
  const capacityEvents = yield select(getWellCapacityEventsSorted, { wellId });

  if (!capacityEvents[CapacityChangeEvent]) return;
  const capacityEvent = capacityEvents[CapacityChangeEvent.index];

  if (!capacityEvent || R.isEmpty(capacityEvent)) return;

  if (!isIdNew(capacityEvent.id)) {
    yield put(updateRemoteCapacity(capacityEvent));
  } else if (isIdNew(capacityEvent.id)) {
    yield put(createRemoteCapacity(R.omit(['id'], capacityEvent)));
  }
}

function* setInitiallyNotesProdDaySaga(action): Generator<any, any, any> {
  const config = action.payload;
  const notesProdDayInStore = yield select(getNotesProductionDay, null);
  const isToday =
    utcDay.floor(new Date()).getTime() ===
    utcDay.floor(notesProdDayInStore).getTime();

  if (config.today && isToday) {
    const parsedTodayFromConfig = new Date(config.today);
    yield put(setNotesProductionDay(parsedTodayFromConfig));
  }
}

function* debounceSetNotesProductionDaySaga(action): Generator<any, any, any> {
  yield put(setNotesProductionDay(action.payload));
}

function* checkGroupingSaga(action): Generator<any, any, any> {
  const payload =
    action.type === SET_APP_CONFIG_LOCALLY
      ? action.payload
      : action.payload.graphql.variables.data;
  if (payload.hasOwnProperty('groupBy') && payload.groupBy === false) {
    yield put(switchTrellises({ name: VARIANCE_TRELLIS, checked: false }));
    yield put(disableGroupMode());
  }
}

function* unsetRightPanelDialogSaga(action): Generator<any, any, any> {
  yield put(unsetRightPanelDialog());
}

function* openConversationSaga(): Generator<any, any, any> {
  yield put(openEventConversation());
}

function* closeConversationSaga(): Generator<any, any, any> {
  yield put(closeEventConversation());
}

function* expandRightPanelSaga(action): Generator<any, any, any> {
  if (/event/i.test(action.payload.type) === false) return;

  const isExpanded = yield select(getIsRightPanelExpanded);
  if (isExpanded) return;

  yield put(expandRightPanelWithoutChangingDialog());
}

function* setRightPanelDialogSaga(): Generator<any, any, any> {
  while (true) {
    const oldType: Dialog['type'] = yield select(getRightPanelDialogType);
    const action = yield take(SET_RIGHT_PANEL_DIALOG);
    const newType: Dialog['type'] = yield select(getRightPanelDialogType);

    yield fork(function* () {
      const open = DIALOG_TYPE_TO_OPEN_ACTION_TYPE[newType];
      const close = DIALOG_TYPE_TO_CLOSE_ACTION_TYPE[oldType];
      if (close && oldType !== newType) {
        yield put({ type: close, payload: action.payload });
      }
      if (open) yield put({ type: open, payload: action.payload });
    });
  }
}

function* uiSagas(): Generator<any, any, any> {
  yield all([
    takeEvery(OPEN_ALLOC_ISSUE_DIALOG, loadDataForIssueSaga),
    takeLatest(SET_CURRENT_WELL_ID, wellSwitchSaga),
    takeLatest(CLEAR_DRILLDOWN_TABLE, clearDrilldownTableSaga),
    takeLatest(DELETE_CAPACITY_LOCALLY, removeCapacitySaga),
    takeLatest(DELETE_VARIANCE_EVENT_LOCALLY, removeVarianceSaga),
    takeLatest(DELETE_LOCAL_ALLOC_ISSUE, removeAllocIssueSaga),
    takeLatest(
      CREATE_REMOTE_VARIANCE_EVENT,
      openDialogForNewlyAddedVarianceSaga,
    ),
    takeLatest(CREATE_REMOTE_RIBBON_EVENT, openDialogForNewlyAddedRibbonSaga),
    takeEvery(
      `${CREATE_REMOTE_ALLOC_ISSUE}_SUCCESS`,
      openDialogForNewlyAddedAllocIssueSaga,
    ),
    takeLatest(
      [
        POPULATE_VARIANCE_DRILLDOWN_TABLE,
        POPULATE_WELL_DRILLDOWN_TABLE,
        DISABLE_GROUP_MODE,
      ],
      setCurrentWellSaga,
    ),
    takeLatest(
      [CHANGE_GROUP_MODE_SUBJECT, ENABLE_GROUP_MODE],
      resetDrilldownTableSortingSaga,
    ),
    takeLatest(
      [CHANGE_GROUP_MODE_SUBJECT, ENABLE_GROUP_MODE],
      setCurrentGroupSaga,
    ),
    takeLatest(NORMALIZE_APP_CONFIG, setInitiallyNotesProdDaySaga),
    throttle(
      20,
      DEBOUNCE_SET_NOTES_PRODUCTION_DAY,
      debounceSetNotesProductionDaySaga,
    ),
    takeLatest([SET_APP_CONFIG, SET_APP_CONFIG_LOCALLY], checkGroupingSaga),
    takeLatest(
      [
        OPEN_CAPACITY_CONVERSATION,
        OPEN_VARIANCE_CONVERSATION,
        OPEN_RIBBON_CONVERSATION,
      ],
      openConversationSaga,
    ),
    takeLatest([CLOSE_CAPACITY_DIALOG], closeCapacityDialogSaga),
    takeLatest(
      [CLOSE_CAPACITY_DIALOG, CLOSE_VARIANCE_DIALOG, CLOSE_RIBBON_EVENT_DIALOG],
      closeConversationSaga,
    ),
    takeLatest(
      [SWITCH_SERIES_MOD, ENABLE_GROUP_MODE],
      unsetRightPanelDialogSaga,
    ),
    takeLatest(SET_RIGHT_PANEL_DIALOG, expandRightPanelSaga),
    fork(setRightPanelDialogSaga),
  ]);
}

export default uiSagas;
