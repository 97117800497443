import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';
import type { Action, Selector } from 'store/models';

import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';

import {
  NormalizedSeries,
  NormalizedSeriesMapping,
  Series,
  SelectedSeriesMapping,
} from './models';
import {
  CLEAR_ALL_GROUP_SERIES,
  FETCH_SERIES,
  FETCH_SERIES_DATE_RANGE,
  FETCH_SERIES_MAPPING,
  namespace,
  SET_GROUP_SERIES,
  SWITCH_SERIES_MAPPING,
} from './SeriesActions';
import { normalizeSeriesMapping, normalizeSeries } from './utils';

const filterRegExp = new RegExp(`${namespace}/`);

export const STATE_KEY = 'series';

interface SeriesState {
  seriesMapping: NormalizedSeriesMapping;
  selectedSeriesMapping: SelectedSeriesMapping | Record<string, never>;
  series:
    | { [key: string]: { data: NormalizedSeries[]; isPartialData: false } }
    | Record<string, never>;
  groupSeries:
    | { subject: { item: NormalizedSeries[] } }
    | Record<string, never>;
  wasDateRangeFatched: boolean;
}

const initialState: SeriesState = {
  seriesMapping: {},
  selectedSeriesMapping: {},
  series: {},
  groupSeries: {},
  wasDateRangeFatched: false,
};

const SeriesReducer = (
  state: SeriesState | undefined = initialState,
  action: Action,
) => {
  switch (action.type) {
    case `${FETCH_SERIES_MAPPING}_SUCCESS`: {
      const listSeriesMapping = getGraphqlPayload(action);

      return R.assoc(
        'seriesMapping',
        normalizeSeriesMapping(listSeriesMapping),
        state,
      );
    }
    case SET_GROUP_SERIES: {
      const { series, groupSubject, groupName } = action.payload;
      return R.assocPath(
        ['groupSeries', groupSubject, groupName],
        series,
        state,
      );
    }
    case CLEAR_ALL_GROUP_SERIES: {
      if (!action.payload) {
        return R.assocPath(['groupSeries'], {}, state);
      }
      const { subject, item } = action.payload;
      const currenData = R.path(['groupSeries', subject, item], state);
      return R.assocPath(
        ['groupSeries'],
        { [subject]: { [item]: currenData } },
        state,
      );
    }
    case SWITCH_SERIES_MAPPING: {
      const { id } = action.payload;
      if (R.pathOr(false, ['selectedSeriesMapping', id], state)) {
        return R.assocPath(
          ['selectedSeriesMapping'],
          R.omit(id, state.selectedSeriesMapping),
          state,
        );
      }
      return R.assocPath(
        ['selectedSeriesMapping'],
        R.assoc(id, true, state.selectedSeriesMapping),
        state,
      );
    }
    case `${FETCH_SERIES_DATE_RANGE}_SUCCESS`: {
      const wellId = getGraphqlPrevActionVariables(action).payload.wellId;
      const { seriesChartData } = getGraphqlPayload(action);
      const { seriesData } = JSON.parse(seriesChartData);
      const series = normalizeSeries(seriesData);

      return R.assocPath(
        ['series', wellId],
        { data: series, isPartialData: true },
        state,
      );
    }
    case `${FETCH_SERIES}_SUCCESS`: {
      const wellId = getGraphqlPrevActionVariables(action).payload.wellId;
      const { seriesChartData } = getGraphqlPayload(action);
      const { seriesData } = JSON.parse(seriesChartData);
      const series = normalizeSeries(seriesData);
      return R.assocPath(
        ['series', wellId],
        { data: series, isPartialData: false },
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getSerieState = (state: any): SeriesState => state[STATE_KEY];
export const getSeriesMapping: Selector<NormalizedSeriesMapping> =
  createSelector(getSerieState, (state: SeriesState) => state.seriesMapping);

export const getSelectedSeriesMapping: Selector<SelectedSeriesMapping> =
  createSelector(
    getSerieState,
    (state: SeriesState) => state.selectedSeriesMapping,
  );

export const getSeriesByWell: Selector<Series[]> = createSelector(
  getSerieState,
  (_, props) => props.wellId,
  (state: SeriesState, wellId: string) =>
    R.pathOr([], ['series', wellId, 'data'], state),
);

export const getSeriesFetchedStatusByWell: Selector<Series[]> = createSelector(
  getSerieState,
  (_, props) => props.wellId,
  (state: SeriesState, wellId: string) =>
    R.pathOr(false, ['series', wellId, 'isPartialData'], state),
);

export const getGroupedSeries: Selector<Series[]> = createSelector(
  getSerieState,
  (_, props) => props,
  (state: SeriesState, props: { subject: string; item: string }) => {
    const series = R.pathOr(
      [],
      ['groupSeries', props.subject, props.item],
      state,
    );
    return series;
  },
);

export default filterActions(SeriesReducer as any, action =>
  action.type.match(filterRegExp),
);
