import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Dialog } from 'modules/ui/models/dialog';
import {
  getIsRightPanelExpanded,
  getRightPanelDialog,
} from 'modules/ui/UIReducer';
import {
  setRightPanelDialog,
  unsetRightPanelDialog,
  expandRightPanel,
  collapseRightPanel,
} from 'modules/ui/UIActions';

const RIGHT_PANEL_DEFAULT_WIDTH = 388;
const RIGHT_PANEL_WIDTHS: Record<Dialog['type'], number> = {
  AllocationIssue: 388,
  CapacityChangeEvent: 388,
  ChartOptions: 388,
  Legend: 388,
  RequestHelp: 450,
  RibbonDetails: 388,
  RibbonEvent: 388,
  Shortcuts: 450,
  VarianceChangeEvent: 388,
  WellNotes: 388,
  WellInfo: 388,
};

const useRightPanel = () => {
  const dispatch = useDispatch();

  const isExpanded: boolean = useSelector(getIsRightPanelExpanded);
  const dialog: Dialog | null = useSelector(getRightPanelDialog);

  // @ts-expect-error
  const { id, index } = dialog?.data || {};
  const dialogId = React.useMemo(() => id, [id]);
  const dialogIndex = React.useMemo(() => index ?? -1, [index]);

  const width = React.useMemo(
    () => RIGHT_PANEL_WIDTHS[String(dialog?.type)] || RIGHT_PANEL_DEFAULT_WIDTH,
    [dialog?.type],
  );

  const expand = React.useCallback(
    () => dispatch(expandRightPanel()),
    [dispatch],
  );

  const collapse = React.useCallback(
    () => dispatch(collapseRightPanel()),
    [dispatch],
  );

  const isDialogOfType = React.useCallback(
    (type: Dialog['type']) => dialog?.type === type,
    [dialog?.type],
  );

  const setDialog = React.useCallback(
    (dialog: Dialog) => dispatch(setRightPanelDialog(dialog)),
    [dispatch],
  );

  const unsetDialog = React.useCallback(
    () => (isExpanded ? dispatch(unsetRightPanelDialog()) : collapse()),
    [dispatch, isExpanded, collapse],
  );

  const unsetDialogOfType = React.useCallback(
    (type: Dialog['type']) => isDialogOfType(type) && unsetDialog(),
    [setDialog, unsetDialog, isDialogOfType],
  );

  const toggleDialog = React.useCallback(
    (dialog: Dialog) =>
      isDialogOfType(dialog.type) ? unsetDialog() : setDialog(dialog),
    [setDialog, unsetDialog, isDialogOfType],
  );

  return {
    dialog,
    dialogId,
    dialogIndex,
    width,
    isExpanded,
    isDialogOfType,
    setDialog,
    unsetDialog,
    unsetDialogOfType,
    toggleDialog,
    expand,
    collapse,
  };
};

export default useRightPanel;
