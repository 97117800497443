import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ShadowDragArea, useShadowDrag } from 'hooks/useShadowDrag';
import { setDraggableColumn } from 'modules/filterLayouts/FilterLayoutsActions';
import { LayoutOption } from 'modules/filterLayouts/models';

interface DataTableHeaderProps {
  colWidths: number[];
  options: LayoutOption[];
  dataTableRef: React.MutableRefObject<HTMLElement | null>;
  height?: number;
  reorderColumns: (dragIndex: number, dropIndex: number) => void;
  scrollX: number;
  scrollTable: (x: number) => void;
  sortDataTableBy: { column: string; order: 'asc' | 'desc' } | null;
  onSortDataTable: (
    data: { column: string; order: 'asc' | 'desc' } | null,
  ) => void;
  isFilterTableOpened: boolean;
  draggableColumn: string;
  onDropIndexChange: (data: {
    indicatorIndex: number;
    shouldDisplayIndicator: boolean;
  }) => void;
}

const DataTableHeader = ({
  colWidths,
  options,
  dataTableRef,
  height = 30,
  scrollX = 0,
  reorderColumns,
  scrollTable,
  sortDataTableBy,
  onSortDataTable,
  isFilterTableOpened,
  draggableColumn,
  onDropIndexChange,
}: DataTableHeaderProps) => {
  const dispatch = useDispatch();

  const sortTable = React.useCallback(
    (columnName, direction) => {
      onSortDataTable({ column: columnName, order: direction });
    },
    [onSortDataTable],
  );

  const onSetDraggableColumn = React.useCallback(
    (columnName: string) => {
      dispatch(setDraggableColumn({ columnName }));
    },
    [dispatch],
  );

  return (
    <DataTableHeader.Container
      height={height}
      isFilterTableOpened={isFilterTableOpened}
    >
      <DataTableHeader.ScrollableContainer
        style={{ left: `${-scrollX}px` }}
        isFilterTableOpened={isFilterTableOpened}
      >
        <DataTableHeader.Scrollable
          colWidths={colWidths.map(w => `${w}px`).join(' ')}
        >
          <ShadowDragArea
            backlightStyle={{ display: 'none' }}
            indicatorStyle={{ display: 'none' }}
            scrollX={scrollX}
            onDropIndexChange={onDropIndexChange}
            onScrollContainer={movementX => scrollTable(scrollX + movementX)}
          >
            {options.map((option, idx) => (
              <HeaderItem
                key={`${option.filterName}-${idx}`}
                order={idx}
                sortTableBy={sortTable}
                title={option.displayName}
                sortDirection={
                  sortDataTableBy?.column === option.id
                    ? sortDataTableBy.order
                    : null
                }
                isDraggingInPanel={option.id === draggableColumn}
                reorderColumns={reorderColumns}
                onSetDraggableColumn={onSetDraggableColumn}
                id={option.id}
              />
            ))}
          </ShadowDragArea>
        </DataTableHeader.Scrollable>
      </DataTableHeader.ScrollableContainer>
    </DataTableHeader.Container>
  );
};

interface HeaderItemProps {
  isDraggingInPanel: boolean;
  order: number;
  sortTableBy: (headerName: string, direction: string | null) => void;
  reorderColumns: (dragIndex: number, dropIndex: number) => void;
  sortDirection: 'asc' | 'desc' | null;
  onSetDraggableColumn: (columnName: string) => void;
  title: string;
  id: string;
}

const HeaderItem = ({
  isDraggingInPanel,
  order,
  reorderColumns,
  sortTableBy,
  title,
  sortDirection,
  onSetDraggableColumn,
  id,
}: HeaderItemProps) => {
  const { draggable, isDragging } = useShadowDrag({
    item: { index: order, id: title },
    end: ({ source, target }) => {
      reorderColumns(source.index, target.index);
      onSetDraggableColumn('');
    },
    start: () => onSetDraggableColumn(id),
  });

  const toggleSortDirection = React.useCallback(() => {
    if (sortDirection === null) sortTableBy(id, 'asc');
    else if (sortDirection === 'asc') sortTableBy(id, 'desc');
    else sortTableBy(id, null);
  }, [id, sortDirection, sortTableBy]);

  return (
    <DataTableHeader.Header
      ref={draggable}
      isDragging={isDragging || isDraggingInPanel}
    >
      <DataTableHeader.ClickableArea onClick={toggleSortDirection}>
        <DataTableHeader.TitleContainer isSorting={sortDirection !== null}>
          <DataTableHeader.Title>{title}</DataTableHeader.Title>
          <DataTableHeader.SortIndicator direction={sortDirection} />
        </DataTableHeader.TitleContainer>

        <DataTableHeader.DraggableSpace>
          <DataTableHeader.DragHandle />
        </DataTableHeader.DraggableSpace>
      </DataTableHeader.ClickableArea>

      <DataTableHeader.Covering isVisible={isDragging} />
    </DataTableHeader.Header>
  );
};

DataTableHeader.Container = styled.div`
  position: absolute;
  border: 1px solid #ccc;
  border-bottom: 0;
  border-top-width: ${({ isFilterTableOpened }) =>
    isFilterTableOpened ? '0px' : '1px'};
  height: ${({ height }) => height}px;
  margin-top: -${({ height }) => height}px;
  width: 100%;
  max-width: 100%;
  box-shadow: ${({ isFilterTableOpened }) =>
    !isFilterTableOpened ? 'inset 0 3px 6px -2px rgb(0 0 0 / 10%)' : ''};
`;

DataTableHeader.ScrollableContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

DataTableHeader.Scrollable = styled.div`
  height: 100%;

  & > div {
    display: grid;
    grid-template-columns: ${({ colWidths }) => colWidths};
    grid-template-rows: 1fr;
    height: 100%;

    > * {
      border-right: 1px solid #ccc;
    }

    > :last-child {
      border-right: 0;
    }
  }
`;

DataTableHeader.Header = styled.div`
  position: relative;
  background-color: ${({ isDragging }) => (isDragging ? '#efefef' : '')};
  border-bottom: 1px solid #ccc;
  height: 100%;
  cursor: pointer;
`;

DataTableHeader.ClickableArea = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: max-content 1fr;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
`;

DataTableHeader.TitleContainer = styled.div`
  display: grid;
  grid-template-columns: max-content ${({ isSorting }) =>
      isSorting ? '15px' : ''};
  gap: 3px;
  align-items: center;
  margin-left: 8px;
`;

DataTableHeader.Title = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
`;

DataTableHeader.SortIndicator = styled.div`
  display: ${({ direction }) => (direction !== null ? 'block' : 'none')};
  transform: rotate(${({ direction }) => (direction === 'asc' ? 0 : 180)}deg);
  background-image: url(${process.env.PUBLIC_URL}/sortIndicator.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
  margin-top: ${({ direction }) => (direction === 'asc' ? 0 : 4)}px;
`;

DataTableHeader.DraggableSpace = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

DataTableHeader.DragHandle = styled.div`
  width: 4px;
  border: 1px solid #ccc;
  border-top: 0;
  border-bottom: 0;
  height: 50%;
  margin-right: 8px;
`;

DataTableHeader.Covering = styled.div`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default DataTableHeader;
