import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';

import * as React from 'react';
import styled from 'styled-components';

import type { Group } from 'modules/well/models/well';

import { DrillDownIcon } from 'components/Icons';
import Button from 'components/Button';

import useMenu from 'hooks/useMenu';

interface FilterMenuType {
  disabled: boolean;
  groupsByOrder: Group[];
  handleFilterGroupSelection: (filter: MouseEvent) => void;
  filterToCurrentItem: () => void;
}

const FilterMenu = ({
  disabled,
  groupsByOrder,
  handleFilterGroupSelection,
  filterToCurrentItem,
}: FilterMenuType) => {
  const menuEl = React.useRef(null);
  const [isMenuOpen, onMenuHover, onMenuLeave, onTargetClick, closeMenu] =
    useMenu(menuEl);

  const handleMenuItemChoose = React.useCallback(
    (e: MouseEvent) => {
      handleFilterGroupSelection(e);
      closeMenu();
    },
    [handleFilterGroupSelection, closeMenu],
  );

  return (
    <div>
      <FilterMenu.MenuButton
        width={30}
        height={26}
        open={isMenuOpen}
        onClick={onTargetClick}
      >
        <DrillDownIcon width={20} height={20} />
      </FilterMenu.MenuButton>
      {isMenuOpen && (
        <FilterMenu.FilterMenuWrapper
          ref={menuEl}
          onMouseLeave={onMenuLeave}
          onMouseEnter={onMenuHover}
        >
          <FilterMenu.StyledPaper>
            <FilterMenu.MenuList>
              <FilterMenu.FilterToCurrentButton
                onClick={filterToCurrentItem}
                disabled={disabled}
              >
                Filter to Marked Row
              </FilterMenu.FilterToCurrentButton>
              <FilterMenu.Label>Filter, then Group By:</FilterMenu.Label>
              {groupsByOrder.map(group => (
                <FilterMenu.MenuItem
                  key={group.key}
                  id={group.key}
                  onClick={handleMenuItemChoose}
                >
                  {group.title}
                </FilterMenu.MenuItem>
              ))}
            </FilterMenu.MenuList>
          </FilterMenu.StyledPaper>
        </FilterMenu.FilterMenuWrapper>
      )}
    </div>
  );
};

FilterMenu.FilterMenuWrapper = styled.div`
  position: absolute;
  top: 136px;
  right: 14px;
  pointer-events: auto;
  z-index: 999;
`;

FilterMenu.FilterToCurrentButton = styled(Button)`
  width: 100%;
  max-width: 160px;
  margin: 0 8px 10px 8px;
`;

FilterMenu.StyledPaper = styled(Paper)`
  box-shadow: none !important;
`;

FilterMenu.Label = styled.span`
  color: #a9a9a9;
  padding: 0 16px;
`;

FilterMenu.MenuList = styled(MenuList)`
  border: 1px solid #3297fd;
  > li:first-child {
    margin-bottom: 10px;
  }
`;

FilterMenu.MenuItem = styled(MenuItem)`
  min-width: 175px;
  &:hover {
    background-color: #3297fd !important;
    color: white;
  }
`;

FilterMenu.MenuButton = styled(Button)`
  border: ${props => {
    return props.open ? '1px solid #3297fd' : '';
  }};
`;

export default FilterMenu;
