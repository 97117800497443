import * as Sentry from '@sentry/browser';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore, Action } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import graphqlClient from 'graphql-middleware/client';
import graphqlMiddleware from 'graphql-middleware/middlewares/graphqlMiddleware';

import { Environment } from 'config/EnvironmentConfig';
import sentryConfig from 'config/SentryConfig';
import loggerMiddleware from './middlewares/loggerMiddleware';
import promiseMiddleware from './middlewares/promiseMiddleware';
import sentryMiddleware from './middlewares/sentryMiddleware';
import heapMiddleware from './middlewares/heapMiddleware';
import newRelicMiddleware from './middlewares/newRelicMiddleware';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const configureStore = (initialState: Record<string, any> = {}) => {
  const history = createBrowserHistory();
  const sagaMiddleware = createSagaMiddleware({
    onError: (error: Record<string, any>) => {
      if (sentryConfig.environment !== Environment.local) {
        Sentry.captureException(error);
      }
      //eslint-disable-next-line no-console
      console.log(error);
    },
  });

  const middlewares = [
    routerMiddleware(history),
    graphqlMiddleware(graphqlClient),
    heapMiddleware,
    newRelicMiddleware,
    thunk,
    sentryMiddleware,
    promiseMiddleware,
    sagaMiddleware,
  ];

  if (typeof jest === 'undefined') {
    middlewares.push(loggerMiddleware);
  }

  const store = createStore(
    rootReducer(history),
    initialState,
    compose(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer: {
        (state: { [x: string]: any } | undefined, action: Action<any>);
      } = require('./rootReducer').default; // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }

  return { history, persistor, store };
};

export default configureStore;
