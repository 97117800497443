import { Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import type { Category } from 'modules/category/models/category';

interface CategoryFormProps {
  categories: { [key: string]: Category };
  currentCategory: Category;
  disabled?: boolean;
  onCategoryChoose: (categoryData: {
    planType: string;
    cause: string;
    subCause: string;
  }) => void;
}

const CategoryForm = ({
  categories,
  currentCategory,
  disabled,
  onCategoryChoose,
}: CategoryFormProps) => {
  const sortedCategories = React.useMemo(() => {
    const categoriesArray: Record<string, any> = Object.values(
      categories,
    ).filter((cat: Category) => cat.cause !== 'Allocation Issue');

    return R.sort((a, b) => {
      if (a.order === b.order) {
        if (a.cause === b.cause) {
          if (a.subCause === b.subCause) {
            return a.planType < b.planType ? -1 : 1;
          }
          return a.subCause < b.subCause ? -1 : 1;
        }
        return a.cause < b.cause ? -1 : 1;
      }
      return a.order < b.order ? -1 : 1;
    }, categoriesArray);
  }, [categories]);

  const causes = React.useMemo(() => {
    const causesWithRepeats = sortedCategories.map(cat => cat.cause);

    return causesWithRepeats.reduce((acc, cause) => {
      if (acc.includes(cause)) return acc;
      acc.push(cause);
      return acc;
    }, []);
  }, [sortedCategories]);

  const getSubCauses = React.useCallback(
    (cause: string) => {
      const filteredCategories = sortedCategories.filter(
        category => category.cause === cause,
      );
      const filteredSubCauses = R.keys(
        // $FlowFixMe
        R.indexBy(R.prop('subCause'), filteredCategories),
      );

      return filteredSubCauses;
    },
    [sortedCategories],
  );

  const getPlanTypes = React.useCallback(
    (cause: string, subCause: string) => {
      const filteredCategories = R.values(sortedCategories).filter(
        category => category.cause === cause && category.subCause === subCause,
      );
      return filteredCategories.map(category => category.planType);
    },
    [sortedCategories],
  );

  const onCauseChange = React.useCallback(
    (e, setFieldValue, field, values) => {
      setFieldValue('subCause', '');
      setFieldValue('type', '');
      setFieldValue('cause', e.target.value);

      const subCauses = getSubCauses(e.target.value);
      setFieldValue('subCause', subCauses[0]);

      const planTypes = getPlanTypes(e.target.value, subCauses[0]);
      setFieldValue('type', planTypes[0]);

      onCategoryChoose({
        planType: planTypes[0],
        subCause: subCauses[0],
        cause: e.target.value,
      });
      e.target.blur();
    },
    [getSubCauses, getPlanTypes, onCategoryChoose],
  );

  const onSubCauseChange = React.useCallback(
    (e, setFieldValue, field, values) => {
      setFieldValue(field.name, e.target.value);

      const planTypes = getPlanTypes(values.cause, e.target.value);
      setFieldValue('type', planTypes[0]);

      onCategoryChoose({
        planType: planTypes[0],
        subCause: e.target.value,
        cause: values.cause,
      });
      e.target.blur();
    },
    [onCategoryChoose, getPlanTypes],
  );

  const onPlanTypeChange = React.useCallback(
    (e, setFieldValue, field, values) => {
      setFieldValue(field.name, e.target.value);
      onCategoryChoose({
        planType: e.target.value,
        subCause: values.subCause,
        cause: values.cause,
      });
      e.target.blur();
    },
    [onCategoryChoose],
  );

  return (
    // @ts-expect-error
    <Formik
      enableReinitialize
      initialValues={{
        type: currentCategory ? currentCategory.planType : '',
        cause: currentCategory ? currentCategory.cause : '',
        subCause: currentCategory ? currentCategory.subCause : '',
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <CategoryForm.Field>
            <CategoryForm.Label>Category</CategoryForm.Label>
            <Field name="cause" id="cause">
              {({ field }) => (
                <CategoryForm.Select
                  disabled={disabled}
                  id={field.id}
                  name={field.name}
                  value={field.value || ''}
                  onChange={e => onCauseChange(e, setFieldValue, field, values)}
                >
                  {causes.map((key, i) => (
                    <option value={key} key={`${key}_${i}`}>
                      {key}
                    </option>
                  ))}
                </CategoryForm.Select>
              )}
            </Field>
          </CategoryForm.Field>

          <CategoryForm.Field>
            <CategoryForm.Label>Sub-Category</CategoryForm.Label>
            <Field name="subCause" id="subCause">
              {({ field }) => (
                <CategoryForm.Select
                  disabled={
                    !values.cause ||
                    getSubCauses(values.cause).length === 1 ||
                    disabled
                  }
                  id={field.id}
                  name={field.name}
                  value={field.value || ''}
                  onChange={e =>
                    onSubCauseChange(e, setFieldValue, field, values)
                  }
                >
                  {values.cause &&
                    getSubCauses(values.cause).map((subCause, i) => (
                      <option value={subCause} key={`${subCause}_${i}`}>
                        {subCause}
                      </option>
                    ))}
                </CategoryForm.Select>
              )}
            </Field>
          </CategoryForm.Field>

          <CategoryForm.Field>
            <CategoryForm.Label>Planned?</CategoryForm.Label>
            <Field name="type" id="type">
              {({ field }) => (
                <CategoryForm.Select
                  id={field.id}
                  name={field.name}
                  value={field.value}
                  disabled={
                    (!values.cause && !values.subCause) ||
                    getPlanTypes(values.cause, values.subCause).length <= 1 ||
                    disabled
                  }
                  onChange={e =>
                    onPlanTypeChange(e, setFieldValue, field, values)
                  }
                >
                  {values.cause &&
                    values.subCause &&
                    getPlanTypes(values.cause, values.subCause).map(
                      (key, i) => (
                        <option value={key} key={`${key}_${i}`}>
                          {key}
                        </option>
                      ),
                    )}
                  {getPlanTypes(values.cause, values.subCause).length === 0 && (
                    <option value="" key="none">
                      Choose category and sub-category
                    </option>
                  )}
                </CategoryForm.Select>
              )}
            </Field>
          </CategoryForm.Field>
        </Form>
      )}
    </Formik>
  );
};

CategoryForm.Field = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }
`;

CategoryForm.Select = styled.select`
  width: 250px;
`;

CategoryForm.Label = styled.p`
  font-size: 14px;
`;

export default CategoryForm;
