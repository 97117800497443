import * as R from 'ramda';

import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

export const getTextMarksIndices = (
  varianceEvents: VarianceEvent[],
  highlightedEvent: { show: boolean; index: number },
  highlightedEventDivider: { show: boolean; index: number },
  varianceDialog: { show: boolean; index: number },
) => {
  const textMarks = {};
  if (
    varianceDialog.show &&
    !R.isNil(varianceDialog.index) &&
    varianceEvents[varianceDialog.index]
  ) {
    textMarks[varianceDialog.index] = 'varianceDialog';
  }
  if (
    highlightedEvent.show &&
    !R.isNil(highlightedEvent.index) &&
    varianceEvents[highlightedEvent.index]
  ) {
    textMarks[highlightedEvent.index] = 'event';
  }
  if (
    highlightedEventDivider.show &&
    !R.isNil(highlightedEventDivider.index) &&
    varianceEvents[highlightedEventDivider.index]
  ) {
    textMarks[highlightedEventDivider.index] = 'eventDivider';
  }

  return textMarks;
};

export default getTextMarksIndices;
