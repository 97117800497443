import * as R from 'ramda';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'store/models';

import { FETCH_ALL } from 'modules/user/UserActions';

import {
  fetchBaseSeriesLayouts,
  fetchUserSeriesLayouts,
  REORDER_SERIES_LAYOUT_LOCALY,
  setUserLayoutsLocally,
} from './SeriesLayoutsActions';
import { getId } from 'modules/auth/AuthReducer';
import { getUserSeriesLayouts } from './SeriesLayoutsReducer';

function* fetchSeriesLayouts(action: Action): Generator<any, any, any> {
  yield put(fetchBaseSeriesLayouts());
  const userId = yield select(getId);
  yield put(fetchUserSeriesLayouts(userId));
}

function* reorderSeriesLayouts(action: Action): Generator<any, any, any> {
  const {
    payload: { dragId, hoverId },
  } = action;
  const userLayouts = yield select(getUserSeriesLayouts);
  const dragLayout = userLayouts[dragId];
  const hoverLayout = userLayouts[hoverId];

  const layouts = R.compose(
    R.assocPath([dragId, 'order'], hoverLayout.order),
    R.assocPath([hoverId, 'order'], dragLayout.order),
  )(userLayouts);
  yield put(setUserLayoutsLocally(layouts));
}

function* seriesLayoutsSagas(): Generator<any, any, any> {
  yield all([takeLatest(`${FETCH_ALL}_SUCCESS`, fetchSeriesLayouts)]);
  yield all([takeLatest(REORDER_SERIES_LAYOUT_LOCALY, reorderSeriesLayouts)]);
}

export default seriesLayoutsSagas;
