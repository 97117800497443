import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { scaleUtc } from 'd3-scale';

import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { stopXAxisDragging } from 'modules/ui/UIActions';
import { changeExtremeDatesByDragging } from 'modules/production/ProductionActions';
import { getExtremeDates } from 'modules/production/ProductionReducer';

import { handleXAxisScaling } from '../utils';
import useChartUIData from './useChartUIData';

const useChartScaling = () => {
  const dispatch = useDispatch();
  const extremeDates = useSelector(getExtremeDates);
  const { chartWidth } = useChartUIData();

  const xScale = React.useMemo(() => {
    const range = [0, chartWidth];
    const domain = [extremeDates.min, extremeDates.max];
    return scaleUtc().range(range).domain(domain);
  }, [chartWidth, extremeDates]);

  const updateUrl = useDebouncedCallback(
    () => dispatch(stopXAxisDragging()),
    [dispatch],
    500,
  );

  const scaleExtremeDates = React.useCallback(
    dates => dispatch(changeExtremeDatesByDragging(dates)),
    [dispatch],
  );

  const onXAxisScaling = React.useCallback(
    (e, svgEl) => {
      handleXAxisScaling(e, svgEl, extremeDates, scaleExtremeDates, xScale);
      updateUrl();
    },
    [xScale, scaleExtremeDates, extremeDates, dispatch],
  );

  return {
    xScale,
    onXAxisScaling,
  };
};

export default useChartScaling;
