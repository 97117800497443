import { utcFormat } from 'd3-time-format';
import { format } from 'd3-format';
import * as React from 'react';
import styled from 'styled-components';

import type { DataSeriesTooltipData } from 'modules/ui/models/ui';

interface InformatioinTooltipProps {
  tooltipData: DataSeriesTooltipData;
}

const DataSeriesInformation = ({ tooltipData }: InformatioinTooltipProps) => {
  const seriesData = tooltipData.data;
  return (
    <>
      <DataSeriesInformation.Date>
        {utcFormat('%m/%d/%Y')(tooltipData.day)}
      </DataSeriesInformation.Date>
      {seriesData.map(series => (
        <>
          <DataSeriesInformation.SeriesName
            color={series.color}
            key={Math.random() + series.name + 'sErIeSnAmE'}
          >
            {`${series.name}:`}
          </DataSeriesInformation.SeriesName>
          <DataSeriesInformation.Value
            color={series.color}
            key={series.name + 'value'}
          >
            {format(',d')(series.value)}
          </DataSeriesInformation.Value>
        </>
      ))}
    </>
  );
};

DataSeriesInformation.Date = styled.span`
  grid-area: 1 / 1 / 2 / 3;
  text-align: center;
`;

DataSeriesInformation.Value = styled.span`
  text-align: right;
  color: ${props => props.color};
`;
DataSeriesInformation.SeriesName = styled.span`
  color: ${props => props.color};
`;

export default DataSeriesInformation;
