import * as R from 'ramda';
import * as React from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { CircleClearIcon, SortIndicator } from 'components/Icons';
import Row from 'components/Row';
import usePrevious from 'hooks/usePrevious';

import {
  getListOfFilterValues,
  getSortedSearchedItems,
} from '../../utils/filter';

import 'react-virtualized/styles.css';
import { useShadowDrag } from 'hooks/useShadowDrag';
import { LayoutOption } from 'modules/filterLayouts/models';
import { setDraggableColumn } from 'modules/filterLayouts/FilterLayoutsActions';

const CELL_HEIGHT = 20;
const UP_KEYCODE = 38;
const DOWN_KEYCODE = 40;

interface RowProps {
  className: string;
  bgColor?: string;
  index: number;
  onRowClick: (data: {
    event: MouseEvent;
    index: number;
    rowData: Record<string, any>;
  }) => void;
  rowData: Record<string, any>;
  style: { [prop: string]: string };
  hasColorEffects?: boolean;
}

type FilterTableProps = {
  addFilter: (filter: { filterName: string; filterValue: string }) => void;
  addSeveralFilters: (filters: {
    filterName: string;
    filterValues: string[];
  }) => void;
  clearFilter: (filter: string) => void;
  counterColWidth: number;
  countedValues: { value: string; quantity: number }[];
  draggableColumn: string;
  filters: string[];
  firstColDataKey: string;
  isActive: boolean;
  order: number;
  onMouseLeaveRow: () => void;
  onMouseEnterRow: React.Dispatch<React.SetStateAction<string>>;
  reorderColumns: (dragIndex: number, dropIndex: number) => void;
  removeFilter: (filter: { filterName: string; filterValue: string }) => void;
  secondColDataKey: string;
  setFilter: (filter: { filterName: string; filterValue: string }) => void;
  searchWord: string;
  filter: LayoutOption;
};

const FilterTable = ({
  addFilter,
  addSeveralFilters,
  countedValues,
  counterColWidth,
  clearFilter,
  draggableColumn,
  filter,
  filters,
  firstColDataKey,
  isActive,
  order,
  onMouseLeaveRow,
  onMouseEnterRow,
  removeFilter,
  reorderColumns,
  secondColDataKey,
  setFilter,
  searchWord,
}: FilterTableProps) => {
  const prevCountedValues = usePrevious(countedValues);
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = React.useState('quantity');
  const [sortDirection, setSortDirection] = React.useState('DESC');
  const { draggable, dragHandle, isDragging } = useShadowDrag({
    item: { id: filter.id, index: order },
    end: ({ source, target }) => {
      reorderColumns(source.index, target.index);
      dispatch(setDraggableColumn({ columnName: '' }));
    },
    start: () => {
      dispatch(setDraggableColumn({ columnName: filter.id }));
    },
  });

  const formattedItems = React.useMemo(
    () =>
      getSortedSearchedItems({
        countedValues,
        searchWord,
        secondColDataKey,
        sortBy,
        sortDirection,
      }),
    [countedValues, searchWord, secondColDataKey, sortBy, sortDirection],
  );
  const hasSelectedRows = React.useMemo(() => filters.length > 0, [filters]);
  const highlightedIndexes = React.useMemo(
    () =>
      hasSelectedRows
        ? filters.map(filterValue =>
            formattedItems.findIndex(
              item => item[firstColDataKey] === filterValue,
            ),
          )
        : [],
    [hasSelectedRows, formattedItems],
  );

  const [lastHighlighted, setLastHighlighted] = React.useState<number | null>(
    null,
  );

  const onClick = React.useCallback(
    ({ event, index, rowData }) => {
      const { shiftKey, ctrlKey, metaKey } = event;
      const filterValue = rowData[firstColDataKey];
      const filterName = filter.filterName;

      const isHighlighted = filters.includes(filterValue);
      if (ctrlKey || metaKey) {
        if (isHighlighted) {
          removeFilter({ filterName, filterValue });
          return;
        }
        addFilter({ filterName, filterValue });
        setLastHighlighted(index);
      } else if (!ctrlKey && !metaKey && !shiftKey) {
        setFilter({ filterName, filterValue });
        setLastHighlighted(index);
      } else if (shiftKey) {
        if (highlightedIndexes.length > 0) {
          const itemsToSelect = getListOfFilterValues({
            lastHighlighted,
            index,
            highlightedIndexes,
            formattedItems,
            firstColDataKey,
          });
          addSeveralFilters({ filterName, filterValues: itemsToSelect });
          setLastHighlighted(index);
        } else if (highlightedIndexes.length === 0) {
          addFilter({ filterName, filterValue });
          setLastHighlighted(index);
        }
      }
    },
    [filter, lastHighlighted, highlightedIndexes],
  );

  const sortTable = React.useCallback(
    ({ sortBy: newSortBy, sortDirection: newSortDirection, event }) => {
      event.stopPropagation();

      if (sortBy === 'value' && newSortBy === 'quantity') {
        setSortBy(newSortBy);
        setSortDirection('DESC');
      } else {
        setSortBy(newSortBy);
        setSortDirection(newSortDirection);
      }
    },
    [sortBy],
  );

  const WellsHeader = React.useMemo(
    () =>
      ({
        dataKey,
        sortBy,
        sortDirection,
      }: {
        dataKey: string;
        sortBy: string;
        sortDirection: string;
      }) => {
        return (
          <FilterTable.WellsHeaderContainer>
            <span>#</span>
            {sortBy === dataKey && (
              <SortIndicator sortDirection={sortDirection} />
            )}
          </FilterTable.WellsHeaderContainer>
        );
      },
    [],
  );

  const TitleHeader = React.useMemo(
    () =>
      ({
        dataKey,
        sortBy,
        sortDirection,
      }: {
        dataKey: string;
        sortBy: string;
        sortDirection: string;
      }) => {
        return (
          <FilterTable.TitleHeaderContainer
            isFiltering={filters.length > 0}
            ref={dragHandle}
          >
            <FilterTable.TitleContainer>
              <span>{filter.displayName}</span>
              {sortBy === dataKey && (
                <SortIndicator sortDirection={sortDirection} />
              )}
            </FilterTable.TitleContainer>

            <FilterTable.ButtonClear
              isFiltering={filters.length > 0}
              onClick={e => {
                e.stopPropagation();
                clearFilter(filter.filterName);
              }}
            >
              <CircleClearIcon />
            </FilterTable.ButtonClear>
          </FilterTable.TitleHeaderContainer>
        );
      },
    [dragHandle, filter, isDragging, filters],
  );

  const rowRenderer = React.useMemo(
    () => (props: RowProps) => {
      const { index, className } = props;
      const isHighlighted = highlightedIndexes.includes(index);
      const newClassName = isHighlighted
        ? className + ' row--highlighted'
        : className;

      return (
        <div
          key={`${filter.filterName}-${index}`}
          style={props.style}
          onMouseOver={event => {
            const span = event.currentTarget.querySelector('span');
            if (!span) return;
            const ellipsisWidth = parseFloat(getComputedStyle(span).width);
            span.style.overflow = 'visible';
            const normalWidth = parseFloat(getComputedStyle(span).width);
            span.style.overflow = 'hidden';

            if (ellipsisWidth - normalWidth < 0) {
              onMouseEnterRow(props.rowData.value);
            }
          }}
          onMouseOut={onMouseLeaveRow}
        >
          <Row
            {...props}
            style={{ ...props.style, position: 'static' }}
            className={newClassName}
            firstColDataKey={firstColDataKey}
            secondColDataKey={secondColDataKey}
            useHTMLTitle={false}
            isHighlighted={isHighlighted}
          />
        </div>
      );
    },
    [filter, highlightedIndexes, firstColDataKey, secondColDataKey],
  );

  const handleKeyPress = React.useCallback(
    (e: KeyboardEvent) => {
      if (
        !isActive ||
        R.isEmpty(formattedItems) ||
        R.isEmpty(highlightedIndexes)
      )
        return;
      const { target } = e;
      const filterName = filter.filterName;

      if (target instanceof HTMLElement) {
        const { tagName } = target;
        if (
          tagName === 'INPUT' ||
          tagName === 'TEXTAREA' ||
          tagName === 'SELECT'
        )
          return;
        const { keyCode } = e;
        if (keyCode === UP_KEYCODE) {
          e.preventDefault();
          const newHighlightedIndex = lastHighlighted
            ? Math.max(lastHighlighted - 1, 0)
            : Math.max(highlightedIndexes[0] - 1, 0);
          const filterValue = formattedItems[newHighlightedIndex].value;
          setFilter({ filterName, filterValue });
          setLastHighlighted(newHighlightedIndex);
        } else if (keyCode === DOWN_KEYCODE) {
          e.preventDefault();
          const lastItemIndex = formattedItems.length - 1;
          const lastHighlightedIndexByOrder = highlightedIndexes.length - 1;
          const newHighlightedIndex = lastHighlighted
            ? Math.min(lastHighlighted + 1, lastItemIndex)
            : Math.min(
                highlightedIndexes[lastHighlightedIndexByOrder] + 1,
                lastItemIndex,
              );
          const filterValue = formattedItems[newHighlightedIndex].value;
          setFilter({ filterName, filterValue });
          setLastHighlighted(newHighlightedIndex);
        }
      }
    },
    [
      filter,
      highlightedIndexes,
      isActive,
      lastHighlighted,
      setFilter,
      formattedItems,
    ],
  );

  const onEmptySpaceClick = React.useCallback(
    e => {
      if (
        filters.length > 0 &&
        e.target.className.includes('ReactVirtualized__Table__Grid')
      )
        clearFilter(filter.filterName);
    },
    [filters, filter, clearFilter],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [
    isActive,
    formattedItems,
    highlightedIndexes,
    lastHighlighted,
    handleKeyPress,
  ]);

  React.useEffect(() => {
    if (
      prevCountedValues &&
      JSON.stringify(prevCountedValues) !== JSON.stringify(countedValues)
    ) {
      setLastHighlighted(null);
    }
  }, [countedValues, prevCountedValues]);

  return (
    <FilterTablePresentation
      counterColWidth={counterColWidth}
      draggable={draggable}
      filterName={filter.filterName}
      firstColDataKey={firstColDataKey}
      formattedItems={formattedItems}
      headerHeight={CELL_HEIGHT + 5}
      isDragging={draggableColumn === filter.id}
      isActive={isActive}
      onEmptySpaceClick={onEmptySpaceClick}
      onRowClick={onClick}
      rowHeight={CELL_HEIGHT}
      rowRenderer={rowRenderer}
      scrollToIndex={highlightedIndexes[0] || lastHighlighted || 0}
      secondColDataKey={secondColDataKey}
      sortBy={sortBy}
      sortDirection={sortDirection}
      sortTable={sortTable}
      title={filter.displayName}
      TitleHeader={TitleHeader}
      WellsHeader={WellsHeader}
    />
  );
};

interface FilterTablePresentationProps {
  draggable: React.MutableRefObject<HTMLElement | null>;
  filterName: string;
  isActive: boolean;
  isDragging: boolean;
  counterColWidth: number;
  rowHeight: number;
  headerHeight: number;
  formattedItems: any[];
  onEmptySpaceClick: (e: MouseEvent) => void;
  sortTable: (data: {
    sortBy: string;
    sortDirection: string;
    event: Event;
  }) => void;
  sortBy: string;
  sortDirection: string;
  onRowClick: (data: { event: Event; index: number; rowData }) => void;
  rowRenderer: (props: RowProps) => JSX.Element;
  scrollToIndex: number;
  title: string;
  firstColDataKey: string;
  secondColDataKey: string;
  TitleHeader: (data: {
    dataKey: string;
    sortBy: string;
    sortDirection: string;
  }) => JSX.Element;
  WellsHeader: (data: {
    dataKey: string;
    sortBy: string;
    sortDirection: string;
  }) => JSX.Element;
}

const FilterTablePresentation = React.memo<FilterTablePresentationProps>(
  ({
    draggable,
    TitleHeader,
    WellsHeader,
    counterColWidth,
    filterName,
    formattedItems,
    firstColDataKey,
    headerHeight,
    isActive,
    isDragging,
    onEmptySpaceClick,
    onRowClick,
    rowHeight,
    rowRenderer,
    scrollToIndex,
    secondColDataKey,
    sortBy,
    sortDirection,
    sortTable,
    title,
  }) => {
    return (
      <FilterTable.DraggableContainer ref={draggable}>
        <FilterTable.Container
          id={filterName}
          className="filter-table"
          isActive={isActive}
          onClick={onEmptySpaceClick}
        >
          <AutoSizer>
            {({ height, width }) => (
              <FilterTable.StyledTable
                counterColWidth={counterColWidth}
                rowStyle={{ alignItems: 'stretch' }}
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                rowCount={formattedItems.length}
                rowGetter={({ index }) => formattedItems[index]}
                height={height - 2}
                width={width - 2}
                sort={sortTable}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onRowClick={onRowClick}
                rowRenderer={rowRenderer}
                scrollToIndex={scrollToIndex}
                isDragging={isDragging}
              >
                <Column
                  label={title}
                  dataKey={firstColDataKey}
                  headerRenderer={TitleHeader}
                  width={0}
                />
                <Column
                  label="#"
                  dataKey={secondColDataKey}
                  headerRenderer={WellsHeader}
                  width={0}
                />
              </FilterTable.StyledTable>
            )}
          </AutoSizer>
        </FilterTable.Container>
      </FilterTable.DraggableContainer>
    );
  },
);

FilterTable.DraggableContainer = styled.div`
  padding: 0 2px;
  flex-grow: 0;
  flex-shrink: 0;

  :first-child {
    padding-left: 4px;
  }
`;

FilterTable.Container = styled.div`
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  border: 1px solid #c1c1c1;
  height: 100%;
`;

FilterTable.StyledTable = styled(Table)`
  user-select: none;
  font-size: 14px;

  & * {
    outline: none;
  }

  & .ReactVirtualized__Table__headerRow {
    position: relative;
    background: ${({ isDragging }) => (isDragging ? '#efefef' : '#fff')};
    padding: 1px 0 1px 5px !important;
    border-bottom: 1px solid #c1c1c1;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: auto min-content;

    > * {
      margin: 0;
    }

    ::after {
      content: '';
      visibility: ${({ isDragging }) => (isDragging ? 'visible' : 'hidden')};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  & .ReactVirtualized__Table__rowSearchResult {
    color: #35995b;
  }

  & .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
    font-family: Lato, sans-serif;
    font-weight: 600;
    gap: 4px;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    > * {
      padding-right: 0 !important;
      > * {
        padding-right: 10px !important;
      }
    }
  }

  & .ReactVirtualized__Table__row {
    font-weight: 400;
    border-bottom: 1px solid #e7e7e7;
    display: grid;
    grid-template-columns: 1fr ${({ counterColWidth }) => counterColWidth}px;

    > * {
      margin: 0;
    }

    > :first-child {
      margin-left: 5px;
    }
  }

  & .ReactVirtualized__Table__rowColumn span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .ReactVirtualized__Table__rowColumn[aria-colindex='2'] {
    color: #909090;
    display: flex;
    justify-content: flex-end;
  }

  & .ReactVirtualized__Table__sortableHeaderIcon {
    flex: 0 0 12px;
    min-height: 15px;
    min-width: 15px;
  }

  & span {
    text-transform: none;
    overflow: hidden;
  }

  & .row--highlighted {
    background-color: #f3f3f3;
    color: #000;
  }
`;

FilterTable.WellsHeaderContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  grid-template-columns: min-content 15px;
  text-transform: capitalize;
  line-height: ${CELL_HEIGHT}px;

  > span {
    color: #909090;
  }
`;

FilterTable.TitleHeaderContainer = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  grid-template-columns: min-content min-content;
  line-height: ${CELL_HEIGHT}px;
`;

FilterTable.TitleContainer = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: max-content 15px;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
`;

FilterTable.ButtonClear = styled.div`
  display: ${({ isFiltering }) => (isFiltering ? 'flex' : 'none')};
  width: 14px;
  color: #c60000;
  margin-right: 3px;
`;

export default React.memo<FilterTableProps>(FilterTable);
