import * as R from 'ramda';
import * as React from 'react';

type useClickOutsideHook = (
  elements: Array<Element>,
  handler: (MouseEvent) => void,
  event: string,
  exceptForClassName: string,
) => void;

const useClickOutside: useClickOutsideHook = (
  elements,
  handler,
  event,
  exceptForClassName,
) => {
  const handlerRef = React.useRef(handler);
  React.useEffect(() => {
    handlerRef.current = handler;
  });

  const listener = React.useCallback(
    (event: MouseEvent) => {
      const target = event.target;

      if (target instanceof HTMLElement || target instanceof Element) {
        if (
          R.isEmpty(elements) ||
          !handlerRef.current ||
          elements.some(element => element.contains(target)) ||
          target.classList.contains(exceptForClassName)
        ) {
          return;
        }
        handlerRef.current(event);
      }
    },
    [elements, exceptForClassName],
  );

  React.useEffect(() => {
    const chartWrapper = document.querySelectorAll('.trellis-chart-wrapper');
    const ribbonChartWrapper = document.querySelectorAll(
      '.ribbon-chart-wrapper, .ribbonInteractiveOverlay',
    );
    const targetArray: Array<Element> = [
      ...chartWrapper,
      ...ribbonChartWrapper,
    ];
    targetArray.forEach(wrapper =>
      //@ts-expect-error
      wrapper.addEventListener(event, listener, true),
    );
    return () => {
      targetArray.forEach(wrapper =>
        //@ts-expect-error
        wrapper.removeEventListener(event, listener, true),
      );
    };
  }, [elements, listener, event]);
};

export default useClickOutside;
