export interface CapacityChangeEvent {
  id: string;
  wellId: string;
  capacityChangeOptionId: string;
  oilRateInit: number;
  oilDeclineInitDailyNom: number;
  oilBFactor: number;
  gasRateInit: number;
  gasDeclineInitDailyNom: number;
  gasBFactor: number;
  waterRateInit: number;
  waterDeclineInitDailyNom: number;
  waterBFactor: number;
  dayInit: Date;
  extraInputsData: Record<string, any> | null;
  oilDeclineMinimum?: number;
  gasDeclineMinimum?: number;
  waterDeclineMinimum?: number;
  notes: string | null;
  syncing?: boolean;
  isLockSlopeCapacityLine: boolean;
}

export interface CreateCapacityChangeEventInput {
  wellId: string;
  oilRateInit: number;
  oilDeclineInitDailyNom: number;
  oilBFactor: number;
  gasRateInit: number;
  gasDeclineInitDailyNom: number;
  gasBFactor: number;
  waterRateInit: number;
  waterDeclineInitDailyNom: number;
  waterBFactor: number;
  dayInit: Date;
  extraInputsData: Record<string, any>;
  oilDeclineMinimum?: number;
  gasDeclineMinimum?: number;
  waterDeclineMinimum?: number;
  isLockSlopeCapacityLine: boolean;
}

export const DEFAULT_BFACTOR = 1.2;
export const DEFAULT_DECLINE_MINIMUM = 6;
