import * as R from 'ramda';
import { all, put, takeLatest, select } from 'redux-saga/effects';

import {
  CREATE_REMOTE_ALLOC_ISSUE,
  DELETE_ALLOC_ISSUE,
  UPDATE_REMOTE_ALLOC_ISSUE,
} from 'modules/allocIssue/AllocIssueActions';
import {
  CREATE_REMOTE_CAPACITY,
  DELETE_REMOTE_CAPACITY,
  UPDATE_REMOTE_CAPACITY,
} from 'modules/capacityChangeEvent/CapacityChangeEventActions';
import { SET_DRILLDOWN_TABLE_PARAMS } from 'modules/drilldownTable/DrilldownTableActions';
import {
  getDrilldownTableParams,
  getSortedGroupedTable,
} from 'modules/drilldownTable/DrilldownTableReducer';
import {
  FETCH_GROUP_FORECAST_DATE_RANGE,
  FETCH_NET_GROUP_FORECAST_DATE_RANGE,
  fetchGroupForecast,
  fetchGroupForecastDateRange,
  fetchNetGroupForecast,
  fetchNetGroupForecastDateRange,
} from 'modules/externalForecast/ExternalForecastActions';
import { getForecastFetchingStatus } from 'modules/externalForecast/ExternalForecastReducer';
import { getFilters } from 'modules/filter/FilterReducer';
import {
  OIL,
  GAS,
  WATER,
  GOR,
  GLR,
  BOE,
  WATERCUT,
  TOTAL_LIQUID,
} from 'modules/phase/models/phase';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import { parseSearchParams } from 'modules/router/utils/router';
import { SET_CURRENT_GROUP, setCurrentGroup } from 'modules/ui/UIActions';
import { getGroupMode, getCurrentGroup } from 'modules/ui/UIReducer';
import {
  CREATE_REMOTE_VARIANCE_EVENT,
  UPDATE_REMOTE_VARIANCE_EVENT,
  DELETE_REMOTE_VARIANCE_EVENT,
} from 'modules/varianceEvent/VarianceEventActions';
import {
  getColumnMapping,
  getColumnMappingBySource,
} from 'modules/well/WellReducer';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';

import {
  clearAllGroupChartsWithExcept,
  FETCH_GROUP_CHART,
  FETCH_GROUP_CHART_DATE_RANGE,
  FETCH_GROUP_VARIANCE_CHART,
  FETCH_GROUP_VARIANCE_CHART_DATE_RANGE,
  FETCH_NET_GROUP_CHART,
  FETCH_NET_GROUP_CHART_DATE_RANGE,
  FETCH_NET_GROUP_VARIANCE_CHART,
  FETCH_NET_GROUP_VARIANCE_CHART_DATE_RANGE,
  fetchGroupChart,
  fetchGroupChartDateRange,
  fetchGroupVarianceChart,
  fetchGroupVarianceChartDateRange,
  fetchNetGroupChart,
  fetchNetGroupChartDateRange,
  fetchNetGroupVarianceChart,
  fetchNetGroupVarianceChartDateRange,
  populateGroupChart,
  populateGroupVarianceChart,
} from './GroupChartActions';
import { getGroupChart, getGroupVarianceChart } from './GroupChartReducer';

import {
  normalizeGroupChartData,
  normalizeGroupVarianceChartData,
} from './utils';
import { getCustomOptionsList } from 'modules/filterLayouts/FilterLayoutsReducer';
import { normalizeFilters } from './utils/normalizeFilters';

function* initGroupChartFetchSaga(action): Generator<any, any, any> {
  const { payload } = action;
  const groupMode = yield select(getGroupMode);
  if (!groupMode.isOn) return;
  const columnMapping = yield select(getColumnMappingBySource);
  if (!columnMapping || R.isEmpty(columnMapping)) return;
  const drilldownTableParams = yield select(getDrilldownTableParams, null);
  const grossNet = payload.grossNet
    ? payload.grossNet
    : drilldownTableParams.grossNet;
  const filters = yield select(getFilters);
  const customOptions = yield select(getCustomOptionsList);

  const normalizedFilters = normalizeFilters(
    filters,
    columnMapping,
    customOptions,
  );

  const currentGroup = yield select(getCurrentGroup);

  const subject = !R.isNil(payload.subject)
    ? payload.subject
    : currentGroup.subject;
  const item = !R.isNil(payload.item)
    ? payload.item === 'empty'
      ? ''
      : payload.item
    : currentGroup.item;

  if (R.isNil(subject) || R.isNil(item)) return;

  const groupedTable = yield select(getSortedGroupedTable, { subject });
  const isWellGroupExist = groupedTable.some(group => {
    return R.pathOr('', [subject], group) === item;
  });
  if (!isWellGroupExist && !R.isEmpty(groupedTable)) {
    yield put(setCurrentGroup({ subject, item: groupedTable[0][subject] }));
    return;
  }

  const column = columnMapping[subject];
  const readyChart = yield select(getGroupChart, { subject, item });

  const needsFetching = R.isEmpty(readyChart) || R.isEmpty(readyChart[OIL]);
  if (!needsFetching) return;

  const extemeDates = yield select(getExtremeDates);
  const searchString = window.location.search;
  const routeSearchParams = parseSearchParams(searchString);
  const dateSource = R.isNil(extemeDates) ? routeSearchParams : extemeDates;

  const group =
    !!column && !R.isEmpty(column)
      ? { name: item, columnIndex: column.columnIndex }
      : null;
  if (grossNet === 'Gross') {
    yield put(
      fetchGroupChartDateRange({
        group,
        filters: normalizedFilters,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
      }),
    );
    yield put(
      fetchGroupVarianceChartDateRange({
        group,
        filters: normalizedFilters,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
      }),
    );
    yield put(
      fetchGroupForecastDateRange({
        group,
        filters: normalizedFilters,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
      }),
    );
  } else {
    yield put(
      fetchNetGroupChartDateRange({
        group,
        filters: normalizedFilters,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
      }),
    );

    yield put(
      fetchNetGroupVarianceChartDateRange({
        group,
        filters: normalizedFilters,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
      }),
    );
    yield put(
      fetchNetGroupForecastDateRange({
        group,
        filters: normalizedFilters,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
      }),
    );
  }

  return;
}

function* clearGroupChartSaga(action): Generator<any, any, any> {
  yield put(clearAllGroupChartsWithExcept());
}

function* normalizeGroupChartSaga(action): Generator<any, any, any> {
  const { groupChartData } = getGraphqlPayload(action);
  const chartData = JSON.parse(groupChartData);
  const prevActionPayload = getGraphqlPrevActionVariables(action);
  const isNet =
    action.type === `${FETCH_NET_GROUP_CHART}_SUCCESS` ||
    action.type === `${FETCH_NET_GROUP_CHART_DATE_RANGE}_SUCCESS`;
  const {
    payload: { group, minDate, maxDate },
  } = prevActionPayload;
  const columnMapping = yield select(getColumnMapping);
  const subject = group
    ? columnMapping.find(column => column.columnIndex === group.columnIndex)
        .sourceName
    : 'all';
  const groupName = group ? group.name : 'all';
  const normalizedData = normalizeGroupChartData(chartData, {
    min: minDate,
    max: maxDate,
  });
  const existingData = yield select(getGroupChart, {
    subject,
    item: groupName,
  });
  const phasesList = [OIL, GAS, WATER, BOE, GOR, GLR, WATERCUT, TOTAL_LIQUID];

  const mergedData = phasesList.reduce((acc, phase) => {
    if (existingData[phase]) {
      const withRepeats = existingData[phase].concat(normalizedData[phase]);
      const indexed = R.indexBy(item => item.day.toISOString(), withRepeats);
      const withoutRepeats = R.values(indexed);

      acc[phase] = R.sort(
        (a, b) => a.day.getTime() - b.day.getTime(),
        withoutRepeats,
      );
    } else {
      acc[phase] = normalizedData[phase];
    }

    return acc;
  }, {});
  yield put(
    populateGroupChart({
      groupSubject: subject,
      groupName: groupName,
      chart: mergedData,
      isNet,
    }),
  );
}

function* normalizeGroupVarianceChartSaga(action): Generator<any, any, any> {
  const { groupChartData } = getGraphqlPayload(action);
  const chartData = JSON.parse(groupChartData);
  const prevActionPayload = getGraphqlPrevActionVariables(action);
  const isNet =
    action.type === `${FETCH_NET_GROUP_VARIANCE_CHART}_SUCCESS` ||
    action.type === `${FETCH_NET_GROUP_VARIANCE_CHART_DATE_RANGE}_SUCCESS`;
  const {
    payload: { group, minDate, maxDate },
  } = prevActionPayload;
  const columnMapping = yield select(getColumnMapping);
  const subject = group
    ? columnMapping.find(column => column.columnIndex === group.columnIndex)
        .sourceName
    : 'all';
  const groupName = group ? group.name : 'all';
  const normalizedData = normalizeGroupVarianceChartData(chartData, {
    min: minDate,
    max: maxDate,
  });
  yield put(
    populateGroupVarianceChart({
      groupSubject: subject,
      groupName: groupName,
      chart: normalizedData,
      isNet,
    }),
  );
}
function* initAllDataFetchSaga(action): Generator<any, any, any> {
  const prevActionPayload = getGraphqlPrevActionVariables(action);

  const {
    payload: { group, filters },
  } = prevActionPayload;
  const columnMapping = yield select(getColumnMapping);
  const subject = group
    ? columnMapping.find(column => column.columnIndex === group.columnIndex)
        .sourceName
    : 'all';
  const groupName = group ? group.name : 'all';
  const type = action.type;
  const chartPartial = yield select(getGroupChart, {
    subject,
    item: groupName,
  });
  const variancePartial = yield select(getGroupVarianceChart, {
    subject,
    item: groupName,
  });
  const forecastFetchigStatus = yield select(getForecastFetchingStatus);

  if (
    (type === `${FETCH_GROUP_CHART_DATE_RANGE}_SUCCESS` ||
      !R.isEmpty(chartPartial)) &&
    (type === `${FETCH_GROUP_VARIANCE_CHART_DATE_RANGE}_SUCCESS` ||
      !R.isEmpty(variancePartial)) &&
    (type === `${FETCH_GROUP_FORECAST_DATE_RANGE}_SUCCESS` ||
      forecastFetchigStatus)
  ) {
    yield put(
      fetchGroupChart({
        group,
        filters: { ...filters },
      }),
    );
    yield put(
      fetchGroupVarianceChart({
        group,
        filters: { ...filters },
      }),
    );
    yield put(
      fetchGroupForecast({
        group,
        filters,
      }),
    );
    return;
  }
  if (
    (type === `${FETCH_NET_GROUP_CHART_DATE_RANGE}_SUCCESS` ||
      !R.isEmpty(chartPartial)) &&
    (type === `${FETCH_NET_GROUP_VARIANCE_CHART_DATE_RANGE}_SUCCESS` ||
      !R.isEmpty(variancePartial)) &&
    (type === `${FETCH_NET_GROUP_FORECAST_DATE_RANGE}_SUCCESS` ||
      forecastFetchigStatus)
  ) {
    yield put(
      fetchNetGroupChart({
        group,
        filters,
      }),
    );
    yield put(
      fetchNetGroupVarianceChart({
        group,
        filters,
      }),
    );
    yield put(
      fetchNetGroupForecast({
        group,
        filters,
      }),
    );
    return;
  }
}

function* groupChartSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      [
        `${FETCH_GROUP_VARIANCE_CHART_DATE_RANGE}_SUCCESS`,
        `${FETCH_GROUP_CHART_DATE_RANGE}_SUCCESS`,
        `${FETCH_GROUP_FORECAST_DATE_RANGE}_SUCCESS`,
        `${FETCH_NET_GROUP_VARIANCE_CHART_DATE_RANGE}_SUCCESS`,
        `${FETCH_NET_GROUP_CHART_DATE_RANGE}_SUCCESS`,
        `${FETCH_NET_GROUP_FORECAST_DATE_RANGE}_SUCCESS`,
      ],
      initAllDataFetchSaga,
    ),
    takeLatest(
      [
        `${FETCH_GROUP_CHART}_SUCCESS`,
        `${FETCH_NET_GROUP_CHART}_SUCCESS`,
        `${FETCH_GROUP_CHART_DATE_RANGE}_SUCCESS`,
        `${FETCH_NET_GROUP_CHART_DATE_RANGE}_SUCCESS`,
      ],
      normalizeGroupChartSaga,
    ),
    takeLatest(
      [
        `${FETCH_GROUP_VARIANCE_CHART}_SUCCESS`,
        `${FETCH_NET_GROUP_VARIANCE_CHART}_SUCCESS`,
        `${FETCH_GROUP_VARIANCE_CHART_DATE_RANGE}_SUCCESS`,
        `${FETCH_NET_GROUP_VARIANCE_CHART_DATE_RANGE}_SUCCESS`,
      ],
      normalizeGroupVarianceChartSaga,
    ),
    takeLatest(
      [SET_CURRENT_GROUP, SET_DRILLDOWN_TABLE_PARAMS],
      initGroupChartFetchSaga,
    ),
    takeLatest(
      [
        CREATE_REMOTE_CAPACITY,
        DELETE_REMOTE_CAPACITY,
        UPDATE_REMOTE_CAPACITY,
        CREATE_REMOTE_VARIANCE_EVENT,
        UPDATE_REMOTE_VARIANCE_EVENT,
        DELETE_REMOTE_VARIANCE_EVENT,
        CREATE_REMOTE_ALLOC_ISSUE,
        DELETE_ALLOC_ISSUE,
        UPDATE_REMOTE_ALLOC_ISSUE,
      ],
      clearGroupChartSaga,
    ),
  ]);
}

export default groupChartSagas;
