import MenuItem from '@material-ui/core/MenuItem';
import { utcDay } from 'd3-time';
import { Field, Form, Formik } from 'formik';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import AlertWindow from 'components/AlertWindow';
import Button from 'components/Button';
import InputCheckbox from 'components/InputCheckbox';
import CloseOnMouseClickOutside from 'components/CloseOnMouseClickOutside';
import DateInput from 'components/DateInput';

import ResizableGrid from 'components/ResizableGrid';
import { getToday, getFeatures } from 'modules/appConfig/AppConfigReducer';
import { getPermissions } from 'modules/auth/AuthReducer';
import ExtraInputs from 'modules/category/forms/ExtraInputs';
import RightPanel from 'modules/dashboard/components/RightPanel';
import EventNoteThread from 'modules/eventNotes/containers/EventNoteThread';
import {
  getCurrentRibbonOptions,
  getRibbonOptions,
  getEventsOfSelectedRibbons,
  getRibbonEvents,
  getRibbonById,
} from 'modules/ribbon/RibbonReducer';
import {
  closeRibbonEventAlertWindow,
  openRibbonEventAlertWindow,
} from 'modules/ui/UIActions';
import {
  getCurrentWellId,
  getIsEventConversationOpen,
  getNoteImageDetailsOverlay,
  getRibbonEventAlertWindow,
  getWasDragging,
} from 'modules/ui/UIReducer';
import useRightPanel from 'modules/ui/hooks/useRightPanel';

import { getRibbonExtremeDates } from 'helpers';
import { useNonInputKeydown } from 'hooks/useKeydown';
import { getLastWellEventsNotes } from 'modules/eventNotes/EventNotesReducer';
import { usePreventContext } from 'context/PreventContext';

import { RibbonEvent } from '../models';
import useRibbonEvent from '../hooks/useRibbonEvent';
import ModalWindow from 'components/ModalWindow';

const EditRibbonEventPanel = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const {
    deleteRibbonEvent,
    updateRibbonEventDates,
    updateRibbonEventOption,
    updateRibbonEventNoEndDate,
    updateRibbonEventExtraInputs,
  } = useRibbonEvent();

  const [hasUnsavedNotes, setHasUnsavedNotes] = React.useState(false);
  const isEventConversationOpen = useSelector(getIsEventConversationOpen);
  const today = useSelector(getToday);
  const ribbonOptions = useSelector(getRibbonOptions);
  const allRibbonEvents = useSelector(getRibbonEvents);
  const ribbonEvents = useSelector(getEventsOfSelectedRibbons);
  const isPossibleEditRibbonEvent =
    useSelector(getPermissions).isAllowedEditRibbonEvents;
  const devFeatures = useSelector(getFeatures);
  const ribbonAlertWindow = useSelector(getRibbonEventAlertWindow);
  const currentRibbonEvent = allRibbonEvents[rightPanel.dialogId] || {};
  const ribbon = useSelector(state =>
    getRibbonById(state, currentRibbonEvent.ribbonId),
  );
  const imageDetailsOverlay = useSelector(getNoteImageDetailsOverlay);
  const chartWasDragging = useSelector(getWasDragging);
  const currentRibbonEvents = React.useMemo(
    () => ribbonEvents[ribbon.id],
    [ribbonEvents, ribbon],
  );
  const currentWellId = useSelector(getCurrentWellId);
  const eventNotes = useSelector(store =>
    getLastWellEventsNotes(store, {
      wellId: currentWellId,
      eventType: 'ribbon',
    }),
  );
  const prevent = usePreventContext();

  const currentOption = ribbonOptions[currentRibbonEvent.ribbonOptionId] || {};
  const endDate = currentRibbonEvent.dayEnd ? currentRibbonEvent.dayEnd : today;
  const currentRibbonOption = useSelector(state =>
    getCurrentRibbonOptions(state, currentRibbonEvent.ribbonId),
  );
  const sortedRibbonOption = React.useMemo(
    () => currentRibbonOption.sort((a, b) => a.order - b.order),
    [currentRibbonOption],
  );
  const shouldDisplayExtraInputs = React.useMemo(
    () =>
      devFeatures?.customInputs &&
      currentOption?.extraInputsType &&
      currentOption?.extraInputsType.length !== 0,
    [devFeatures?.customInputs, currentOption?.extraInputsType],
  );

  const onRibbonEventDialogClose = React.useCallback(() => {
    if (!currentRibbonEvent.id) return;

    prevent.dispatchEvent(() => rightPanel.unsetDialogOfType('RibbonEvent'));
  }, [
    rightPanel.unsetDialogOfType,
    currentRibbonEvent.id,
    prevent.dispatchEvent,
  ]);

  const onRibbonAlertWindowClose = React.useCallback(
    () => dispatch(closeRibbonEventAlertWindow()),
    [dispatch],
  );

  const onDeleteRibbonEvent = React.useCallback(() => {
    const ribbonEvent = allRibbonEvents[ribbonAlertWindow.index];
    if (ribbonEvent) deleteRibbonEvent(ribbonEvent.id);
    onRibbonAlertWindowClose();
  }, [
    onRibbonAlertWindowClose,
    allRibbonEvents,
    ribbonAlertWindow,
    deleteRibbonEvent,
  ]);

  const onDeleteClick = React.useCallback(() => {
    isPossibleEditRibbonEvent &&
      dispatch(openRibbonEventAlertWindow(rightPanel.dialogId));
  }, [dispatch, isPossibleEditRibbonEvent, rightPanel.dialogId]);

  const onRibbonEventDatesUpdate = React.useCallback(
    (day, newDate) => {
      const ribbonEventId = currentRibbonEvent.id;
      const start = day === 'dayStart' ? newDate : currentRibbonEvent.dayStart;
      const end = day === 'dayEnd' ? newDate : currentRibbonEvent.dayEnd;
      const dates = [start, end];
      updateRibbonEventDates({ ribbonEventId, dates });
    },
    [currentRibbonEvent, updateRibbonEventDates],
  );

  const onExtraInputsChange = React.useCallback(
    extraInputsData => {
      const ribbonEventId = currentRibbonEvent.id;
      const payload = { ribbonEventId, extraInputsData };
      updateRibbonEventExtraInputs(payload);
    },
    [currentRibbonEvent.id],
  );

  const minMax = getRibbonExtremeDates(today);

  const onOptionChange = React.useCallback(
    (e, field, values, setFieldValue) => {
      setFieldValue('type', e.target.value);
      const ribbonOptionId = e.target.value;
      const ribbonEventId = currentRibbonEvent.id;
      updateRibbonEventOption({ ribbonEventId, ribbonOptionId });
      e.target.blur();
    },
    [currentRibbonEvent.id, updateRibbonEventOption],
  );

  const onNoEndDateCheck = React.useCallback(
    ({ ribbonEventId, noEndDate }) => {
      if (noEndDate) {
        const dates = [currentRibbonEvent.dayStart, utcDay.offset(today, -1)];
        updateRibbonEventDates({ ribbonEventId, dates });
      }
      updateRibbonEventNoEndDate({ ribbonEventId, noEndDate });
    },
    [
      currentRibbonEvent,
      today,
      updateRibbonEventDates,
      updateRibbonEventNoEndDate,
    ],
  );

  const tempSubmit = value => value;

  const sortedRibbonEvents: RibbonEvent[] = React.useMemo(() => {
    if (currentRibbonEvents) {
      return R.clone(currentRibbonEvents).sort((a, b) =>
        utcDay.count(a.dayStart, b.dayStart),
      );
    }
    return [];
  }, [currentRibbonEvents]);

  const currentEventIndex = React.useMemo(
    () =>
      sortedRibbonEvents.findIndex(event => event.id === currentRibbonEvent.id),
    [sortedRibbonEvents, currentRibbonEvent.id],
  );

  const isShownLeftArrow = React.useMemo(
    () =>
      currentEventIndex >= 0 &&
      sortedRibbonEvents.length - 1 > currentEventIndex,
    [sortedRibbonEvents, currentEventIndex],
  );

  const isShownRightArrow = React.useMemo(
    () => currentEventIndex > 0,
    [currentEventIndex],
  );

  const notesCount = React.useMemo(() => {
    if (
      R.isEmpty(currentRibbonEvent) ||
      eventNotes.length === 0 ||
      !eventNotes[currentRibbonEvent.id]
    )
      return 0;

    return R.values(eventNotes[currentRibbonEvent.id]).length;
  }, [currentRibbonEvent, eventNotes]);

  const notesSubtitle = React.useMemo(() => {
    if (notesCount === 0) return '';
    return `${notesCount} Note${notesCount === 1 ? '' : 's'}`;
  }, [notesCount]);

  const onLeftArrowClick = React.useCallback(() => {
    if (isShownLeftArrow) {
      const prevIndex = currentEventIndex + 1;
      const prevEvent = sortedRibbonEvents[prevIndex];
      rightPanel.setDialog({
        type: 'RibbonEvent',
        data: { id: prevEvent.id },
      });
    }
  }, [isShownLeftArrow, currentEventIndex, sortedRibbonEvents, dispatch]);
  const onRightArrowClick = React.useCallback(() => {
    if (isShownRightArrow) {
      const nextIndex = currentEventIndex - 1;
      const nextEvent = sortedRibbonEvents[nextIndex];
      rightPanel.setDialog({
        type: 'RibbonEvent',
        data: { id: nextEvent.id },
      });
    }
  }, [isShownRightArrow, currentEventIndex, dispatch, sortedRibbonEvents]);

  const handleClickOutside = React.useCallback(() => {
    if (document.activeElement?.tagName !== 'TEXTAREA' && !chartWasDragging) {
      onRibbonEventDialogClose();
    }
  }, [chartWasDragging, onRibbonEventDialogClose]);

  const onNoteSaveStateChange = React.useCallback(
    (state: boolean) => {
      setHasUnsavedNotes(state);
    },
    [setHasUnsavedNotes],
  );

  React.useEffect(() => {
    if (
      !prevent.event ||
      hasUnsavedNotes ||
      !rightPanel.isDialogOfType('RibbonEvent')
    )
      return;

    if (!hasUnsavedNotes) {
      prevent.event.proceed();
    }
  }, [prevent.event]);

  useNonInputKeydown(
    ({ keyName }) => {
      if (imageDetailsOverlay.show) return;
      if (!rightPanel.isDialogOfType('RibbonEvent')) return;
      if (keyName === 'ESCAPE') return onRibbonEventDialogClose();
      if (keyName === 'DELETE') return onDeleteClick();
      if (keyName === 'RIGHT') return onRightArrowClick();
      if (keyName === 'LEFT') return onLeftArrowClick();
    },
    [
      rightPanel.isDialogOfType,
      onRibbonEventDialogClose,
      onDeleteClick,
      imageDetailsOverlay,
      onLeftArrowClick,
      onRightArrowClick,
    ],
  );

  if (!rightPanel.isDialogOfType('RibbonEvent')) return null;

  return (
    <RightPanel
      isShown
      onDialogClose={onRibbonEventDialogClose}
      title={`${R.pathOr('', ['name'], ribbon)} Event Details`}
      isShownLeftArrow={isShownLeftArrow}
      isShownRightArrow={isShownRightArrow}
      onLeftArrowClick={onLeftArrowClick}
      onRightArrowClick={onRightArrowClick}
    >
      {!R.isEmpty(currentRibbonEvent) && (
        <CloseOnMouseClickOutside
          exceptForClassName="ribbon-event-interactive"
          closeHandler={handleClickOutside}
          event="mouseup"
        >
          <ResizableGrid
            initialTemplate={
              isEventConversationOpen
                ? ['32px', 'calc(100% - 32px)']
                : ['235px', 'calc(100% - 235px)']
            }
          >
            <ResizableGrid.Cell
              title="Event Inputs"
              subtitle={currentOption.type ?? ''}
              headerBorders="bottom"
            >
              <EditRibbonEventPanel.EventInputsContainer>
                <EditRibbonEventPanel.EventInputSection>
                  <EditRibbonEventPanel.EventInputContent>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        type: currentOption ? currentOption.id : '',
                      }}
                      onSubmit={tempSubmit}
                    >
                      {({ setFieldValue, values }) => (
                        <Form>
                          <EditRibbonEventPanel.EventInput>
                            <EditRibbonEventPanel.Label>
                              Option
                            </EditRibbonEventPanel.Label>

                            <Field name="type" id="type">
                              {({ field }) => (
                                <EditRibbonEventPanel.Select
                                  disabled={
                                    !isPossibleEditRibbonEvent ||
                                    ribbon.readOnly
                                  }
                                  id={field.id}
                                  name={field.name}
                                  value={field.value}
                                  onChange={e =>
                                    onOptionChange(
                                      e,
                                      field,
                                      values,
                                      setFieldValue,
                                    )
                                  }
                                >
                                  {sortedRibbonOption.map(option => (
                                    <option value={option.id} key={option.id}>
                                      {option.type}
                                    </option>
                                  ))}
                                </EditRibbonEventPanel.Select>
                              )}
                            </Field>
                          </EditRibbonEventPanel.EventInput>
                        </Form>
                      )}
                    </Formik>

                    <EditRibbonEventPanel.EventInput>
                      <EditRibbonEventPanel.Label>
                        Date Range
                      </EditRibbonEventPanel.Label>

                      <form>
                        <EditRibbonEventPanel.DateInputWrapper>
                          <DateInput
                            disabled={
                              !isPossibleEditRibbonEvent || ribbon.readOnly
                            }
                            id="dayStart"
                            name="dayStart"
                            date={currentRibbonEvent.dayStart}
                            min={minMax[0]}
                            max={endDate}
                            onProcess={newDate =>
                              onRibbonEventDatesUpdate('dayStart', newDate)
                            }
                            transparent
                          />

                          {!currentRibbonEvent.noEndDate && (
                            <>
                              <EditRibbonEventPanel.TextDash />

                              <DateInput
                                disabled={
                                  !isPossibleEditRibbonEvent ||
                                  currentRibbonEvent.noEndDate ||
                                  ribbon.readOnly
                                }
                                id="dayEnd"
                                name="dayEnd"
                                date={endDate}
                                min={currentRibbonEvent.dayStart}
                                max={minMax[1]}
                                onProcess={newDate =>
                                  onRibbonEventDatesUpdate('dayEnd', newDate)
                                }
                                transparent
                              />
                            </>
                          )}
                        </EditRibbonEventPanel.DateInputWrapper>
                      </form>
                    </EditRibbonEventPanel.EventInput>
                  </EditRibbonEventPanel.EventInputContent>

                  <EditRibbonEventPanel.BtnWrapper>
                    <InputCheckbox
                      marginBottom={0}
                      label="Ribbon Event Has No End Date"
                      aria-label={currentRibbonEvent.id}
                      width="220px"
                      disabled={ribbon.readOnly}
                      checked={!!currentRibbonEvent.noEndDate}
                      onChange={e =>
                        onNoEndDateCheck({
                          ribbonEventId: currentRibbonEvent.id,
                          noEndDate: e.target.checked,
                        })
                      }
                    />

                    <Button
                      width={80}
                      height={30}
                      variant="critical"
                      onClick={() => onDeleteClick()}
                      className="ribbon-interactive interactive"
                      disabled={!isPossibleEditRibbonEvent || ribbon.readOnly}
                    >
                      Delete
                    </Button>
                  </EditRibbonEventPanel.BtnWrapper>
                </EditRibbonEventPanel.EventInputSection>

                {shouldDisplayExtraInputs && (
                  <EditRibbonEventPanel.EventInputSection>
                    <EditRibbonEventPanel.EventInputTitle>
                      Event Attributes
                    </EditRibbonEventPanel.EventInputTitle>

                    <Formik
                      enableReinitialize
                      initialValues={{
                        eventId: currentRibbonEvent
                          ? currentRibbonEvent.id
                          : '',
                      }}
                      onSubmit={tempSubmit}
                    >
                      {({ setFieldValue, values }) => (
                        <Form className="test">
                          <EditRibbonEventPanel.ExtraInputsContainer>
                            <ExtraInputs
                              inputs={currentOption.extraInputsType}
                              data={currentRibbonEvent.extraInputsData}
                              onSubmit={onExtraInputsChange}
                              disabled={!isPossibleEditRibbonEvent}
                            />
                          </EditRibbonEventPanel.ExtraInputsContainer>
                        </Form>
                      )}
                    </Formik>
                  </EditRibbonEventPanel.EventInputSection>
                )}
              </EditRibbonEventPanel.EventInputsContainer>
            </ResizableGrid.Cell>

            <ResizableGrid.Cell title="Conversation" subtitle={notesSubtitle}>
              <EventNoteThread
                wellId={currentRibbonEvent.wellId}
                eventType="ribbon"
                eventId={currentRibbonEvent.id}
                noAnyMessages={notesCount === 0}
                onNoteSaveStateChange={onNoteSaveStateChange}
              />
            </ResizableGrid.Cell>
          </ResizableGrid>
        </CloseOnMouseClickOutside>
      )}
      {ribbonAlertWindow.show && (
        <AlertWindow
          handleClose={onRibbonAlertWindowClose}
          onDelete={onDeleteRibbonEvent}
          subject="ribbon event"
        />
      )}

      {prevent.event && hasUnsavedNotes && (
        <ModalWindow
          title="Are you sure you want to leave without saving your note?"
          close={prevent.event.cancel}
          style={{
            width: window.innerWidth - rightPanel.width - 10,
          }}
          backdropStyle={{
            width: window.innerWidth - rightPanel.width - 10,
          }}
          controls={[
            {
              text: 'Yes, discard the note',
              action: prevent.event.proceed,
              style: {
                width: 150,
              },
              type: 'danger',
            },
            {
              text: 'No, continue editing',
              action: prevent.event.cancel,
              style: {
                width: 150,
              },
            },
          ]}
        />
      )}
    </RightPanel>
  );
};

EditRibbonEventPanel.BtnWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

EditRibbonEventPanel.ExtraInputsContainer = styled.div`
  &&& > :first-child {
    max-height: initial;
  }
`;

EditRibbonEventPanel.Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  margin-bottom: 10px;
`;

EditRibbonEventPanel.Select = styled.select`
  width: 250px;

  :disabled {
    color: #a9a9a9;
  }
`;

EditRibbonEventPanel.Label = styled.p`
  font-size: 14px;
`;

EditRibbonEventPanel.DateInputWrapper = styled.div`
  height: 30px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid #c1c1c1;
  padding: 0;
`;

EditRibbonEventPanel.MenuItem = styled(MenuItem)`
  padding: 0 !important;
  height: 30px !important;
  min-height: 30px !important;
  width: 100% !important;
  margin: 10px 0 0 0 !important;
`;

EditRibbonEventPanel.TextDash = styled.div`
  width: 5px;
  height: 1px;
  background-color: ${(props: Record<string, any>) =>
    props.theme.colors.primaryText};
`;

EditRibbonEventPanel.EventInputsContainer = styled.div`
  padding: 10px 14px 0 14px;
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

EditRibbonEventPanel.EventInputSection = styled.div``;

EditRibbonEventPanel.EventInputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

EditRibbonEventPanel.EventInputTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
`;

EditRibbonEventPanel.EventInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default EditRibbonEventPanel;
