import React, { useState, useEffect, useRef } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  getActiveFilter,
  getAvailablePagesCount,
  getCurrentPage,
  getFetchingState,
  getFilteredLatestEventNotes,
  getPaginatedLatestEventNotes,
  getSelectedNote,
} from '../InboxConversationReducer';
import {
  LatestEventNote,
  NoteActionButtons,
  NoteFilter,
  EMPTY_INBOX_MESSAGE,
} from '../models';
import {
  initBackgroundLatestEventNotesFetching,
  initLatestEventNotesFetching,
  refetchEventNotes,
  setActiveFilter,
  resetState,
  getLatestUnreadNotes,
  goToPage,
} from '../InboxConversationActions';
import { EventNote } from '../components/EventNote';
import { CircularProgress } from '@material-ui/core';
import { FaInbox, FaMinus } from 'react-icons/fa';

import Button from 'components/Button';
import {
  Refresh,
  Archive,
  MoveToInbox,
  OpenEnvelope,
  CloseEnvelope,
} from 'components/Icons';
import { getUsersByEmailName } from 'modules/user/UserReducer';
import InputCheckbox from 'components/InputCheckbox';
import NoSelectedItemsMessage from 'components/NoSelectedItemsMessage/NoSelectedItemsMessage';
import { getAppConfig } from '../../appConfig/AppConfigReducer';
import type { ActionButton } from '../models';
import { useConversation } from '../utils/useConversation';
import { areNotesEqual } from '../utils';
import usePrevious from 'hooks/usePrevious';
import { PageCounter } from '../components/PageCounter';
import useComponentSize from 'hooks/useComponentSize';

export const InboxConversation = () => {
  const dispatch = useDispatch();
  const activeFilter: NoteFilter = useSelector(getActiveFilter);
  const conversation = useConversation();

  const filteredEventNotes = useSelector(getFilteredLatestEventNotes);
  const paginatedEventNotes = useSelector(getPaginatedLatestEventNotes);
  const availablePages = useSelector(getAvailablePagesCount);
  const currentPage = useSelector(getCurrentPage);
  const isFetchingEventNotes: boolean = useSelector(getFetchingState);
  const selectedNote: LatestEventNote | null = useSelector(getSelectedNote);
  const usersByEmailName = useSelector(getUsersByEmailName);

  const [checkedNotes, setCheckedNotes] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [isScrollbarVisible, setScrollbarVisible] = useState<boolean>(false);
  const hasCheckedNotes = React.useMemo(
    () => Object.keys(checkedNotes).length > 0,
    [checkedNotes],
  );
  const [isGroupChecked, setIsGroupChecked] = useState(hasCheckedNotes);

  const notesStaticContainerRef = React.useRef<HTMLElement>(null);
  const notesContainerSize = useComponentSize(notesStaticContainerRef);
  const containerScrollValue = React.useRef(0);
  const keyUpListener = React.useRef<HTMLInputElement | null>(null);

  const conversationsPolling = useSelector(getAppConfig).conversationsPolling;

  const actionButtons = React.useMemo<NoteActionButtons | null>(() => {
    const checkedIndexes = Object.keys(checkedNotes);

    if (
      paginatedEventNotes.length === 0 ||
      checkedIndexes.length > paginatedEventNotes.length
    )
      return null;

    const notes: LatestEventNote[] = checkedIndexes.map(
      idx => paginatedEventNotes[parseInt(idx)],
    );

    const hasUnread = notes.every(n => n.read);
    const readButton: ActionButton<'read' | 'unread'> = {
      type: hasUnread ? 'unread' : 'read',
      action: hasUnread
        ? conversation.unreadConversation.bind(null, ...notes)
        : conversation.readConversation.bind(null, ...notes),
    };

    if (activeFilter === NoteFilter.incoming) {
      return {
        main: {
          type: 'archive',
          action: () => {
            setCheckedNotes({});
            conversation.archiveConversation(...notes);
          },
        },
        read: readButton,
      };
    }

    const sortedNotes = notes.reduce<{
      archived: LatestEventNote[];
      inbox: LatestEventNote[];
      other: LatestEventNote[];
    }>(
      (acc, n) => {
        if (n.archived) acc.archived.push(n);
        else if (n.inbox || n.markedAsInbox) acc.inbox.push(n);
        else acc.other.push(n);

        return acc;
      },
      {
        archived: [],
        inbox: [],
        other: [],
      },
    );
    const hasNotInbox = sortedNotes.inbox.length === 0;

    return {
      main: {
        type: hasNotInbox ? 'inbox' : 'archive',
        action: () => {
          if (hasNotInbox) {
            conversation.moveConversationToInbox(
              ...sortedNotes.other,
              ...sortedNotes.archived,
            );
          } else {
            conversation.archiveConversation(
              ...sortedNotes.other,
              ...sortedNotes.inbox,
            );
          }
        },
      },
      read: readButton,
    };
  }, [activeFilter, conversation, checkedNotes, paginatedEventNotes]);

  const previousActionButtons: NoteActionButtons | null =
    usePrevious(actionButtons);
  const availableActionButtons = React.useMemo(() => {
    if (actionButtons) return actionButtons;
    if (previousActionButtons) {
      return Object.entries(previousActionButtons).reduce(
        (acc, [key, button]) => {
          acc[key] = { ...button, action: () => undefined };
          return acc;
        },
        {},
      ) as NoteActionButtons;
    }
    return null;
  }, [actionButtons, previousActionButtons]);

  const refreshEventNotes = React.useCallback(() => {
    dispatch(refetchEventNotes());
  }, [dispatch]);

  const handleFilterSwitch = React.useCallback(
    (e: any) => {
      e.target.blur();
      const filter = e.target.value;
      if (NoteFilter[filter] === undefined) return;
      setCheckedNotes({});
      setIsGroupChecked(false);
      dispatch(setActiveFilter(filter));
    },
    [dispatch, setCheckedNotes, setIsGroupChecked],
  );

  useEffect(() => {
    dispatch(resetState());
  }, []);

  const toggleNote = React.useCallback(
    (index, isActive) => {
      setCheckedNotes(checkState => {
        if (!isActive) return R.omit([index], checkState);

        return { ...checkState, [index]: isActive };
      });
    },
    [setCheckedNotes],
  );

  const changeAllCheckboxesOnScreen = React.useCallback(
    isActive => {
      setIsGroupChecked(isActive);

      if (!isActive) return setCheckedNotes({});

      const newSelectedNotes = {};
      for (let i = 0; i < paginatedEventNotes.length; i++) {
        newSelectedNotes[i] = true;
      }
      setCheckedNotes(newSelectedNotes);
    },
    [paginatedEventNotes, checkedNotes],
  );

  const openNextEventNote = React.useCallback(
    (direction: 1 | -1) => {
      if (!selectedNote) return;

      const index = paginatedEventNotes.findIndex(
        n =>
          n.eventType === selectedNote.eventType &&
          n.eventId === selectedNote.eventId,
      );
      const nextIndex = index + direction;

      if (
        index === -1 ||
        nextIndex < 0 ||
        nextIndex > paginatedEventNotes.length - 1
      )
        return;
      const note = paginatedEventNotes[nextIndex];

      conversation.openConversation(note);
      conversation.readConversation(note);
    },
    [paginatedEventNotes, selectedNote, conversation.openConversation],
  );

  const onConversationArchived = React.useCallback(
    (index: number) => {
      if (paginatedEventNotes.length - 1 <= 0) return setCheckedNotes({});

      setCheckedNotes(states => {
        return Object.entries(states).reduce((acc, [idx, state]) => {
          const parsedIndex = Number(idx);
          if (parsedIndex > index) {
            acc[parsedIndex - 1] = state;
          } else if (parsedIndex < index) {
            acc[parsedIndex] = state;
          }
          return acc;
        }, {});
      });
    },
    [setCheckedNotes, paginatedEventNotes],
  );

  const onKeyUp = React.useCallback(
    e => {
      if (e.code !== 'ArrowUp' && e.code !== 'ArrowDown') return;

      const direction = e.code === 'ArrowUp' ? -1 : 1;
      openNextEventNote(direction);
    },
    [openNextEventNote],
  );

  const semiLiveInterval: any = useRef();

  const createInterval = () => {
    semiLiveInterval.current = setInterval(() => {
      dispatch(initBackgroundLatestEventNotesFetching());
      dispatch(getLatestUnreadNotes());
    }, 3000);
  };

  const onSelectPage = React.useCallback(
    (page: number) => {
      if (page < 1 || page > availablePages) return;
      setCheckedNotes({});
      dispatch(goToPage(page));
    },
    [dispatch, availablePages, setCheckedNotes],
  );

  const onBlur = () => {
    clearInterval(semiLiveInterval.current);
  };

  const onFocus = () => {
    createInterval();
  };

  useEffect(() => {
    if (!conversationsPolling) return;
    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);
    createInterval();

    return () => {
      clearInterval(semiLiveInterval.current);
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  useEffect(() => {
    dispatch(initLatestEventNotesFetching());
  }, []);

  useEffect(() => setIsGroupChecked(hasCheckedNotes), [hasCheckedNotes]);

  useEffect(() => {
    const listener = e => {
      keyUpListener.current?.dispatchEvent(
        new KeyboardEvent('keydown', {
          code: e.code,
          bubbles: true,
        }),
      );
    };
    document.addEventListener('keyup', listener);

    return () => document.removeEventListener('keyup', listener);
  }, [keyUpListener.current]);

  useEffect(() => {
    const keysToIgnore = ['ArrowUp', 'ArrowDown'];
    const preventScroll = e =>
      keysToIgnore.includes(e.code) && e.preventDefault();
    document.addEventListener('keydown', preventScroll);

    return () => document.removeEventListener('keydown', preventScroll);
  }, []);

  useEffect(() => setCheckedNotes({}), [activeFilter]);

  return (
    <InboxConversation.Container>
      <input ref={keyUpListener} onKeyDown={onKeyUp} hidden />
      <InboxConversation.HeaderBar>
        <InboxConversation.HeaderLeft>
          <InboxConversation.CheckboxContainer>
            <InputCheckbox
              paddingLeft={'0'}
              marginBottom={'0'}
              width={'16px'}
              onChange={e => {
                if (isFetchingEventNotes) return;
                changeAllCheckboxesOnScreen(e.target.checked);
              }}
              Icon={() => (
                <FaMinus
                  color="#ffffff"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '10px',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              )}
              checked={isGroupChecked}
            />
          </InboxConversation.CheckboxContainer>

          <InboxConversation.DropdownContainer>
            <InboxConversation.Dropdown
              onChange={handleFilterSwitch}
              value={activeFilter}
            >
              <option value="incoming">Inbox</option>
              <option value="sent">Sent</option>
              <option value="all">All</option>
            </InboxConversation.Dropdown>

            <FaInbox />
          </InboxConversation.DropdownContainer>

          {hasCheckedNotes && availableActionButtons && (
            <InboxConversation.OperationIconsContainer>
              {availableActionButtons.main.type === 'archive' ? (
                <Button
                  width={30}
                  height={26}
                  onClick={() => availableActionButtons.main.action()}
                  title="Archive"
                >
                  <Archive width={20} height={20} />
                </Button>
              ) : (
                <Button
                  width={30}
                  height={26}
                  onClick={() => availableActionButtons.main.action()}
                  title="Move to Inbox"
                >
                  <MoveToInbox width={20} height={20} />
                </Button>
              )}

              {availableActionButtons.read.type === 'unread' ? (
                <Button
                  width={30}
                  height={26}
                  onClick={() => availableActionButtons.read.action()}
                  title="Mark as Unread"
                >
                  <CloseEnvelope width={20} height={20} />
                </Button>
              ) : (
                <Button
                  width={30}
                  height={26}
                  onClick={() => availableActionButtons.read.action()}
                  title="Mark as Read"
                >
                  <OpenEnvelope width={20} height={20} />
                </Button>
              )}
            </InboxConversation.OperationIconsContainer>
          )}
        </InboxConversation.HeaderLeft>

        {/* Temporary disabled */}
        {/* <InboxConversation.SearchContainer>
          <SearchForm
            placeholder="Search"
            value=""
            fullWidth
            inputHandler={() => null}
          />

          <FaSlidersH color="#484848" />
        </InboxConversation.SearchContainer> */}

        <InboxConversation.ButtonRefresh
          width={30}
          height={26}
          onClick={refreshEventNotes}
        >
          <Refresh />
        </InboxConversation.ButtonRefresh>
      </InboxConversation.HeaderBar>

      <InboxConversation.EventNotes ref={notesStaticContainerRef}>
        <Scrollbars
          style={{
            width: notesContainerSize.width,
            height: notesContainerSize.height,
            right: 0,
          }}
          onUpdate={e => {
            setScrollbarVisible(e.clientHeight !== e.scrollHeight);
            containerScrollValue.current = e.scrollTop;
          }}
          renderThumbVertical={props => (
            <div {...props} className="scrollbar" />
          )}
          renderTrackVertical={props => (
            <div {...props} className="track-vertical" />
          )}
        >
          {paginatedEventNotes.length === 0 && isFetchingEventNotes ? (
            <InboxConversation.InitialProgressContainer>
              <CircularProgress color="inherit" />
            </InboxConversation.InitialProgressContainer>
          ) : paginatedEventNotes?.length !== 0 ? (
            <InboxConversation.ScrollableContainer>
              {paginatedEventNotes.map((note, index) => (
                <InboxConversation.NoteContainer
                  key={note.eventType + note.eventId}
                  isActive={
                    selectedNote ? areNotesEqual(note, selectedNote) : false
                  }
                >
                  <EventNote
                    eventNote={note}
                    isActive={
                      selectedNote ? areNotesEqual(note, selectedNote) : false
                    }
                    usersByEmailName={usersByEmailName}
                    onConversationArchived={() => onConversationArchived(index)}
                    toggleNote={isActive => toggleNote(index, isActive)}
                    noteIsChecked={checkedNotes[index]}
                    itLastNoteWithPaginatorOnPage={
                      isScrollbarVisible &&
                      paginatedEventNotes.length - 1 === index
                    }
                  />
                </InboxConversation.NoteContainer>
              ))}
            </InboxConversation.ScrollableContainer>
          ) : (
            <InboxConversation.NoItemsMessage message={EMPTY_INBOX_MESSAGE} />
          )}
        </Scrollbars>
      </InboxConversation.EventNotes>

      {!isFetchingEventNotes && (
        <PageCounter
          allNotesCount={filteredEventNotes.length}
          availablePages={availablePages}
          paginatedNotesCount={paginatedEventNotes.length}
          currentPage={currentPage}
          onSelectPage={onSelectPage}
        />
      )}
    </InboxConversation.Container>
  );
};

InboxConversation.Container = styled.div`
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr) min-content;
  background: #fff;
  height: 100%;
`;

InboxConversation.EventNotes = styled.div`
  overflow: hidden;

  .scrollbar {
    height: 10px;
    background-color: #484848;
  }

  .track-vertical {
    right: 0;
    height: 100%;
    width: 8px !important;
  }
`;

InboxConversation.HeaderBar = styled.div`
  position: relative;
  padding: 7px;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #c1c1c1;
`;

InboxConversation.HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

InboxConversation.CheckboxContainer = styled.div`
  margin-left: 1.7px;
  margin-right: 1.2px;
`;

InboxConversation.UncheckedCheckboxContainer = styled.div``;

InboxConversation.OperationIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

InboxConversation.DropdownContainer = styled.div`
  position: relative;

  & > svg {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
`;

InboxConversation.Dropdown = styled.select`
  width: 104px;
  height: 26px;
  padding-left: 26px;

  svg {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }

  option {
    padding: 0;
  }
`;

InboxConversation.SearchContainer = styled.div`
  position: relative;

  div,
  input {
    height: 26px;
  }

  div > svg {
    transform: scaleX(-1) translateY(2px);
  }

  > svg {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 4px;
    width: 18px;
    height: 18px;
  }
`;

InboxConversation.ButtonRefresh = styled(Button)`
  svg {
    width: 17px;
    height: 17px;
  }
`;

InboxConversation.InitialProgressContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

InboxConversation.FetchProgressContainer = styled.div`
  padding: 10px 0 30px 0;
  display: flex;
  justify-content: center;
`;

InboxConversation.ScrollableContainer = styled.div``;

InboxConversation.NoteContainer = styled.div`
  box-shadow: ${props => {
    return props.isActive
      ? '0 3px 6px -2px rgba(0,0,0,0.1), 0 0 10px 0 rgba(0,0,0,0.05);'
      : 'none';
  }};
`;

InboxConversation.TitleContainer = styled.div``;

InboxConversation.NoItemsMessage = styled(NoSelectedItemsMessage)`
  padding-bottom: 24px;
`;
