import * as React from 'react';
import { TimeInterval, utcDay, utcHour } from 'd3-time';
import { getMinDate } from 'helpers';
import { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import {
  countCapacity,
  countIndicatorsCapacityChartDataByIndicator,
} from 'modules/capacityChangeEvent/utils';
import { ALL_PHASES_LIST, BOE } from 'modules/phase/models/phase';
import { Phase } from './model';
import { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';
import { CapacityVarianceReducer, initialState } from './store/reducer';
import { Actions } from './store/actions';
import { CapacityVarianceContext } from './CapacityVarianceContext';
import { ProductionPoint } from 'modules/production/models/production';

interface Props {
  capacityEvents: CapacityChangeEvent[];
  varianceEvents: VarianceEvent[];
  children: React.ReactNode;
  lastDate: Date;
  limitDate: Date;
  onCapacityEventChange?: (
    eventId: string,
    events: CapacityChangeEvent[],
  ) => void;
  production: ProductionPoint[];
  nri: number;
  drilldownPhase: string;
  grossNet: string;
  isIndicatorChartShown: boolean;
}

export const CapacityVarianceProvider = ({
  capacityEvents,
  children,
  lastDate,
  limitDate,
  varianceEvents,
  production,
  onCapacityEventChange,
  nri,
  drilldownPhase,
  grossNet,
  isIndicatorChartShown,
}: Props) => {
  const [state, dispatch] = React.useReducer(
    CapacityVarianceReducer,
    initialState,
  );
  const [updateEvent, setUpdateEvent] = React.useState<{
    eventId: string | null;
  }>({ eventId: null });

  const calculateDecline = React.useCallback(
    (
      phasePrefix: string,
      capacityEvent: CapacityChangeEvent,
      date: Date,
      rate: number,
    ) => {
      const rateInit = capacityEvent[`${phasePrefix}RateInit`];
      const declineInitDailyNom =
        capacityEvent[`${phasePrefix}DeclineInitDailyNom`];
      const bFactor = capacityEvent[`${phasePrefix}BFactor`];
      const { dayInit } = capacityEvent;
      const daysElapsed = utcDay.count(dayInit, utcDay.ceil(date));

      if (rate > rateInit) {
        const newDecline =
          (rateInit - rate) / daysElapsed || declineInitDailyNom;

        return newDecline;
      } else if (bFactor > 0) {
        const newDecline =
          (Math.pow(rateInit / rate, bFactor) - 1) / (bFactor * daysElapsed) ||
          declineInitDailyNom;

        return newDecline;
      }
      const newDecline =
        -Math.log(rate / rateInit) / daysElapsed || declineInitDailyNom;

      return newDecline;
    },
    [],
  );

  const calculateBFactor = React.useCallback(
    (
      phasePrefix: string,
      capacityEvent: CapacityChangeEvent,
      date: Date,
      rate: number,
    ) => {
      const declineInitDailyNom =
        capacityEvent[`${phasePrefix}DeclineInitDailyNom`];
      if (declineInitDailyNom <= 0)
        return capacityEvent[`${phasePrefix}BFactor`];
      const bFactorRange = Array.from({ length: 501 }, (item, i) => i / 100);
      const capacityValues = bFactorRange.reduce((acc, bFactor) => {
        const capacity = countCapacity(
          date,
          { ...capacityEvent, [`${phasePrefix}BFactor`]: bFactor },
          phasePrefix,
        );
        acc.push({ bFactor, capacity });

        return acc;
      }, [] as { bFactor: number; capacity: number }[]);

      const newBFactorObj = capacityValues.reduce((acc, capacityObj, idx) => {
        const difference = Math.abs(rate - capacityObj.capacity);
        if (idx === 0) return { difference, bFactor: capacityObj.bFactor };
        if (acc.difference > difference)
          return { difference, bFactor: capacityObj.bFactor };

        return acc;
      }, {} as { difference: number; bFactor: number });

      return newBFactorObj.bFactor;
    },
    [],
  );

  const getTimeIntervalFor = React.useCallback(
    (event: CapacityChangeEvent, events: CapacityChangeEvent[]) => {
      const interval = utcHour.every(12) as TimeInterval;
      const index = events.findIndex(e => e.id === event.id);

      if (index < 0) return [];

      const lastRangeDate =
        index !== 0
          ? getMinDate(events[index - 1].dayInit, lastDate)
          : lastDate;

      if (event.dayInit > lastRangeDate) return [];

      return interval.range(event.dayInit, utcHour.offset(lastRangeDate, 12));
    },
    [lastDate],
  );

  const calculatePhaseCapacityFor = React.useCallback(
    (
      phasePrefix: string,
      event: CapacityChangeEvent,
      events: CapacityChangeEvent[],
      nri?: number,
    ) => {
      const interval = getTimeIntervalFor(event, events);
      if (!interval) return [];
      const multiplier = nri ?? 1;

      return interval.map(date => ({
        date,
        capacity: countCapacity(date, event, phasePrefix) * multiplier,
      }));
    },
    [getTimeIntervalFor],
  );

  const calculateFullCapacityForEach = React.useCallback(
    (events: CapacityChangeEvent[], nri?: number) => {
      const timeIntervals = events.map(e => getTimeIntervalFor(e, events));

      const multiplier = nri ?? 1;

      const capacity = ALL_PHASES_LIST.reduce((acc, phase) => {
        acc[phase] = timeIntervals.map(
          (interval, idx) =>
            interval?.map(date => ({
              date,
              capacity:
                countCapacity(date, events[idx], phase.toLowerCase()) *
                multiplier,
            })) ?? [],
        );
        return acc;
      }, {});

      return capacity;
    },
    [getTimeIntervalFor],
  );

  const changeDecline = React.useCallback(
    (capacityEventId: string, phase: Phase, date: Date, rate: number) => {
      const event = state.capacityEvents.find(e => e.id === capacityEventId);
      if (!event) return;

      const phasePrefix = phase.toLowerCase();
      const decline = calculateDecline(phasePrefix, event, date, rate);
      const newEvent = {
        ...event,
        [`${phasePrefix}DeclineInitDailyNom`]: decline,
      };
      const capacity = calculatePhaseCapacityFor(
        phasePrefix,
        newEvent,
        state.capacityEvents,
        grossNet === 'Net' ? nri : undefined,
      );

      dispatch(
        Actions.updateCapacityEventDecline({
          decline,
          eventId: capacityEventId,
          phase,
        }),
      );
      dispatch(
        Actions.setCapacityFor({ eventId: capacityEventId, phase, capacity }),
      );

      return newEvent;
    },
    [
      calculatePhaseCapacityFor,
      calculateDecline,
      dispatch,
      state.capacityEvents,
      grossNet,
      nri,
    ],
  );

  const changeBFactor = React.useCallback(
    (capacityEventId: string, phase: Phase, date: Date, rate: number) => {
      const event = state.capacityEvents.find(e => e.id === capacityEventId);
      if (!event) return;

      const phasePrefix = phase.toLocaleLowerCase();
      const bFactor = calculateBFactor(phasePrefix, event, date, rate);
      const newEvent = {
        ...event,
        [`${phasePrefix}BFactor`]: bFactor,
      };

      const capacity = calculatePhaseCapacityFor(
        phasePrefix,
        newEvent,
        state.capacityEvents,
        grossNet === 'Net' ? nri : undefined,
      );

      dispatch(
        Actions.updateCapacityEventBFactor({
          eventId: capacityEventId,
          bFactor,
          phase,
        }),
      );
      dispatch(
        Actions.setCapacityFor({
          eventId: capacityEventId,
          capacity,
          phase,
        }),
      );

      return newEvent;
    },
    [
      calculateBFactor,
      calculatePhaseCapacityFor,
      dispatch,
      state.capacityEvents,
      grossNet,
      nri,
    ],
  );

  const changeInitialRate = React.useCallback(
    (capacityEventId: string, phase: Phase, rate: number) => {
      const event = state.capacityEvents.find(e => e.id === capacityEventId);
      if (!event) return;

      const phasePrefix = phase.toLocaleLowerCase();
      const newEvent = {
        ...event,
        [`${phasePrefix}RateInit`]: rate,
      };
      const capacity = calculatePhaseCapacityFor(
        phasePrefix,
        newEvent,
        state.capacityEvents,
        grossNet === 'Net' ? nri : undefined,
      );

      dispatch(
        Actions.updateCapacityEventInitialRate({
          eventId: capacityEventId,
          rate,
          phase,
        }),
      );
      dispatch(
        Actions.setCapacityFor({
          eventId: capacityEventId,
          capacity,
          phase,
        }),
      );

      return newEvent;
    },
    [
      calculateBFactor,
      calculatePhaseCapacityFor,
      dispatch,
      state.capacityEvents,
      grossNet,
      nri,
    ],
  );

  const context = React.useMemo(
    () => ({
      capacity: { ...state.capacity, ...state.indicatorsCapacity },
      changeBFactor,
      changeDecline,
      changeInitialRate,
      finishEditingCurve: (eventId: string) => setUpdateEvent({ eventId }),
      capacityEvents: state.capacityEvents,
      varianceEvents,
      production,
      nri,
      drilldownPhase,
      lastDate,
      limitDate,
    }),
    [
      state.capacity,
      state.indicatorsCapacity,
      state.capacityEvents,
      changeBFactor,
      changeDecline,
      changeInitialRate,
      setUpdateEvent,
      varianceEvents,
      production,
      nri,
      drilldownPhase,
      lastDate,
      limitDate,
    ],
  );

  // Set updated capacity
  React.useEffect(() => {
    const capacity = calculateFullCapacityForEach(
      state.capacityEvents,
      grossNet === 'Net' ? nri : undefined,
    );

    if (isIndicatorChartShown || drilldownPhase === BOE) {
      const indicatorsCapacity = countIndicatorsCapacityChartDataByIndicator({
        capacityEvents: state.capacityEvents,
        capacityDataByPhase: capacity,
        lastDate,
        nri: grossNet === 'Net' ? nri : undefined,
      });

      dispatch(Actions.setIndicatorsCapacity(indicatorsCapacity));
    }

    dispatch(Actions.setCapacity(capacity));
  }, [
    state.capacityEvents,
    lastDate,
    grossNet,
    nri,
    drilldownPhase,
    isIndicatorChartShown,
  ]);

  // Update capacity events
  React.useEffect(() => {
    dispatch(Actions.setCapacityEvents(capacityEvents));
  }, [capacityEvents]);

  React.useEffect(() => {
    if (updateEvent.eventId === null) return;
    onCapacityEventChange?.call(null, updateEvent.eventId, [
      ...state.capacityEvents,
    ]);
    setUpdateEvent({ eventId: null });
  }, [updateEvent.eventId]);

  return (
    <CapacityVarianceContext.Provider value={context}>
      {children}
    </CapacityVarianceContext.Provider>
  );
};
