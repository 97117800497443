import { max } from 'd3-array';
import { utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import RegionOfInterest from 'modules/chart/components/RegionOfInterest';
import SeriesPill from 'modules/chart/components/SeriesPill';
import YAxis from 'modules/chart/components/YAxis';
import { createNormalYScale } from 'modules/chart/utils';
import { ForecastData } from 'modules/externalForecast/models';
import type { GroupChartPoint } from 'modules/groupChart/models/groupChart';
import type { TooltipData, TrellisTooltipData } from 'modules/ui/models/ui';

import usePrevious from 'hooks/usePrevious';

import SVGGroupTrellis from './SVGGroupTrellis';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';

const getMaxDataPoint = (
  groupChartData: GroupChartPoint[] = [],
  extremeDates,
  forecastData: ForecastData,
  phase: string,
): number => {
  const inTimeRange = groupChartData.filter(
    (datapoint: GroupChartPoint) =>
      datapoint.day.getTime() < extremeDates.max.getTime() &&
      datapoint.day.getTime() > extremeDates.min.getTime(),
  );
  const forecastValues = forecastData
    .filter(f => f.day <= extremeDates.max && f.day >= extremeDates.min)
    .map(f => f[phase]);

  const maxForecastValue = R.isEmpty(forecastValues) ? 0 : max(forecastValues);
  const maxChartValues = max(inTimeRange, d =>
    Math.max(d.production, d.capacity),
  ) as number;
  return Math.max(maxForecastValue, maxChartValues) * 1.05 || 1000;
};

interface DivGroupChartProps {
  changeMinDrilldownTableDate: (minDate: Date) => void;
  changeMaxDrilldownTableDate: (maxDate: Date) => void;
  clearDrilldownTable: () => void;
  currentGroup: { subject: string; item: string };
  displaysRegionOfInterest: boolean;
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    compareOption: string;
  };
  extremeDates: { min: Date; max: Date };
  finishDrag: () => void;
  groupChartData: GroupChartPoint[];
  groupForecastData: ForecastData;
  format: string;
  height: number;
  isAxisDragging: boolean;
  isDisplayingForecast: boolean;
  isDragging: boolean;
  leftOffset: number;
  onPillClick: () => void;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  pillText: string;
  position: number;
  regionOfInterest: boolean;
  regionOfInterestMode: boolean;
  startDrag: () => void;
  today: Date;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  width: number;
  xScale: any;
  isLast: boolean;
}

const DivGroupChart = ({
  changeMinDrilldownTableDate,
  changeMaxDrilldownTableDate,
  clearDrilldownTable,
  currentGroup,
  displaysRegionOfInterest,
  drilldownTableParams,
  extremeDates,
  format,
  finishDrag,
  groupChartData,
  groupForecastData,
  height,
  isAxisDragging,
  isDisplayingForecast,
  isDragging,
  leftOffset,
  onPillClick,
  onSetTooltipData,
  onXAxisScaling,
  pillText,
  position,
  regionOfInterest,
  regionOfInterestMode,
  startDrag,
  today,
  tooltipData,
  trellisTitle,
  width,
  xScale,
  isLast,
}: DivGroupChartProps) => {
  const maxDataPoint = React.useMemo(
    () =>
      getMaxDataPoint(
        groupChartData,
        extremeDates,
        groupForecastData,
        trellisTitle === 'Total Liquid'
          ? 'total_liquid'
          : trellisTitle.toLocaleLowerCase(),
      ),
    [groupChartData, extremeDates, groupForecastData, trellisTitle],
  );
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const [displayMaxDataPoint, setDisplayMaxDataPoint] =
    React.useState(maxDataPoint);

  const yScale = React.useMemo(
    () => createNormalYScale(height, displayMaxDataPoint),
    [displayMaxDataPoint, height],
  );

  const [isAdjusted, setIsAdjusted] = React.useState(false);

  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setDisplayMaxDataPoint(maxDataPoint);
  }, [setIsAdjusted, setDisplayMaxDataPoint, maxDataPoint]);

  const prevMaxDataPoint = usePrevious(maxDataPoint);
  const prevGroup = usePrevious(currentGroup);

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );
  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  React.useEffect(() => {
    if (
      prevMaxDataPoint &&
      prevMaxDataPoint !== maxDataPoint &&
      prevMaxDataPoint === displayMaxDataPoint
    ) {
      setDisplayMaxDataPoint(maxDataPoint);
    }
  }, [prevMaxDataPoint, maxDataPoint, displayMaxDataPoint]);

  React.useEffect(() => {
    if (
      prevGroup &&
      JSON.stringify(prevGroup) !== JSON.stringify(currentGroup)
    ) {
      resetMax();
    }
  }, [currentGroup, prevGroup, resetMax]);

  const dataMap = React.useMemo(() => {
    const phase = trellisTitle.toLowerCase();
    const tempDate = groupChartData.reduce((acc, dataPoint) => {
      const key = dataPoint.day.toISOString();
      acc[key] = {
        day: dataPoint.day,
        production: dataPoint.production,
        capacity: dataPoint.capacity,
      };

      return acc;
    }, {});
    if (!R.isEmpty(groupForecastData)) {
      groupForecastData?.reduce((acc, dataPoint) => {
        if (utcDay.count(dataPoint.day, today) < 0) {
          return acc;
        }
        const key = dataPoint.day.toISOString();
        if (acc[key]) {
          acc[key] = {
            ...acc[key],
            forecast: dataPoint[phase],
          };
          return acc;
        }
        acc[key] = {
          day: dataPoint.day,
          production: 0,
          capacity: 0,
          forecast: dataPoint[phase],
        };

        return acc;
      }, tempDate);
    }

    return tempDate;
  }, [groupChartData, groupForecastData, trellisTitle, today]);

  const tooltipDate =
    R.pathOr(false, ['trellisTooltipData', 'day'], tooltipData) || null;
  const groupTooltipData = tooltipDate
    ? R.path([tooltipDate.toISOString()], dataMap)
    : null;

  return (
    <>
      <DivGroupChart.Container height={height} isLast={isLast}>
        <DivGroupChart.SVGWrapper>
          <SVGGroupTrellis
            dataMap={dataMap}
            groupChartData={groupChartData}
            groupForecastData={groupForecastData}
            height={height}
            isAxisDragging={isAxisDragging}
            isDisplayingForecast={isDisplayingForecast}
            maxDataPoint={displayMaxDataPoint}
            onXAxisScaling={onXAxisScaling}
            onSetTooltipData={onSetTooltipData}
            regionOfInterestMode={regionOfInterestMode}
            today={today}
            tooltipData={tooltipData}
            trellisTitle={trellisTitle}
            xScale={xScale}
            yAxisLinePos={yAxisLinePos}
          />
          {displaysRegionOfInterest &&
            regionOfInterest &&
            drilldownTableParams &&
            drilldownTableParams.phase === trellisTitle &&
            !regionOfInterestMode && (
              <RegionOfInterest
                changeMaxDrilldownTableDate={changeMaxDrilldownTableDate}
                changeMinDrilldownTableDate={changeMinDrilldownTableDate}
                clearDrilldownTable={clearDrilldownTable}
                leftOffset={leftOffset}
                maxDate={drilldownTableParams.maxDate}
                minDate={drilldownTableParams.minDate}
                xScale={xScale}
                startDrag={startDrag}
                finishDrag={finishDrag}
                height={height}
                width={width}
                position={height * position}
              />
            )}
        </DivGroupChart.SVGWrapper>
        <DivGroupChart.YAxisContainer>
          <YAxis
            format={format}
            height={height}
            hideLine={hideLine}
            isAdjusted={isAdjusted}
            isDragging={isDragging}
            isXAxisDragging={false}
            maxDataPoint={displayMaxDataPoint}
            setDisplayMaxDataPoint={setDisplayMaxDataPoint}
            resetMax={resetMax}
            setIsAdjusted={setIsAdjusted}
            showLine={showLine}
            yScale={yScale}
          />
        </DivGroupChart.YAxisContainer>
        <SeriesPill onPillClick={onPillClick} text={pillText} />
        {tooltipData &&
          (tooltipData.trellisTooltipData ||
            tooltipData.ribbonTooltipData ||
            tooltipData.dataSeriesTooltipData) &&
          tooltipData.trellisTooltipData?.trellis !== trellisTitle && (
            <SecondaryInformationTooltip
              containerHeight={height}
              isDisplayingForecast={isDisplayingForecast}
              leftOffset={leftOffset}
              tooltipData={tooltipData}
              secondaryCavTooltipData={groupTooltipData}
              trellisTitle={trellisTitle}
              yScale={yScale}
            />
          )}
      </DivGroupChart.Container>
    </>
  );
};

DivGroupChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 73;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

DivGroupChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

DivGroupChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

export default React.memo<DivGroupChartProps>(DivGroupChart);
