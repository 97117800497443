import * as React from 'react';
import * as R from 'ramda';
import { CapacityVarianceContext } from './CapacityVarianceContext';
import { Capacity } from './model';

export const useVarianceData = () => {
  const context = React.useContext(CapacityVarianceContext);

  if (!context)
    throw new Error(
      'CapacityVarianceContext must be used within a CapacityVarianceProvider!',
    );

  const dateToProduction = React.useMemo(
    () =>
      context.production.reduce((acc, p) => {
        acc[p.day.toISOString()] = p;

        return acc;
      }, {}),
    [context.production],
  );

  const varianceData: { date: Date; variance: number }[] = React.useMemo(() => {
    if (!context.capacity[context.drilldownPhase]) return [];
    const reverseCapacityData = R.reverse([
      ...context.capacity[context.drilldownPhase],
    ]);

    return R.flatten(reverseCapacityData).reduce((acc, cap) => {
      // if (
      //   cap.date.getTime() > today.getTime() ||
      //   cap.date.getTime() > extremeDates.max.getTime() ||
      //   cap.date.getTime() < extremeDates.min.getTime()
      // ) {
      //   return acc;
      // }
      if (dateToProduction[cap.date.toISOString()]) {
        acc.push({
          date: cap.date,
          variance:
            dateToProduction[cap.date.toISOString()][
              context.drilldownPhase.toLowerCase()
            ] - cap.capacity,
        });
      }

      return acc;
    }, []);
  }, [dateToProduction, context.drilldownPhase, context.capacity]);

  const getAll = React.useCallback(() => varianceData, [varianceData]);

  const getVarianceFromVarianceAreas = React.useCallback(
    (varianceAreas: Capacity[][]) => {
      return varianceAreas.map(area =>
        area
          .map(v => {
            const production = dateToProduction[v.date.toISOString()];

            return {
              date: v.date,
              variance:
                production && v.capacity !== 0
                  ? production[context.drilldownPhase.toLowerCase()] -
                    v.capacity
                  : NaN,
            };
          })
          .filter(e => !Number.isNaN(e.variance)),
      );
    },
    [varianceData, dateToProduction, context.drilldownPhase],
  );

  const selectors = React.useMemo(
    () => ({ getAll, getVarianceFromVarianceAreas }),
    [getAll, getVarianceFromVarianceAreas],
  );

  return selectors;
};
