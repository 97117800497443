import React from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalHotKeys } from 'react-hotkeys';
import styled from 'styled-components';

import Card from 'components/Card';
import { Tab, TabsProvider } from 'components/Tabs';
import { Menu } from 'modules/logoMenu/Menu';
import { DrilldownPanel } from 'modules/drilldownTable/containers/DrilldownPanel';
import { InboxConversation } from 'modules/inboxConversation/containers/InboxConversation';
import { getFilters } from 'modules/filter/FilterReducer';
import {
  enableRegionOfInterestMode,
  resetActivePanel,
} from 'modules/ui/UIActions';
import { TabsHeader } from '../components/TabsHeader';

const LeftPanels = () => {
  const dispatch = useDispatch();

  const regionOfInterestModeOn = React.useCallback(() => {
    dispatch(resetActivePanel());
    dispatch(enableRegionOfInterestMode());
  }, [dispatch]);

  const keyMap: { regionOfInterestModeOn: string } = {
    regionOfInterestModeOn: 'r',
  };

  const handlers = {
    regionOfInterestModeOn,
  };

  const filters = useSelector(getFilters);
  const filtersApplied = R.pickBy(filter => !R.isEmpty(filter), filters);
  const filterQuant = R.keys(filtersApplied).length;

  return (
    <LeftPanels.Container>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />

      <TabsProvider>
        {tabs => (
          <LeftPanels.TabsContainer>
            <TabsHeader tabs={tabs} appliedFilters={filterQuant} />
            <Tab tabName="Menu">
              <Menu />
            </Tab>

            <Tab tabName="Drilldown" defaultActive>
              <DrilldownPanel />
            </Tab>

            <Tab tabName="Inbox">
              <InboxConversation />
            </Tab>

            <Tab tabName="Wells" />
          </LeftPanels.TabsContainer>
        )}
      </TabsProvider>
    </LeftPanels.Container>
  );
};

LeftPanels.Container = styled(Card)`
  width: 100%;
  height: 100%;
  position: relative; ;
`;

LeftPanels.TabsContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: 1fr;
`;

LeftPanels.InternalContainer = styled.div`
  overflow: hidden;
  margin: 0 8px;
  border-bottom: ${(props: Record<string, any>) =>
    props.varianceTableHeight <= 32 ? '1px solid #c1c1c1' : ''};
`;

export default LeftPanels;
