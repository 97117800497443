import type { AC } from 'store/models';
import { initialState, TooltipData, UIState } from './models/ui';
import type { AbstractDialog } from './models/dialog';

export const namespace = 'UI';
// Trellises
export const SWITCH_TRELLISES = `${namespace}/SWITCH_TRELLISES`;
export const SET_TRELLISES = `${namespace}/SET_TRELLISES`;

// Event Notes
export const OPEN_IMAGE_DETAILS_OVERLAY = `${namespace}/OPEN_IMAGE_DETAILS_OVERLAY`;
export const CLOSE_IMAGE_DETAILS_OVERLAY = `${namespace}/CLOSE_IMAGE_DETAILS_OVERLAY`;
export const OPEN_EVENT_CONVERSATION = `${namespace}/OPEN_EVENT_CONVERSATION`;
export const CLOSE_EVENT_CONVERSATION = `${namespace}/CLOSE_EVENT_CONVERSATION`;

//Alert windows
export const CLOSE_ALLOC_ISSUE_ALERT_WINDOW = `${namespace}/CLOSE_ALLOC_ISSUE_ALERT_WINDOW`;
export const CLOSE_CAPACITY_DELETE_ALERT_WINDOW = `${namespace}/CLOSE_CAPACITY_DELETE_ALERT_WINDOW`;
export const CLOSE_CAPACITY_CANCEL_EDITS_ALERT_WINDOW = `${namespace}/CLOSE_CAPACITY_CANCEL_EDITS_ALERT_WINDOW`;
export const CLOSE_VARIANCE_ALERT_WINDOW = `${namespace}/CLOSE_VARIANCE_ALERT_WINDOW`;
export const OPEN_ALLOC_ISSUE_ALERT_WINDOW = `${namespace}/OPEN_ALLOC_ISSUE_ALERT_WINDOW`;
export const OPEN_CAPACITY_DELETE_ALERT_WINDOW = `${namespace}/OPEN_CAPACITY_DELETE_ALERT_WINDOW`;
export const OPEN_CAPACITY_CANCEL_EDITS_ALERT_WINDOW = `${namespace}/OPEN_CAPACITY_CANCEL_EDITS_ALERT_WINDOW`;
export const OPEN_VARIANCE_ALERT_WINDOW = `${namespace}/OPEN_VARIANCE_ALERT_WINDOW`;

// Show Issues
export const TOGGLE_ISSUES_VISIBILITY = `${namespace}/TOGGLE_ISSUES_VISIBILITY`;

// Active Panel
export const ACTIVATE_NOTES_PANEL = `${namespace}/ACTIVATE_NOTES_PANEL`;
export const ACTIVATE_DRILLDOWN_PANEL = `${namespace}/ACTIVATE_DRILLDOWN_PANEL`;
export const RESET_ACTIVE_PANEL = `${namespace}/RESET_ACTIVE_PANEL`;

// General UI state (for ui state sharing)
export const SET_UI_STATE = `${namespace}/SET_UI_STATE`;

// Notes Production Day
export const SET_NOTES_PRODUCTION_DAY = `${namespace}/SET_NOTES_PRODUCTION_DAY`;
export const DEBOUNCE_SET_NOTES_PRODUCTION_DAY = `${namespace}/DEBOUNCE_SET_NOTES_PRODUCTION_DAY`;

//Set Notes Production Day Mode
export const ENABLE_NOTES_MODE = `${namespace}/ENABLE_NOTES_MODE`;
export const DISABLE_NOTES_MODE = `${namespace}/DISABLE_NOTES_MODE`;

// Addition Mode
export const ENABLE_ADDITION_MODE = `${namespace}/ENABLE_ADDITION_MODE`;
export const DISABLE_ADDITION_MODE = `${namespace}/DISABLE_ADDITION_MODE`;

// Zoom In Mode
export const ENABLE_ZOOM_IN_MODE = `${namespace}/ENABLE_ZOOM_IN_MODE`;
export const DISABLE_ZOOM_IN_MODE = `${namespace}/DISABLE_ZOOM_IN_MODE`;

//Region Of Interest
export const ENABLE_REGION_OF_INTEREST_MODE = `${namespace}/ENABLE_REGION_OF_INTEREST_MODE`;
export const DISABLE_REGION_OF_INTEREST_MODE = `${namespace}/DISABLE_REGION_OF_INTEREST_MODE`;

//Highlighting of chart areas and legend panel
export const HIGHLIGHT_ALLOC_ISSUE = `${namespace}/HIGHLIGHT_ALLOC_ISSUE`;
export const HIGHLIGHT_ALLOC_ISSUE_OFF = `${namespace}/HIGHLIGHT_ALLOC_ISSUE_OFF`;
export const HIGHLIGHT_ALLOC_ISSUE_DIVIDER = `${namespace}/HIGHLIGHT_ALLOC_ISSUE_DIVIDER`;
export const HIGHLIGHT_ALLOC_ISSUE_DIVIDER_OFF = `${namespace}/HIGHLIGHT_ALLOC_ISSUE_DIVIDER_OFF`;
export const HIGHLIGHT_CAPACITY = `${namespace}/HIGHLIGHT_CAPACITY`;
export const HIGHLIGHT_CAPACITY_OFF = `${namespace}/HIGHLIGHT_CAPACITY_OFF`;
export const HIGHLIGHT_CAPACITY_DIVIDER = `${namespace}/HIGHLIGHT_CAPACITY_DIVIDER`;
export const HIGHLIGHT_CAPACITY_DIVIDER_OFF = `${namespace}/HIGHLIGHT_CAPACITY_DIVIDER_OFF`;
export const HIGHLIGHT_VARIANCE = `${namespace}/HIGHLIGHT_VARIANCE`;
export const HIGHLIGHT_VARIANCE_OFF = `${namespace}/HIGHLIGHT_VARIANCE_OFF`;
export const HIGHLIGHT_VARIANCE_DIVIDER = `${namespace}/HIGHLIGHT_VARIANCE_DIVIDER`;
export const HIGHLIGHT_VARIANCE_DIVIDER_OFF = `${namespace}/HIGHLIGHT_VARIANCE_DIVIDER_OFF`;
export const HIGHLIGHT_OFF_EVERYTHING = `${namespace}/HIGHLIGHT_OFF_EVERYTHING`;

//Group Mode
export const ENABLE_GROUP_MODE = `${namespace}/ENABLE_GROUP_MODE`;
export const CHANGE_GROUP_MODE_SUBJECT = `${namespace}/CHANGE_GROUP_MODE_SUBJECT`;
export const DISABLE_GROUP_MODE = `${namespace}/DISABLE_GROUP_MODE`;

//Current well / group
export const SET_CURRENT_WELL_ID = `${namespace}/SET_CURRENT_WELL_ID`;
export const SET_CURRENT_GROUP = `${namespace}/SET_CURRENT_GROUP`;
export const RESET_CURRENT_GROUP = `${namespace}/RESET_CURRENT_GROUP`;

//Tooltip Data
export const SET_TOOLTIP_DATA = `${namespace}/SET_TOOLTIP_DATA`;

//Request Help Panel
export const OPEN_REQUEST_HELP_PANEL = `${namespace}/OPEN_REQUEST_HELP_PANEL`;
export const CLOSE_REQUEST_HELP_PANEL = `${namespace}/CLOSE_REQUEST_HELP_PANEL`;

//Ribbons
export const SWITCH_RIBBONS = `${namespace}/SWITCH_RIBBONS`;
export const SET_RIBBONS = `${namespace}/SET_RIBBONS`;
export const HIGHLIGHT_RIBBON_EVENT_DIVIDER_OFF = `${namespace}/HIGHLIGHT_RIBBON_EVENT_DIVIDER_OFF`;
export const HIGHLIGHT_RIBBON_EVENT_DIVIDER = `${namespace}/HIGHLIGHT_RIBBON_EVENT_DIVIDER`;
export const OPEN_RIBBON_EVENT_ALERT_WINDOW = `${namespace}/OPEN_RIBBON_EVENT_ALERT_WINDOW`;
export const CLOSE_RIBBON_EVENT_ALERT_WINDOW = `${namespace}/CLOSE_RIBBON_EVENT_ALERT_WINDOW`;

//X, Y-axes
export const START_X_AXIS_DRAGGING = `${namespace}/START_X_AXIS_DRAGGING`;
export const START_Y_AXIS_DRAGGING = `${namespace}/START_Y_AXIS_DRAGGING`;
export const STOP_X_AXIS_DRAGGING = `${namespace}/STOP_X_AXIS_DRAGGING`;
export const STOP_Y_AXIS_DRAGGING = `${namespace}/STOP_Y_AXIS_DRAGGING`;
export const SET_WAS_DRAGGING = `${namespace}/SET_WAS_DRAGGING`;

// Filter window
export const CHANGE_FILTER_VIEW = `${namespace}/CHANGE_FILTER_VIEW`;
export const OPEN_FILTER_LAYOUTS_PANEL = `${namespace}/OPEN_FILTER_LAYOUTS_PANEL`;
export const CLOSE_FILTER_LAYOUTS_PANEL = `${namespace}/CLOSE_FILTER_LAYOUTS_PANEL`;

// Dialog panels new action types
export const SET_RIGHT_PANEL_DIALOG = `${namespace}/SET_RIGHT_PANEL_DIALOG`;
export const UNSET_RIGHT_PANEL_DIALOG = `${namespace}/UNSET_RIGHT_PANEL_DIALOG`;
export const EXPAND_RIGHT_PANEL = `${namespace}/EXPAND_RIGHT_PANEL`;
export const EXPAND_RIGHT_PANEL_WITHOUT_CHANGING_DIALOG = `${namespace}/EXPAND_RIGHT_PANEL_WITHOUT_CHANGING_DIALOG`;
export const COLLAPSE_RIGHT_PANEL = `${namespace}/COLLAPSE_RIGHT_PANEL`;
// Dialog panels old action types
export const OPEN_ALLOC_ISSUE_DIALOG = `${namespace}/OPEN_ALLOC_ISSUE_DIALOG`;
export const OPEN_CAPACITY_DIALOG = `${namespace}/OPEN_CAPACITY_DIALOG`;
export const OPEN_CHART_OPTIONS_PANEL = `${namespace}/OPEN_CHART_OPTIONS_PANEL`;
export const OPEN_LEGEND_PANEL = `${namespace}/OPEN_LEGEND_PANEL`;
export const OPEN_NOTES_DIALOG = `${namespace}/OPEN_NOTES_DIALOG`;
export const OPEN_RIBBON_EVENT_DIALOG = `${namespace}/OPEN_RIBBON_EVENT_DIALOG`;
export const OPEN_RIBBON_DETAILS_PANEL = `${namespace}/OPEN_RIBBON_DETAILS_PANEL`;
export const OPEN_SHORTCUT_PANEL = `${namespace}/OPEN_SHORTCUT_PANEL`;
export const OPEN_VARIANCE_DIALOG = `${namespace}/OPEN_VARIANCE_DIALOG`;
export const CLOSE_ALLOC_ISSUE_DIALOG = `${namespace}/CLOSE_ALLOC_ISSUE_DIALOG`;
export const CLOSE_CAPACITY_DIALOG = `${namespace}/CLOSE_CAPACITY_DIALOG`;
export const CLOSE_CHART_OPTIONS_PANEL = `${namespace}/CLOSE_CHART_OPTIONS_PANEL`;
export const CLOSE_LEGEND_PANEL = `${namespace}/CLOSE_LEGEND_PANEL`;
export const CLOSE_NOTES_DIALOG = `${namespace}/CLOSE_NOTES_DIALOG`;
export const CLOSE_RIBBON_EVENT_DIALOG = `${namespace}/CLOSE_RIBBON_EVENT_DIALOG`;
export const CLOSE_RIBBON_DETAILS_PANEL = `${namespace}/CLOSE_RIBBON_DETAILS_PANEL`;
export const CLOSE_SHORTCUT_PANEL = `${namespace}/CLOSE_SHORTCUT_PANEL`;
export const CLOSE_VARIANCE_DIALOG = `${namespace}/CLOSE_VARIANCE_DIALOG`;

export const setRightPanelDialog: AC<AbstractDialog> = dialog => ({
  type: SET_RIGHT_PANEL_DIALOG,
  payload: dialog,
});

export const unsetRightPanelDialog: AC = () => ({
  type: SET_RIGHT_PANEL_DIALOG,
  payload: initialState.rightPanelDialog,
});

export const expandRightPanel: AC = () => ({
  type: EXPAND_RIGHT_PANEL,
});

export const expandRightPanelWithoutChangingDialog: AC = () => ({
  type: EXPAND_RIGHT_PANEL_WITHOUT_CHANGING_DIALOG,
});

export const collapseRightPanel: AC = () => ({
  type: COLLAPSE_RIGHT_PANEL,
});

export const changeFilterView = () => ({ type: CHANGE_FILTER_VIEW });
export const openFilterLayoutsPanel = () => ({
  type: OPEN_FILTER_LAYOUTS_PANEL,
});
export const closeFilterLayoutsPanel = () => ({
  type: CLOSE_FILTER_LAYOUTS_PANEL,
});

export const startXAxisDragging = () => ({ type: START_X_AXIS_DRAGGING });
export const startYAxisDragging = () => ({ type: START_Y_AXIS_DRAGGING });
export const stopXAxisDragging = () => ({ type: STOP_X_AXIS_DRAGGING });
export const stopYAxisDragging = () => ({ type: STOP_Y_AXIS_DRAGGING });
export const setWasDragging = () => ({ type: SET_WAS_DRAGGING });

export const switchRibbons: AC<{ name: string; checked: boolean }> =
  payload => ({
    type: SWITCH_RIBBONS,
    payload,
  });

export const switchTrellises: AC<{ name: string; checked: boolean }> =
  payload => ({
    type: SWITCH_TRELLISES,
    payload,
  });

export const setTrellises: AC<string[]> = payload => ({
  type: SET_TRELLISES,
  payload,
});

export const openImageDetailsOverlay = () => ({
  type: OPEN_IMAGE_DETAILS_OVERLAY,
});
export const closeImageDetailsOverlay = () => ({
  type: CLOSE_IMAGE_DETAILS_OVERLAY,
});

export const toggleIssuesVisibility = () => ({
  type: TOGGLE_ISSUES_VISIBILITY,
});

export const setUIState: AC<UIState> = payload => ({
  type: SET_UI_STATE,
  payload,
});

export const openAllocIssueAlertWindow: AC<{ index: number; id: string }> =
  payload => ({
    type: OPEN_ALLOC_ISSUE_ALERT_WINDOW,
    payload,
  });

export const closeAllocIssueAlertWindow = () => ({
  type: CLOSE_ALLOC_ISSUE_ALERT_WINDOW,
});

export const openCapacityDeleteAlertWindow: AC<number> = payload => ({
  type: OPEN_CAPACITY_DELETE_ALERT_WINDOW,
  payload,
});

export const openCapacityCancelEditsAlertWindow: AC<number> = payload => ({
  type: OPEN_CAPACITY_CANCEL_EDITS_ALERT_WINDOW,
  payload,
});

export const closeCapacityDeleteAlertWindow = () => ({
  type: CLOSE_CAPACITY_DELETE_ALERT_WINDOW,
});

export const closeCapacityCancelEditsAlertWindow = () => ({
  type: CLOSE_CAPACITY_CANCEL_EDITS_ALERT_WINDOW,
});

export const openVarianceAlertWindow: AC<number> = payload => ({
  type: OPEN_VARIANCE_ALERT_WINDOW,
  payload,
});

export const closeVarianceAlertWindow = () => ({
  type: CLOSE_VARIANCE_ALERT_WINDOW,
});

export const activateNotesPanel = () => ({
  type: ACTIVATE_NOTES_PANEL,
});

export const activateDrilldownPanel = () => ({
  type: ACTIVATE_DRILLDOWN_PANEL,
});

export const resetActivePanel = () => ({
  type: RESET_ACTIVE_PANEL,
});

export const setNotesProductionDay: AC<Date> = payload => ({
  type: SET_NOTES_PRODUCTION_DAY,
  payload,
});

export const debounceSetNotesProductionDay: AC<Date> = payload => ({
  type: DEBOUNCE_SET_NOTES_PRODUCTION_DAY,
  payload,
});

export const enableAdditionMode: AC<string> = payload => ({
  type: ENABLE_ADDITION_MODE,
  payload,
});

export const disableAdditionMode = () => ({
  type: DISABLE_ADDITION_MODE,
});

export const enableZoomInMode = () => ({
  type: ENABLE_ZOOM_IN_MODE,
});

export const disableZoomInMode = () => ({
  type: DISABLE_ZOOM_IN_MODE,
});

export const highlightCapacity: AC<number> = payload => ({
  type: HIGHLIGHT_CAPACITY,
  payload,
});

export const highlightCapacityDivider: AC<number> = payload => ({
  type: HIGHLIGHT_CAPACITY_DIVIDER,
  payload,
});

export const highlightVariance: AC<number> = payload => ({
  type: HIGHLIGHT_VARIANCE,
  payload,
});

export const highlightVarianceDivider: AC<number> = payload => ({
  type: HIGHLIGHT_VARIANCE_DIVIDER,
  payload,
});

export const highlightAllocIssue: AC<string> = payload => ({
  type: HIGHLIGHT_ALLOC_ISSUE,
  payload,
});

export const highlightAllocIssueDivider: AC<string> = payload => ({
  type: HIGHLIGHT_ALLOC_ISSUE_DIVIDER,
  payload,
});

export const highlightAllocIssueOff = () => ({
  type: HIGHLIGHT_ALLOC_ISSUE_OFF,
});

export const highlightAllocIssueDividerOff = () => ({
  type: HIGHLIGHT_ALLOC_ISSUE_DIVIDER_OFF,
});

export const highlightCapacityOff = () => ({
  type: HIGHLIGHT_CAPACITY_OFF,
});

export const highlightCapacityDividerOff = () => ({
  type: HIGHLIGHT_CAPACITY_DIVIDER_OFF,
});

export const highlightVarianceOff = () => ({
  type: HIGHLIGHT_VARIANCE_OFF,
});

export const highlightVarianceDividerOff = () => ({
  type: HIGHLIGHT_VARIANCE_DIVIDER_OFF,
});

export const highlightOffEverything = () => ({
  type: HIGHLIGHT_OFF_EVERYTHING,
});

export const enableGroupMode: AC<string> = payload => ({
  type: ENABLE_GROUP_MODE,
  payload,
});

export const disableGroupMode = () => ({
  type: DISABLE_GROUP_MODE,
});

export const changeGroupModeSubject: AC<string> = payload => ({
  type: CHANGE_GROUP_MODE_SUBJECT,
  payload,
});

export const setCurrentWellId: AC<string> = payload => ({
  type: SET_CURRENT_WELL_ID,
  payload,
});

export const setCurrentGroup: AC<{ subject: string; item: string }> = ({
  subject,
  item,
}) => ({
  type: SET_CURRENT_GROUP,
  payload: {
    subject,
    item: item || 'empty',
  },
});

export const resetCurrentGroup = () => ({
  type: RESET_CURRENT_GROUP,
});

export const enableRegionOfInterestMode = () => ({
  type: ENABLE_REGION_OF_INTEREST_MODE,
});

export const disableRegionOfInterestMode = () => ({
  type: DISABLE_REGION_OF_INTEREST_MODE,
});

export const enableNotesMode = () => ({
  type: ENABLE_NOTES_MODE,
});

export const disableNotesMode = () => ({
  type: DISABLE_NOTES_MODE,
});

export const setTooltipData: AC<{ data: TooltipData | null }> = payload => ({
  type: SET_TOOLTIP_DATA,
  payload,
});

export const highlightRibbonEventDividerOff = () => ({
  type: HIGHLIGHT_RIBBON_EVENT_DIVIDER_OFF,
});

export const highlightRibbonEventDivider: AC<string> = payload => ({
  type: HIGHLIGHT_RIBBON_EVENT_DIVIDER,
  payload,
});

export const openRibbonEventAlertWindow: AC<{ index: number; id: string }> =
  payload => ({
    type: OPEN_RIBBON_EVENT_ALERT_WINDOW,
    payload,
  });

export const closeRibbonEventAlertWindow = () => ({
  type: CLOSE_RIBBON_EVENT_ALERT_WINDOW,
});

export const openRequestHelpPanel: AC = () => ({
  type: OPEN_REQUEST_HELP_PANEL,
});

export const closeRequestHelpPanel: AC = () => ({
  type: CLOSE_REQUEST_HELP_PANEL,
});

export const setRibbons: AC<string[]> = payload => ({
  type: SET_RIBBONS,
  payload,
});

export const openEventConversation: AC = () => ({
  type: OPEN_EVENT_CONVERSATION,
});

export const closeEventConversation: AC = () => ({
  type: CLOSE_EVENT_CONVERSATION,
});
