import * as R from 'ramda';
import { all, put, takeLatest, select } from 'redux-saga/effects';

import { AUTHENTICATED } from 'modules/app/AppActions';
import { SET_CURRENT_WELL_ID } from 'modules/ui/UIActions';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import { getColumnMapping } from 'modules/well/WellReducer';
import { parseSearchParams } from 'modules/router/utils/router';

import {
  FETCH_GROUP_SERIES,
  fetchSeriesMapping,
  setGroupSeries,
  REFETCH_SERIES,
  fetchWellSeriesDateRange,
} from './SeriesActions';
import { getSeriesByWell } from './SeriesReducer';
import { normalizeSeries } from './utils';

import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import { getDataSeriesGroupsToDisplay } from 'modules/chartOptions/ChartOptionsReducer';

function* fetchSeriesMappingSaga(): Generator<any, any, any> {
  yield put(fetchSeriesMapping());
}

function* initFetchWellSeriesSaga(): Generator<any, any, any> {
  // TODO: handle the case when the series is disabled
  // const appConfig = yield select(getAppConfig);
  // if (!appConfig.timeSeries) {
  //   console.error('2', appConfig);

  //   return;
  // }
  const currentWellId = yield select(getCurrentWellId);
  if (!currentWellId) {
    return;
  }
  const currentWellSeries = yield select(state =>
    getSeriesByWell(state, { wellId: currentWellId }),
  );
  const groupToDisplay = yield select(getDataSeriesGroupsToDisplay);
  const seriesToDisplay = groupToDisplay.reduce(
    (acc, group) => acc.concat(group.ids),
    [],
  );
  if (R.isEmpty(currentWellSeries)) {
    const extemeDates = yield select(getExtremeDates);
    const searchString = window.location.search;
    const routeSearchParams = parseSearchParams(searchString);
    const dateSource = R.isNil(extemeDates) ? routeSearchParams : extemeDates;

    yield put(
      fetchWellSeriesDateRange({
        wellId: currentWellId,
        minDate: R.pathOr(new Date('01-01-15'), ['min'], dateSource),
        maxDate: R.pathOr(new Date(), ['max'], dateSource),
        series: seriesToDisplay,
      }),
    );
  }
}
// temporarily disable series for group mode
// function* initFetchGrouSeriesSaga(action): Generator<any, any, any> {
//   const { payload } = action;
//   const appConfig = yield select(getAppConfig);
//   if (!appConfig.timeSeries) {
//     return;
//   }
//   const groupMode = yield select(getGroupMode);
//   if (!groupMode.isOn) return;
//   const columnMapping = yield select(getColumnMappingBySource);
//   if (!columnMapping || R.isEmpty(columnMapping)) return;
//   const filters = yield select(getFilters);
//   const filtersWithoutEmpty = R.pickBy(isNotEmpty, filters);
//   const normalizedFilters = Object.keys(filtersWithoutEmpty).map(filterName => {
//     const columnIndex = columnMapping[filterName].columnIndex;

//     return {
//       columnIndex,
//       values: filtersWithoutEmpty[filterName],
//     };
//   });
//   const currentGroup = yield select(getCurrentGroup);
//   const subject = !R.isNil(payload.subject)
//     ? payload.subject
//     : currentGroup.subject;
//   const item = !R.isNil(payload.item)
//     ? payload.item === 'empty'
//       ? ''
//       : payload.item
//     : currentGroup.item;

//   if (R.isNil(subject) || R.isNil(item)) {
//     return;
//   }

//   const groupSeries = yield select(getGroupedSeries, { subject, item });
//   if (!R.isEmpty(groupSeries)) {
//     return;
//   }
//   const column = columnMapping[subject];
//   const group =
//     !!column && !R.isEmpty(column)
//       ? { name: item, columnIndex: column.columnIndex }
//       : null;
//   yield delay(700);

//   yield put(
//     fetchGroupSeries({
//       group,
//       filters: normalizedFilters,
//     }),
//   );
// }

function* normalizeGroupSeriesSaga(action): Generator<any, any, any> {
  const { seriesChartData } = getGraphqlPayload(action);
  const { groupData } = JSON.parse(seriesChartData);
  const prevActionPayload = getGraphqlPrevActionVariables(action);
  const {
    payload: { group },
  } = prevActionPayload;
  const columnMapping = yield select(getColumnMapping);
  const subject = group
    ? columnMapping.find(column => column.columnIndex === group.columnIndex)
        .sourceName
    : 'all';
  const groupName = group ? group.name : 'all';
  const normalizedSeries = normalizeSeries(groupData);
  yield put(
    setGroupSeries({
      groupSubject: subject,
      groupName: groupName,
      series: normalizedSeries,
    }),
  );
}

function* SeriesSaga(): Generator<any, any, any> {
  yield all([
    takeLatest(AUTHENTICATED, fetchSeriesMappingSaga),
    takeLatest([SET_CURRENT_WELL_ID, REFETCH_SERIES], initFetchWellSeriesSaga),
    //temporarily disable series for group mode
    // takeLatest(
    //   [SET_CURRENT_GROUP, REFETCH_GROUP_SERIES],
    //   initFetchGrouSeriesSaga,
    // ),
    takeLatest(`${FETCH_GROUP_SERIES}_SUCCESS`, normalizeGroupSeriesSaga),
  ]);
}

export default SeriesSaga;
