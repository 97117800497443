import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import * as React from 'react';
import styled from 'styled-components';

interface DevSubMenuProps {
  anchor: DOMRect;
  devFeatures: Record<string, boolean>;
  onChange: (props: [string, boolean]) => void;
  onClose: () => void;
}

const allowedDevFeatures = [
  'inboxConversation',
  'allocationIssues',
  'conversationsPolling',
  'spotfireDashboards',
];

const DevSubMenu = ({
  anchor,
  devFeatures,
  onChange,
  onClose,
}: DevSubMenuProps) => {
  const featuresForRendering = Object.keys(devFeatures)
    .filter(d => allowedDevFeatures.includes(d))
    .map((feature: string): [string, boolean] => [
      feature,
      devFeatures[feature],
    ]);
  return (
    <DevSubMenu.SubMenu
      anchor={anchor}
      onMouseLeave={() => setTimeout(onClose, 200)}
    >
      <List>
        {featuresForRendering.map(feature => (
          <DevSubMenu.FormControlLabel
            key={feature[0]}
            control={
              <Switch
                checked={feature[1]}
                onChange={() => onChange(feature)}
                value="quickEditMode"
                color="primary"
                disabled={
                  ['showExternalForecast', 'timeSeries'].includes(feature[0]) &&
                  devFeatures.capacityVsExternal
                }
              />
            }
            label={feature[0]}
          />
        ))}
      </List>
    </DevSubMenu.SubMenu>
  );
};

DevSubMenu.SubMenu = styled(Paper)`
  position: absolute;
  top: ${props => props.anchor.top - 56}px;
  left: ${props => props.anchor.width}px;
`;

DevSubMenu.FormControlLabel = styled(FormControlLabel)`
  width: 240px;
  margin: 0 !important;
  line-height: 1em !important;
  height: 30px;
  padding: 0 24px 0 0 !important;

  & .MuiTypography-root {
    font-size: 0.875rem !important;
  }

  & > span {
    text-transform: capitalize;
  }
`;

DevSubMenu.FormGroup = styled(FormGroup)`
  width: 155px;
`;

DevSubMenu.FormLabel = styled.legend`
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 9px;
  margin-left: 15px;
  margin-top: 10px;
`;
export default DevSubMenu;
