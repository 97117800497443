import * as R from 'ramda';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import DB from 'database';

import {
  fetchWellForecast,
  FETCH_WELL_FORECAST_DATE_RANGE,
} from 'modules/externalForecast/ExternalForecastActions';
import {
  fetchWellProduction,
  FETCH_WELL_PRODUCTION_DATE_RANGE,
} from 'modules/production/ProductionActions';
import {
  fetchWellSeries,
  FETCH_SERIES_DATE_RANGE,
} from 'modules/series/SeriesActions';
import { getGraphqlPrevActionVariables } from 'store/helpers';
import { getCurrentWellId } from 'modules/ui/UIReducer';
import {
  getForecast,
  getForecastFetchingStatus,
} from 'modules/externalForecast/ExternalForecastReducer';
import {
  getSeriesByWell,
  getSeriesFetchedStatusByWell,
} from 'modules/series/SeriesReducer';
import { getFullProduction } from 'modules/production/ProductionReducer';

function* initAllDataFetchSaga(action): Generator<any, any, any> {
  const {
    payload: { wellId },
  } = getGraphqlPrevActionVariables(action);
  const type = action.type;
  const currentWellId = yield select(getCurrentWellId);
  if (wellId !== currentWellId) {
    return;
  }
  const forecastPartialData = yield select(getForecast);
  const seriesPartialData = yield select(state =>
    getSeriesByWell(state, { wellId: currentWellId }),
  );
  const seriesFetchedStatusByWell = yield select(state =>
    getSeriesFetchedStatusByWell(state, { wellId: currentWellId }),
  );
  const forecastFetchingStatus = yield select(getForecastFetchingStatus);
  const productionPartialData = yield select(getFullProduction);

  if (
    (type === `${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS` ||
      !R.isEmpty(forecastPartialData) ||
      forecastFetchingStatus) &&
    (type === `${FETCH_WELL_PRODUCTION_DATE_RANGE}_SUCCESS` ||
      !R.isEmpty(productionPartialData)) &&
    (type === `${FETCH_SERIES_DATE_RANGE}_SUCCESS` ||
      !R.isEmpty(seriesPartialData) ||
      seriesFetchedStatusByWell)
  ) {
    yield put(fetchWellForecast(wellId));
    yield put(fetchWellSeries({ wellId }));
    const dataFromDB = yield call(DB.getWellProduction, currentWellId);
    if (R.isEmpty(dataFromDB)) {
      yield put(
        fetchWellProduction({
          wellId,
        }),
      );
    }
  }
}

function* chartSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      [
        `${FETCH_WELL_FORECAST_DATE_RANGE}_SUCCESS`,
        `${FETCH_WELL_PRODUCTION_DATE_RANGE}_SUCCESS`,
        `${FETCH_SERIES_DATE_RANGE}_SUCCESS`,
      ],
      initAllDataFetchSaga,
    ),
  ]);
}

export default chartSagas;
