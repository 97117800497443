import { max, min } from 'd3-array';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import SeriesPill from 'modules/chart/components/SeriesPill';
import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import {
  createVarianceDataMapForSelectedBar,
  createVarianceYScale,
} from 'modules/chart/utils';
import RegionOfInterest from 'modules/chart/components/RegionOfInterest';
import YAxis from 'modules/chart/components/YAxis';
import type { ProductionPoint } from 'modules/production/models/production';
import type {
  IdDialog,
  IdIndexDialog,
  TooltipData,
  TrellisTooltipData,
} from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import usePrevious from 'hooks/usePrevious';

import SVGVarianceInteraction from './SVGVarianceInteraction';
import SVGVarianceTrellis from './SVGVarianceTrellis';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';

interface DivVarianceChartProps {
  allocIssueDialog: IdIndexDialog;
  allWellAllocIssues: AllocIssue[];
  allocIssuesVisibility: boolean;
  capacity: CapacityChangeEvent[];
  capacityData: { date: { date: Date; capacity: number }[] };
  capacityDialog: { show: boolean; index: number };
  changeMinDrilldownTableDate: (minDate: Date) => void;
  changeMaxDrilldownTableDate: (maxDate: Date) => void;
  chartWasDragging: boolean;
  clearDrilldownTable: () => void;
  currentWellId: string;
  displaysRegionOfInterest: boolean;
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
    compareOption: string;
  };
  extremeDates: { min: Date; max: Date };
  eventColors: { [key: string]: string };
  finishDrag: () => void;
  format: string;
  hasCapacityChanges: boolean;
  height: number;
  highlightedAllocIssue: IdDialog;
  highlightedAllocIssueDivider: IdDialog;
  indicatorsCapacity: { date: { date: Date; capacity: number }[] };
  isDragging: boolean;
  isPossibleEditAlloc: boolean;
  isPossibleEditCapacity: boolean;
  isPossibleEditVariance: boolean;
  isXAxisDragging: boolean;
  isYAxisDragging: boolean;
  leftOffset: number;
  production: ProductionPoint[];
  startDrag: () => void;
  onAllocIssueDialogOpen: ({ index: number, id: string }) => void;
  onAllocIssueDividerHover: (eventId: string) => void;
  onAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  onCapacityDialogClose: () => void;
  onCapacityDialogOpen: (index: number, eventId: string) => void;
  onCapacityDividerHover: (eventId: number) => void;
  onDayInitChange: ({ capacityEventId: string, newDayInit: Date }) => void;
  onEventDividerHover: (eventId: number) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  onHighlightAllocIssueDividerOff: () => void;
  onHighlightCapacityDividerOff: () => void;
  onHighlightEventDividerOff: () => void;
  onLocalAllocIssueUpdate: (allocData: {
    updatedIssue: AllocIssue;
    data: Record<string, any>;
  }) => void;
  onPillClick: () => void;
  onStartYAxisDragging: () => void;
  onStopDragging: () => void;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  onVarianceEventUpdate: (varianceData: {
    dates: Date[];
    varianceEventId: string;
  }) => void;
  pillText: string;
  position: number;
  regionOfInterest: boolean;
  showBarHoverEffect: boolean;
  today: Date;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  varianceDialog: {
    show: boolean;
    index: number;
  };
  varianceEvents: VarianceEvent[];
  width: number;
  xScale: any;
  isLast: boolean;
}

const DivVarianceChart = ({
  allWellAllocIssues,
  allocIssueDialog,
  allocIssuesVisibility,
  capacity,
  capacityData,
  capacityDialog,
  changeMinDrilldownTableDate,
  changeMaxDrilldownTableDate,
  chartWasDragging,
  clearDrilldownTable,
  currentWellId,
  displaysRegionOfInterest,
  drilldownTableParams,
  eventColors,
  extremeDates,
  finishDrag,
  format,
  hasCapacityChanges,
  height,
  highlightedAllocIssue,
  highlightedAllocIssueDivider,
  indicatorsCapacity,
  isDragging,
  isPossibleEditCapacity,
  isPossibleEditVariance,
  isPossibleEditAlloc,
  isXAxisDragging,
  isYAxisDragging,
  leftOffset,
  pillText,
  production,
  startDrag,
  tooltipData,
  onAllocIssueDialogOpen,
  onAllocIssueDividerHover,
  onAllocIssueUpdate,
  onDayInitChange,
  onCapacityDividerHover,
  onCapacityDialogClose,
  onCapacityDialogOpen,
  onEventDividerHover,
  onXAxisScaling,
  onHighlightAllocIssueDividerOff,
  onHighlightCapacityDividerOff,
  onHighlightEventDividerOff,
  onLocalAllocIssueUpdate,
  onPillClick,
  onSetTooltipData,
  onStartYAxisDragging,
  onStopDragging,
  onVarianceDialogOpen,
  onVarianceEventUpdate,
  position,
  regionOfInterest,
  showBarHoverEffect,
  today,
  trellisTitle,
  varianceDialog,
  varianceEvents,
  width,
  xScale,
  isLast,
}: DivVarianceChartProps) => {
  const productionKey = drilldownTableParams.phase.toLowerCase();
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const currentCapacityData: Array<{ date: Date; capacity: number }> =
    drilldownTableParams.phase === 'BOE'
      ? indicatorsCapacity[drilldownTableParams.phase]
      : capacityData[drilldownTableParams.phase];

  const prodData = React.useMemo(
    () =>
      production.reduce((acc, p) => {
        acc[p.day.toISOString()] = p;

        return acc;
      }, {}),
    [production],
  );

  const varianceData: { date: Date; variance: number }[] = React.useMemo(() => {
    const reverseCapacityData = R.reverse([...currentCapacityData]);

    return R.flatten(reverseCapacityData).reduce((acc, cap) => {
      if (
        cap.date.getTime() > today.getTime() ||
        cap.date.getTime() > extremeDates.max.getTime() ||
        cap.date.getTime() < extremeDates.min.getTime()
      ) {
        return acc;
      }
      if (prodData[cap.date.toISOString()]) {
        acc.push({
          date: cap.date,
          variance:
            prodData[cap.date.toISOString()][productionKey] - cap.capacity,
        });
      }

      return acc;
    }, []);
  }, [currentCapacityData, prodData, productionKey, today, extremeDates]);
  const tempMaxDataPoint = React.useMemo((): number => {
    const maxData: any = R.isEmpty(varianceData)
      ? 0
      : max(
          varianceData,
          (v: { date: Date; variance: number }): number => v.variance,
        );
    return maxData < 1 ? 1 : (maxData as number);
  }, [varianceData]);
  const tempMinDataPoint = React.useMemo(() => {
    const minData: any = R.isEmpty(varianceData)
      ? 0
      : min(varianceData, v => v.variance);
    return minData > -1 ? -1 : (minData as number);
  }, [varianceData]);

  const padding = Math.max(tempMaxDataPoint, Math.abs(tempMinDataPoint)) * 0.1;
  const minDataPoint = tempMinDataPoint - padding;
  const maxDataPoint = tempMaxDataPoint + padding;

  const [displayMinDataPoint, setDisplayMinDataPoint] =
    React.useState(minDataPoint);
  const [displayMaxDataPoint, setDisplayMaxDataPoint] =
    React.useState(maxDataPoint);

  const yScale = React.useMemo(
    () =>
      createVarianceYScale(height, displayMinDataPoint, displayMaxDataPoint),
    [height, displayMinDataPoint, displayMaxDataPoint],
  );
  const [isAdjusted, setIsAdjusted] = React.useState(false);
  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setDisplayMinDataPoint(minDataPoint);
    setDisplayMaxDataPoint(maxDataPoint);
  }, [
    setIsAdjusted,
    setDisplayMinDataPoint,
    setDisplayMaxDataPoint,
    maxDataPoint,
    minDataPoint,
  ]);

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );

  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );
  const prevMaxDataPoint = usePrevious(maxDataPoint);
  const prevMinDataPoint = usePrevious(minDataPoint);
  const prevWellId = usePrevious(currentWellId);

  React.useEffect(() => {
    if (
      !R.isNil(prevMaxDataPoint) &&
      !isAdjusted &&
      (prevMaxDataPoint !== maxDataPoint ||
        prevMinDataPoint !== minDataPoint) &&
      (maxDataPoint !== displayMaxDataPoint ||
        minDataPoint !== displayMinDataPoint)
    ) {
      setDisplayMaxDataPoint(maxDataPoint);
      setDisplayMinDataPoint(minDataPoint);
    }
  }, [
    prevMaxDataPoint,
    prevMinDataPoint,
    maxDataPoint,
    minDataPoint,
    displayMaxDataPoint,
    displayMinDataPoint,
    isAdjusted,
  ]);

  React.useEffect(() => {
    if (prevWellId && prevWellId !== currentWellId) resetMax();
  }, [currentWellId, prevWellId, resetMax]);

  const dateMap = React.useMemo(
    () =>
      createVarianceDataMapForSelectedBar(
        varianceData,
        varianceEvents,
        allWellAllocIssues,
      ),
    [varianceData, varianceEvents, allWellAllocIssues],
  );

  const tooltipDate =
    R.pathOr(false, ['trellisTooltipData', 'day'], tooltipData) ||
    R.pathOr(false, ['dataSeriesTooltipData', 'day'], tooltipData) ||
    null;

  const varianceTooltipData = tooltipDate
    ? R.path([tooltipDate.toISOString()], dateMap)
    : null;

  return (
    <>
      <DivVarianceChart.Container height={height} isLast={isLast}>
        <DivVarianceChart.SVGWrapper className="trellis-chart-wrapper">
          <SVGVarianceTrellis
            allocIssues={allWellAllocIssues}
            allocIssueDialog={allocIssueDialog}
            allocIssuesVisibility={allocIssuesVisibility}
            eventColors={eventColors}
            height={height}
            highlightedAllocIssue={highlightedAllocIssue}
            highlightedAllocIssueDivider={highlightedAllocIssueDivider}
            isPossibleEditAlloc={isPossibleEditAlloc}
            isAxisDragging={isXAxisDragging || isYAxisDragging}
            maxDataPoint={displayMaxDataPoint}
            minDataPoint={displayMinDataPoint}
            onSetTooltipData={onSetTooltipData}
            showBarHoverEffect={showBarHoverEffect}
            tooltipData={tooltipData}
            trellisTitle={trellisTitle}
            varianceData={varianceData}
            varianceEvents={varianceEvents}
            xScale={xScale}
            yAxisLinePos={yAxisLinePos}
          />
          {!(isXAxisDragging && chartWasDragging) && !isYAxisDragging && (
            <SVGVarianceInteraction
              allocIssueDialog={allocIssueDialog}
              allWellAllocIssues={allWellAllocIssues}
              allocIssuesVisibility={allocIssuesVisibility}
              capacity={capacity}
              capacityDialog={capacityDialog}
              finishDrag={finishDrag}
              height={height}
              leftOffset={leftOffset}
              isAxisDragging={isXAxisDragging}
              isDragging={isDragging}
              isPossibleEditAlloc={isPossibleEditAlloc}
              isPossibleEditCapacity={isPossibleEditCapacity}
              isPossibleEditVariance={isPossibleEditVariance}
              onAllocIssueDialogOpen={onAllocIssueDialogOpen}
              onAllocIssueDividerHover={onAllocIssueDividerHover}
              onAllocIssueUpdate={onAllocIssueUpdate}
              onLocalAllocIssueUpdate={onLocalAllocIssueUpdate}
              onCapacityDialogOpen={onCapacityDialogOpen}
              onCapacityDividerHover={onCapacityDividerHover}
              onDayInitChange={onDayInitChange}
              onEventDividerHover={onEventDividerHover}
              onXAxisScaling={onXAxisScaling}
              onHighlightAllocIssueDividerOff={onHighlightAllocIssueDividerOff}
              onHighlightCapacityDividerOff={onHighlightCapacityDividerOff}
              onHighlightEventDividerOff={onHighlightEventDividerOff}
              onVarianceDialogOpen={onVarianceDialogOpen}
              onVarianceEventUpdate={onVarianceEventUpdate}
              startDrag={startDrag}
              today={today}
              trellisTitle={productionKey}
              varianceData={varianceData}
              varianceDialog={varianceDialog}
              varianceEvents={varianceEvents}
              width={width}
              xScale={xScale}
              yScale={yScale}
            />
          )}
          {displaysRegionOfInterest &&
            regionOfInterest &&
            drilldownTableParams && (
              <RegionOfInterest
                changeMaxDrilldownTableDate={changeMaxDrilldownTableDate}
                changeMinDrilldownTableDate={changeMinDrilldownTableDate}
                clearDrilldownTable={clearDrilldownTable}
                hasCapacityChanges={hasCapacityChanges}
                leftOffset={leftOffset}
                maxDate={drilldownTableParams.maxDate}
                minDate={drilldownTableParams.minDate}
                xScale={xScale}
                startDrag={startDrag}
                finishDrag={finishDrag}
                height={height}
                width={width}
                position={height * position}
                onCapacityDialogClose={onCapacityDialogClose}
              />
            )}
        </DivVarianceChart.SVGWrapper>
        <DivVarianceChart.YAxisContainer>
          <YAxis
            format={format}
            height={height}
            hideLine={hideLine}
            maxDataPoint={displayMaxDataPoint}
            minDataPoint={displayMinDataPoint}
            onStartYAxisDragging={onStartYAxisDragging}
            onStopDragging={onStopDragging}
            setDisplayMaxDataPoint={setDisplayMaxDataPoint}
            setDisplayMinDataPoint={setDisplayMinDataPoint}
            isDragging={isYAxisDragging}
            isXAxisDragging={isXAxisDragging}
            resetMax={resetMax}
            isAdjusted={isAdjusted}
            setIsAdjusted={setIsAdjusted}
            showLine={showLine}
            varianceTrellis
            yScale={yScale}
          />
        </DivVarianceChart.YAxisContainer>
        <SeriesPill onPillClick={onPillClick} text={pillText} />
        {tooltipData &&
          (tooltipData.trellisTooltipData ||
            tooltipData.ribbonTooltipData ||
            tooltipData.dataSeriesTooltipData) &&
          tooltipData.trellisTooltipData?.trellis !== trellisTitle && (
            <SecondaryInformationTooltip
              containerHeight={height}
              secondaryCavTooltipData={{}}
              leftOffset={leftOffset}
              tooltipData={tooltipData}
              trellisTitle={trellisTitle}
              varianceTooltipData={varianceTooltipData}
              yScale={yScale}
            />
          )}
      </DivVarianceChart.Container>
    </>
  );
};

DivVarianceChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

DivVarianceChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

DivVarianceChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

export default React.memo<DivVarianceChartProps>(DivVarianceChart);
