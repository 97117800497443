import { push } from 'connected-react-router';
import * as R from 'ramda';
import { all, put, select, takeLatest, putResolve } from 'redux-saga/effects';

import { getIsAuthenticated } from 'modules/auth/AuthReducer';
import {
  getCoreSeriesOptionsToDisplay,
  getDataSeriesOptionsGroups,
} from 'modules/chartOptions/ChartOptionsReducer';
import {
  setDrilldownTableParams,
  setSortCriteria,
  setSortDirection,
  setSortVarDirectionIndex,
} from 'modules/drilldownTable/DrilldownTableActions';
import {
  getDrilldownTableParams,
  getSortCriteria,
  getSortDirection,
  getSortVarDirectionIndex,
} from 'modules/drilldownTable/DrilldownTableReducer';
import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import { getForecastStatus } from 'modules/externalForecast/ExternalForecastReducer';

import { clearAllFilters } from 'modules/filter/FilterActions';
import { getFilters } from 'modules/filter/FilterReducer';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import { encodeGroup, SearchParams } from 'modules/router/utils/router';
import { EventPanel, EventPanelData } from 'modules/router/models/router';
import { setUIState } from 'modules/ui/UIActions';
import { getUIState } from 'modules/ui/UIReducer';
import { getGraphqlPayload } from 'store/helpers';

import {
  INIT_SHARE_SETTINGS,
  FETCH_SETTINGS,
  shareSettings,
} from './SettingsActions';
import { sharedUISettings } from './models/settings';
import { UIState } from 'modules/ui/models/ui';
import { redirectToChartFromSettings } from 'modules/router/RouterActions';

function* compileSettingsDataSaga(): Generator<any, any, any> {
  const extremeDates = yield select(getExtremeDates);
  const filters = yield select(getFilters);
  const sortCriteria = yield select(getSortCriteria, null);
  const sortDirection = yield select(getSortDirection, null);
  const sortVarDirectionIndex = yield select(getSortVarDirectionIndex, null);
  const drilldownTableParams = yield select(getDrilldownTableParams, null);
  const chartOptionsGroups = yield select(getDataSeriesOptionsGroups);
  const coreSeriesOption = yield select(getCoreSeriesOptionsToDisplay);
  const forecastStatus = yield select(getForecastStatus);
  const ui = yield select(getUIState);
  const sharedUI = R.pick(sharedUISettings, ui);
  const dataForSharing = {
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    drilldownTableParams,
    extremeDates,
    coreSeriesOption,
    filters,
    ui: sharedUI,
    chartOptionsGroups,
    forecastStatus,
  };
  yield put(shareSettings(dataForSharing));
}

function* populateSettingsSaga(action): Generator<any, any, any> {
  const isAuthenticated = yield select(getIsAuthenticated);
  if (!isAuthenticated) {
    yield put(push('/login'));
  }
  const payload = getGraphqlPayload(action);
  const settings = JSON.parse(payload.settings);

  const {
    drilldownTableParams,
    extremeDates,
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    ui,
    dataSeriesOptionsGroups,
    coreSeriesOption,
    forecastStatus,
  } = settings;

  yield putResolve(clearAllFilters());
  // Temporary disabled filters restoring
  // if (filters) {
  //   const filterNamesToSet = R.keys(filters).filter(
  //     filterName => !R.isEmpty(filters[filterName]),
  //   );
  //   yield all(
  //     filterNamesToSet.map(filterName =>
  //       putResolve(
  //         addSeveralFilters({
  //           filterName,
  //           filterValues: filters[filterName],
  //         }),
  //       ),
  //     ),
  //   );
  // }

  // if (ui.ribbons.ribbonDetailsPanel.index) {
  //   yield put(openRibbonDetailsPanel(ui.ribbons.ribbonDetailsPanel.index));
  // }
  // if (ui.ribbonDialog.index) {
  //   yield put(openRibbonEventDialog(ui.ribbonDialog.index));
  // }
  if (sortCriteria && sortDirection && !R.isNil(sortVarDirectionIndex)) {
    yield put(setSortCriteria(sortCriteria));
    yield put(setSortDirection(sortDirection));
    yield put(setSortVarDirectionIndex(sortVarDirectionIndex));
  }

  if (drilldownTableParams) {
    yield put(setDrilldownTableParams(drilldownTableParams));
  }

  if (ui) {
    const newUI: UIState = R.assoc<Date, Record<string, any>>(
      'notesProductionDay',
      new Date(ui.notesProductionDay),
      ui,
    );
    yield put(setUIState(newUI));
  }

  let eventPanel: EventPanelData | null = null;

  if (ui?.rightPanelDialog) {
    const match = /\w[a-z]+/.exec(ui.rightPanelDialog.type);
    const type = match ? match[0].toLowerCase() : null;
    if (type && EventPanel[type] !== undefined)
      eventPanel = { type: EventPanel[type], id: ui.rightPanelDialog.data.id };
  }

  const searchParams: SearchParams = {
    extremeDates: {
      min: new Date(extremeDates.min),
      max: new Date(extremeDates.max),
    },
    coreSeries: coreSeriesOption
      ? coreSeriesOption.map(option => option.id)
      : [],
    dataSeries: dataSeriesOptionsGroups,
    legend: ui ? ui.rightPanelExpanded : true,
    grossNet: drilldownTableParams ? drilldownTableParams.grossNet : 'Gross',
    ribbons: R.keys(ui.ribbons.selectedRibbons),
    eventPanel,
    external: forecastStatus,
    compare: drilldownTableParams
      ? drilldownTableParams.compareOption
      : COMPARE_OPTION.actual,
    debug: '0',
    drilldown: true,
    min: new Date(extremeDates.min),
    max: new Date(extremeDates.max),
  };
  const wellId = !ui.groupMode.isOn && ui.currentWellId;

  if (wellId) {
    yield put(
      redirectToChartFromSettings({
        pathname: `/dashboard/${wellId}`,
        search: searchParams,
      }),
    );
  } else if (ui.groupMode && ui.groupMode.isOn && ui.currentGroup) {
    const encodedGroup = encodeGroup(ui.groupSubject, ui.groupItem);
    yield put(
      redirectToChartFromSettings({
        pathname: `/dashboard/${encodedGroup.subject}/${encodedGroup.item
          .split(' ')
          .join('-')}`,
        search: searchParams,
      }),
    );
  } else {
    yield put(
      redirectToChartFromSettings({
        pathname: `/dashboard/`,
        search: searchParams,
      }),
    );
  }
}

function* redirectSaga(action): Generator<any, any, any> {
  yield put(push('/login'));
}

function* settingsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(INIT_SHARE_SETTINGS, compileSettingsDataSaga),
    takeLatest(`${FETCH_SETTINGS}_SUCCESS`, populateSettingsSaga),
    takeLatest(`${FETCH_SETTINGS}_FAIL`, redirectSaga),
  ]);
}

export default settingsSagas;
