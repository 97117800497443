import { utcMonth, utcDay, utcSunday, utcYear, TimeInterval } from 'd3-time';
import { utcFormat } from 'd3-time-format';
import * as React from 'react';
import styled from 'styled-components';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';

import Button from 'components/Button';
import Portal from 'components/Portal';

import usePrevious from 'hooks/usePrevious';
import useMouseDownOutside from 'hooks/useMouseDownOutside';

const DROPDOWN_HEIGHT = 235;
const DROPDOWN_WIDTH = 241;

const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const createMonthMatrix = (date: Date): Array<Date[]> => {
  const firstDayOfMonth = utcMonth.floor(date);
  const lastDayOfMonth =
    utcMonth.ceil(date).getTime() !== firstDayOfMonth.getTime()
      ? utcMonth.ceil(date)
      : utcMonth.offset(firstDayOfMonth, 1);
  const firstDayOfTableNumber = utcSunday.floor(firstDayOfMonth);
  const lastDayOfTableNumber = utcSunday.ceil(lastDayOfMonth);
  const range = (utcDay.every(1) as TimeInterval).range(
    firstDayOfTableNumber,
    lastDayOfTableNumber,
  );
  const weeksQuant = Math.ceil(
    utcDay.count(firstDayOfTableNumber, lastDayOfTableNumber) / 7,
  );

  return Array.from({ length: weeksQuant }, (day, i) => {
    const weekIndex = i + 1;
    const weekRange = range.slice(i * 7, weekIndex * 7);
    return weekRange;
  });
};
interface DatePickerProps {
  close: () => void;
  maxDate: Date;
  minDate: Date;
  selectedDate: Date | null;

  inputSizes: DOMRect | Record<string, any>;
  inputEl: React.RefObject<HTMLElement | null>;
  onChange: (date: string) => void;
  defaultDate: Date;
}

const DatePicker = ({
  maxDate,
  minDate,
  selectedDate,
  inputSizes,
  close,
  onChange,
  defaultDate,
  inputEl,
}: DatePickerProps) => {
  const [tempDate, setTempDate] = React.useState(selectedDate || defaultDate); // date of current viewed month
  const refDate = React.useRef({ tempDate });
  React.useEffect(() => {
    refDate.current = { tempDate };
  }, [tempDate]);
  const prevDate = usePrevious({ selectedDate, tempDate });

  const matrix = createMonthMatrix(tempDate);

  const changeWithValidation = React.useCallback(
    date => {
      onChange(utcFormat('%x')(date));
    },
    [onChange],
  );

  const incrementMonth = () => {
    const incremented = utcMonth.offset(tempDate, 1);
    const tempDateMonth = tempDate.getMonth();
    const incrementedMonth = incremented.getMonth();
    if (incrementedMonth - tempDateMonth === 2) incremented.setDate(0);
    if (incremented > maxDate) {
      setTempDate(maxDate);
    } else {
      setTempDate(incremented);
    }
  };
  const decrementMonth = () => {
    const decremented = utcMonth.offset(tempDate, -1);
    const tempDateMonth = tempDate.getMonth();
    const decrementedMonth = decremented.getMonth();
    if (tempDateMonth === decrementedMonth) decremented.setDate(0);
    if (decremented < minDate) {
      setTempDate(minDate);
    } else {
      setTempDate(decremented);
    }
  };
  const incrementYear = () => {
    const incremented = utcYear.offset(tempDate, 1);
    if (incremented > maxDate) {
      setTempDate(maxDate);
    } else {
      setTempDate(incremented);
    }
  };
  const decrementYear = () => {
    const decremented = utcYear.offset(tempDate, -1);
    if (decremented < minDate) {
      setTempDate(minDate);
    } else {
      setTempDate(decremented);
    }
  };

  React.useEffect(() => {
    return () => {
      if (refDate && refDate.current)
        changeWithValidation(refDate.current.tempDate);
    };
  }, [changeWithValidation]);

  // To update calendar, when date input updates
  React.useEffect(() => {
    if (
      prevDate &&
      selectedDate &&
      tempDate.getTime() === prevDate.tempDate.getTime()
    ) {
      if (
        !prevDate.selectedDate ||
        (prevDate.selectedDate &&
          selectedDate.getTime() !== prevDate.selectedDate.getTime())
      ) {
        setTempDate(selectedDate);
      }
    }
  }, [selectedDate, prevDate, tempDate]);

  const refEl = React.useRef<HTMLElement>(null);
  const rect = inputEl?.current?.getBoundingClientRect();

  useMouseDownOutside([refEl, inputEl], close);

  return (
    <Portal>
      <DatePicker.Container
        id="date-picker"
        inputSizes={inputSizes}
        ref={refEl}
        anchor={rect}
      >
        <DatePicker.Header>
          <Button
            width={25}
            height={25}
            transparent
            onClick={() => decrementYear()}
            style={{ padding: 0 }}
          >
            <FaAngleDoubleLeft size="1em" />
          </Button>
          <Button
            width={22}
            height={25}
            transparent
            onClick={() => decrementMonth()}
            style={{ padding: 0 }}
          >
            <FaAngleLeft size="1em" />
          </Button>

          <DatePicker.RangeTitle>
            {utcFormat('%B %Y')(tempDate)}
          </DatePicker.RangeTitle>

          <Button
            width={22}
            height={25}
            transparent
            onClick={() => incrementMonth()}
            style={{ padding: 0 }}
          >
            <FaAngleRight size="1em" />
          </Button>
          <Button
            width={25}
            height={25}
            transparent
            onClick={() => incrementYear()}
            style={{ padding: 0 }}
          >
            <FaAngleDoubleRight size="1em" />
          </Button>
        </DatePicker.Header>

        <DatePicker.Table>
          <tbody>
            <tr>
              {WEEK_DAYS.map(day => (
                <th key={day}>{day}</th>
              ))}
            </tr>
            {matrix.map(week => (
              <tr key={`row_${week[0].toISOString()}`}>
                {week.map(day => {
                  const dateNum = utcFormat('%e')(day);
                  if (day.getTime() === tempDate.getTime()) {
                    return (
                      <DatePicker.CurrentDateCell
                        key={dateNum}
                        onClick={async () => {
                          await setTempDate(day);
                          close();
                        }}
                      >
                        {dateNum}
                      </DatePicker.CurrentDateCell>
                    );
                  } else if (day < minDate || day > maxDate) {
                    return (
                      <DatePicker.InvalidDate key={dateNum}>
                        {dateNum}
                      </DatePicker.InvalidDate>
                    );
                  } else if (day.getMonth() !== tempDate.getMonth()) {
                    return (
                      <DatePicker.NotCurrentMonthCell
                        key={dateNum}
                        onClick={async () => {
                          await setTempDate(day);
                          close();
                        }}
                      >
                        {dateNum}
                      </DatePicker.NotCurrentMonthCell>
                    );
                  }
                  return (
                    <td
                      key={dateNum}
                      onClick={async () => {
                        await setTempDate(day);
                        close();
                      }}
                    >
                      {dateNum}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </DatePicker.Table>
      </DatePicker.Container>
    </Portal>
  );
};

DatePicker.Container = styled.div`
  position: absolute;
  left: ${(props: Record<string, any>) =>
    props.inputSizes.left + DROPDOWN_WIDTH > window.innerWidth
      ? `${window.innerWidth - DROPDOWN_WIDTH - 10}px`
      : `${props.anchor.x}px`};
  top: ${props => props.anchor.y + 35}px;
  z-index: 1000;
  max-height: ${DROPDOWN_HEIGHT}px;
  min-height: ${DROPDOWN_HEIGHT - 25}px;
  width: ${DROPDOWN_WIDTH}px;
  padding: 9px;
  background: #fff;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.2), 0 6px 12px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid lightgrey;
`;

DatePicker.Header = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

DatePicker.RangeTitle = styled.div`
  font-weight: bold;
  font-size: 13px;
  width: 120px;
  text-align: center;
`;

DatePicker.Table = styled.table`
  width: 100%;
  font-size: 12px;
  border-collapse: collapse;

  & tr {
    padding-top: 3px;
  }

  & th {
    width: 30px;
    font-weight: bold;
    text-align: center;
  }

  & td {
    width: 30px;
    text-align: center;
    padding: 4px 0 3px 0;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
`;

DatePicker.CurrentDateCell = styled.td`
  background-color: ${(props: Record<string, any>) =>
    props.theme.colors.primary};
  color: white;
`;

DatePicker.NotCurrentMonthCell = styled.td`
  color: rgba(0, 0, 0, 0.4);
`;

DatePicker.InvalidDate = styled.td`
  color: rgba(0, 0, 0, 0.15);
`;

export default React.memo<DatePickerProps>(DatePicker);
