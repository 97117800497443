import * as R from 'ramda';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { getGraphqlPayload } from 'store/helpers';

import { AUTHENTICATED } from 'modules/app/AppActions';

import {
  fetchAppConfig,
  SET_APP_CONFIG,
  FETCH_APP_CONFIG,
  normalizeAppConfig,
} from './AppConfigActions';
import { getAppConfig } from './AppConfigReducer';
import { getIsAdmin } from 'modules/auth/AuthReducer';

function* fetchAppConfigsSaga(): Generator<any, any, any> {
  yield put(fetchAppConfig());
}

function* normilizeAppConfigsSaga(action): Generator<any, any, any> {
  const persistedConfig = yield select(getAppConfig);
  const config = getGraphqlPayload(action);
  const isAdmin = yield select(getIsAdmin);
  const devFeatures = Object.values(persistedConfig).filter(
    v => typeof v === 'boolean',
  );
  const configToMerge =
    devFeatures.length > 3 && isAdmin
      ? R.omit(
          [
            'drilldownDefaultFromDate',
            'drilldownDefaultToDate',
            'today',
            '_persist',
          ],
          persistedConfig,
        )
      : {};

  yield put(
    normalizeAppConfig({
      ...config,
      ...configToMerge,
    }),
  );
}

function* appConfigSagas(): Generator<any, any, any> {
  yield all([
    takeLatest([`${FETCH_APP_CONFIG}_SUCCESS`], normilizeAppConfigsSaga),
    takeLatest([AUTHENTICATED, `${SET_APP_CONFIG}_FAIL`], fetchAppConfigsSaga),
  ]);
}

export default appConfigSagas;
