import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getSearchHighlightsHtml } from 'helpers';

import SearchForm from 'components/SearchForm';
import Button from 'components/Button';
import { PlusIcon } from 'components/Icons';
import LongNameMouseTooltip from 'components/LongNameMouseTooltip/LongNameMouseTooltip';
import { WellColumnMappingItem } from 'modules/well/models/well';

import { LayoutOption } from '../models';
import { selectFilterOption } from '../FilterLayoutsActions';
import { CustomAttribute } from './CustomAttribute';

type FiltersLayoutOption = { custom?: boolean } & LayoutOption;

interface AvailableFilterMenu {
  checkIfAttributeNameAlreadyExists: (name: string) => boolean;
  columnMapingByIndex: { [id: string]: WellColumnMappingItem };
  filtersLayoutsOptions: LayoutOption[];
  customFiltersLayoutsOptions: LayoutOption[];
  height: number;
  setDragHandleDragging: () => void;
}

const AvailableFiltersMenu = ({
  checkIfAttributeNameAlreadyExists,
  columnMapingByIndex,
  filtersLayoutsOptions,
  customFiltersLayoutsOptions,
  height,
  setDragHandleDragging,
}: AvailableFilterMenu) => {
  const dispatch = useDispatch();

  const [searchWord, setSearchWord] = React.useState('');
  const [tooltipText, setTooltipText] = React.useState('');
  const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });
  const search = React.useMemo(() => searchWord.toLowerCase(), [searchWord]);

  const getOptionDesc = React.useCallback(
    (option: FiltersLayoutOption) => {
      return columnMapingByIndex?.[option.id]?.description || '';
    },
    [columnMapingByIndex],
  );

  const getOptionSource = React.useCallback(
    (option: FiltersLayoutOption) => {
      return columnMapingByIndex?.[option.id]?.source || '';
    },
    [columnMapingByIndex],
  );

  const selectOption = React.useCallback(
    (optionId: string) => dispatch(selectFilterOption({ optionId })),
    [dispatch],
  );

  const makeOnMouseMoveHandler = React.useCallback(
    (option: FiltersLayoutOption) => {
      if (option.custom) return undefined;
      return (e: MouseEvent) =>
        setTooltipPosition({ x: e.clientX, y: e.clientY });
    },
    [setTooltipPosition],
  );

  const makeOnMouseEnterHandler = React.useCallback(
    (option: FiltersLayoutOption) => {
      if (option.custom) return undefined;
      return () => setTooltipText(getOptionDesc(option));
    },
    [setTooltipText, getOptionDesc],
  );

  const makeOnMouseLeaveHandler = React.useCallback(
    (option: FiltersLayoutOption) => {
      if (option.custom) return undefined;
      return () => setTooltipText('');
    },
    [setTooltipText],
  );

  const getSearchedHtml = React.useCallback(
    (htmlString: string) => {
      const __html = getSearchHighlightsHtml(htmlString, search);
      return { __html };
    },
    [search],
  );

  const availableAttributes = React.useMemo(() => {
    const arr: FiltersLayoutOption[] = [];
    filtersLayoutsOptions.forEach(o => arr.push(o));
    customFiltersLayoutsOptions.forEach(o => arr.push({ ...o, custom: true }));
    return arr.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, [customFiltersLayoutsOptions, filtersLayoutsOptions]);

  const attributes = React.useMemo(() => {
    if (!search) return availableAttributes;
    return availableAttributes.filter(attr => {
      if (attr.displayName.toLowerCase().includes(search)) return true;
      if (getOptionSource(attr).toLowerCase().includes(search)) return true;
      return false;
    });
  }, [availableAttributes, getOptionSource, search]);

  return (
    <>
      <AvailableFiltersMenu.Wrapper>
        <div>
          <AvailableFiltersMenu.LineWrapper>
            <AvailableFiltersMenu.DragHandle
              onMouseDown={setDragHandleDragging}
            >
              <AvailableFiltersMenu.DragIcon />
            </AvailableFiltersMenu.DragHandle>
          </AvailableFiltersMenu.LineWrapper>

          <div
            style={{
              height: height + 'px',
              overflowY: 'auto',
            }}
          >
            <AvailableFiltersMenu.SearchContainer>
              <SearchForm
                placeholder="Search"
                inputHandler={setSearchWord}
                value={searchWord}
                fullWidth
              />
            </AvailableFiltersMenu.SearchContainer>

            <div>
              <AvailableFiltersMenu.CustomAttributesLegend>
                <span>Available Attributes</span>
              </AvailableFiltersMenu.CustomAttributesLegend>

              <AvailableFiltersMenu.Container>
                {attributes.length ? (
                  <AvailableFiltersMenu.OptionWrapper>
                    {attributes.map((option, i) => {
                      const displayNameHtml = getSearchedHtml(
                        option.displayName,
                      );

                      return (
                        <AvailableFiltersMenu.ItemWrapper
                          isFirst={i === 0}
                          key={option.id}
                          onMouseMove={makeOnMouseMoveHandler(option)}
                          onMouseLeave={makeOnMouseLeaveHandler(option)}
                          onMouseEnter={makeOnMouseEnterHandler(option)}
                        >
                          {option.custom && (
                            <CustomAttribute
                              displayNameHtml={displayNameHtml}
                              attribute={option}
                              checkIfAttributeNameAlreadyExists={
                                checkIfAttributeNameAlreadyExists
                              }
                            />
                          )}

                          {!option.custom && (
                            <AvailableFiltersMenu.FilterOptionName>
                              <span dangerouslySetInnerHTML={displayNameHtml} />
                              <AvailableFiltersMenu.Source
                                dangerouslySetInnerHTML={getSearchedHtml(
                                  getOptionSource(option),
                                )}
                              />
                            </AvailableFiltersMenu.FilterOptionName>
                          )}

                          <AvailableFiltersMenu.AddButton
                            onClick={() => selectOption(option.id)}
                          >
                            <PlusIcon />
                          </AvailableFiltersMenu.AddButton>
                        </AvailableFiltersMenu.ItemWrapper>
                      );
                    })}
                  </AvailableFiltersMenu.OptionWrapper>
                ) : null}
              </AvailableFiltersMenu.Container>
            </div>
          </div>
        </div>
        {tooltipText ? (
          <LongNameMouseTooltip
            position={tooltipPosition}
            text={tooltipText}
            maxWidth={0}
            isAlwaysShow
          />
        ) : null}
      </AvailableFiltersMenu.Wrapper>
    </>
  );
};

AvailableFiltersMenu.DragHandle = styled.div`
  z-index: 53 !important;
  position: relative;
  top: -7px;
  min-height: 12px;
  max-height: 100%;
  width: 54px;
  cursor: ns-resize;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  border: 1px solid #c1c1c1;
  z-index: 2;
`;

AvailableFiltersMenu.LineWrapper = styled.div`
  height: 8px;
  display: flex;
  justify-content: center;
  position: relative;
`;

AvailableFiltersMenu.DragIcon = styled.div`
  height: 4px;
  width: 20px;
  border-bottom: ${props => props.theme.borders.thingray};
  border-top: ${props => props.theme.borders.thingray};
`;

AvailableFiltersMenu.TrellisMenuLegend = styled.legend`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0 7px;
`;

AvailableFiltersMenu.CustomAttributesLegend = styled(
  AvailableFiltersMenu.TrellisMenuLegend,
)`
  display: flex;
  justify-content: space-between;
`;

AvailableFiltersMenu.SmallButton = styled(Button)`
  height: initial;
  width: initial;
  font-weight: bold;
  margin-right: 7px;
`;

AvailableFiltersMenu.BackgroundlessButton = styled(Button)`
  height: 20px;
  border-radius: 0;
  border: none;
  padding: 0;
  background: none;
  box-shadow: none;
  width: initial;
  min-width: 16px;
  outline: none;

  svg {
    width: ${({ iconWidth }) => (iconWidth ?? 20) + 'px'};
  }
`;

AvailableFiltersMenu.SearchContainer = styled.div`
  padding: 5px 10px 5px 0;

  input {
    height: 30px;
  }

  input::placeholder {
    font-style: normal;
  }
`;

AvailableFiltersMenu.Container = styled.div`
  border: ${props =>
    props.isDragging ? props.theme.borders.thingray : 'none'};
  margin: ${props => (props.isDragging ? '0' : '1px 0')};
  max-height: 100%;
  padding-right: 10px;
`;

AvailableFiltersMenu.ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.isCoreSeries ? 'auto 50px 25px' : 'auto 25px'};
  border-top: ${props =>
    props.isFirst ? 'none' : props.theme.borders.thingray};
  align-items: center;
`;

AvailableFiltersMenu.InputWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 25px;
  border: ${props => props.theme.borders.thingray}
  border-bottom: ${props =>
    props.isSingle ? props.theme.borders.thingray : '0'};
`;

AvailableFiltersMenu.RowInput = styled.input`
  outline: none;
  border: 0;
  margin: 0 5px;
  padding: 0;
  width: 100%;
`;

AvailableFiltersMenu.InputLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

AvailableFiltersMenu.ButtonsInputLayout = styled(
  AvailableFiltersMenu.InputLayout,
)`
  margin-right: 5px;
  overflow: hidden;
`;

AvailableFiltersMenu.CoreSeriesLabel = styled.span`
  color: #9a9a9a;
  margin-right: 5px;
  text-align: right;
`;

AvailableFiltersMenu.OptionWrapper = styled.div`
  border: ${props => props.theme.borders.thingray};
  background-color: #efefef;
  margin-bottom: ${props => (props.isOnlyCoreSeries ? '10px' : '0')};
`;

AvailableFiltersMenu.FilterOptionName = styled.span`
  margin: 0 5px;
  display: flex;
  justify-content: space-between;
`;

AvailableFiltersMenu.Source = styled.span`
  font-style: italic;
`;

AvailableFiltersMenu.Wrapper = styled.div`
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-top: 1px solid #c1c1c1;
  max-height: 100%;
  align-self: end;
  display: flex;

  .match {
    font-weight: bold;
    text-decoration: underline;
  }

  > div {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-width: 100%;

    > div {
      padding: 0 0 0 10px;
    }
  }
`;

AvailableFiltersMenu.AddButton = styled.button`
  height: 20px;
  border-radius: 0;
  border: none;
  border-left: ${props => props.theme.borders.thingray};
  padding: 0;
  min-width: 23px;
  outline: none;
`;

export default AvailableFiltersMenu;
