import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect'; //eslint-disable-line

import type { Action, Selector } from 'store/models';
import { getToday } from 'modules/appConfig/AppConfigReducer';
import { LOGOUT } from 'modules/auth/AuthActions';
import { getCurrentGroup, getCurrentWellId } from 'modules/ui/UIReducer';
import {
  getColumnMapping,
  getWellsWithCustomValues,
} from 'modules/well/WellReducer';
import type { WellColumnMappingItem } from 'modules/well/models/well';

import {
  ADD_CURRENT_VARIANCE_OPTION,
  ADD_SEVERAL_VARIANCE_OPTIONS,
  CLEAR_DRILLDOWN_TABLE,
  FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE,
  FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE,
  FETCH_FILTERED_WELL_DRILLDOWN_TABLE,
  FETCH_FORECAST_DRILLDOWN_TABLE,
  FETCH_VARIANCE_DRILLDOWN_TABLE,
  FETCH_WELL_DRILLDOWN_TABLE,
  INIT_DRILLDOWN_TABLE_FETCH,
  namespace,
  POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE,
  POPULATE_FILTERED_WELL_DRILLDOWN_TABLE,
  POPULATE_FORECAST_DRILLDOWN_TABLE,
  POPULATE_VARIANCE_DRILLDOWN_TABLE,
  POPULATE_WELL_DRILLDOWN_TABLE,
  REMOVE_ONE_VARIANCE_OPTION,
  RESET_CURRENT_VARIANCE_OPTION,
  RESET_SORTING,
  SET_CURRENT_VARIANCE_OPTION,
  SET_DRILLDOWN_TABLE_PARAMS,
  SET_DRILLDOWN_TABLE_PHASE,
  SET_MAX_DRILLDOWN_TABLE_DATE,
  SET_MIN_DRILLDOWN_TABLE_DATE,
  SET_NUMBER_OF_ROI_DAYS,
  SET_SORT_CRITERIA,
  SET_SORT_DIRECTION,
  SET_SORT_VAR_INDEX,
} from './DrilldownTableActions';
import {
  ASC,
  COMPARE_OPTION,
  DrilldownTable,
  DrilldownTableParams,
  GroupDrilldownTable,
  VarianceDrilldownTableItem,
  VarianceTableItem,
  WellDrilldownTableItem,
} from './models/drilldownTable';
import {
  createGroupedTable,
  createForecastGroupedTable,
  getDefaulDrilldownDates,
  filterDrilldownTable,
  sortForecastTable,
  sortTable,
} from './utils';

const filterRegExp = new RegExp(`${namespace}/|${LOGOUT}`);

export const STATE_KEY = 'drilldownTable';

export type DrilldownTableState = {
  currentVarianceOptions: string[];
  fethcingStatus: {
    isVarianceTableFetching: boolean;
    isForecastFetching: boolean;
    isWellTableFetching: boolean;
  };
  params: DrilldownTableParams;
  sortDirection: string;
  sortCriteria: string;
  sortVarDirectionIndex: number;
  varianceTable: VarianceDrilldownTableItem[];
  wellTable: WellDrilldownTableItem[];
  numberOfRoiDays: number;
  fetchingDates: {
    minDate: Date | null;
    maxDate: Date | null;
  };
};

const initialState: DrilldownTableState = {
  currentVarianceOptions: [],
  params: {} as DrilldownTableParams,
  sortDirection: ASC,
  sortCriteria: 'variance',
  sortVarDirectionIndex: 0,
  fethcingStatus: {
    isVarianceTableFetching: false,
    isForecastFetching: false,
    isWellTableFetching: false,
  },
  fetchingDates: {
    minDate: null,
    maxDate: null,
  },
  varianceTable: [],
  wellTable: [],
  numberOfRoiDays: 0,
};

const DrilldownTableReducer = (
  state: DrilldownTableState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case FETCH_FILTERED_VARIANCE_DRILLDOWN_TABLE:
    case FETCH_VARIANCE_DRILLDOWN_TABLE: {
      return R.assocPath<boolean, DrilldownTableState>(
        ['fethcingStatus', 'isVarianceTableFetching'],
        true,
        state,
      );
    }
    case INIT_DRILLDOWN_TABLE_FETCH: {
      const {
        payload: { minDate, maxDate },
      } = action;
      const stateMinDate = state.fetchingDates.minDate;
      const stateMaxDate = state.fetchingDates.maxDate;
      const dates = { minDate: stateMinDate, maxDate: stateMaxDate };
      if (
        minDate !== state.fetchingDates.minDate ||
        maxDate !== state.fetchingDates.maxDate
      ) {
        dates.minDate = minDate;
        dates.maxDate = maxDate;
      }

      return R.compose(
        R.assocPath(['fethcingStatus', 'isVarianceTableFetching'], true),
        R.assocPath(['fethcingStatus', 'isWellTableFetching'], true),
        R.assoc('fetchingDates', dates),
      )(state);
    }
    case FETCH_FILTERED_WELL_DRILLDOWN_TABLE:
    case FETCH_WELL_DRILLDOWN_TABLE: {
      const {
        graphql: {
          variables: {
            payload: { minDate, maxDate },
          },
        },
      } = action.payload;
      const stateMinDate = state.fetchingDates.minDate;
      const stateMaxDate = state.fetchingDates.maxDate;
      const dates = { minDate: stateMinDate, maxDate: stateMaxDate };
      if (
        minDate !== state.fetchingDates.minDate ||
        maxDate !== state.fetchingDates.maxDate
      ) {
        dates.minDate = minDate;
        dates.maxDate = maxDate;
      }

      return R.compose(
        R.assocPath(['fethcingStatus', 'isWellTableFetching'], true),
        R.assoc('fetchingDates', dates),
      )(state);
    }
    case FETCH_FILTERED_FORECAST_DRILLDOWN_TABLE:
    case FETCH_FORECAST_DRILLDOWN_TABLE: {
      return R.assocPath<boolean, DrilldownTableState>(
        ['fethcingStatus', 'isForecastFetching'],
        true,
        state,
      );
    }
    case POPULATE_VARIANCE_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isVarianceTableFetching'], false),
        R.assoc('varianceTable', drilldownTable),
      )(state);
    }
    case POPULATE_FILTERED_VARIANCE_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isVarianceTableFetching'], false),
        R.assoc('filteredVarianceTable', drilldownTable),
      )(state);
    }
    case POPULATE_WELL_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isWellTableFetching'], false),
        R.assoc('wellTable', drilldownTable),
      )(state);
    }
    case POPULATE_FILTERED_WELL_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isWellTableFetching'], false),
        R.assoc('filteredWellTable', drilldownTable),
      )(state);
    }
    case POPULATE_FORECAST_DRILLDOWN_TABLE: {
      const drilldownTable = action.payload;
      return R.compose(
        R.assocPath(['fethcingStatus', 'isForecastFetching'], false),
        R.assoc('forecastTable', drilldownTable),
      )(state);
    }
    case LOGOUT: {
      return R.compose(
        R.assoc<VarianceDrilldownTableItem[], DrilldownTableState>(
          'varianceTable',
          [],
        ),
        R.assoc<GroupDrilldownTable[], DrilldownTableState>('groupedTable', []),
        R.assoc('fethcingStatus', initialState.fethcingStatus),
      )(state);
    }
    case CLEAR_DRILLDOWN_TABLE: {
      return R.compose(
        R.assoc<VarianceDrilldownTableItem[], DrilldownTableState>(
          'varianceTable',
          [],
        ),
        R.assoc<GroupDrilldownTable[], DrilldownTableState>('groupedTable', []),
        R.assoc('forecastTable', []),
        R.assoc('wellTable', []),
        R.assoc('filteredWellTable', []),
        R.assoc('filteredVarianceTable', []),
        R.assoc('filteredForecastTable', []),
      )(state);
    }
    case SET_MIN_DRILLDOWN_TABLE_DATE: {
      return R.assocPath<string, DrilldownTableState, Date>(
        ['params', 'minDate'],
        action.payload,
        state,
      );
    }
    case SET_MAX_DRILLDOWN_TABLE_DATE: {
      return R.assocPath<string, DrilldownTableState, Date>(
        ['params', 'maxDate'],
        action.payload,
        state,
      );
    }
    case SET_DRILLDOWN_TABLE_PHASE: {
      return R.assocPath<string, DrilldownTableState, string>(
        ['params', 'phase'],
        action.payload,
        state,
      );
    }
    case SET_DRILLDOWN_TABLE_PARAMS: {
      const newParams = { ...state.params, ...action.payload };
      return R.assoc<DrilldownTableParams, DrilldownTableState, string>(
        'params',
        newParams,
        state,
      );
    }
    case SET_SORT_CRITERIA: {
      return R.assoc<string, DrilldownTableState, string>(
        'sortCriteria',
        action.payload,
        state,
      );
    }
    case SET_SORT_DIRECTION: {
      return R.assoc<string, DrilldownTableState, string>(
        'sortDirection',
        action.payload,
        state,
      );
    }
    case SET_SORT_VAR_INDEX: {
      return R.assoc<string, DrilldownTableState, string>(
        'sortVarDirectionIndex',
        action.payload,
        state,
      );
    }
    case RESET_SORTING: {
      return R.compose(
        R.assoc<string, any, DrilldownTableState>('sortCriteria', 'variance'),
        R.assoc('sortDirection', ASC),
        R.assoc('sortVarDirectionIndex', 0),
      )(state);
    }
    case SET_CURRENT_VARIANCE_OPTION: {
      const { optionId } = action.payload;

      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        [optionId],
        state,
      );
    }
    case RESET_CURRENT_VARIANCE_OPTION: {
      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        [],
        state,
      );
    }
    case ADD_CURRENT_VARIANCE_OPTION: {
      const newVarOption = action.payload.optionId;
      const prevOptions = state.currentVarianceOptions;

      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        [...prevOptions, newVarOption],
        state,
      );
    }
    case ADD_SEVERAL_VARIANCE_OPTIONS: {
      const newVarOptions = action.payload.optionIds;
      const prevOptions = state.currentVarianceOptions;

      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        [...prevOptions, ...newVarOptions],
        state,
      );
    }
    case REMOVE_ONE_VARIANCE_OPTION: {
      const varianceOptionIdToRemove = action.payload.optionId;
      const newOptions = state.currentVarianceOptions.filter(
        optionId => optionId !== varianceOptionIdToRemove,
      );

      return R.assoc<string[], DrilldownTableState, string[]>(
        'currentVarianceOptions',
        newOptions,
        state,
      );
    }
    case SET_NUMBER_OF_ROI_DAYS: {
      return R.assoc('numberOfRoiDays', action.payload.days, state);
    }
    default: {
      return state;
    }
  }
};

export const getDrilldownTableState = (
  state: DrilldownTableState,
): DrilldownTableState => state[STATE_KEY];

export const getRawVarianceDrilldownTable = (
  state: any,
): VarianceDrilldownTableItem[] => state[STATE_KEY].varianceTable || [];

export const getRawFilteredVarianceDrilldownTable = (
  state: any,
): VarianceDrilldownTableItem[] => state[STATE_KEY].filteredVarianceTable || [];

export const getRawCommonVarianceDrilldownTable: Selector<
  VarianceDrilldownTableItem[]
> = createSelector(
  getRawFilteredVarianceDrilldownTable,
  getRawVarianceDrilldownTable,
  (filtered, full) => (R.isEmpty(full) ? filtered : full),
);

export const getRawWellDrilldownTable = (
  state: any,
): VarianceDrilldownTableItem[] => state[STATE_KEY].wellTable || [];

export const getRawFilteredWellDrilldownTable = (
  state: DrilldownTableState,
): VarianceDrilldownTableItem[] => state[STATE_KEY].filteredWellTable || [];

export const getRawCommonWellDrilldownTable: Selector<
  VarianceDrilldownTableItem[]
> = createSelector(
  getRawFilteredWellDrilldownTable,
  getRawWellDrilldownTable,
  (filtered, full) => (R.isEmpty(full) ? filtered : full),
);

export const getRawForecastDrilldownTable = (
  state: DrilldownTableState,
): VarianceDrilldownTableItem[] => state[STATE_KEY].forecastTable || [];

export const getCurrentVarianceOptions = (state: any) =>
  state[STATE_KEY].currentVarianceOptions;

export const getDrilldownTableParams: Selector<DrilldownTableParams> =
  createSelector(
    getDrilldownTableState,
    getColumnMapping,
    (drilldownTableState, columnMapping: WellColumnMappingItem[]) => {
      const { params } = drilldownTableState;
      const hasNri = columnMapping.some(item => item.wiserockBinding === 'NRI');
      const defaultDates = getDefaulDrilldownDates();
      const minDate = new Date(
        R.defaultTo(defaultDates.minDate, params.minDate),
      );
      const maxDate = new Date(
        R.defaultTo(defaultDates.maxDate, params.maxDate),
      );
      const compareOption = params.compareOption || COMPARE_OPTION.actual;
      const grossNet =
        hasNri && R.pathOr(false, ['params', 'grossNet'], drilldownTableState)
          ? params.grossNet
          : 'Gross';
      return !params || R.isEmpty(params)
        ? initialState.params
        : {
            minDate,
            maxDate,
            phase: params.phase || 'Oil',
            grossNet,
            rateVolume: params.rateVolume || 'Rate',
            compareOption,
          };
    },
  );

export const getActualGrossNet: Selector<string> = createSelector(
  getDrilldownTableState,
  drilldownTableState =>
    !drilldownTableState.params || R.isEmpty(drilldownTableState.params)
      ? ''
      : drilldownTableState.params.grossNet,
);

export const getGrossNet: Selector<string> = createSelector(
  getActualGrossNet,
  getColumnMapping,
  (grossNet, columnMapping) => {
    if (
      R.isEmpty(columnMapping) ||
      columnMapping.some(item => item.wiserockBinding === 'NRI')
    ) {
      return grossNet;
    }
    return 'Gross';
  },
);

export const getNumberOfRoiDays = (state: any) =>
  state[STATE_KEY].numberOfRoiDays;

export const getFilteredVarianceTable = createSelector(
  getRawCommonVarianceDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  state => state.filter || {},
  getWellsWithCustomValues,
  (
    varianceTable: VarianceDrilldownTableItem[],
    params: DrilldownTableParams,
    numberOfRoiDays: number,
    filters,
    wells,
  ) => {
    return filterDrilldownTable(
      varianceTable,
      params,
      numberOfRoiDays,
      filters,
      wells,
    );
  },
);

export const getFilteredWellTable = createSelector(
  getRawCommonWellDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  state => state.filter || {},
  getWellsWithCustomValues,
  (
    wellTabel: WellDrilldownTableItem[],
    params: DrilldownTableParams,
    numberOfRoiDays: number,
    filters,
    wells,
  ) => {
    return filterDrilldownTable(
      wellTabel,
      params,
      numberOfRoiDays,
      filters,
      wells,
    );
  },
);

export const getWellDrilldownTable: Selector<VarianceDrilldownTableItem[]> =
  createSelector(
    getRawCommonVarianceDrilldownTable,
    getRawCommonWellDrilldownTable,
    getDrilldownTableParams,
    getCurrentVarianceOptions,
    getNumberOfRoiDays,
    state => state.filter || {},
    getWellsWithCustomValues,
    (
      varianceTable: VarianceDrilldownTableItem[],
      wellTabel: WellDrilldownTableItem[],
      params: DrilldownTableParams,
      currentVarianceOptions,
      numberOfRoiDays: number,
      filters,
      wells,
    ) => {
      const filteredWellTable = filterDrilldownTable(
        wellTabel,
        params,
        numberOfRoiDays,
        filters,
        wells,
      );

      const wellTablewithoutEmptyWells = (
        filteredWellTable as WellDrilldownTableItem[]
      ).reduce((acc, item) => {
        if (!item.wellId) return acc;
        if (!acc[item.wellId]) {
          acc[item.wellId] = item;
          return acc;
        }
        acc[item.wellId] = {
          ...acc[item.wellId],
          oilVariance: acc[item.wellId].oilVariance + item.oilVariance,
          gasVariance: acc[item.wellId].gasVariance + item.gasVariance,
          waterVariance: acc[item.wellId].waterVariance + item.waterVariance,
          boeVariance: acc[item.wellId].boeVariance + item.boeVariance,
          oilActual: acc[item.wellId].oilActual + item.oilActual,
          gasActual: acc[item.wellId].gasActual + item.gasActual,
          waterActual: acc[item.wellId].waterActual + item.waterActual,
          boeActual: acc[item.wellId].boeActual + item.boeActual,
          oilCapacity: acc[item.wellId].oilCapacity + item.oilCapacity,
          gasCapacity: acc[item.wellId].gasCapacity + item.gasCapacity,
          waterCapacity: acc[item.wellId].waterCapacity + item.waterCapacity,
          boeCapacity: acc[item.wellId].boeCapacity + item.boeCapacity,
        };
        return acc;
      }, {});
      if (R.isEmpty(currentVarianceOptions)) {
        return R.values(wellTablewithoutEmptyWells);
      }
      const filteredVarianceTable = filterDrilldownTable(
        varianceTable,
        params,
        numberOfRoiDays,
        filters,
        wells,
      );
      const filteredTable = filteredVarianceTable.filter(item =>
        currentVarianceOptions.includes(item.varianceOptionId),
      );

      const varianceTablewithoutEmptyWells = (
        filteredTable as VarianceDrilldownTableItem[]
      ).reduce((acc, item) => {
        if (!item.wellId || !wellTablewithoutEmptyWells[item.wellId])
          return acc;
        if (!acc[item.wellId]) {
          acc[item.wellId] = {
            ...wellTablewithoutEmptyWells[item.wellId],
            oilVariance: item.oilVariance,
            gasVariance: item.gasVariance,
            waterVariance: item.waterVariance,
            boeVariance: item.boeVariance,
          };

          return acc;
        }
        acc[item.wellId] = {
          ...acc[item.wellId],
          oilVariance: acc[item.wellId].oilVariance + item.oilVariance,
          gasVariance: acc[item.wellId].gasVariance + item.gasVariance,
          waterVariance: acc[item.wellId].waterVariance + item.waterVariance,
          boeVariance: acc[item.wellId].boeVariance + item.boeVariance,
          oilActual: wellTablewithoutEmptyWells[item.wellId].oilActual,
          gasActual: wellTablewithoutEmptyWells[item.wellId].gasActual,
          waterActual: wellTablewithoutEmptyWells[item.wellId].waterActual,
          boeActual: wellTablewithoutEmptyWells[item.wellId].boeActual,
          oilCapacity: wellTablewithoutEmptyWells[item.wellId].oilCapacity,
          gasCapacity: wellTablewithoutEmptyWells[item.wellId].gasCapacity,
          waterCapacity: wellTablewithoutEmptyWells[item.wellId].waterCapacity,
          boeCapacity: wellTablewithoutEmptyWells[item.wellId].boeCapacity,
        };
        return acc;
      }, {});

      return R.values(varianceTablewithoutEmptyWells);
    },
  );

export const getDrilldowTablePhase: Selector<string> = createSelector(
  getDrilldownTableParams,
  (params: DrilldownTableParams) => params.phase,
);

export const getSortCriteria: Selector<string> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.sortCriteria || 'variance',
);

export const getSortDirection: Selector<string> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.sortDirection || 'ASC',
);

export const getSortVarDirectionIndex: Selector<number> = createSelector(
  getDrilldownTableState,
  drilldowntableState => drilldowntableState.sortVarDirectionIndex || 0,
);

export const getGroupedWellTable: Selector<GroupDrilldownTable[]> =
  createCachedSelector(
    getWellDrilldownTable,
    (_, props) => props.subject,
    (table: VarianceDrilldownTableItem[], groupingSubject) =>
      createGroupedTable(table, groupingSubject),
  )((state, props) => props.subject || 'well');

export const getSortedWellDrilldownTable: Selector<DrilldownTable[]> =
  createSelector(
    [
      getSortCriteria,
      getSortDirection,
      getSortVarDirectionIndex,
      getDrilldownTableParams,
      getWellDrilldownTable,
    ],
    (
      sortCriteria: string,
      sortDirection: string,
      sortVarDirectionIndex: number,
      drilldownTableParams: DrilldownTableParams,
      drilldownTableFiltered: VarianceDrilldownTableItem[],
    ) => {
      if (R.isEmpty(drilldownTableParams)) return [];
      const sorted = sortTable(
        sortCriteria,
        sortDirection,
        sortVarDirectionIndex,
        drilldownTableParams.phase.toLowerCase(),
        drilldownTableFiltered,
      );
      const table = sorted.map(tableItem => ({
        well: tableItem.well,
        wellId: tableItem.wellId,
        variance:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}Variance`],
        actual: tableItem[`${drilldownTableParams.phase.toLowerCase()}Actual`],
        capacity:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}Capacity`],
      }));

      return table;
    },
  );
export const getSortedGroupedTable: Selector<
  VarianceDrilldownTableItem[] | undefined
> = createCachedSelector(
  [
    getSortCriteria,
    getSortDirection,
    getSortVarDirectionIndex,
    getDrilldownTableParams,
    getGroupedWellTable,
  ],
  (
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    drilldownTableParams,
    groupedTable,
  ) => {
    if (R.isEmpty(drilldownTableParams)) return;
    const sorted = sortTable(
      sortCriteria,
      sortDirection,
      sortVarDirectionIndex,
      drilldownTableParams.phase.toLowerCase(),
      groupedTable as any,
    );
    const table = sorted.map(tableItem => {
      const itemWithoutPhases = R.omit(
        ['oil', 'gas', 'water', 'boe'],
        tableItem,
      );
      return {
        ...itemWithoutPhases,
        variance:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}Variance`],
        actual: tableItem[`${drilldownTableParams.phase.toLowerCase()}Actual`],
        capacity:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}Capacity`],
      };
    });

    return table as VarianceDrilldownTableItem[];
  },
)((state, props) => props.subject || 'well');

export const getVarianceDrilldownTable: Selector<VarianceDrilldownTableItem[]> =
  createSelector(
    getRawCommonVarianceDrilldownTable,
    getDrilldownTableParams,
    getNumberOfRoiDays,
    state => state.filter || {},
    getWellsWithCustomValues,
    (
      table: VarianceDrilldownTableItem[],
      params: DrilldownTableParams,
      numberOfRoiDays: number,
      filters,
      wells,
    ) => {
      const filteredTable: VarianceDrilldownTableItem[] =
        filterDrilldownTable(table, params, numberOfRoiDays, filters, wells) ||
        [];

      const groupedByVarianceOption = filteredTable.reduce((acc, item) => {
        if (!acc[item.varianceOptionId]) {
          acc[item.varianceOptionId] = item;
          return acc;
        }
        acc[item.varianceOptionId] = {
          ...acc[item.varianceOptionId],
          oilVariance:
            acc[item.varianceOptionId].oilVariance + item.oilVariance,
          gasVariance:
            acc[item.varianceOptionId].gasVariance + item.gasVariance,
          waterVariance:
            acc[item.varianceOptionId].waterVariance + item.waterVariance,
          boeVariance:
            acc[item.varianceOptionId].boeVariance + item.boeVariance,
          oilActual: acc[item.varianceOptionId].oilActual + item.oilActual,
          gasActual: acc[item.varianceOptionId].gasActual + item.gasActual,
          waterActual:
            acc[item.varianceOptionId].waterActual + item.waterActual,
          boeActual: acc[item.varianceOptionId].boeActual + item.boeActual,
          oilCapacity:
            acc[item.varianceOptionId].oilCapacity + item.oilCapacity,
          gasCapacity:
            acc[item.varianceOptionId].gasCapacity + item.gasCapacity,
          waterCapacity:
            acc[item.varianceOptionId].waterCapacity + item.waterCapacity,
          boeCapacity:
            acc[item.varianceOptionId].boeCapacity + item.boeCapacity,
        };

        return acc;
      }, {});

      return R.values(groupedByVarianceOption);
    },
  );

export const getSortedVarianceDrilldownTable: Selector<VarianceTableItem[]> =
  createSelector(
    getVarianceDrilldownTable,
    getDrilldownTableParams,
    (
      varianceDrilldownTable: VarianceDrilldownTableItem[],
      drilldownTableParams: DrilldownTableParams,
    ) => {
      if (R.isEmpty(drilldownTableParams)) return [];
      const phase = drilldownTableParams.phase.toLowerCase();
      const withVariance: VarianceTableItem[] = varianceDrilldownTable.map(
        tableItem => ({
          varianceOptionId: tableItem.varianceOptionId,
          subCause: tableItem.subCause,
          planType: tableItem.planType,
          color: tableItem.color,
          variance: tableItem[`${phase}Variance`],
          actual: tableItem[`${phase}Actual`],
          capacity: tableItem[`${phase}Capacity`],
        }),
      );

      return withVariance.sort((a, b) => a.variance - b.variance);
    },
  );

export const getDrilldownLoadingStatus = (state: Record<string, any>) =>
  state[STATE_KEY].fethcingStatus;

export const getWellProductionStartDate: Selector<Date> = createSelector(
  getRawCommonVarianceDrilldownTable,
  getCurrentWellId,
  getToday,
  (wells, currentWellId, defaultDate) => {
    const currentWell = wells.find(well => well.wellId === currentWellId);
    return R.pathOr(defaultDate, ['startProdDate'], currentWell);
  },
);

export const getGroupProductionStartDate: Selector<Date> = createSelector(
  getRawCommonWellDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  state => state.filter || {},
  getWellsWithCustomValues,
  getCurrentGroup,
  getToday,
  (
    table: VarianceDrilldownTableItem[],
    params: DrilldownTableParams,
    numberOfRoiDays: number,
    filters,
    wells,
    { subject, item },
    defaultDate: Date,
  ) => {
    const filterTable =
      filterDrilldownTable(table, params, numberOfRoiDays, filters, wells) ||
      [];

    if (subject === 'all') {
      const startProdDate = filterTable.reduce(
        (min, well: VarianceDrilldownTableItem) => {
          const tempDate = new Date(
            well.startProdDate ? well.startProdDate : defaultDate,
          );
          return min > tempDate ? tempDate : min;
        },
        defaultDate,
      );
      return R.defaultTo(defaultDate, startProdDate);
    }
    const groupedWells = R.groupBy(R.prop(subject), filterTable);
    const currentGroup = R.pathOr([], [item], groupedWells);
    const startProdDate = currentGroup.reduce((min, well) => {
      const tempDate = new Date(
        well.startProdDate ? well.startProdDate : defaultDate,
      );
      return min > tempDate ? tempDate : min;
    }, defaultDate);
    return R.defaultTo(defaultDate, startProdDate);
  },
);

const getFiltredTable = (filters, table, wells) => {
  const isNotEmpty = (val, key) => !R.isEmpty(val);
  const filtersWithoutEmpty = R.pickBy(isNotEmpty, filters);

  const filteredWellIdIndexedList = R.values(wells).reduce((acc, well) => {
    const isIncluded = R.keys(filtersWithoutEmpty).every(
      filterName =>
        filters[filterName].includes(well[filterName]) ||
        (R.path([filterName, 0], filters) === '' && well[filterName] === null),
    );
    if (isIncluded) acc[well.id] = well;

    return acc;
  }, {});
  const filteredTable = table.filter(tableItem =>
    Boolean(filteredWellIdIndexedList[tableItem.wellId]),
  );
  return filteredTable;
};

export const getFilteredForecastDrilldownTableItems: Selector<
  VarianceDrilldownTableItem[]
> = createSelector(
  getRawCommonWellDrilldownTable,
  getDrilldownTableParams,
  getNumberOfRoiDays,
  state => state.filter || {},
  getWellsWithCustomValues,
  (
    table: VarianceDrilldownTableItem[],
    params: DrilldownTableParams,
    numberOfRoiDays: number,
    filters,
    wells,
  ) => {
    const filteredTable = getFiltredTable(filters, table, wells);
    const isCapacityVsExternal = params.compareOption === 'extVsCap';

    const grossNetNormalized = filteredTable.map(tableItem => {
      if (params.grossNet === 'Gross') {
        const {
          oilCapVsExtVariance,
          gasCapVsExtVariance,
          waterCapVsExtVariance,
          boeCapVsExtVariance,
          oilActVsExtVariance,
          gasActVsExtVariance,
          waterActVsExtVariance,
          boeActVsExtVariance,
          oilCapacity,
          waterCapacity,
          gasCapacity,
          boeCapacity,
          oilForecast,
          waterForecast,
          gasForecast,
          boeForecast,
          oilActual,
          waterActual,
          gasActual,
          boeActual,
        } = tableItem;

        const oilVariance = isCapacityVsExternal
          ? oilCapVsExtVariance
          : oilActVsExtVariance;
        const gasVariance = isCapacityVsExternal
          ? gasCapVsExtVariance
          : gasActVsExtVariance;
        const waterVariance = isCapacityVsExternal
          ? waterCapVsExtVariance
          : waterActVsExtVariance;
        const boeVariance = isCapacityVsExternal
          ? boeCapVsExtVariance
          : boeActVsExtVariance;
        const oilSecondCol = isCapacityVsExternal ? oilForecast : oilActual;
        const gasSecondCol = isCapacityVsExternal ? gasForecast : gasActual;
        const waterSecondCol = isCapacityVsExternal
          ? waterForecast
          : waterActual;
        const boeSecondCol = isCapacityVsExternal ? boeForecast : boeActual;
        const oilThirdCol = isCapacityVsExternal ? oilCapacity : oilForecast;
        const gasThirdCol = isCapacityVsExternal ? gasCapacity : gasForecast;
        const waterThirdCol = isCapacityVsExternal
          ? waterCapacity
          : waterForecast;
        const boeThirdCol = isCapacityVsExternal ? boeCapacity : boeForecast;

        return {
          ...tableItem,
          oilVariance,
          waterVariance,
          gasVariance,
          boeVariance,
          oilThirdCol,
          waterThirdCol,
          gasThirdCol,
          boeThirdCol,
          oilSecondCol,
          waterSecondCol,
          gasSecondCol,
          boeSecondCol,
        };
      }
      const nriNumber = wells[tableItem.wellId].NRI
        ? parseFloat(wells[tableItem.wellId].NRI)
        : 1;
      const nriNormalized = nriNumber <= 1 && nriNumber >= 0 ? nriNumber : 1;

      const {
        oilCapVsExtVariance,
        gasCapVsExtVariance,
        waterCapVsExtVariance,
        boeCapVsExtVariance,
        oilActVsExtVariance,
        gasActVsExtVariance,
        waterActVsExtVariance,
        boeActVsExtVariance,
        oilCapacity,
        waterCapacity,
        gasCapacity,
        boeCapacity,
        oilForecast,
        waterForecast,
        gasForecast,
        boeForecast,
        oilActual,
        waterActual,
        gasActual,
        boeActual,
      } = tableItem;

      const oilVariance = isCapacityVsExternal
        ? oilCapVsExtVariance
        : oilActVsExtVariance;
      const gasVariance = isCapacityVsExternal
        ? gasCapVsExtVariance
        : gasActVsExtVariance;
      const waterVariance = isCapacityVsExternal
        ? waterCapVsExtVariance
        : waterActVsExtVariance;
      const boeVariance = isCapacityVsExternal
        ? boeCapVsExtVariance
        : boeActVsExtVariance;
      const oilSecondCol = isCapacityVsExternal ? oilForecast : oilActual;
      const gasSecondCol = isCapacityVsExternal ? gasForecast : gasActual;
      const waterSecondCol = isCapacityVsExternal ? waterForecast : waterActual;
      const boeSecondCol = isCapacityVsExternal ? boeForecast : boeActual;
      const oilThirdCol = isCapacityVsExternal ? oilCapacity : oilForecast;
      const gasThirdCol = isCapacityVsExternal ? gasCapacity : gasForecast;
      const waterThirdCol = isCapacityVsExternal
        ? waterCapacity
        : waterForecast;
      const boeThirdCol = isCapacityVsExternal ? boeCapacity : boeForecast;

      return {
        ...tableItem,
        oilVariance: R.isNil(oilVariance)
          ? oilVariance
          : oilVariance * nriNormalized,
        waterVariance: R.isNil(waterVariance)
          ? waterVariance
          : waterVariance * nriNormalized,
        gasVariance: R.isNil(gasVariance)
          ? gasVariance
          : gasVariance * nriNormalized,
        boeVariance: R.isNil(boeVariance)
          ? boeVariance
          : boeVariance * nriNormalized,
        oilThirdCol: R.isNil(oilThirdCol)
          ? oilThirdCol
          : oilThirdCol * nriNormalized,
        waterThirdCol: R.isNil(waterThirdCol)
          ? waterThirdCol
          : waterThirdCol * nriNormalized,
        gasThirdCol: R.isNil(gasThirdCol)
          ? gasThirdCol
          : gasThirdCol * nriNormalized,
        boeThirdCol: R.isNil(boeThirdCol)
          ? boeThirdCol
          : boeThirdCol * nriNormalized,
        oilSecondCol: R.isNil(oilSecondCol)
          ? oilSecondCol
          : oilSecondCol * nriNormalized,
        waterSecondCol: R.isNil(waterSecondCol)
          ? waterSecondCol
          : waterSecondCol * nriNormalized,
        gasSecondCol: R.isNil(gasSecondCol)
          ? gasSecondCol
          : gasSecondCol * nriNormalized,
        boeSecondCol: R.isNil(boeSecondCol)
          ? boeSecondCol
          : boeSecondCol * nriNormalized,
      };
    });
    const rateVolumeNormalized = grossNetNormalized.map(tableItem => {
      if (params.rateVolume === 'Rate') return tableItem;
      const {
        oilVariance,
        waterVariance,
        gasVariance,
        boeVariance,
        oilThirdCol,
        waterThirdCol,
        gasThirdCol,
        boeThirdCol,
        oilSecondCol,
        waterSecondCol,
        gasSecondCol,
        boeSecondCol,
      } = tableItem;
      return {
        ...tableItem,
        oilVariance: R.isNil(oilVariance)
          ? oilVariance
          : oilVariance * numberOfRoiDays,
        waterVariance: R.isNil(waterVariance)
          ? waterVariance
          : waterVariance * numberOfRoiDays,
        gasVariance: R.isNil(gasVariance)
          ? gasVariance
          : gasVariance * numberOfRoiDays,
        boeVariance: R.isNil(boeVariance)
          ? boeVariance
          : boeVariance * numberOfRoiDays,
        oilThirdCol: R.isNil(oilThirdCol)
          ? oilThirdCol
          : oilThirdCol * numberOfRoiDays,
        waterThirdCol: R.isNil(waterThirdCol)
          ? waterThirdCol
          : waterThirdCol * numberOfRoiDays,
        gasThirdCol: R.isNil(gasThirdCol)
          ? gasThirdCol
          : gasThirdCol * numberOfRoiDays,
        boeThirdCol: R.isNil(boeThirdCol)
          ? boeThirdCol
          : boeThirdCol * numberOfRoiDays,
        oilSecondCol: R.isNil(oilSecondCol)
          ? oilSecondCol
          : oilSecondCol * numberOfRoiDays,
        waterSecondCol: R.isNil(waterSecondCol)
          ? waterSecondCol
          : waterSecondCol * numberOfRoiDays,
        gasSecondCol: R.isNil(gasSecondCol)
          ? gasSecondCol
          : gasSecondCol * numberOfRoiDays,
        boeSecondCol: R.isNil(boeSecondCol)
          ? boeSecondCol
          : boeSecondCol * numberOfRoiDays,
      };
    });
    return rateVolumeNormalized;
  },
);

export const getSortedWellForecastDrilldownTable: Selector<DrilldownTable[]> =
  createSelector(
    [
      getSortCriteria,
      getSortDirection,
      getSortVarDirectionIndex,
      getDrilldownTableParams,
      getFilteredForecastDrilldownTableItems,
    ],
    (
      sortCriteria: string,
      sortDirection: string,
      sortVarDirectionIndex: number,
      drilldownTableParams: DrilldownTableParams,
      drilldownTableFiltered: VarianceDrilldownTableItem[],
    ) => {
      if (R.isEmpty(drilldownTableParams)) return [];
      const sorted = sortForecastTable(
        sortCriteria,
        sortDirection,
        sortVarDirectionIndex,
        drilldownTableParams.phase.toLowerCase(),
        R.clone(drilldownTableFiltered),
        drilldownTableParams.compareOption,
      );
      const table = sorted.map(tableItem => ({
        well: tableItem.well,
        wellId: tableItem.wellId,
        variance:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}Variance`],
        secondCol:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}SecondCol`],
        thirdCol:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}ThirdCol`],
      }));

      return table;
    },
  );

export const getGroupedWellForecastTable: Selector<GroupDrilldownTable[]> =
  createCachedSelector(
    getFilteredForecastDrilldownTableItems,
    (_, props) => props.subject,
    (table: VarianceDrilldownTableItem[], groupingSubject) =>
      createForecastGroupedTable(table, groupingSubject),
  )((state, props) => props.subject || 'well');

export const getSortedForecastGroupedTable: Selector<
  VarianceDrilldownTableItem[] | undefined
> = createCachedSelector(
  [
    getSortCriteria,
    getSortDirection,
    getSortVarDirectionIndex,
    getDrilldownTableParams,
    getGroupedWellForecastTable,
  ],
  (
    sortCriteria,
    sortDirection,
    sortVarDirectionIndex,
    drilldownTableParams,
    groupedTable,
  ) => {
    if (R.isEmpty(drilldownTableParams)) return;
    const sorted = sortForecastTable(
      sortCriteria,
      sortDirection,
      sortVarDirectionIndex,
      drilldownTableParams.phase.toLowerCase(),
      R.clone(groupedTable) as any,
      drilldownTableParams.compareOption,
    );
    const table = sorted.map(tableItem => {
      const itemWithoutPhases = R.omit(
        ['oil', 'gas', 'water', 'boe'],
        tableItem,
      );
      return {
        ...itemWithoutPhases,
        variance:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}Variance`],
        secondCol:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}SecondCol`],
        thirdCol:
          tableItem[`${drilldownTableParams.phase.toLowerCase()}ThirdCol`],
      };
    });

    return table as VarianceDrilldownTableItem[];
  },
)((state, props) => props.subject || 'well');

export const getFetchingDates = createSelector(
  getDrilldownTableState,
  state => state.fetchingDates,
);
export const getDrilldownTableTotalRow = createSelector(
  getWellDrilldownTable,
  getFilteredWellTable,
  getFilteredForecastDrilldownTableItems,
  getDrilldownTableParams,
  getCurrentVarianceOptions,

  (
    varianceTable: VarianceDrilldownTableItem[],
    wellTabel: WellDrilldownTableItem[],
    forecastTable: any,
    params: DrilldownTableParams,
    currentVarianceOptions,
  ) => {
    const phase = R.pathOr('oil', ['phase'], params).toLowerCase();
    const capacityKey = phase + 'Capacity';
    const varianceKey = phase + 'Variance';
    const actualKey = phase + 'Actual';
    const secondColKey = phase + 'SecondCol';
    const thirdColKey = phase + 'ThirdCol';

    const wellTeabelSum = wellTabel.reduce(
      (acc, row) => {
        acc[capacityKey] += row[capacityKey];
        acc[actualKey] += row[actualKey];
        return acc;
      },
      {
        [actualKey]: 0,
        [capacityKey]: 0,
      },
    );
    if (R.isEmpty(currentVarianceOptions)) {
      if (params.compareOption === 'actual') {
        const wellTeabelTotalRow = {
          secondCol: wellTeabelSum[actualKey],
          thirdCol: wellTeabelSum[capacityKey],
          variance: wellTeabelSum[actualKey] - wellTeabelSum[capacityKey],
        };
        return wellTeabelTotalRow;
      }
      if (params.compareOption === 'extVsCap') {
        const wellTeabelSum = forecastTable.reduce(
          (acc, row) => {
            acc.secondCol += row[secondColKey];
            acc.thirdCol += row[thirdColKey];
            acc.variance += row[varianceKey];
            return acc;
          },
          {
            secondCol: 0,
            thirdCol: 0,
            variance: 0,
          },
        );
        return wellTeabelSum;
      }
      if (params.compareOption === 'actVsExt') {
        const wellTeabelSum = forecastTable.reduce(
          (acc, row) => {
            acc.secondCol += row[secondColKey];
            acc.thirdCol += row[thirdColKey];
            acc.variance += row[varianceKey];
            return acc;
          },
          {
            secondCol: 0,
            thirdCol: 0,
            variance: 0,
          },
        );
        return wellTeabelSum;
      }
    }
    const varianceSum = varianceTable.reduce(
      (acc, row) => (acc += row[varianceKey]),
      0,
    );
    if (params.compareOption === 'actual') {
      const wellTeabelTotalRow = {
        secondCol: wellTeabelSum[actualKey],
        thirdCol: wellTeabelSum[capacityKey],
        variance: varianceSum,
      };
      return wellTeabelTotalRow;
    }
  },
);

export default filterActions(DrilldownTableReducer as any, action =>
  action.type.match(filterRegExp),
);
