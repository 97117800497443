import gql from 'graphql-tag';

import { GraphqlQueryAction, Action } from 'store/models';

import { NormalizedSeries } from './models';

export const namespace = 'SERIES';

export const FETCH_SERIES = `${namespace}/FETCH_SERIES`;
export const FETCH_SERIES_DATE_RANGE = `${namespace}/FETCH_SERIES_DATE_RANGE`;
export const REFETCH_SERIES = `${namespace}/REFETCH_SERIES`;
export const FETCH_SERIES_MAPPING = `${namespace}/FETCH_SERIES_MAPPING`;
export const FETCH_GROUP_SERIES = `${namespace}/FETCH_GROUP_SERIES`;
export const REFETCH_GROUP_SERIES = `${namespace}/REFETCH_GROUP_SERIES`;
export const SWITCH_SERIES_MAPPING = `${namespace}/SWITCH_SERIES_MAPPING`;
export const SET_GROUP_SERIES = `${namespace}/SET_GROUP_SERIES`;
export const CLEAR_ALL_GROUP_SERIES = `${namespace}/CLEAR_ALL_GROUP_SERIES`;

type FetchWellSeries = (payload: { wellId: string }) => Action;
type FetchWellSeriesDateRange = (payload: {
  wellId: string;
  minDate: Date;
  maxDate: Date;
  series: string[];
}) => Action;

type fetchAction = () => GraphqlQueryAction;

const FETCH_SERIES_MAPPING_QUERY = gql`
  query {
    listSeriesMapping {
      id
      seriesIndex
      sourceName
      displayName
      units
      order
      color
      aggregateMethod
    }
  }
`;

const FETCH_SERIES_QUERY = gql`
  query ($payload: SeriesInput!) {
    listWellSeries(data: $payload) {
      seriesChartData
    }
  }
`;

const FETCH_SERIES_DATE_RANGE_QUERY = gql`
  query ($payload: SeriesDateRangeInput!) {
    listWellSeriesDateRange(data: $payload) {
      seriesChartData
    }
  }
`;

const GET_GROUP_SERIES_QUERY = gql`
  query ($payload: GroupSeriesInput!) {
    listGroupSeries(data: $payload) {
      seriesChartData
    }
  }
`;

export const fetchSeriesMapping: fetchAction = () => ({
  type: FETCH_SERIES_MAPPING,
  payload: {
    key: 'listSeriesMapping',
    graphql: {
      query: FETCH_SERIES_MAPPING_QUERY,
    },
  },
});

export const fetchWellSeriesDateRange: FetchWellSeriesDateRange = payload => ({
  type: FETCH_SERIES_DATE_RANGE,
  payload: {
    key: 'listWellSeriesDateRange',
    graphql: {
      query: FETCH_SERIES_DATE_RANGE_QUERY,
      variables: {
        payload,
      },
    },
  },
});

export const fetchWellSeries: FetchWellSeries = payload => ({
  type: FETCH_SERIES,
  payload: {
    key: 'listWellSeries',
    graphql: {
      query: FETCH_SERIES_QUERY,
      variables: {
        payload: {
          wellId: payload.wellId,
        },
      },
    },
  },
});

type fetchGroupSeriesAC = (payload: {
  group: { columnIndex: number; name: string } | null;
  filters: { columnIndex: number; values: string[] }[];
}) => GraphqlQueryAction;

export const fetchGroupSeries: fetchGroupSeriesAC = payload => ({
  type: FETCH_GROUP_SERIES,
  payload: {
    key: 'listGroupSeries',
    graphql: {
      query: GET_GROUP_SERIES_QUERY,
      variables: { payload },
    },
  },
});

type setGroupSeriesAC = (payload: {
  groupSubject: string;
  groupName: string;
  series: NormalizedSeries[];
}) => Action;

export const setGroupSeries: setGroupSeriesAC = payload => ({
  type: SET_GROUP_SERIES,
  payload,
});

type clearAllGroupSeriesAC = (payload?: {
  subject: string;
  item: string;
}) => Action;

export const clearAllGroupSeries: clearAllGroupSeriesAC = payload => ({
  type: CLEAR_ALL_GROUP_SERIES,
  payload,
});

type refetchGroupSeriesAC = (payload?: {
  subject: string;
  item: string;
}) => Action;

export const refetchGroupSeries: refetchGroupSeriesAC = payload => ({
  type: REFETCH_GROUP_SERIES,
  payload,
});
export const refetchSeries = (): Action => ({
  type: REFETCH_SERIES,
});
