import * as R from 'ramda';
import { v4 as uuid } from 'uuid';

import type { ProductionPoint } from 'modules/production/models/production';
import {
  getAverageProductionRateByPhase,
  getMaxProductionRateByPhase,
} from 'modules/production/utils';
import { OIL, GAS, WATER } from 'modules/phase/models/phase';

import {
  CapacityChangeEvent,
  DEFAULT_BFACTOR,
  DEFAULT_DECLINE_MINIMUM,
} from '../models/capacityChangeEvent';
import modifyCapacityChangeEventDay from './modifyCapacityChangeEventDay';

const generateNewCapacityEvent = (
  wellId: string,
  oldCapacity: CapacityChangeEvent[],
  date: Date,
  production: ProductionPoint[],
  defaultCategoryId: string,
  today: Date,
): CapacityChangeEvent => {
  const capacityEventsBefore = oldCapacity.filter(
    capEvent => capEvent.dayInit < date,
  );
  const capacityEventsAfter = oldCapacity.filter(
    capEvent => capEvent.dayInit > date,
  );
  const prevCapEvent = capacityEventsBefore[0];
  const nextCapEvent = R.last(capacityEventsAfter);

  const nextCapacityDateIndex = oldCapacity.findIndex(
    capacityEvent =>
      JSON.stringify(capacityEvent) === JSON.stringify(nextCapEvent),
  );
  const endDateForAverage =
    nextCapacityDateIndex > -1
      ? oldCapacity[nextCapacityDateIndex].dayInit
      : today;
  const averageProduction = getAverageProductionRateByPhase(
    date,
    endDateForAverage,
    production,
  );
  const maxDataPoints = getMaxProductionRateByPhase(production);
  const defaultRate = {
    [OIL]: averageProduction[OIL] || maxDataPoints[OIL] / 2,
    [GAS]: averageProduction[GAS] || maxDataPoints[GAS] / 2,
    [WATER]: averageProduction[WATER] || maxDataPoints[WATER] / 2,
  };

  if (!prevCapEvent) {
    return {
      id: uuid(),
      wellId,
      dayInit: date,
      oilDeclineInitDailyNom: 0,
      oilRateInit: defaultRate[OIL],
      oilBFactor: nextCapEvent ? nextCapEvent.oilBFactor : DEFAULT_BFACTOR,
      gasDeclineInitDailyNom: 0,
      gasRateInit: defaultRate[GAS],
      gasBFactor: nextCapEvent ? nextCapEvent.oilBFactor : DEFAULT_BFACTOR,
      waterDeclineInitDailyNom: 0,
      waterRateInit: defaultRate[WATER],
      waterBFactor: nextCapEvent ? nextCapEvent.oilBFactor : DEFAULT_BFACTOR,
      capacityChangeOptionId: defaultCategoryId,
      extraInputsData: null,
      oilDeclineMinimum: DEFAULT_DECLINE_MINIMUM,
      gasDeclineMinimum: DEFAULT_DECLINE_MINIMUM,
      waterDeclineMinimum: DEFAULT_DECLINE_MINIMUM,
      notes: '',
      isLockSlopeCapacityLine: false,
    };
  }

  const capEventModified = modifyCapacityChangeEventDay({
    sourceCapEvent: prevCapEvent || nextCapEvent,
    newDayInit: date,
    defaultRate,
    defaultCategoryId,
  });

  return {
    ...capEventModified,
    id: uuid(),
    oilDeclineMinimum: DEFAULT_DECLINE_MINIMUM,
    gasDeclineMinimum: DEFAULT_DECLINE_MINIMUM,
    waterDeclineMinimum: DEFAULT_DECLINE_MINIMUM,
  };
};

export default generateNewCapacityEvent;
