import * as R from 'ramda';
import * as React from 'react';

import useComponentSize from 'hooks/useComponentSize';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import { VARIANCE_UNITS, COLORS } from 'modules/chart/models/chart';
import {
  createNormalYScale,
  getTrellisGeneralClipPathId,
  getTrellisId,
} from 'modules/chart/utils';
import MainBarsGroup from 'modules/chart/components/MainBarsGroup';
import SelectedBar from 'modules/chart/components/SelectedBar';
import { ForecastData } from 'modules/externalForecast/models';
import ExternalForecastLine from 'modules/externalForecast/components/ExternalForecastLine';
import type { ProductionPoint } from 'modules/production/models/production';
import { OIL, GAS, WATER } from 'modules/phase/models/phase';
import type {
  IdIndexDialog,
  IdDialog,
  TooltipData,
  TrellisTooltipData,
} from 'modules/ui/models/ui';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import AllocIssueArea from './allocIssue/AllocIssueArea';
import CapacityLine from './capacityEvent/CapacityLine';
import EventArea from './varianceEvent/EventArea';
import EventProductionArea from './varianceEvent/EventProductionArea';
import VarianceArea from './varianceEvent/VarianceArea';
import { useCapacityData } from '../context/capacityVariance';
import { Phase } from '../context/capacityVariance/model';

type CapacityData = { date: Date; capacity: number }[];
interface SVGWellTrellisProps {
  allocIssues: AllocIssue[];
  allocIssueDialog?: IdIndexDialog;
  allocIssuesVisibility: boolean;
  capacityDialog: { show: boolean; index: number };
  capacityLineData: Array<CapacityData>;
  capacityVarianceData: Array<CapacityData>;
  eventCapacityData: CapacityData[];
  height: number;
  isPossibleEditAlloc: boolean;
  isAxisDragging: boolean;
  isDisplayingForecast: boolean;
  forecastData: ForecastData;
  eventColors: { [key: string]: string };
  highlightedAllocIssue?: IdDialog;
  highlightedAllocIssueDivider?: IdDialog;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  production: ProductionPoint[];
  capacity: CapacityChangeEvent[];
  isPossibleEditCapacity?: boolean;
  maxDataPoint: number;
  showBarHoverEffect: boolean;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  varianceEvents: VarianceEvent[];
  xScale: any;
  yAxisLinePos: number | null;
}

const SVGWellTrellis = ({
  allocIssues,
  allocIssueDialog,
  allocIssuesVisibility,
  capacity,
  capacityDialog,
  capacityLineData,
  capacityVarianceData: asd,
  eventCapacityData: asdd,
  highlightedAllocIssue,
  highlightedAllocIssueDivider,
  height,
  isAxisDragging,
  isDisplayingForecast,
  isPossibleEditCapacity,
  isPossibleEditAlloc,
  forecastData,
  maxDataPoint,
  eventColors,
  onSetTooltipData,
  production,
  showBarHoverEffect,
  tooltipData,
  trellisTitle,
  varianceEvents,
  xScale,
  yAxisLinePos,
}: SVGWellTrellisProps) => {
  const svgEl: React.RefObject<HTMLElement | null> = React.useRef(null);
  const trellisCapacityIsEditable = React.useMemo(
    () =>
      isPossibleEditCapacity &&
      (trellisTitle === OIL || trellisTitle === GAS || trellisTitle === WATER),
    [isPossibleEditCapacity, trellisTitle],
  );
  const productionKey = React.useMemo(
    () => trellisTitle.toLowerCase(),
    [trellisTitle],
  );
  const svgBoundingRect = useComponentSize(svgEl);

  const capacityData = useCapacityData();
  const eventCapacityData = React.useMemo(
    () => capacityData.getVarianceAreas(trellisTitle as Phase),
    [capacityData.getVarianceAreas],
  );

  const capacityVarianceData = React.useMemo(
    () => ({
      cut: capacityData.getAllByPhase(trellisTitle as Phase, true),
      full: capacityData.getAllByPhase(trellisTitle as Phase),
    }),
    [capacityData.getAllByPhase],
  );

  const flatCapacity = React.useMemo((): Array<{
    date: Date;
    capacity: number;
  }> => {
    const tempArr: CapacityData = [];
    return tempArr.concat(...capacityVarianceData.full);
  }, [capacityVarianceData]);

  const yScale = React.useMemo(
    () => createNormalYScale(height, maxDataPoint),
    [maxDataPoint, height],
  );

  return (
    <svg
      //@ts-expect-error
      ref={svgEl}
      className="trellis_chart"
      height="100%"
      id={getTrellisId(trellisTitle)}
      preserveAspectRatio="none"
      viewBox={`0 0 ${xScale.range()[1]} ${height}`}
      width={xScale.range()[1] - 1}
      shapeRendering="geometricPrecision"
      style={{ cursor: isAxisDragging ? 'grabbing' : 'auto' }}
    >
      <defs>
        <clipPath id={getTrellisGeneralClipPathId(trellisTitle)}>
          <rect height={height} width={xScale.range()[1] - 1} />
        </clipPath>
      </defs>
      {R.keys(VARIANCE_UNITS).includes(trellisTitle) &&
        !R.isEmpty(capacityVarianceData) &&
        capacity.length === capacityVarianceData.cut.length &&
        capacityVarianceData.cut.map((dataset, i) => {
          if (R.isEmpty(dataset)) return null;
          return (
            <VarianceArea
              key={`varianceArea_${capacity[i].id}`}
              clipPath={`url(#${getTrellisGeneralClipPathId(trellisTitle)})`}
              color={COLORS.uncategorizedVariance}
              varianceData={dataset}
              xScale={xScale}
              yScale={yScale}
            />
          );
        })}
      {allocIssues &&
        allocIssuesVisibility &&
        !R.isEmpty(allocIssues) &&
        allocIssues.map((allocIssue, i) => (
          <AllocIssueArea
            key={`allocIssueArea_${allocIssue.id}`}
            allocIssue={allocIssue}
            height={height}
            xScale={xScale}
            isHighlighted={
              (!!highlightedAllocIssue &&
                highlightedAllocIssue.show &&
                highlightedAllocIssue.id === allocIssue.id) ||
              (!!highlightedAllocIssueDivider &&
                highlightedAllocIssueDivider.show &&
                highlightedAllocIssueDivider.id === allocIssue.id) ||
              (!!allocIssueDialog &&
                allocIssueDialog.show &&
                allocIssueDialog.id === allocIssue.id)
            }
            isEditable={
              isPossibleEditAlloc &&
              !!allocIssueDialog &&
              allocIssueDialog.show &&
              allocIssueDialog.id === allocIssue.id
            }
          />
        ))}
      {R.keys(VARIANCE_UNITS).includes(trellisTitle) &&
        !R.isEmpty(eventCapacityData) &&
        varianceEvents.map(
          (varianceEvent, i) =>
            eventCapacityData[i].length >= 2 && (
              <EventArea
                color={eventColors[varianceEvent.varianceOptionId]}
                key={`eventArea_${varianceEvent.id}`}
                varianceData={eventCapacityData[i]}
                xScale={xScale}
                yScale={yScale}
                varianceEvent={varianceEvent}
                title={trellisTitle}
              />
            ),
        )}
      {production && !R.isEmpty(production) && (
        <MainBarsGroup
          data={production}
          xScale={xScale}
          yScale={yScale}
          purpose="production"
          phase={trellisTitle}
          type="well"
        />
      )}

      {R.keys(VARIANCE_UNITS).includes(trellisTitle) &&
        production &&
        !R.isEmpty(production) &&
        !R.isEmpty(varianceEvents) &&
        varianceEvents.map(varianceEvent => (
          <EventProductionArea
            key={varianceEvent.id}
            varianceEvent={varianceEvent}
            eventData={production}
            xScale={xScale}
            yScale={yScale}
            color={eventColors[varianceEvent.varianceOptionId]}
            title={trellisTitle}
            productionKey={productionKey}
          />
        ))}
      {isDisplayingForecast && (
        <ExternalForecastLine
          forecastData={forecastData}
          phase={trellisTitle.toLocaleLowerCase()}
          xScale={xScale}
          yScale={yScale}
        />
      )}

      {!R.isEmpty(capacity) &&
        capacityLineData.map(
          (dataset, i) =>
            !R.isEmpty(dataset) && (
              <CapacityLine
                capacitySegment={dataset}
                capacityEvent={capacity[i]}
                isEditable={
                  trellisCapacityIsEditable &&
                  capacityDialog.show &&
                  i === capacityDialog.index
                }
                trellisTitle={trellisTitle}
                key={`capacityLine_${capacity[i].id || dataset[0].capacity}`}
                xScale={xScale}
                yScale={yScale}
              />
            ),
        )}
      {yAxisLinePos && (
        <line
          stroke="#c42424"
          strokeWidth={1}
          x1={0}
          x2={xScale.range()[1]}
          y1={yAxisLinePos}
          y2={yAxisLinePos}
          vectorEffect="non-scaling-stroke"
        />
      )}

      {svgEl.current && showBarHoverEffect && (
        <SelectedBar
          allocIssues={allocIssues}
          capacityData={flatCapacity}
          forecastData={forecastData}
          onSetTooltipData={onSetTooltipData}
          production={production}
          productionKey={productionKey}
          svgBoundingRect={svgBoundingRect}
          tooltipData={tooltipData}
          trellisTitle={trellisTitle}
          varianceEvents={varianceEvents}
          xScale={xScale}
          yScale={yScale}
        />
      )}
    </svg>
  );
};

export default React.memo<SVGWellTrellisProps>(SVGWellTrellis);
