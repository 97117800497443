import * as R from 'ramda';

import { countBoeValue } from 'modules/production/utils';

import type { VarianceDrilldownTableItem } from 'modules/drilldownTable/models/drilldownTable';
import sum from './sum';

const createForecastGroupedTable = (
  table: VarianceDrilldownTableItem[],
  subject = 'well',
) => {
  if (!subject) return [];
  const groupedTable = R.groupBy(R.prop(subject), table);

  return R.keys(groupedTable).reduce((acc, subjectItemName) => {
    const oilVariance = sum(groupedTable[subjectItemName], 'oilVariance');
    const gasVariance = sum(groupedTable[subjectItemName], 'gasVariance');
    const waterVariance = sum(groupedTable[subjectItemName], 'waterVariance');
    const boeVariance = countBoeValue(oilVariance, gasVariance);

    const oilSecondCol = sum(groupedTable[subjectItemName], 'oilSecondCol');
    const gasSecondCol = sum(groupedTable[subjectItemName], 'gasSecondCol');
    const waterSecondCol = sum(groupedTable[subjectItemName], 'waterSecondCol');
    const boeSecondCol = countBoeValue(oilSecondCol, gasSecondCol);

    const oilThirdCol = sum(groupedTable[subjectItemName], `oilThirdCol`);
    const gasThirdCol = sum(groupedTable[subjectItemName], `gasThirdCol`);
    const waterThirdCol = sum(groupedTable[subjectItemName], `waterThirdCol`);
    const boeThirdCol = countBoeValue(oilThirdCol, gasThirdCol);
    const displaySubjectItemName =
      subjectItemName === 'null' ? '' : subjectItemName;

    const tableRowData = {
      [subject || 'well']: subject === 'all' ? 'all' : displaySubjectItemName,
      oilVariance,
      gasVariance,
      waterVariance,
      boeVariance,
      oilSecondCol,
      gasSecondCol,
      waterSecondCol,
      boeSecondCol,
      oilThirdCol,
      gasThirdCol,
      waterThirdCol,
      boeThirdCol,
    };

    acc.push(tableRowData);
    return acc;
  }, []);
};

export default createForecastGroupedTable;
