import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import DashboardPage from 'modules/dashboard/pages/DashboardPage';
import FilterPage from 'modules/filter/pages/FilterPage';
import { AuthCallback } from 'modules/auth/pages/AuthCallback';
import { Redirect } from 'react-router';

const AdminRoute = loadable(() => import('modules/auth/components/AdminRoute'));
const BacklogPage = loadable(() => import('modules/backlog/pages/BacklogPage'));
const ChangePasswordPage = loadable(
  () => import('modules/auth/pages/ChangePasswordPage'),
);
const LoginPage = loadable(() => import('modules/auth/pages/LoginPage'));
const LogoutPage = loadable(() => import('modules/auth/pages/LogoutPage'));
const PrivateRoute = loadable(
  () => import('modules/auth/components/PrivateRoute'),
);
const SharePage = loadable(() => import('modules/settings/pages/SharePage'));
const ForgotPassword = loadable(
  () => import('modules/user/pages/ForgotPasswordPage'),
);
const UserManagementPage = loadable(
  () => import('modules/user/pages/UserManagementPage'),
);
const DashboardsPage = loadable(
  () => import('modules/spotfire/pages/SpotfireDashboardsPage'),
);
const SpotfirePage = loadable(
  () => import('modules/spotfire/pages/SpotfireDashboardPage'),
);

export default (
  <div>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/auth_callback" component={AuthCallback} />
        <Route
          exact
          path="/login"
          render={() => <LoginPage loginType="common" />}
        />
        <Route
          exact
          path="/login-internal"
          render={() => <LoginPage loginType="internal" />}
        />
        <Route exact path="/logout" component={LogoutPage} />
        <Route
          exact
          path="/accounts/reset/:token?"
          component={ForgotPassword}
        />
        <Route path="/share/:shareId" component={SharePage} />
        <Route exact path="/changePassword" component={ChangePasswordPage} />
        <PrivateRoute exact path="/dashboard" component={DashboardPage} />
        <PrivateRoute
          exact
          path="/dashboard/:wellId"
          component={DashboardPage}
        />
        <PrivateRoute
          exact
          path="/dashboard/:groupSubject/:groupItem"
          component={DashboardPage}
        />
        <PrivateRoute exact path="/filter" component={FilterPage} />
        <PrivateRoute exact path="/backlog" component={BacklogPage} />
        <AdminRoute
          exact
          path="/user-management"
          component={UserManagementPage}
        />

        <AdminRoute
          exact
          path="/spotfire/dashboards"
          component={DashboardsPage}
        />

        <AdminRoute exact path="/spotfire" component={SpotfirePage} />
        <Route exact path="" render={() => <Redirect to="/login" />} />
      </Switch>
    </Suspense>
  </div>
);
