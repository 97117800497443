import { LOCATION_CHANGE, push } from 'connected-react-router';
import * as R from 'ramda';
import { all, takeLatest, put, select, take } from 'redux-saga/effects';

import { getAllAllocIssuesByWell } from 'modules/allocIssue/AllocIssueReducer';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import {
  getDataSeriesGroupsWithColorAndTypeToDisplay,
  getCoreSeriesOptionsToDisplay,
} from 'modules/chartOptions/ChartOptionsReducer';
import {
  setDrilldownTableParams,
  SET_DRILLDOWN_TABLE_PARAMS,
} from 'modules/drilldownTable/DrilldownTableActions';
import {
  getSortedGroupedTable,
  getSortedWellDrilldownTable,
} from 'modules/drilldownTable/DrilldownTableReducer';
import {
  changeVisibilityStatus,
  CHANGE_VISIBILITY_STATUS,
} from 'modules/externalForecast/ExternalForecastActions';
import {
  CHANGE_EXTREME_DATES,
  changeExtremeDates,
  UNDO_EXTREME_DATES_CHANGE,
  setInitialExtremeDates,
} from 'modules/production/ProductionActions';
import { getExtremeDates } from 'modules/production/ProductionReducer';
import {
  SET_CURRENT_WELL_ID,
  SET_CURRENT_GROUP,
  SET_RIBBONS,
  SWITCH_RIBBONS,
  SWITCH_TRELLISES,
  setCurrentWellId,
  STOP_X_AXIS_DRAGGING,
  OPEN_VARIANCE_DIALOG,
  CLOSE_VARIANCE_DIALOG,
  OPEN_CAPACITY_DIALOG,
  CLOSE_CAPACITY_DIALOG,
  OPEN_RIBBON_EVENT_DIALOG,
  CLOSE_RIBBON_EVENT_DIALOG,
  setRightPanelDialog,
  unsetRightPanelDialog,
  EXPAND_RIGHT_PANEL,
  COLLAPSE_RIGHT_PANEL,
  setRibbons,
  collapseRightPanel,
  EXPAND_RIGHT_PANEL_WITHOUT_CHANGING_DIALOG,
  expandRightPanelWithoutChangingDialog,
  enableGroupMode,
  setCurrentGroup,
} from 'modules/ui/UIActions';
import {
  getCurrentWellId,
  getGroupMode,
  getSelectedRibbons,
  getRightPanelDialog,
  getCurrentGroup,
} from 'modules/ui/UIReducer';
import { Action } from 'store/models';

import {
  REDIRECT_TO_CHART_FROM_BACKLOG,
  REDIRECT_TO_CHART_FROM_FILTERS,
  REDIRECT_TO_CHART_FROM_LOGIN,
  REDIRECT_TO_CHART_FROM_SETTINGS,
  replaceParameters,
  REPLACE_PARAMETERS,
  setParameters,
  SET_PARAMETERS,
  UPDATE_ROUTE_AFTER_CREATING_EVENT,
} from './RouterActions';
import {
  countNewDateRangeForRedirectFromBacklog,
  decodeCoreSeries,
  decodeDataSeries,
  encodeGroup,
  stringifySearchParams,
  parseSearchParams,
  encodeCoreSeries,
  encodeDataSeries,
} from './utils/router';
import {
  ADD_OPTIONS_GROUP,
  ADD_OPTION_TO_GROUP,
  REMOVE_AVAILABLE_OPTION,
  REMOVE_OPTION_FROM_GROUP,
  setCoreSeriesFromRoute,
  setInitialState,
  SET_ALL_CORESERIES_OPTION,
  SET_CORESERIES_OPTION,
  SET_CUSTOM_COLOR,
  SET_NEW_GROUP_ORDER,
  SET_NEW_OPTIONS_ORDER,
  SWITCH_CHART_TYPE,
  SWITCH_OPTION_VISIBLE,
  setDataSeriesFromRoute,
  SET_CORESERIES,
} from 'modules/chartOptions/ChartOptionsActions';
import { FETCH_WELL_COLUMN_MAPPING } from 'modules/well/WellActions';
import { getGraphqlPayload } from 'store/helpers';
import {
  setCurrentSeriesConfiguration,
  SET_CURRENT_SERIES_LAYOUT,
} from 'modules/seriesLayouts/SeriesLayoutsActions';
import {
  EventPanel,
  EventPanelData,
  RouterQueryParameters,
} from './models/router';
import { getCurrentLayout } from 'modules/seriesLayouts/SeriesLayoutsReducer';
import { getDashboardRedirectParameters, getUrlValues } from './RouterReducer';
import { SET_URL_PARAMETERS } from '../inboxConversation/InboxConversationActions';
import { getColumnMapping } from 'modules/well/WellReducer';
import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import { getIsAuthenticated } from 'modules/auth/AuthReducer';

function* updateDashboardPathWithInitialValuesSaga(
  action,
): Generator<any, any, any> {
  if (
    action.type === LOCATION_CHANGE &&
    (!action.payload.isFirstRendering ||
      /(\/$)|(^\/dashboard)/.test(action.payload.location.pathname) === false)
  )
    return;

  const urlData = yield select(getUrlValues);
  const isDashboard = urlData.pathname.startsWith('/dashboard');

  if (!isDashboard) {
    while (true) {
      const change = yield take(LOCATION_CHANGE);
      if (change.payload.location.pathname.startsWith('/dashboard')) break;
      if (change.payload.location.pathname.endsWith('/') === false) return;
    }
  }

  const routeSearchParams = urlData.search;
  const storeExtremeDates = yield select(getExtremeDates);
  const seriesLayout = yield select(getCurrentLayout);
  const coreSeriesToDisplay = yield select(getCoreSeriesOptionsToDisplay);
  const parsedSeries = parseSearchParams(seriesLayout.configuration ?? '');

  if (!routeSearchParams.extremeDates)
    routeSearchParams.extremeDates = storeExtremeDates;

  if (!routeSearchParams.coreSeries) {
    routeSearchParams.coreSeries =
      parsedSeries.coreSeries ?? coreSeriesToDisplay.map(o => o.id);
  }

  if (!routeSearchParams.dataSeries)
    routeSearchParams.dataSeries = parsedSeries.dataSeries;

  yield put(
    setParameters({
      pathname: window.location.pathname,
      search: routeSearchParams,
    }),
  );
}

function* updateDashboardPathWithWellSaga(
  action: Action,
): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');
  const groupMode = yield select(getGroupMode);

  if (!currentPageIsDashboard || groupMode.isOn) return;

  const currentWellId = action.payload;

  yield put(
    replaceParameters({
      pathname: `/dashboard/${currentWellId || ''}`,
    }),
  );
}

function* updateDashboardPathWithDatesSaga(
  action: Action,
): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');
  const currentExtremeDates = yield select(getExtremeDates);

  if (!(currentPageIsDashboard && currentExtremeDates)) return;

  yield put(
    replaceParameters({
      search: {
        extremeDates: currentExtremeDates,
      },
    }),
  );
}
function* updateDashboardWithCoreSeriesSaga(
  action: Action,
): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  if (!currentPageIsDashboard) return;

  const selectedRibbons = yield select(getSelectedRibbons);
  const CoreSeriesOptionsToDisplay = yield select(
    getCoreSeriesOptionsToDisplay,
  );
  const coreSeries = CoreSeriesOptionsToDisplay.map(
    (series: { id: any }) => series.id,
  );
  const dataSeries = yield select(getDataSeriesGroupsWithColorAndTypeToDisplay);

  const coreSeriesString = encodeCoreSeries(coreSeries);
  const dataSeriesString = encodeDataSeries(dataSeries);
  const configuration = `&coreseries=${coreSeriesString}&dataseries=${dataSeriesString}`;
  const encodedRibbons =
    Object.keys(selectedRibbons).length > 0
      ? `&ribbons=${Object.keys(selectedRibbons).join('-')}`
      : '';
  yield put(
    setCurrentSeriesConfiguration({
      configuration: configuration + encodedRibbons,
    }),
  );

  yield put(
    replaceParameters({
      search: {
        coreSeries,
      },
    }),
  );
}

function* updateLegendPanelSaga(): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  if (!currentPageIsDashboard) return;

  const legend = Boolean(yield select(getRightPanelDialog));

  yield put(
    replaceParameters({
      search: {
        legend,
      },
    }),
  );
}

function* updateDashboardPathWithGroupSaga(
  action: Action,
): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  const groupMode = yield select(getGroupMode);
  if (!(groupMode.isOn && currentPageIsDashboard)) return;

  const { subject, item } = action.payload;
  const encoded = encodeGroup(subject, item);

  yield put(
    replaceParameters({
      pathname: `/dashboard/${encoded.subject}/${encoded.item}`,
    }),
  );
}

function* redirectToChartFromBacklogSaga(
  action: Action,
): Generator<any, any, any> {
  const allocIssue = action.payload;
  const allocIssuesByWell = yield select(getAllAllocIssuesByWell);

  const extremeDates = yield select(getExtremeDates);
  const currentWellId = yield select(getCurrentWellId);
  const redirectToWellId =
    !allocIssue || R.isEmpty(allocIssue) ? currentWellId : allocIssue.wellId;
  const allocIssueWellId = R.path(['wellId'], allocIssue);
  if (allocIssueWellId && allocIssueWellId !== currentWellId) {
    const index = allocIssuesByWell[allocIssueWellId].findIndex(
      (issue: { id: any }) => issue.id === allocIssue.id,
    );
    const data = { index, id: allocIssue.id };
    yield put(setCurrentWellId(allocIssueWellId));
    yield put(setRightPanelDialog({ type: 'AllocationIssue', data }));
  }

  const appConfig = yield select(getAppConfig);
  const { min, max, shouldUpdate } = countNewDateRangeForRedirectFromBacklog(
    allocIssue,
    extremeDates,
    appConfig.today,
  );
  if (shouldUpdate) {
    yield put(changeExtremeDates({ min, max }));
  }

  const dashboardRedirectParameters = yield select(
    getDashboardRedirectParameters,
  );

  yield put(
    replaceParameters({
      pathname: `/dashboard/${redirectToWellId}`,
      search: {
        ...dashboardRedirectParameters,
        extremeDates: { min, max },
      },
    }),
  );
}

function* redirectToChartFromFiltersSaga(): Generator<any, any, any> {
  yield put(unsetRightPanelDialog());

  const groupMode = yield select(getGroupMode);
  const defaultParameters = yield select(getUrlValues);
  const dashboardRedirectParameters = yield select(
    getDashboardRedirectParameters,
  );
  const joinedParameters = {
    ...defaultParameters.search,
    ...dashboardRedirectParameters,
  };

  if (groupMode.isOn) {
    const currentGroup = yield select(getCurrentGroup);
    const drilldownTable = yield select(getSortedGroupedTable, {
      subject: groupMode.subject,
    });

    const row = drilldownTable.find(
      d => d[currentGroup.subject] === currentGroup.item,
    );
    const firstTableRow = drilldownTable[0];
    const encodedGroup = encodeGroup(
      groupMode.subject,
      (row ?? firstTableRow)[groupMode.subject],
    );

    return yield put(
      setParameters({
        pathname: `/dashboard/${encodedGroup.subject}/${encodedGroup.item}`,
        search: joinedParameters,
      }),
    );
  }

  const drilldownTable = yield select(getSortedWellDrilldownTable);

  if (R.isEmpty(drilldownTable)) {
    return yield put(
      setParameters({
        pathname: `/dashboard`,
        search: joinedParameters,
      }),
    );
  }

  const currentWellId = yield select(getCurrentWellId);
  const firstWellId = drilldownTable[0].wellId;
  const shouldChooseFirstWell = drilldownTable.every(
    (item: { wellId: any }) => item.wellId !== currentWellId,
  );
  const wellIdToSet = shouldChooseFirstWell ? firstWellId : currentWellId;
  yield put(setCurrentWellId(wellIdToSet));

  yield put(
    setParameters({
      pathname: `/dashboard/${wellIdToSet}`,
      search: joinedParameters,
    }),
  );
}

function* updateDashboardPathWithDrilldownSaga(
  action: Action,
): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  if (!currentPageIsDashboard) return;

  const drilldownData = Object.entries({
    grossNet: action.payload.grossNet,
    compare: action.payload.compareOption,
  }).reduce((acc, [key, value]) => {
    if (value) acc[key] = value;

    return acc;
  }, {});

  yield put(
    replaceParameters({
      search: drilldownData,
    }),
  );
}

function* redirectToChartFromSettingsSaga(
  action: Action,
): Generator<any, any, any> {
  const urlData = action.payload;
  const defaultSearchParams = yield select(getUrlValues);
  const routeSearchParams = urlData.search;
  const storeExtremeDates = yield select(getExtremeDates);
  const seriesLayout = yield select(getCurrentLayout);
  const parsedSeries = parseSearchParams(seriesLayout.configuration ?? '');

  if (!routeSearchParams.extremeDates)
    routeSearchParams.extremeDates = storeExtremeDates;

  if (routeSearchParams.coreSeries.length === 0)
    routeSearchParams.coreSeries = parsedSeries.coreSeries;

  if (routeSearchParams.dataSeries.length === 0)
    routeSearchParams.dataSeries = parsedSeries.dataSeries;

  return yield put(
    setParameters({
      pathname: urlData.pathname,
      search: { ...defaultSearchParams.search, ...routeSearchParams },
    }),
  );
}

function* updateDashboardpathWithRibbons(): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  if (!currentPageIsDashboard) return;

  const selectedRibbons = yield select(getSelectedRibbons);

  yield put(
    replaceParameters({
      search: {
        ribbons: R.keys(selectedRibbons),
      },
    }),
  );
}

function* changeGrossNetParamsSaga(
  action: Record<string, any>,
): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  const payload = getGraphqlPayload(action);
  const hasNri = payload.some(
    (item: { wiserockBinding: string }) => item.wiserockBinding === 'NRI',
  );

  if (!currentPageIsDashboard || hasNri) return;

  yield put(
    replaceParameters({
      search: {
        grossNet: 'Gross',
      },
    }),
  );
}

function* changeForecastVisibilitySaga(action: any): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  if (!currentPageIsDashboard) return;

  yield put(
    replaceParameters({
      search: { external: action.payload.status },
    }),
  );
}

function* updateDashboardPathWithDatesSeriesSaga(
  action: any,
): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  if (!currentPageIsDashboard) return;

  const selectedRibbons = yield select(getSelectedRibbons);
  const CoreSeriesOptionsToDisplay = yield select(
    getCoreSeriesOptionsToDisplay,
  );
  const coreSeries = CoreSeriesOptionsToDisplay.map(
    (series: { id: any }) => series.id,
  );
  const dataSeries = yield select(getDataSeriesGroupsWithColorAndTypeToDisplay);
  const coreSeriesString = encodeCoreSeries(coreSeries);
  const dataSeriesString = encodeDataSeries(dataSeries);
  const configuration = `&coreseries=${coreSeriesString}&dataseries=${dataSeriesString}`;
  const encodedRibbons =
    Object.keys(selectedRibbons).length > 0
      ? `&ribbons=${Object.keys(selectedRibbons).join('-')}`
      : '';

  yield put(
    setCurrentSeriesConfiguration({
      configuration: configuration + encodedRibbons,
    }),
  );

  yield put(
    replaceParameters({
      search: {
        dataSeries,
      },
    }),
  );
}

function* updateDashboardPathWithSeriesLayout(
  action: any,
): Generator<any, any, any> {
  const currentPageIsDashboard =
    window.location.pathname.includes('/dashboard');

  if (!currentPageIsDashboard) return;

  const { configuration } = action.payload;
  const [, coreSeriesString, dataSeriesString] = configuration.split('&');

  const coreSeries = decodeCoreSeries(coreSeriesString.split('=')[1]);
  const dataSeries = decodeDataSeries(dataSeriesString.split('=')[1]);

  yield put(
    replaceParameters({
      search: {
        coreSeries,
        dataSeries,
      },
    }),
  );
}

function* updateRouteWithEventPanelSaga(action: any): Generator<any, any, any> {
  let eventPanel: EventPanelData | null = null;

  if (/OPEN/i.test(action.type)) {
    const id = action.payload?.data?.id;
    const parsedType = /OPEN_(?<type>[a-z]+)/i.exec(action.type)?.groups?.type;
    if (parsedType === undefined) return;

    const type = EventPanel[parsedType.toLowerCase()];
    if (type === undefined || id === undefined) return;
    eventPanel = {
      id,
      type,
    };
  }

  yield put(
    replaceParameters({
      search: {
        eventPanel,
      },
    }),
  );
}

function* updateRouteWithEventPanelAfterCreatingSaga(
  action: any,
): Generator<any, any, any> {
  const { oldId, newId } = action.payload;

  const searchParams = yield select(getUrlValues);

  const eventPanel = searchParams.search.eventPanel;

  if (!eventPanel || eventPanel.id !== oldId) return;

  yield put(
    replaceParameters({
      search: { eventPanel: { ...eventPanel, id: newId } },
    }),
  );
}

function* changeUrlSaga(): Generator<any, any, any> {
  const newUrlData = yield select(getUrlValues);
  if (
    !(newUrlData.pathname ?? window.location.pathname).startsWith('/dashboard')
  )
    return;

  yield put(
    push({
      pathname: newUrlData.pathname ?? window.location.pathname,
      search: stringifySearchParams(
        newUrlData.search ?? window.location.search,
      ),
    }),
  );
}

function* updateStoreWithInitialUrlParametersSaga(): Generator<any, any, any> {
  const isAuthenticated = yield select(getIsAuthenticated);
  if (!isAuthenticated) return;

  const urlData: RouterQueryParameters = yield select(getUrlValues);
  const columnMapping = yield select(getColumnMapping);
  const isAllowedChangeGross =
    R.isEmpty(columnMapping) ||
    columnMapping.some(item => item.wiserockBinding === 'NRI');

  const match = (urlData.pathname as string).match(
    /\/dashboard\/?(?<subject>[\w-]+)?\/?(?<item>[\w-]+)?/,
  );

  if (match && match.groups) {
    const { item, subject } = match.groups;

    if (item) {
      yield put(enableGroupMode(subject));
      yield put(
        setCurrentGroup({
          subject,
          item: item === 'empty' ? '' : item.split('-').join(' '),
        }),
      );
    } else if (subject) {
      const { subject } = match.groups;
      yield put(setCurrentWellId(subject));
    } else {
      const currentWellId = yield select(getCurrentWellId);
      yield put(setCurrentWellId(currentWellId));
    }
  }

  if (urlData.search.extremeDates) {
    const { min, max } = urlData.search.extremeDates;
    if (min !== null && max !== null)
      yield put(setInitialExtremeDates({ min, max }));
  }

  if (isAllowedChangeGross) {
    yield put(setDrilldownTableParams({ grossNet: urlData.search.grossNet }));
  }

  if (urlData.search.ribbons) {
    yield put(setRibbons(urlData.search.ribbons));
  }

  yield put(changeVisibilityStatus({ status: urlData.search.external }));

  if (urlData.search.legend) {
    yield put(expandRightPanelWithoutChangingDialog());
  } else {
    yield put(collapseRightPanel());
  }

  const compareOption = COMPARE_OPTION[urlData.search.compare];

  if (compareOption) {
    yield put(setDrilldownTableParams({ compareOption: compareOption }));
  }

  if (urlData.search.coreSeries) {
    yield put(setCoreSeriesFromRoute({ series: urlData.search.coreSeries }));
  } else {
    yield put(setInitialState());
  }

  if (urlData.search.dataSeries) {
    yield put(
      //@ts-ignore FIX TYPES
      setDataSeriesFromRoute({ dataSeries: urlData.search.dataSeries }),
    );
  }
}

function* changeUrlParametersSaga(action): Generator<any, any, any> {
  const parameters = parseSearchParams(action.payload);
  if (!parameters.drilldown) parameters.drilldown = true;
  const {
    extremeDates,
    drilldown,
    external,
    dataSeries,
    ribbons,
    grossNet,
    compare,
    legend,
    coreSeries,
  } = parameters;

  yield put(
    replaceParameters({ search: { drilldown, coreSeries, dataSeries } }),
  );
  if (coreSeries) {
    yield put(setCoreSeriesFromRoute({ series: coreSeries }));
  }
  yield put(changeVisibilityStatus({ status: external }));
  if (dataSeries) {
    //Fix types
    //@ts-ignore
    yield put(setDataSeriesFromRoute({ dataSeries }));
  }
  yield put(setDrilldownTableParams({ grossNet, compareOption: compare }));
  if (legend) {
    yield put(expandRightPanelWithoutChangingDialog());
  } else {
    yield put(collapseRightPanel());
  }
  if (ribbons) {
    yield put(setRibbons(ribbons));
  }

  if (extremeDates && extremeDates.min && extremeDates.max) {
    yield put(
      changeExtremeDates({
        min: extremeDates.min,
        max: extremeDates.max,
      }),
    );
  }
}

function* routerSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      [
        OPEN_VARIANCE_DIALOG,
        CLOSE_VARIANCE_DIALOG,
        OPEN_CAPACITY_DIALOG,
        CLOSE_CAPACITY_DIALOG,
        OPEN_RIBBON_EVENT_DIALOG,
        CLOSE_RIBBON_EVENT_DIALOG,
      ],
      updateRouteWithEventPanelSaga,
    ),
    takeLatest(
      UPDATE_ROUTE_AFTER_CREATING_EVENT,
      updateRouteWithEventPanelAfterCreatingSaga,
    ),
    takeLatest(REDIRECT_TO_CHART_FROM_BACKLOG, redirectToChartFromBacklogSaga),
    takeLatest(REDIRECT_TO_CHART_FROM_FILTERS, redirectToChartFromFiltersSaga),
    takeLatest(
      REDIRECT_TO_CHART_FROM_SETTINGS,
      redirectToChartFromSettingsSaga,
    ),
    takeLatest([SWITCH_RIBBONS, SET_RIBBONS], updateDashboardpathWithRibbons),
    takeLatest(
      SET_DRILLDOWN_TABLE_PARAMS,
      updateDashboardPathWithDrilldownSaga,
    ),
    takeLatest(
      [
        EXPAND_RIGHT_PANEL_WITHOUT_CHANGING_DIALOG,
        EXPAND_RIGHT_PANEL,
        COLLAPSE_RIGHT_PANEL,
      ],
      updateLegendPanelSaga,
    ),
    takeLatest(SET_CURRENT_WELL_ID, updateDashboardPathWithWellSaga),
    takeLatest(
      [CHANGE_EXTREME_DATES, UNDO_EXTREME_DATES_CHANGE, STOP_X_AXIS_DRAGGING],
      updateDashboardPathWithDatesSaga,
    ),
    takeLatest(
      [
        ADD_OPTIONS_GROUP,
        REMOVE_AVAILABLE_OPTION,
        SET_NEW_GROUP_ORDER,
        REMOVE_OPTION_FROM_GROUP,
        SET_CUSTOM_COLOR,
        SWITCH_CHART_TYPE,
        ADD_OPTION_TO_GROUP,
        SWITCH_OPTION_VISIBLE,
        SET_NEW_OPTIONS_ORDER,
      ],
      updateDashboardPathWithDatesSeriesSaga,
    ),
    takeLatest(SET_CURRENT_GROUP, updateDashboardPathWithGroupSaga),
    takeLatest(
      [
        SWITCH_TRELLISES,
        SET_CORESERIES_OPTION,
        SET_ALL_CORESERIES_OPTION,
        SET_CORESERIES,
      ],
      updateDashboardWithCoreSeriesSaga,
    ),
    takeLatest(
      `${FETCH_WELL_COLUMN_MAPPING}_SUCCESS`,
      changeGrossNetParamsSaga,
    ),
    takeLatest(CHANGE_VISIBILITY_STATUS, changeForecastVisibilitySaga),
    takeLatest(SET_CURRENT_SERIES_LAYOUT, updateDashboardPathWithSeriesLayout),
    takeLatest([REPLACE_PARAMETERS, SET_PARAMETERS], changeUrlSaga),
    takeLatest(
      [REDIRECT_TO_CHART_FROM_LOGIN, LOCATION_CHANGE],
      updateDashboardPathWithInitialValuesSaga,
    ),
    takeLatest([SET_URL_PARAMETERS], changeUrlParametersSaga),
    takeLatest([SET_PARAMETERS], updateStoreWithInitialUrlParametersSaga),
  ]);
}

export default routerSagas;
