import * as React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import { CloseIcon } from 'components/Icons';
import { useNonInputKeydown } from 'hooks/useKeydown';
import useRightPanel from 'modules/ui/hooks/useRightPanel';

const tableData = [
  { functionality: 'Open/close Shortcuts Panel', shortcut: '/' },
  {
    functionality: 'Move between wells in Drilldown Table',
    shortcut: 'up/down arrow',
  },
  { functionality: 'Extend last Variance end date to today', shortcut: 'e' },
  { functionality: 'Set Drilldown  Region of Interest', shortcut: 'r' },
  { functionality: 'Close right panel', shortcut: 'esc' },
  {
    functionality: 'Zoom in or out on Production Chart',
    shortcut: 'scroll wheel',
  },
  { functionality: 'Zoom to show all production history', shortcut: 's' },
  { functionality: 'Zoom in', shortcut: 'i' },
  { functionality: 'Zoom out', shortcut: 'o' },
  { functionality: 'Zoom previous', shortcut: 'p' },
  { functionality: 'Zoom to prior 3 months', shortcut: 'z, 1' },
  { functionality: 'Zoom to prior 6 months', shortcut: 'z, 2' },
  { functionality: 'Zoom to prior 12 months', shortcut: 'z, 3' },
  { functionality: 'Zoom to prior 24 months', shortcut: 'z, 4' },
  { functionality: 'Toggle BOE trellis', shortcut: '1' },
  { functionality: 'Toggle Oil trellis', shortcut: '2' },
  { functionality: 'Toggle Gas  trellis', shortcut: '3' },
  { functionality: 'Toggle GOR trellis', shortcut: '4' },
  { functionality: 'Toggle Water trellis', shortcut: '5' },
  { functionality: 'Toggle Watercut trellis', shortcut: '6' },
  { functionality: 'Toggle GLR trellis', shortcut: '7' },
  { functionality: 'Toggle Total Liquid trellis', shortcut: '8' },
  { functionality: 'Toggle Variance trellis', shortcut: '9' },
  { functionality: 'Toggle all trellises', shortcut: '0' },
  { functionality: 'Begin adding new Variance Event', shortcut: 'v' },
  { functionality: 'Begin adding new Capacity Change', shortcut: 'c' },
  { functionality: 'Begin adding new Allocation Issue', shortcut: 'a' },
  {
    functionality: 'Delete active capacity/variance event',
    shortcut: 'delete',
  },
  { functionality: 'Confirm delete in pop-up dialog box', shortcut: 'enter' },
  { functionality: 'Open the Notes Panel', shortcut: 'n' },
  {
    functionality: 'Prior/next note (w/ Notes Panel open)',
    shortcut: 'left/right arrow',
  },
  {
    functionality: 'Prior/next image (w/ Image Details open)',
    shortcut: 'left/right arrow',
  },
  {
    functionality: 'Select multiple separate table rows',
    shortcut: 'ctrl + click',
  },
  {
    functionality: 'Select multiple adjacent table rows',
    shortcut: 'shift + click',
  },
];

type ShortcutPanelProps = {
  onShortcutPanelClose: () => void;
  onRequestHelpPanelOpen: () => void;
};

const ShortcutPanel = ({
  onShortcutPanelClose,
  onRequestHelpPanelOpen,
}: ShortcutPanelProps) => {
  const rightPanel = useRightPanel();
  const shownCloseButton = window.location.pathname.includes('/dashboard');

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('Shortcuts')) return;
      if (keyName === 'ESCAPE') return onShortcutPanelClose();
    },
    [onShortcutPanelClose, rightPanel.isDialogOfType],
  );

  return (
    <ShortcutPanel.Card>
      <ShortcutPanel.Header>
        <ShortcutPanel.TitleWrapper>
          <ShortcutPanel.Title>{'Keyboard Shortcuts'}</ShortcutPanel.Title>
        </ShortcutPanel.TitleWrapper>
        <ShortcutPanel.RequestHelpButton
          onClick={onRequestHelpPanelOpen}
          width={110}
        >
          Request Help
        </ShortcutPanel.RequestHelpButton>
        {shownCloseButton && (
          <ShortcutPanel.CloseButton
            transparent
            onClick={onShortcutPanelClose}
            width={25}
          >
            <CloseIcon />
          </ShortcutPanel.CloseButton>
        )}
      </ShortcutPanel.Header>
      <ShortcutPanel.PanelContent>
        <ShortcutPanel.Table>
          <thead>
            <ShortcutPanel.HeadRow>
              <ShortcutPanel.Cel>{'Functionality'}</ShortcutPanel.Cel>
              <ShortcutPanel.Cel>{'Shortcut'}</ShortcutPanel.Cel>
            </ShortcutPanel.HeadRow>
          </thead>
          <tbody>
            {tableData.map((row, i) => (
              <ShortcutPanel.Row key={i}>
                <ShortcutPanel.Cel>{row.functionality}</ShortcutPanel.Cel>
                <ShortcutPanel.Cel>{row.shortcut}</ShortcutPanel.Cel>
              </ShortcutPanel.Row>
            ))}
          </tbody>
        </ShortcutPanel.Table>
      </ShortcutPanel.PanelContent>
    </ShortcutPanel.Card>
  );
};

ShortcutPanel.Card = styled.div`
  background: white;
  height: 100%;
  overflow-x: hidden;
`;

ShortcutPanel.Table = styled.table`
  background: white;
  width: 100%;
  border: 1px solid black;
  overflow-x: hidden;
`;

ShortcutPanel.HeadRow = styled.tr`
  height: 20px;
  border-bottom: 1px solid black;
  overflow-x: hidden;
  background: #e1e1e1;
  > th {
    text-align: center !important;
    font-weight: bold;
  }
`;

ShortcutPanel.Row = styled.tr`
  background: white;
  height: 20px;
  border-bottom: 1px solid black;
  overflow-x: hidden;
`;

ShortcutPanel.Cel = styled.th`
  overflow-x: hidden;
  text-align: center;
  vertical-align: middle;
  &:first-child {
    border-right: 1px solid black;
    text-align: left;
    padding-left: 10px;
  }
`;

ShortcutPanel.PanelContent = styled.div`
  padding: 10px;
`;

ShortcutPanel.Header = styled.div`
  height: 47px;
  display: grid;
  grid-template-columns: auto auto 25px;
  align-content: center;
  padding: 15px 10px;
  font-size: 16px;
  border-bottom: 1px solid #000;
  font-weight: bold;
  align-items: center;
`;

ShortcutPanel.Content = styled.div`
  height: calc(100% - 49px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

ShortcutPanel.TitleWrapper = styled.div`
  display: flex;
  align-content: center;
`;

ShortcutPanel.CloseButton = styled(Button)`
  justify-self: end;
`;

ShortcutPanel.Title = styled.div`
  display: flex;
  align-items: center;
`;

ShortcutPanel.RequestHelpButton = styled(Button)`
  justify-self: end;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

export default React.memo<ShortcutPanelProps>(ShortcutPanel);
