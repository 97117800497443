import * as R from 'ramda';
import { push } from 'connected-react-router';
import { all, delay, fork, put, select, takeEvery } from 'redux-saga/effects';

import { setInitialState } from 'modules/chartOptions/ChartOptionsActions';
import { clearSelectedFilterOptions } from 'modules/filterLayouts/FilterLayoutsActions';
import { pushNotification } from 'modules/notification/NotificationActions';
import { FETCH_ALL } from 'modules/user/UserActions';
import { FETCH_WELL_COLUMN_MAPPING } from 'modules/well/WellActions';
import { getGraphqlPayload } from 'store/helpers';

import { LOGIN_VIA_SSO, LOGOUT, logout, updateToken } from './AuthActions';
import { getId, getIsAuthenticated, getTokenCreationTime } from './AuthReducer';
import {
  CUSTOMER_IS_NOT_VERIFIED,
  TOKEN_EXPIRED,
  USER_IS_INACTIVE,
  USER_SHOULD_CHANE_PASSWORD,
  USER_NOT_AUTHENTICATED,
} from './models/auth';

function* logoutSaga(): any {
  yield put(setInitialState());
  yield put(clearSelectedFilterOptions());
  const isChangePasswordPage =
    window.location.pathname.includes('/changePassword');
  if (!isChangePasswordPage) {
    yield put(push('/login'));
  }
}

function* checkAuthSaga(action): Generator<any, any, any> {
  const { error } = action;
  const isLoginPage = window.location.pathname.includes('/login');
  const isLogoutPage = window.location.pathname.includes('/logout');
  const pageNeedsRedirect = !isLoginPage && !isLogoutPage;
  if (action.type === `${LOGIN_VIA_SSO}_FAIL`) {
    const SSOError = error[0].message.replace(/Error: /, '');
    yield put(
      pushNotification({
        message: SSOError,
      }),
    );
    yield put(
      push({
        pathname: '/login',
      }),
    );
  }

  if (
    error.some(err => err.message === USER_NOT_AUTHENTICATED) &&
    pageNeedsRedirect
  ) {
    yield put(pushNotification({ message: 'User is not authenticated' }));
    yield put(logout());
  }

  if (
    error.some(err => err.message === CUSTOMER_IS_NOT_VERIFIED) &&
    pageNeedsRedirect
  ) {
    yield put(logout());
  }

  if (
    error.some(err => err.message === USER_IS_INACTIVE) &&
    pageNeedsRedirect
  ) {
    yield put(pushNotification({ message: 'Your account is inactive' }));
    yield put(logout());
  }

  if (error.some(err => err.message === TOKEN_EXPIRED) && pageNeedsRedirect) {
    yield put(
      pushNotification({
        message:
          'You have been logged out due to inactivity.  Please log in again',
      }),
    );
    yield put(logout());
  }

  if (
    error.some(err => err.message === USER_SHOULD_CHANE_PASSWORD) &&
    pageNeedsRedirect
  ) {
    yield put(logout());
    yield delay(1000);
    yield put(push('/changePassword'));
  }
}

function* checkPasswordChangeNessSaga(action): Generator<any, any, any> {
  const payload = getGraphqlPayload(action);
  const myId = yield select(getId);
  const me = payload.find(user => user.id === myId);

  if (me && me.mustChangePassword) {
    yield put(push('/changePassword'));
  }
}

function* checkTokenForExpiration() {
  //eslint-disable-next-line
  while (true) {
    const isAuthenticated = yield select(getIsAuthenticated);
    if (isAuthenticated) {
      const tokenCreationTime = yield select(getTokenCreationTime);
      if (!tokenCreationTime) {
        yield put(updateToken());
        return;
      }
      const tokenExpirationTime = tokenCreationTime + 1000 * 60 * 60;
      const inFiveMinutes = Number(new Date()) + 1000 * 60 * 5;

      if (tokenExpirationTime < inFiveMinutes) {
        yield put(updateToken());
      }
    }

    yield delay(1000 * 20);
  }
}

function* reloginSaga(action): Generator<any, any, any> {
  if (R.pathOr('', ['error', 0, 'message'], action) === 'Not Authorised!') {
    yield put(logout());
  }
}

function* authSagas(): any {
  yield all([
    takeEvery(`${FETCH_WELL_COLUMN_MAPPING}_FAIL`, reloginSaga),
    takeEvery(LOGOUT, logoutSaga),
    takeEvery(action => /_FAIL/g.test(action.type), checkAuthSaga),
    takeEvery(`${FETCH_ALL}_SUCCESS`, checkPasswordChangeNessSaga),
    fork(checkTokenForExpiration),
  ]);
}

export default authSagas;
