import * as React from 'react';
import styled from 'styled-components';

interface SeriesPillProps {
  color?: string;
  text: string;
  onPillClick: () => void;
}

const SeriesPill = ({ color, text, onPillClick }: SeriesPillProps) => {
  return (
    <SeriesPill.Container onClick={onPillClick} color={color}>
      {text}
    </SeriesPill.Container>
  );
};

SeriesPill.Container = styled.div`
  color: ${props => props.color || '#484848'};
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 5px;
  padding: 3px;
  border: ${props => `1px solid ${props.color || '#484848'}`};
  border-radius: 4px;
  background: white;
  font-weight: 600;
  font-size: 14px;
  z-index: 100;
`;

export default React.memo<SeriesPillProps>(SeriesPill);
