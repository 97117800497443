import gql from 'graphql-tag';

import type { GraphqlQueryAction, Action } from 'store/models';
import { Well } from './models/well';

export const namespace = 'WELL';
export const FETCH_WELLS = `${namespace}/FETCH_WELLS`;
export const POPULATE_WELLS = `${namespace}/POPULATE_WELLS`;
export const SET_WELLS_WITH_CUSTOM_VALUES = `${namespace}/SET_WELLS_WITH_CUSTOM_VALUES`;

export const FETCH_WELL_COLUMN_MAPPING = `${namespace}/FETCH_WELL_COLUMN_MAPPING`;

type populateWellsAC = (wells: { [id: string]: Well }) => Action;

export const populateWells: populateWellsAC = payload => ({
  type: POPULATE_WELLS,
  payload,
});

const FETCH_WELL_LIST_QUERY = gql`
  query {
    listWells {
      wells
    }
  }
`;

type setWellsWithCustomValuesAC = (payload: { [id: string]: Well }) => Action;

export const setWellsWithCustomValues: setWellsWithCustomValuesAC =
  payload => ({ type: SET_WELLS_WITH_CUSTOM_VALUES, payload });

type fetchWellsAC = (wells: number[]) => GraphqlQueryAction;

export const fetchWells: fetchWellsAC = payload => ({
  type: FETCH_WELLS,
  payload: {
    key: 'listWells',
    graphql: {
      query: FETCH_WELL_LIST_QUERY,
    },
  },
});

const FETCH_WELL_COLUMN_MAPPING_QUERY = gql`
  query {
    listWellColumnMapping {
      id
      columnIndex
      sourceName
      displayName
      filterColumn
      filterColumnOrder
      filterColumnHeight
      wiserockBinding
      groupByOrder
      source
      description
    }
  }
`;
type fetchWellColumnMappingAC = () => GraphqlQueryAction;

export const fetchWellColumnMapping: fetchWellColumnMappingAC = () => ({
  type: FETCH_WELL_COLUMN_MAPPING,
  payload: {
    key: 'listWellColumnMapping',
    graphql: {
      query: FETCH_WELL_COLUMN_MAPPING_QUERY,
    },
  },
});
