import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { utcDay, utcYear } from 'd3-time';
import * as React from 'react';
import styled from 'styled-components';

import { AppConfig } from 'modules/appConfig/models/appConfig';
import { BOE, OIL, GAS, WATER } from 'modules/phase/models/phase';

import Button from 'components/Button';
import DateInput from 'components/DateInput';
import { MousePointer, Refresh } from 'components/Icons';
import LogoMenu from 'components/LogoMenu';

import useMouseDownOutside from 'hooks/useMouseDownOutside';

import {
  COMPARE_OPTION,
  DrilldownTableParams,
} from 'modules/drilldownTable/models/drilldownTable';

const varianceTypesOptionsTitles = {
  [BOE]: 'BOE',
  [OIL]: 'Oil',
  [GAS]: 'Gas',
  [WATER]: 'Water',
};

const grossNetOptionsTitles = {
  gross: 'Gross',
  net: 'Net',
};

const volumeRateOptionsTitles = {
  volume: 'Volume',
  rate: 'Rate',
};

const compareTitles = {
  actual: 'Capacity vs Actual',
  extVsCap: 'Capacity vs RE Forecast',
  actVsExt: 'RE Forecast vs Actual',
};

const buttonLabels = {
  grossNet: {
    Gross: 'Gr.',
    Net: 'Nt.',
  },
  rateVolume: {
    Rate: 'Rate',
    Volume: 'Vol.',
  },
  phase: {
    [OIL]: 'Oil',
    [GAS]: 'Gas',
    [WATER]: 'Wat.',
    [BOE]: 'BOE',
  },
};

type DrilldownTableFormProps = {
  appConfig: AppConfig;
  beforeSubmit: () => void;
  currentDrilldownTableState: DrilldownTableParams;
  hasNri: boolean;
  isActive: boolean;
  isDisplayedForecast: boolean;
  onChangeForecastStatus: (staus: boolean) => void;
  onSetCompareOption: (optionKey: string) => void;
  regionOfInterestModeOn: () => void;
  setDrilldownTableOption: (option: string) => void;
  setMaxDrilldownTableDate: (date: Date) => void;
  setMinDrilldownTableDate: (date: Date) => void;
  setRateVolume: (rate: string) => void;
  setGrossNet: (grossNet: string) => void;
};

const DrilldownTableForm = ({
  appConfig,
  beforeSubmit,
  currentDrilldownTableState,
  hasNri,
  isActive,
  isDisplayedForecast,
  onChangeForecastStatus,
  onSetCompareOption,
  regionOfInterestModeOn,
  setGrossNet,
  setRateVolume,
  setMaxDrilldownTableDate,
  setMinDrilldownTableDate,
  setDrilldownTableOption,
}: DrilldownTableFormProps) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), []);
  const [menuTimer, setMenuTimer] = React.useState<any>(null);

  const maxAvailableDate = React.useMemo(() => {
    if (currentDrilldownTableState.compareOption === COMPARE_OPTION.extVsCap) {
      return utcYear.offset(appConfig.today, 5);
    }
    return utcDay.offset(appConfig.today, -1);
  }, [appConfig, currentDrilldownTableState]);

  const menuEl = React.useRef(null);

  const closeTrellisMenuWithTimeout = () =>
    setMenuTimer(setTimeout(closeMenu, 500));
  const onTrellisMenuHover = () => {
    if (menuTimer) clearTimeout(menuTimer);
  };

  useMouseDownOutside([menuEl], closeMenu);

  return (
    <DrilldownTableForm.FormWrapper>
      <form>
        <LogoMenu width={200} noText />
        <div style={{ width: '95px' }}>
          <Button width={95} height={30} onClick={() => setMenuOpen(true)}>
            <span>
              {buttonLabels.grossNet[currentDrilldownTableState.grossNet]}{' '}
              {buttonLabels.phase[currentDrilldownTableState.phase]}{' '}
              {buttonLabels.rateVolume[currentDrilldownTableState.rateVolume]}
            </span>
          </Button>
          {isMenuOpen && (
            <DrilldownTableForm.MenuWrapper
              ref={menuEl}
              onMouseLeave={closeTrellisMenuWithTimeout}
              onMouseEnter={onTrellisMenuHover}
            >
              <Paper>
                {appConfig.capacityVsExternal &&
                  appConfig.showExternalForecast && (
                    <MenuItem>
                      <DrilldownTableForm.MenuItemLabel>
                        Compare
                      </DrilldownTableForm.MenuItemLabel>
                      <select
                        id="compare"
                        name="compare"
                        value={currentDrilldownTableState.compareOption}
                        style={{ width: '100%' }}
                        onChange={e => {
                          onSetCompareOption(e.target.value);
                          e.target.blur();
                        }}
                      >
                        {Object.keys(compareTitles).map(optionTitle => (
                          <option
                            key={optionTitle}
                            value={optionTitle}
                            disabled={optionTitle === 'net' && !hasNri}
                          >
                            {compareTitles[optionTitle]}
                          </option>
                        ))}
                      </select>
                    </MenuItem>
                  )}
                <MenuItem>
                  <DrilldownTableForm.MenuItemLabel>
                    Gross/Net
                  </DrilldownTableForm.MenuItemLabel>
                  <select
                    id="grossNet"
                    name="grossNet"
                    value={currentDrilldownTableState.grossNet}
                    style={{ width: '100%' }}
                    onChange={e => {
                      setGrossNet(e.target.value);
                      e.target.blur();
                    }}
                  >
                    {Object.keys(grossNetOptionsTitles).map(optionTitle => (
                      <option
                        key={optionTitle}
                        value={grossNetOptionsTitles[optionTitle]}
                        disabled={optionTitle === 'net' && !hasNri}
                      >
                        {`${grossNetOptionsTitles[optionTitle]}${
                          optionTitle === 'net' && !hasNri
                            ? ' (Unavailable)'
                            : ''
                        }`}
                      </option>
                    ))}
                  </select>
                </MenuItem>
                <MenuItem>
                  <DrilldownTableForm.MenuItemLabel>
                    Phase
                  </DrilldownTableForm.MenuItemLabel>
                  <select
                    id="phase"
                    name="phase"
                    value={currentDrilldownTableState.phase}
                    onChange={e => {
                      setDrilldownTableOption(e.target.value);
                      e.target.blur();
                    }}
                    tabIndex={1}
                    style={{ width: '100%' }}
                  >
                    {Object.keys(varianceTypesOptionsTitles).map(
                      optionTitle => (
                        <option
                          key={optionTitle}
                          value={varianceTypesOptionsTitles[optionTitle]}
                        >
                          {varianceTypesOptionsTitles[optionTitle]}
                        </option>
                      ),
                    )}
                  </select>
                </MenuItem>
                <MenuItem>
                  <DrilldownTableForm.MenuItemLabel>
                    Rate/Volume
                  </DrilldownTableForm.MenuItemLabel>
                  <select
                    id="rateVolume"
                    name="rateVolume"
                    value={currentDrilldownTableState.rateVolume}
                    onChange={e => {
                      setRateVolume(e.target.value);
                      e.target.blur();
                    }}
                    style={{ width: '100%' }}
                  >
                    {Object.keys(volumeRateOptionsTitles).map(optionTitle => (
                      <option
                        key={optionTitle}
                        value={volumeRateOptionsTitles[optionTitle]}
                      >
                        {volumeRateOptionsTitles[optionTitle]}
                      </option>
                    ))}
                  </select>
                </MenuItem>
                {appConfig.showExternalForecast && (
                  <DrilldownTableForm.FormControlLabel
                    control={
                      <Checkbox
                        checked={isDisplayedForecast}
                        onChange={e => onChangeForecastStatus(e.target.checked)}
                        disabled={
                          currentDrilldownTableState.compareOption !==
                          COMPARE_OPTION.actual
                        }
                        color="primary"
                      />
                    }
                    label="Show RE Forecast"
                  />
                )}
              </Paper>
            </DrilldownTableForm.MenuWrapper>
          )}
        </div>
        <DrilldownTableForm.DateInputsContainer isActive={isActive}>
          <DateInput
            id="minDate"
            name="minDate"
            isActive={isActive}
            activeColor="primaryText"
            date={currentDrilldownTableState.minDate}
            max={currentDrilldownTableState.maxDate}
            min={new Date(0)}
            onProcess={date => {
              beforeSubmit();
              setMinDrilldownTableDate(date);
            }}
            tabIndex={2}
            transparent
          />
          <DrilldownTableForm.TextDash isActive={isActive} />
          <DateInput
            id="maxDate"
            name="maxDate"
            isActive={isActive}
            activeColor="primaryText"
            date={currentDrilldownTableState.maxDate}
            max={maxAvailableDate}
            min={currentDrilldownTableState.minDate}
            onProcess={date => {
              beforeSubmit();
              setMaxDrilldownTableDate(date);
            }}
            tabIndex={3}
            transparent
          />
        </DrilldownTableForm.DateInputsContainer>
        <Button width={34} height={30} onClick={regionOfInterestModeOn}>
          <MousePointer />
        </Button>
        <Button width={34} height={30} onClick={beforeSubmit}>
          <Refresh />
        </Button>
      </form>
    </DrilldownTableForm.FormWrapper>
  );
};

DrilldownTableForm.FormWrapper = styled.div`
  height: 41px;
  padding: 0;
  margin-left: -2px;

  & > form {
    display: flex;
    justify-content: space-between;
    height: 35px;
    align-items: center;
  }
`;

DrilldownTableForm.PhaseRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  & select {
    width: 140px;
    margin-right: 10px;
  }
`;

DrilldownTableForm.Label = styled.p`
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 10px;
`;

DrilldownTableForm.VariancePhaseWrapper = styled.div`
  margin-bottom: 18px;
`;

DrilldownTableForm.DateRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  & > input {
    width: 105px;
    padding-left: 10px;
  }
`;

DrilldownTableForm.DateInputsContainer = styled.div`
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  border: ${(props: Record<string, any>) =>
    props.isActive
      ? '2px solid ' + props.theme.colors.primary
      : '1px solid #c1c1c1'};
  width: 180px;
  padding: ${(props: Record<string, any>) =>
    props.isActive ? '0' : '0 1px 0 1px'};
`;

DrilldownTableForm.TextDash = styled.div`
  width: 5px;
  height: 1px;
  border-top: ${(props: Record<string, any>) =>
    `2px solid ${props.theme.colors.primaryText}`};
  flex-grow: 1;
  margin-right: 4px;
`;

DrilldownTableForm.MenuWrapper = styled.div`
  position: absolute;
  top: 35px;
  left: 35px;
  pointer-events: auto;
  z-index: 999;
`;

DrilldownTableForm.MenuItemLabel = styled.div`
  width: 200px;
  margin-left: 10px;
  padding: 10px 0;
`;

DrilldownTableForm.FormControlLabel = styled(FormControlLabel)`
  position: relative;
  top: -3px;
  margin: 0 0 3px 0 !important;
  padding: 0 24px 0 0 !important;
  width: 100%;
  line-height: 1em !important;
  height: 30px;
  justify-content: center;
  & .MuiTypography-root {
    font-size: 0.875rem !important;
  }

  & > label {
    width: 100%;
  }
`;

export default DrilldownTableForm;
