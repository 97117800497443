import { area, curveStepAfter } from 'd3-shape';
import { utcDay } from 'd3-time';
import * as React from 'react';

import type { ProductionPoint } from 'modules/production/models/production';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';

import { getEventClipPathId } from '../../utils';

interface EventClickableAreaProps {
  eventCapacityData: { date: Date; capacity: number }[];
  eventProductionData: ProductionPoint[];
  eventIndex: number;
  isAxisDragging: boolean;
  onVarianceDialogOpen: (index: number, eventId: string) => void;
  productionKey: string;
  title: string;
  varianceEvent: VarianceEvent;
  xScale: any;
  yScale: any;
}

const EventClickableArea = ({
  eventCapacityData,
  eventProductionData,
  eventIndex,
  isAxisDragging,
  onVarianceDialogOpen,
  productionKey,
  title,
  varianceEvent,
  xScale,
  yScale,
}: EventClickableAreaProps) => {
  const createVarianceArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.date || 0))
        .y1((d: any) => yScale(d.capacity))
        .y0(yScale(0)),
    [xScale, yScale],
  );
  const createClipArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(d.day || 0))
        .y1((d: any) => yScale(d[productionKey]))
        .y0(yScale.range()[1])
        .curve(curveStepAfter),
    [xScale, yScale, productionKey],
  );

  const lastEventDayLimit = utcDay.offset(varianceEvent.dayEnd, 1).getTime();
  const firstEventDayLimit = varianceEvent.dayStart.getTime();
  const filteredData = eventProductionData.filter(
    dataPoint =>
      dataPoint.day.getTime() <= lastEventDayLimit &&
      dataPoint.day.getTime() >= firstEventDayLimit,
  );
  const createProductionArea: (data: any) => any = area()
    .x((d: any) => xScale(d.day || 0))
    .y1((d: any) => yScale(d[productionKey]))
    .y0(yScale(0))
    .curve(curveStepAfter);

  const handleClick = React.useCallback(
    e => onVarianceDialogOpen(eventIndex, varianceEvent.id),
    [onVarianceDialogOpen, eventIndex, varianceEvent],
  );

  return (
    <>
      <path
        d={createVarianceArea(eventCapacityData)}
        onMouseUp={handleClick}
        fill="transparent"
        cursor={isAxisDragging ? 'grabbing' : 'pointer'}
        clipPath={`url(#clickableEventClip${varianceEvent.id + productionKey})`}
        className="variance-interactive interactive panInteraction"
      />
      <defs>
        <clipPath id={`clickableEventClip${varianceEvent.id + productionKey}`}>
          <path d={createClipArea(filteredData)} />
        </clipPath>
      </defs>
      <path
        d={createProductionArea(filteredData)}
        clipPath={`url(#${getEventClipPathId(title, varianceEvent.id)}`}
        onMouseUp={handleClick}
        fill="transparent"
        cursor={isAxisDragging ? 'grabbing' : 'pointer'}
        className="variance-interactive interactive panInteraction"
      />
    </>
  );
};

export default React.memo<EventClickableAreaProps>(EventClickableArea);
