import gql from 'graphql-tag';

export const namespace = 'SPOTFIRE';
export const FETCH_SPOTFIRE_SETTINGS = `${namespace}/FETCH_SETTINGS`;
export const FETCH_SPOTFIRE_DASHBOARDS = `${namespace}/FETCH_DASHBOARDS`;
export const FETCH_SPOTFIRE_SETTINGS_SUCCESS = `${FETCH_SPOTFIRE_SETTINGS}_SUCCESS`;
export const FETCH_SPOTFIRE_DASHBOARDS_SUCCESS = `${FETCH_SPOTFIRE_DASHBOARDS}_SUCCESS`;

const spotfireDataQuery = gql`
  query {
    listSpotfireData {
      url
      apiVersion
    }
  }
`;

const spotfireDashboardsQuery = gql`
  query {
    listAllSpotfireDashboards {
      id
      name
      description
      path
      page
      customization
      parameters
      reloadInstances
    }
  }
`;

export const fetchSpotfireSettings = () => ({
  type: FETCH_SPOTFIRE_SETTINGS,
  payload: {
    key: 'listSpotfireData',
    graphql: {
      query: spotfireDataQuery,
    },
  },
});

export const fetchSpotfireDashboards = () => ({
  type: FETCH_SPOTFIRE_DASHBOARDS,
  payload: {
    key: 'listAllSpotfireDashboards',
    graphql: {
      query: spotfireDashboardsQuery,
    },
  },
});
