import * as R from 'ramda';
import * as React from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import styled from 'styled-components';

import { SortIndicator } from 'components/Icons';
import Row from 'components/Row';
import usePrevious from 'hooks/usePrevious';

import {
  getListOfFilterValues,
  getSortedSearchedItems,
} from '../../utils/filter';

import 'react-virtualized/styles.css';

const CELL_HEIGHT = 18;
const UP_KEYCODE = 38;
const DOWN_KEYCODE = 40;

interface RowProps {
  className: string;
  bgColor?: string;
  index: number;
  onRowClick: (data: {
    event: MouseEvent;
    index: number;
    rowData: Record<string, any>;
  }) => void;
  rowData: Record<string, any>;
  style: { [prop: string]: string };
  hasColorEffects?: boolean;
}

type FilterTableProps = {
  addFilter: (filter: { filterName: string; filterValue: string }) => void;
  addSeveralFilters: (filters: {
    filterName: string;
    filterValues: string[];
  }) => void;
  clearFilter: (filter: string) => void;
  countedValues: { value: string; quantity: number }[];
  filters: string[];
  filterName: string;
  firstColDataKey: string;
  isActive: boolean;
  removeFilter: (filter: { filterName: string; filterValue: string }) => void;
  secondColDataKey: string;
  setFilter: (filter: { filterName: string; filterValue: string }) => void;
  size: { height: number };
  searchWord: string;
  title: string;
};

const FilterTable = ({
  addFilter,
  addSeveralFilters,
  countedValues,
  clearFilter,
  filters,
  filterName,
  firstColDataKey,
  isActive,
  removeFilter,
  secondColDataKey,
  setFilter,
  size,
  searchWord,
  title,
}: FilterTableProps) => {
  const prevCountedValues = usePrevious(countedValues);

  const [sortBy, setSortBy] = React.useState('value');
  const [sortDirection, setSortDirection] = React.useState('ASC');

  const formattedItems = React.useMemo(
    () =>
      getSortedSearchedItems({
        countedValues,
        searchWord,
        secondColDataKey,
        sortBy,
        sortDirection,
      }),
    [countedValues, searchWord, secondColDataKey, sortBy, sortDirection],
  );
  const hasSelectedRows = filters.length > 0;
  const highlightedIndexes = hasSelectedRows
    ? filters.map(filterValue =>
        formattedItems.findIndex(item => item[firstColDataKey] === filterValue),
      )
    : [];
  const [lastHighlighted, setLastHighlighted] = React.useState<number | null>(
    null,
  );

  const onClick = ({ event, index, rowData }) => {
    const { shiftKey, ctrlKey, metaKey } = event;
    const filterValue = rowData[firstColDataKey];
    const isHighlighted = filters.includes(filterValue);
    if (ctrlKey || metaKey) {
      if (isHighlighted) {
        removeFilter({ filterName, filterValue });
        return;
      }
      addFilter({ filterName, filterValue });
      setLastHighlighted(index);
    } else if (!ctrlKey && !metaKey && !shiftKey) {
      setFilter({ filterName, filterValue });
      setLastHighlighted(index);
    } else if (shiftKey) {
      if (highlightedIndexes.length > 0) {
        const itemsToSelect = getListOfFilterValues({
          lastHighlighted,
          index,
          highlightedIndexes,
          formattedItems,
          firstColDataKey,
        });
        addSeveralFilters({ filterName, filterValues: itemsToSelect });
        setLastHighlighted(index);
      } else if (highlightedIndexes.length === 0) {
        addFilter({ filterName, filterValue });
        setLastHighlighted(index);
      }
    }
  };

  const sortTable = ({
    sortBy: newSortBy,
    sortDirection: newSortDirection,
    event,
  }) => {
    event.stopPropagation();

    if (sortBy === 'value' && newSortBy === 'quantity') {
      setSortBy(newSortBy);
      setSortDirection('DESC');
    } else {
      setSortBy(newSortBy);
      setSortDirection(newSortDirection);
    }
  };

  const WellsHeader = ({
    dataKey,
    sortBy,
    sortDirection,
  }: {
    dataKey: string;
    sortBy: string;
    sortDirection: string;
  }) => {
    return (
      <FilterTable.WellsHeaderContainer>
        Wells
        {sortBy === dataKey && <SortIndicator sortDirection={sortDirection} />}
      </FilterTable.WellsHeaderContainer>
    );
  };

  const rowRenderer = (props: RowProps) => {
    const { index, className } = props;
    const isHighlighted = highlightedIndexes.includes(index);
    const newClassName = isHighlighted
      ? className + ' row--highlighted'
      : className;

    return (
      <Row
        {...props}
        className={newClassName}
        firstColDataKey={firstColDataKey}
        secondColDataKey={secondColDataKey}
        isHighlighted={isHighlighted}
      />
    );
  };

  const handleKeyPress = React.useCallback(
    (e: KeyboardEvent) => {
      if (
        !isActive ||
        R.isEmpty(formattedItems) ||
        R.isEmpty(highlightedIndexes)
      )
        return;
      const { target } = e;

      if (target instanceof HTMLElement) {
        const { tagName } = target;
        if (
          tagName === 'INPUT' ||
          tagName === 'TEXTAREA' ||
          tagName === 'SELECT'
        )
          return;
        const { keyCode } = e;
        if (keyCode === UP_KEYCODE) {
          e.preventDefault();
          const newHighlightedIndex = lastHighlighted
            ? Math.max(lastHighlighted - 1, 0)
            : Math.max(highlightedIndexes[0] - 1, 0);
          const filterValue = formattedItems[newHighlightedIndex].value;
          setFilter({ filterName, filterValue });
          setLastHighlighted(newHighlightedIndex);
        } else if (keyCode === DOWN_KEYCODE) {
          e.preventDefault();
          const lastItemIndex = formattedItems.length - 1;
          const lastHighlightedIndexByOrder = highlightedIndexes.length - 1;
          const newHighlightedIndex = lastHighlighted
            ? Math.min(lastHighlighted + 1, lastItemIndex)
            : Math.min(
                highlightedIndexes[lastHighlightedIndexByOrder] + 1,
                lastItemIndex,
              );
          const filterValue = formattedItems[newHighlightedIndex].value;
          setFilter({ filterName, filterValue });
          setLastHighlighted(newHighlightedIndex);
        }
      }
    },
    [
      filterName,
      highlightedIndexes,
      isActive,
      lastHighlighted,
      setFilter,
      formattedItems,
    ],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [
    isActive,
    formattedItems,
    highlightedIndexes,
    lastHighlighted,
    handleKeyPress,
  ]);

  React.useEffect(() => {
    if (
      prevCountedValues &&
      JSON.stringify(prevCountedValues) !== JSON.stringify(countedValues)
    ) {
      setLastHighlighted(null);
    }
  }, [countedValues, prevCountedValues]);

  return (
    <FilterTable.Container
      id={filterName}
      className="filter-table"
      isActive={isActive}
      size={size}
      onClick={e => {
        if (e.target.className.includes('ReactVirtualized__Table__Grid'))
          clearFilter(filterName);
      }}
    >
      {filters.length > 0 && (
        <FilterTable.ClearButtonWrapper>
          <FilterTable.ButtonOffset>{title}</FilterTable.ButtonOffset>
          <FilterTable.ClearButton
            title={title}
            onClick={() => clearFilter(filterName)}
            height={CELL_HEIGHT}
          >
            Clear Selection
          </FilterTable.ClearButton>
        </FilterTable.ClearButtonWrapper>
      )}
      <AutoSizer>
        {({ height, width }) => (
          <FilterTable.StyledTable
            rowStyle={{ alignItems: 'stretch' }}
            rowHeight={CELL_HEIGHT}
            headerHeight={CELL_HEIGHT}
            rowCount={formattedItems.length}
            rowGetter={({ index }) => formattedItems[index]}
            height={height - 2}
            width={width - 2}
            sort={sortTable}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onRowClick={({ event, index, rowData }) =>
              onClick({ event, index, rowData })
            }
            rowRenderer={rowRenderer}
            scrollToIndex={highlightedIndexes[0] || lastHighlighted || 0}
          >
            <Column
              label={title}
              dataKey={firstColDataKey}
              width={140}
              flexGrow={1}
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <Column
              width={50}
              label="Wells"
              dataKey={secondColDataKey}
              flexGrow={0}
              flexShrink={0}
              style={{ display: 'flex', alignItems: 'center' }}
              headerRenderer={WellsHeader}
            />
          </FilterTable.StyledTable>
        )}
      </AutoSizer>
    </FilterTable.Container>
  );
};

FilterTable.Container = styled.div`
  position: relative;
  height: ${(props: Record<string, any>) =>
    `calc(${props.size.height}% - 5px)`};
  margin-bottom: 5px;
  border: 1px solid #c1c1c1;
  box-shadow: ${(props: Record<string, any>) =>
    props.isActive ? `0 0 1px 1px ${props.theme.colors.primaryText}` : 'none'};
  flex-grow: 0;
  flex-shrink: 0;
`;

FilterTable.ClearButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 12px;
  padding-bottom: 2px;
  height: ${CELL_HEIGHT}px;
`;

FilterTable.ButtonOffset = styled.div`
  color: transparent;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
`;

FilterTable.ClearButton = styled.button`
  font-size: 12px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  color: ${(props: Record<string, any>) => props.theme.colors.criticalText};
  line-height: 1.3em;
`;

FilterTable.StyledTable = styled(Table)`
  user-select: none;

  & * {
    outline: none;
  }

  & .ReactVirtualized__Table__headerRow {
    background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
    border-bottom: 1px solid #c1c1c1;
    font-size: 12px;
    padding-right: 8px;
  }

  & .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;

    &:nth-child(2) {
      justify-content: flex-end;
      border-left: 1px solid #c1c1c1;
    }
  }

  & .ReactVirtualized__Table__row {
    border-bottom: 1px solid #c1c1c1;
  }

  & .ReactVirtualized__Table__rowColumn[aria-colindex='2'] {
    display: flex;
    justify-content: flex-end;
    border-left: 1px solid #c1c1c1;
  }

  & .ReactVirtualized__Table__sortableHeaderIcon {
    flex: 0 0 12px;
    min-height: 15px;
    min-width: 15px;
  }

  & span {
    text-transform: none;
    overflow: visible;
  }

  & .row--highlighted {
    background-color: #e7e7e7;
  }
`;

FilterTable.WellsHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 30px 7px;
  text-transform: capitalize;
  line-height: ${CELL_HEIGHT}px;
`;

export default React.memo<FilterTableProps>(FilterTable);
