import { all, put, takeLatest } from 'redux-saga/effects';

import { fetchNotes } from 'modules/notes/NotesActions';
import { SET_CURRENT_WELL_ID } from 'modules/ui/UIActions';
import { Action } from 'store/models';

function* fetchNotesForCurrentWellSaga(
  action: Action,
): Generator<any, any, any> {
  if (
    window.location.pathname.includes('/dashboard') ||
    window.location.pathname.includes('/filter') ||
    window.location.pathname.includes('/share')
  ) {
    const currentWellId = action.payload;
    yield put(fetchNotes(currentWellId));
  }
}

function* notesSagas(): Generator<any, any, any> {
  yield all([takeLatest(SET_CURRENT_WELL_ID, fetchNotesForCurrentWellSaga)]);
}

export default notesSagas;
