import { max, sum, min } from 'd3-array';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';
import { stack, stackOrderNone, stackOffsetDiverging } from 'd3-shape';
import { utcDay } from 'd3-time';

import usePrevious from 'hooks/usePrevious';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import SeriesPill from 'modules/chart/components/SeriesPill';
import { createVarianceYScale } from 'modules/chart/utils';
import RegionOfInterest from 'modules/chart/components/RegionOfInterest';
import YAxis from 'modules/chart/components/YAxis';
import type { TooltipData, TrellisTooltipData } from 'modules/ui/models/ui';

import type { GroupChartPoint } from '../models/groupChart';
import SVGGroupVariance from './SVGGroupVariance';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';

const getExtremePoint = (date: string, formatedLayers: any): any => {
  const dayData = R.pathOr({}, [date], formatedLayers);
  if (dayData.lenght === 0) {
    return { min: 0, max: 0 };
  }
  const extremePoints = Object.keys(dayData).reduce(
    (acc, dataKey) => {
      const data = dayData[dataKey];
      const min = Math.min(data[0], data[1]);
      const max = Math.max(data[0], data[1]);
      if (min < acc.min) {
        acc = { ...acc, min };
      }
      if (max > acc.max) {
        acc = { ...acc, max };
      }
      return acc;
    },
    { min: 0, max: 0 },
  );
  return extremePoints;
};

interface GroupVarianceTrellisProps {
  changeMinDrilldownTableDate: (minDate: Date) => void;
  changeMaxDrilldownTableDate: (maxDate: Date) => void;
  clearDrilldownTable: () => void;
  displaysRegionOfInterest: boolean;
  drilldownTableParams: {
    maxDate: Date;
    minDate: Date;
    phase: string;
    grossNet: string;
    compareOption: string;
  };
  extremeDates: { min: Date; max: Date };
  finishDrag: () => void;
  format: string;
  groupChartData: GroupChartPoint[];
  height: number;
  isXAxisDragging: boolean;
  isYAxisDragging: boolean;
  leftOffset: number;
  onPillClick: () => void;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  onStartYAxisDragging: () => void;
  onStopDragging: () => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  startDrag: () => void;
  trellisTitle: string;
  pillText: string;
  position: number;
  regionOfInterest: boolean;
  regionOfInterestMode: boolean;
  today: Date;
  tooltipData: TooltipData | null;
  width: number;
  varianceColors: { [varianceOptionId: string]: string };
  xScale: any;
  isLast: boolean;
}

const GroupVarianceTrellis = ({
  changeMaxDrilldownTableDate,
  changeMinDrilldownTableDate,
  clearDrilldownTable,
  displaysRegionOfInterest,
  drilldownTableParams,
  extremeDates,
  finishDrag,
  format,
  groupChartData,
  height,
  isXAxisDragging,
  isYAxisDragging,
  onPillClick,
  onStartYAxisDragging,
  onStopDragging,
  onXAxisScaling,
  leftOffset,
  onSetTooltipData,
  pillText,
  position,
  regionOfInterest,
  startDrag,
  today,
  tooltipData,
  trellisTitle,
  varianceColors,
  width,
  xScale,
  isLast,
}: GroupVarianceTrellisProps) => {
  const dataBeforeToday = React.useMemo(
    () =>
      groupChartData.filter(
        datum =>
          datum.day <= today &&
          datum.day >= extremeDates.min &&
          datum.day <= extremeDates.max,
      ),
    [today, groupChartData, extremeDates.min, extremeDates.max],
  );
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const totalVariances = React.useMemo(
    () =>
      dataBeforeToday.map(point => {
        const { variance } = point;
        const values: number[] = Object.values(variance);
        const negativeValues = values.filter(val => val < 0);
        const positiveValues = values.filter(val => val > 0);
        const negativeSum = sum(negativeValues);
        const positiveSum = sum(positiveValues);

        return { negativeSum, positiveSum };
      }),
    [dataBeforeToday],
  );
  totalVariances.pop();
  const tempMaxDataPoint = React.useMemo(() => {
    const maxData = R.isEmpty(totalVariances)
      ? 0
      : (max(totalVariances, t => t.positiveSum) as number);
    return maxData < 1 ? 1 : maxData;
  }, [totalVariances]);
  const tempMinDataPoint = React.useMemo(() => {
    const minData = R.isEmpty(totalVariances)
      ? 0
      : (min(totalVariances, t => t.negativeSum) as number);
    return minData > -1 ? -1 : minData;
  }, [totalVariances]);

  const padding = Math.max(Math.abs(tempMinDataPoint), tempMaxDataPoint) * 0.1;
  const minDataPoint = tempMinDataPoint - padding;
  const maxDataPoint = tempMaxDataPoint + padding;

  const [displayMaxDataPoint, setDisplayMaxDataPoint] =
    React.useState(maxDataPoint);
  const [displayMinDataPoint, setDisplayMinDataPoint] =
    React.useState(minDataPoint);
  const varianceData = React.useMemo(
    () =>
      dataBeforeToday.map(data => {
        const keys = Object.keys(data.variance);
        const minDataToDisplay =
          (displayMaxDataPoint + Math.abs(displayMinDataPoint)) * 0.01;
        const keysToRemove = [] as string[];
        keys.forEach(key => {
          if (Math.abs(data.variance[key]) < minDataToDisplay) {
            keysToRemove.push(key);
          }
        });
        const result = R.omit(keysToRemove, data.variance);
        return R.assoc('variance', result, data);
      }),
    [dataBeforeToday, displayMaxDataPoint, displayMinDataPoint],
  );

  const colorsForRender = React.useMemo(() => {
    const optionsIds = new Set();
    varianceData.forEach(data => {
      Object.keys(data.variance).forEach(key => {
        optionsIds.add(key);
      });
    });
    return R.pick([...optionsIds], varianceColors);
  }, [varianceData, varianceColors]);

  const yScale = React.useMemo(
    () =>
      createVarianceYScale(height, displayMinDataPoint, displayMaxDataPoint),
    [height, displayMaxDataPoint, displayMinDataPoint],
  );
  const [isAdjusted, setIsAdjusted] = React.useState(false);
  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setDisplayMaxDataPoint(maxDataPoint);
    setDisplayMinDataPoint(minDataPoint);
  }, [
    setIsAdjusted,
    setDisplayMaxDataPoint,
    setDisplayMinDataPoint,
    maxDataPoint,
    minDataPoint,
  ]);

  const prevMaxDataPoint = usePrevious(maxDataPoint);
  const prevMinDataPoint = usePrevious(minDataPoint);

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );
  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  React.useEffect(() => {
    if (
      !R.isNil(prevMaxDataPoint) &&
      !isAdjusted &&
      (prevMaxDataPoint !== maxDataPoint ||
        prevMinDataPoint !== minDataPoint) &&
      (maxDataPoint !== displayMaxDataPoint ||
        minDataPoint !== displayMinDataPoint)
    ) {
      setDisplayMaxDataPoint(maxDataPoint);
      setDisplayMinDataPoint(minDataPoint);
    }
  }, [
    prevMaxDataPoint,
    maxDataPoint,
    minDataPoint,
    displayMaxDataPoint,
    displayMinDataPoint,
    prevMinDataPoint,
    padding,
    isAdjusted,
  ]);

  const varianceCategoriesWithColors = React.useMemo(
    () =>
      Object.keys(varianceColors).filter(
        varianceCatecoryId => varianceColors[varianceCatecoryId],
      ),
    [varianceColors],
  );

  const varianceDataForLayers = React.useMemo(
    () =>
      varianceData.map(dataItem => ({
        ...dataItem.variance,
        day: dataItem.day,
      })),
    [varianceData],
  );

  const dataStack: (data: any) => any = stack()
    .keys([...varianceCategoriesWithColors, 'allocIssue', 'null'])
    .value((d, key) => d[key] || 0)
    .offset(stackOffsetDiverging)
    .order(stackOrderNone);

  const tooltipDay = R.pathOr(null, ['trellisTooltipData', 'day'], tooltipData);

  const layers = React.useMemo(
    () => dataStack(varianceDataForLayers),
    [varianceDataForLayers],
  );
  const formatedLayers = React.useMemo(
    () =>
      layers.reduce((acc, layer) => {
        layer.forEach(dataPoin => {
          if (dataPoin[0] === 0 && dataPoin[1] === 0) {
            return;
          }
          const dataKey = utcDay.floor(dataPoin.data.day).toString();
          acc[dataKey] = { ...acc[dataKey], [layer.key]: dataPoin };
        });
        return acc;
      }, {}),
    [layers],
  );

  const extremePoints = React.useMemo(() => {
    return tooltipDay ? getExtremePoint(tooltipDay, formatedLayers) : null;
  }, [tooltipDay, formatedLayers]);

  return (
    <>
      <GroupVarianceTrellis.Container height={height} isLast={isLast}>
        <GroupVarianceTrellis.SVGWrapper className="trellis-chart-wrapper">
          <SVGGroupVariance
            height={height}
            isAxisDragging={isXAxisDragging || isYAxisDragging}
            layers={layers}
            formatedLayers={formatedLayers}
            onXAxisScaling={onXAxisScaling}
            onSetTooltipData={onSetTooltipData}
            trellisTitle={trellisTitle}
            today={today}
            tooltipData={tooltipData}
            varianceColors={colorsForRender}
            xScale={xScale}
            yScale={yScale}
            yAxisLinePos={yAxisLinePos}
          />
          {displaysRegionOfInterest &&
            regionOfInterest &&
            drilldownTableParams && (
              <RegionOfInterest
                changeMaxDrilldownTableDate={changeMaxDrilldownTableDate}
                changeMinDrilldownTableDate={changeMinDrilldownTableDate}
                clearDrilldownTable={clearDrilldownTable}
                leftOffset={leftOffset}
                maxDate={drilldownTableParams.maxDate}
                minDate={drilldownTableParams.minDate}
                xScale={xScale}
                startDrag={startDrag}
                finishDrag={finishDrag}
                height={height}
                width={width}
                position={height * position}
              />
            )}
        </GroupVarianceTrellis.SVGWrapper>
        <GroupVarianceTrellis.YAxisContainer>
          <YAxis
            onStartYAxisDragging={onStartYAxisDragging}
            onStopDragging={onStopDragging}
            isXAxisDragging={isXAxisDragging}
            isDragging={isYAxisDragging}
            format={format}
            height={height}
            hideLine={hideLine}
            maxDataPoint={displayMaxDataPoint}
            minDataPoint={displayMinDataPoint}
            setDisplayMaxDataPoint={setDisplayMaxDataPoint}
            setDisplayMinDataPoint={setDisplayMinDataPoint}
            resetMax={resetMax}
            isAdjusted={isAdjusted}
            setIsAdjusted={setIsAdjusted}
            showLine={showLine}
            varianceTrellis
            yScale={yScale}
          />
        </GroupVarianceTrellis.YAxisContainer>
        <SeriesPill onPillClick={onPillClick} text={pillText} />
        {tooltipData &&
          (tooltipData.trellisTooltipData ||
            tooltipData.ribbonTooltipData ||
            tooltipData.dataSeriesTooltipData) &&
          tooltipData.trellisTooltipData?.trellis !== trellisTitle && (
            <SecondaryInformationTooltip
              containerHeight={height}
              leftOffset={leftOffset}
              tooltipData={tooltipData}
              trellisTitle={trellisTitle}
              yScale={yScale}
              groupVarianceExtremePoints={extremePoints}
            />
          )}
      </GroupVarianceTrellis.Container>
    </>
  );
};

GroupVarianceTrellis.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 73;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

GroupVarianceTrellis.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

GroupVarianceTrellis.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

export default React.memo<GroupVarianceTrellisProps>(GroupVarianceTrellis);
