import Tooltip from '@material-ui/core/Tooltip';
import { utcDay, utcYear } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getMinDate } from 'helpers';
import NoSelectedItemsMessage from 'components/NoSelectedItemsMessage/NoSelectedItemsMessage';
import { getResolvedStatus } from 'modules/allocIssueStatus/AllocIssueStatusReducer';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import {
  createLocalAllocIssue,
  initCreateAllocIssue,
  deleteLocalAllocIssue,
  updateLocalAllocIssue,
} from 'modules/allocIssue/AllocIssueActions';
import { getFeatures, getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { getPermissions } from 'modules/auth/AuthReducer';
import { getWellAllocIssues } from 'modules/allocIssue/AllocIssueReducer';
import {
  createCapacityLocally,
  initUpdateCapacityDayLocally,
  updateCapacityRateLocally,
  updateCapacityBFactorIndirectlyLocally,
  updateCapacityEventDeclineIndirectlyLocally,
  setCapacity,
} from 'modules/capacityChangeEvent/CapacityChangeEventActions';
import {
  getWellCapacityDataByPhasePerEvent,
  getFullWellCapacityDataByPhasePerEvent,
  getWellCapacityEventsSorted,
  getHasChanges,
  getIndicatorsCapacity,
  getWellCapacityDataByPhasePerEventNri,
  getFullWellCapacityDataByPhasePerEventNri,
  getIndicatorsCapacityNri,
} from 'modules/capacityChangeEvent/CapacityChangeEventReducer';
import type { CapacityChangeEvent } from 'modules/capacityChangeEvent/models/capacityChangeEvent';
import {
  getCapacityCategories,
  getVarianceCategoriesColors,
  getVarianceCategories,
} from 'modules/category/CategoryReducer';
import {
  CHARTS_OPTIONS,
  CHART_TITLE_HEIGHT,
  RIBBON_HEIGHT,
  X_AXIS_HEIGHT,
  Y_AXIS_WIDTH,
  VARIANCE_TRELLIS,
  NO_SERIES_MESSAGE,
} from 'modules/chart/models/chart';
import InformationTooltip from 'modules/chart/components/InformationTooltip';
import OverlayRegionOfInterest from 'modules/chart/components/OverlayRegionOfInterest';
import OverlayZoomIn from 'modules/chart/components/OverlayZoomIn';
import XAxis from 'modules/chart/components/XAxis';
import { isBetweenX, isInside, getPillText } from 'modules/chart/utils';
import {
  getAllDataSeriesOptions,
  getDataSeriesGroupsToDisplay,
  getCoreSeriesOptionsToDisplay,
} from 'modules/chartOptions/ChartOptionsReducer';
import { ChartOption, GroupToDisplay } from 'modules/chartOptions/models';

import {
  initClearDrilldownTable,
  setMaxDrilldownTableDate,
  setMinDrilldownTableDate,
  setDrilldownTableParams,
} from 'modules/drilldownTable/DrilldownTableActions';
import {
  getForecast,
  getForecastFetchingStatus,
  getForecastNri,
  getForecastStatus,
} from 'modules/externalForecast/ExternalForecastReducer';
import { getDrilldownTableParams } from 'modules/drilldownTable/DrilldownTableReducer';
import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import { getWellNotesDates } from 'modules/notes/NotesReducer';
import {
  BOE,
  GOR,
  GLR,
  WATERCUT,
  TOTAL_LIQUID,
} from 'modules/phase/models/phase';
import { changeExtremeDates } from 'modules/production/ProductionActions';
import {
  getExtremeDates,
  getFiltredProduction,
  getFiltredProductionNri,
  getIsProductionAvailible,
  getFullProduction,
  getFullProductionNri,
} from 'modules/production/ProductionReducer';
import { countBoeValue } from 'modules/production/utils';
import {
  getEventsOfSelectedRibbons,
  getRibbonEvents,
  getRibbonFetchingStatus,
  getRibbonOptions,
  getRibbons,
} from 'modules/ribbon/RibbonReducer';
import useLocalRibbonEvent from 'modules/ribbon/hooks/useLocalRibbonEvent';
import SeriesChart from 'modules/series/containers/SeriesChart';
import {
  getSeriesByWell,
  getSeriesMapping,
} from 'modules/series/SeriesReducer';
import TopControls from 'modules/topControls/containers/TopControls';
import { DRILLDOWN_PANEL, DataFetchingStatus } from 'modules/ui/models/ui';
import {
  activateDrilldownPanel,
  activateNotesPanel,
  disableNotesMode,
  disableRegionOfInterestMode,
  disableZoomInMode,
  highlightAllocIssue,
  highlightAllocIssueDivider,
  highlightAllocIssueDividerOff,
  highlightAllocIssueOff,
  highlightCapacity,
  highlightCapacityDivider,
  highlightCapacityDividerOff,
  highlightCapacityOff,
  highlightVariance,
  highlightVarianceDivider,
  highlightVarianceDividerOff,
  highlightRibbonEventDivider,
  highlightRibbonEventDividerOff,
  highlightVarianceOff,
  setNotesProductionDay,
  setTooltipData,
} from 'modules/ui/UIActions';
import {
  getActivePanel,
  getCurrentWellId,
  getDataFetchingStatus,
  getHighlightedAllocIssue,
  getHighlightedAllocIssueDivider,
  getHighlightedCapacity,
  getHighlightedCapacityDivider,
  getHighlightedRibbonEvent,
  getHighlightedVariance,
  getHighlightedVarianceDivider,
  getNotesMode,
  getNotesProductionDay,
  getRegionOfInterestMode,
  getSelectedRibbons,
  getTooltipData,
  getZoomInMode,
} from 'modules/ui/UIReducer';
import useAdditionMode from 'modules/ui/hooks/useAdditionMode';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import useRightPanelDialogs from 'modules/ui/hooks/useRightPanelDialogs';
import {
  createVarianceEventLocally,
  createRemoveVarianceEvent,
  updateVarianceEventDatesLocally,
  deleteVarianceEventLocally,
} from 'modules/varianceEvent/VarianceEventActions';
import { getWellVarianceEventsSortedByDate } from 'modules/varianceEvent/VarianceEventReducer';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';
import { getWellInfo } from 'modules/well/WellReducer';
import LoaderIndicatorWithDelay from 'modules/chart/components/LoaderIndicatorWithDelay';

import useMouseDownOutside from 'hooks/useMouseDownOutside';
import useThrottledCallback from 'hooks/useThrottledCallback';

import useChartUIData from 'modules/chart/hooks/useChartUIData';
import useChartScaling from 'modules/chart/hooks/useChartScaling';
import useChartDragging from 'modules/chart/hooks/useChartDragging';
import useChartRect from 'modules/chart/hooks/useChartRect';

import DivIndicatorChart from '../components/DivIndicatorChart';
import DivPhaseChart from '../components/DivPhaseChart';
import Highlightables from '../components/Highlightables';
import OverlayAddAllocIssue from '../components/OverlayAddAllocIssue';
import OverlayAddCapacity from '../components/OverlayAddCapacity';
import OverlayAddVariance from '../components/OverlayAddVariance';
import OverlayNotesProdDay from '../components/OverlayNotesProdDay';
import NotesProductionLine from '../components/NotesProductionLine';
import DivVarianceChart from '../components/DivVarianceChart';
import OverlayAddRibbonEvent from '../components/ribbon/OverlayAddRibbonEvent';
import RibbonChart from '../components/ribbon';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { usePreventContext } from 'context/PreventContext';
import { CapacityVarianceProvider } from '../context/capacityVariance';
import { INDICATORS_LIST } from 'modules/capacityChangeEvent/utils/countIndicatorsCapacityChartDataByIndicator';

interface IssueAreas {
  issueId: string;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

const E_KEY_CODE = 69;

const getCapacitySegmentsAreas = (
  capacityEvents: CapacityChangeEvent[],
  xScale: any,
  chartPos: { x1: number; x2: number },
) =>
  capacityEvents.map((capacityEvent, i) => {
    if (i === 0) {
      return {
        x1:
          Math.max(xScale(capacityEvent.dayInit), xScale.range()[0]) +
          chartPos.x1,
        x2: xScale.range()[1] + chartPos.x1,
      };
    }
    const prevEvent = capacityEvents[i - 1];
    return {
      x1:
        Math.max(xScale(capacityEvent.dayInit), xScale.range()[0]) +
        chartPos.x1,
      x2: Math.min(xScale(prevEvent.dayInit), xScale.range()[1]) + chartPos.x1,
    };
  });

const getVarianceEventsAreas = (
  varianceEvents: VarianceEvent[],
  xScale: any,
  chartPos: { x1: number; x2: number },
) =>
  varianceEvents.map(event => ({
    x1: Math.max(
      xScale(event.dayStart) + chartPos.x1,
      xScale.range()[0] + chartPos.x1,
    ),
    x2: Math.min(
      xScale(utcDay.offset(event.dayEnd, 1)) + chartPos.x1,
      xScale.range()[1] + chartPos.x1,
    ),
  }));

const getAllocIssuesAreas = (
  allocIssues: AllocIssue[],
  xScale: any,
  trellisHeight: number,
  chartPos: { x1: number; x2: number; y1: number; y2: number },
  groupsToDisplay: GroupToDisplay,
) => {
  const areas = allocIssues.reduce((acc, issue) => {
    const issueAreas = groupsToDisplay.reduce((accumulator, group, i) => {
      accumulator.push({
        issueId: issue.id,
        x1: Math.max(
          xScale(issue.dateStart) + chartPos.x1,
          xScale.range()[0] + chartPos.x1,
        ),
        x2: Math.min(
          xScale(utcDay.offset(issue.dateEnd, 1)) + chartPos.x1,
          xScale.range()[1] + chartPos.x1,
        ),
        y1: chartPos.y1 + i * trellisHeight,
        y2: chartPos.y1 + (1 + i) * trellisHeight,
      });
      return accumulator;
    }, [] as any[]);

    return acc.concat(issueAreas);
  }, [] as IssueAreas[]);

  return areas;
};

const WellProductionChart = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const {
    notesDialog,
    ribbonDialog,
    capacityDialog,
    varianceDialog,
    allocIssueDialog,
  } = useRightPanelDialogs();

  // data from Redux Store
  const appConfig = useSelector(getAppConfig);
  const currentWellId = useSelector(getCurrentWellId);
  const activePanel = useSelector(getActivePanel);
  const allocIssues = useSelector(store =>
    getWellAllocIssues(store, { wellId: currentWellId }),
  );
  const drilldownTableParams = useSelector(getDrilldownTableParams);
  const capacityCategories = useSelector(getCapacityCategories);
  const eventColors = useSelector(getVarianceCategoriesColors);
  const varianceCategories = useSelector(getVarianceCategories);
  const extremeDates = useSelector(getExtremeDates);
  const highlightedAllocIssue = useSelector(getHighlightedAllocIssue);
  const highlightedAllocIssueDivider = useSelector(
    getHighlightedAllocIssueDivider,
  );
  const eventFetchingStatus: DataFetchingStatus = useSelector(
    getDataFetchingStatus,
  );
  const forecastLoadingStatus = useSelector(getForecastFetchingStatus);
  const ribbonEventFetchingStatus = useSelector(getRibbonFetchingStatus);
  const highlightedCapacity = useSelector(getHighlightedCapacity);
  const highlightedCapacityDivider = useSelector(getHighlightedCapacityDivider);
  const highlightedVariance = useSelector(getHighlightedVariance);
  const highlightedVarianceDivider = useSelector(getHighlightedVarianceDivider);
  const notesProductionDay = useSelector(getNotesProductionDay);
  const notesDates = useSelector(store =>
    getWellNotesDates(store, currentWellId),
  );
  const seriesOfCurrentWell = useSelector(state =>
    getSeriesByWell(state, { wellId: currentWellId }),
  );
  const seriesMapping = useSelector(getSeriesMapping);
  const notesMode = useSelector(getNotesMode);
  const zoomInMode = useSelector(getZoomInMode);
  const regionOfInterestMode = useSelector(getRegionOfInterestMode);
  const dateRef = React.useRef();

  const [isDragging, setDragState] = React.useState(false);
  const startDrag = React.useCallback(() => setDragState(true), []);
  const finishDrag = React.useCallback(() => setDragState(false), []);

  const { additionMode, additionModeOff } = useAdditionMode();
  const { deleleLatestLocalRibbonEvent } = useLocalRibbonEvent();
  const { height, leftOffset } = useChartRect();
  const { chartWidth, chartHeight, chartPos } = useChartUIData();
  const { xScale, onXAxisScaling } = useChartScaling();
  const {
    isXAxisDragging,
    isYAxisDragging,
    handleDragStop,
    handleDragStartX,
    handleDragStartY,
    handleDragX,
    chartWasDragging,
  } = useChartDragging({ skipDragging: isDragging });

  const previousEndDate = dateRef.current;
  const endDate: Date | undefined = React.useMemo(() => {
    const newMaxDate = utcDay.offset(appConfig.today, 180);
    if (
      previousEndDate &&
      // @ts-expect-error
      utcDay.count(extremeDates.max, previousEndDate) > 0
    ) {
      return previousEndDate;
    } else if (previousEndDate) {
      return utcDay.offset(extremeDates.max, 180);
    }
    return newMaxDate;
  }, [appConfig.today, extremeDates.max, previousEndDate]);
  // @ts-expect-error
  dateRef.current = endDate;
  const capacityData = useSelector(state =>
    drilldownTableParams.grossNet === 'Net'
      ? getWellCapacityDataByPhasePerEventNri(state, {
          wellId: currentWellId,
          lastDate: endDate,
        })
      : getWellCapacityDataByPhasePerEvent(state, {
          wellId: currentWellId,
          lastDate: endDate,
        }),
  );
  const fullCapacityData = useSelector(state =>
    drilldownTableParams.grossNet === 'Net'
      ? getFullWellCapacityDataByPhasePerEventNri(state, {
          wellId: currentWellId,
        })
      : getFullWellCapacityDataByPhasePerEvent(state, {
          wellId: currentWellId,
        }),
  );
  const capacityChangeEvents = useSelector(state =>
    getWellCapacityEventsSorted(state, { wellId: currentWellId }),
  );
  const indicatorsCapacity = useSelector(state =>
    drilldownTableParams.grossNet === 'Net'
      ? getIndicatorsCapacityNri(state, {
          lastDate: endDate,
          wellId: currentWellId,
        })
      : getIndicatorsCapacity(state, {
          lastDate: endDate,
          wellId: currentWellId,
        }),
  );
  const production = useSelector(state =>
    drilldownTableParams.grossNet === 'Net'
      ? getFiltredProductionNri(state, { wellId: currentWellId })
      : getFiltredProduction(state),
  );
  const fullProduction = useSelector(state =>
    drilldownTableParams.grossNet === 'Net'
      ? getFullProductionNri(state, { wellId: currentWellId })
      : getFullProduction(state),
  );
  const forecastData = useSelector(state =>
    drilldownTableParams.grossNet === 'Net'
      ? getForecastNri(state, { wellId: currentWellId })
      : getForecast(state),
  );
  const hasProductionData = useSelector(getIsProductionAvailible);

  const hasEnoughData =
    hasProductionData &&
    eventFetchingStatus.isCapacityEventsFetched &&
    eventFetchingStatus.isVarianceEventsFetched &&
    forecastLoadingStatus &&
    ribbonEventFetchingStatus;

  const varianceEvents = useSelector(store =>
    getWellVarianceEventsSortedByDate(store, { wellId: currentWellId }),
  );
  const wellInfo = useSelector(store => getWellInfo(store, currentWellId));
  const title = wellInfo.LEASE;
  const nri =
    R.isNil(wellInfo.NRI) || wellInfo.NRI === '' ? 1 : parseFloat(wellInfo.NRI);
  const permissions = useSelector(getPermissions);
  const resolvedAllocIssueStatus = useSelector(getResolvedStatus);
  const issuesWithoutResolved = React.useMemo(
    () =>
      allocIssues.filter(
        issue =>
          issue.statusId !==
          R.pathOr(undefined, ['id'], resolvedAllocIssueStatus),
      ),
    [allocIssues, resolvedAllocIssueStatus],
  );
  const hasCapacityChanges = useSelector(state =>
    getHasChanges(state, currentWellId),
  );
  const tooltipData = useSelector(getTooltipData);
  const forecastStatus = useSelector(getForecastStatus);
  const devFeatures = useSelector(getFeatures);
  const selectedRibbons = useSelector(getSelectedRibbons);
  const ribbonOptions = useSelector(getRibbonOptions);
  const ribbonEvents = useSelector(getEventsOfSelectedRibbons);
  const ribbons = useSelector(getRibbons);
  const allRibbonEvents = useSelector(getRibbonEvents);
  const highlightedRibbonEvent = useSelector(getHighlightedRibbonEvent);
  const cavSeries: ChartOption[] = useSelector(getCoreSeriesOptionsToDisplay);
  const isDisplayingForecast = forecastStatus && appConfig.showExternalForecast;
  const selectedRibbonsData = React.useMemo(() => {
    const ribbonData = R.keys(selectedRibbons).reduce((acc, id) => {
      const ribbon = ribbons.find(ribbon => ribbon.id === id);
      if (ribbon) {
        acc.push(ribbon);
      }
      return acc;
    }, []);
    return ribbonData.sort((a, b) => a.order - b.order);
  }, [selectedRibbons, ribbons]);
  const groupToDisplay: GroupToDisplay = useSelector(
    getDataSeriesGroupsToDisplay,
  );
  const chartOptions = useSelector(getAllDataSeriesOptions);
  const prevent = usePreventContext();

  const isCavTrellis = cavSeries.length > 0;
  const isSeriesTrellis = groupToDisplay.length > 0;

  // calculate variance for each event
  const wellCapacity = React.useMemo(() => {
    const capList = {
      oil: R.compose(R.flatten(), R.pathOr([], ['Oil']))(fullCapacityData),
      gas: R.compose(R.flatten(), R.pathOr([], ['Gas']))(fullCapacityData),
      water: R.compose(R.flatten(), R.pathOr([], ['Water']))(fullCapacityData),
    };
    const capObj = {
      oil: capList.oil.reduce((acc, data) => {
        acc[data.date] = data;
        return acc;
      }, {}),
      gas: capList.gas.reduce((acc, data) => {
        acc[data.date] = data;
        return acc;
      }, {}),
      water: capList.water.reduce((acc, data) => {
        acc[data.date] = data;
        return acc;
      }, {}),
    };
    return capObj;
  }, [fullCapacityData]);
  const productionObj = React.useMemo(
    () =>
      fullProduction.reduce((acc, data) => {
        acc[data.day] = data;
        return acc;
      }, {}),
    [fullProduction],
  );
  const varianceEventSum = React.useMemo(() => {
    const varianceSum = varianceEvents.map((event, i) => {
      const interval = utcDay.every(1);
      const dateRange = interval?.range(
        event.dayStart,
        utcDay.offset(event.dayEnd, 1),
      );
      const sum = dateRange?.reduce(
        (acc, date) => {
          acc.oil +=
            R.pathOr(0, ['oil', date, 'capacity'], wellCapacity) -
            R.pathOr(0, [date, 'oil'], productionObj);
          acc.gas +=
            R.pathOr(0, ['gas', date, 'capacity'], wellCapacity) -
            R.pathOr(0, [date, 'gas'], productionObj);
          acc.water +=
            R.pathOr(0, ['water', date, 'capacity'], wellCapacity) -
            R.pathOr(0, [date, 'water'], productionObj);
          acc.boe = countBoeValue(acc.oil, acc.gas);
          return acc;
        },
        { oil: 0, gas: 0, water: 0, boe: 0 },
      );

      return sum;
    });
    return varianceSum;
  }, [productionObj, wellCapacity, varianceEvents]);

  //Derived data

  const isPossibleEditCapacity = permissions.isAllowedEditCapChanges;
  const isPossibleEditVariance = permissions.isAllowedEditVarEvents;
  const isPossibleEditAlloc = permissions.isAllowedEditAllocIssues;

  //Actions
  const regionOfInterestModeOff = React.useCallback(
    () => dispatch(disableRegionOfInterestMode()),
    [dispatch],
  );
  const zoomInModeOff = React.useCallback(
    () => dispatch(disableZoomInMode()),
    [dispatch],
  );
  const onCapacityHover = React.useCallback(
    index => dispatch(highlightCapacity(index)),
    [dispatch],
  );
  const onCapacityDividerHover = React.useCallback(
    index => dispatch(highlightCapacityDivider(index)),
    [dispatch],
  );
  const onEventHover = React.useCallback(
    index => {
      dispatch(highlightVariance(index));
    },
    [dispatch],
  );
  const onEventDividerHover = React.useCallback(
    index => dispatch(highlightVarianceDivider(index)),
    [dispatch],
  );
  const onAllocIssueHover = React.useCallback(
    id => dispatch(highlightAllocIssue(id)),
    [dispatch],
  );
  const onAllocIssueDividerHover = React.useCallback(
    id => dispatch(highlightAllocIssueDivider(id)),
    [dispatch],
  );
  const onHighlightAllocIssueOff = React.useCallback(
    () => dispatch(highlightAllocIssueOff()),
    [dispatch],
  );
  const onHighlightAllocIssueDividerOff = React.useCallback(
    () => dispatch(highlightAllocIssueDividerOff()),
    [dispatch],
  );
  const onHighlightCapacityOff = React.useCallback(
    () => dispatch(highlightCapacityOff()),
    [dispatch],
  );
  const onHighlightCapacityDividerOff = React.useCallback(
    () => dispatch(highlightCapacityDividerOff()),
    [dispatch],
  );
  const onHighlightVarianceOff = React.useCallback(
    () => dispatch(highlightVarianceOff()),
    [dispatch],
  );
  const onHighlightEventDividerOff = React.useCallback(
    () => dispatch(highlightVarianceDividerOff()),
    [dispatch],
  );
  const changeTwoExtremeDates = React.useCallback(
    dates => dispatch(changeExtremeDates(dates)),
    [dispatch],
  );
  const changeMinDrilldownTableDate = React.useCallback(
    date => {
      dispatch(setMinDrilldownTableDate(date));
    },
    [dispatch],
  );
  const changeMaxDrilldownTableDate = React.useCallback(
    date => {
      const maxAvailableDate =
        drilldownTableParams.compareOption === COMPARE_OPTION.extVsCap
          ? utcYear.offset(appConfig.today, 5)
          : utcDay.offset(appConfig.today, -1);
      dispatch(setMaxDrilldownTableDate(getMinDate(date, maxAvailableDate)));
    },
    [dispatch, appConfig.today, drilldownTableParams],
  );
  const changeDrilldownTableParams = React.useCallback(
    newParams => {
      dispatch(setDrilldownTableParams(newParams));
      dispatch(activateDrilldownPanel());
      dispatch(initClearDrilldownTable());
    },
    [dispatch],
  );
  const resetDrilldownTable = React.useCallback(() => {
    dispatch(initClearDrilldownTable());
  }, [dispatch]);
  const changeNotesDay = React.useCallback(
    date => {
      dispatch(setNotesProductionDay(date));
      dispatch(activateNotesPanel());
    },
    [dispatch],
  );
  const notesModeOff = React.useCallback(
    () => dispatch(disableNotesMode()),
    [dispatch],
  );
  const onCapacityDialogClose = React.useCallback(
    () => rightPanel.unsetDialogOfType('CapacityChangeEvent'),
    [rightPanel.unsetDialogOfType],
  );
  const onCapacityDialogOpen = React.useCallback(
    (index, id) => {
      prevent.dispatchEvent(() =>
        rightPanel.setDialog({
          type: 'CapacityChangeEvent',
          data: { index, id },
        }),
      );
    },
    [chartWasDragging, rightPanel.setDialog, prevent.dispatchEvent],
  );
  const onVarianceDialogOpen = React.useCallback(
    (index, id) => {
      if (chartWasDragging) return;
      prevent.dispatchEvent(() =>
        rightPanel.setDialog({
          type: 'VarianceChangeEvent',
          data: { index, id },
        }),
      );
    },
    [rightPanel.setDialog, chartWasDragging, prevent.dispatchEvent],
  );
  const onVarianceDialogClose = React.useCallback(
    () => rightPanel.unsetDialogOfType('VarianceChangeEvent'),
    [rightPanel.unsetDialogOfType],
  );
  const onAllocIssueDialogOpen = React.useCallback(
    ({ index, id }) => {
      if (chartWasDragging) return;
      prevent.dispatchEvent(() =>
        rightPanel.setDialog({
          type: 'AllocationIssue',
          data: { index, id },
        }),
      );
    },
    [rightPanel.setDialog, chartWasDragging, prevent.dispatchEvent],
  );

  //Ribbon Change Event handlers

  const onRibbonEventDialogOpen = React.useCallback(
    id => {
      if (chartWasDragging) return;
      prevent.dispatchEvent(() =>
        rightPanel.setDialog({ type: 'RibbonEvent', data: { id } }),
      );
    },
    [rightPanel.setDialog, prevent.dispatchEvent, chartWasDragging],
  );
  const onCloseRibbonEventDialog = React.useCallback(
    () => rightPanel.unsetDialogOfType('RibbonEvent'),
    [rightPanel.unsetDialogOfType],
  );

  const onRibbonDetailsPanelOpen = React.useCallback(
    index => {
      if (chartWasDragging) return;
      rightPanel.setDialog({ type: 'RibbonDetails', data: { index } });
    },
    [dispatch, onCloseRibbonEventDialog, chartWasDragging],
  );

  const onHighlightRibbonEventDividerOff = React.useCallback(
    () => dispatch(highlightRibbonEventDividerOff()),
    [dispatch],
  );
  const onRibbonEventDividerHover = React.useCallback(
    id => {
      if (isXAxisDragging || isYAxisDragging) {
        return;
      }
      dispatch(highlightRibbonEventDivider(id));
    },
    [dispatch, isXAxisDragging, isYAxisDragging],
  );

  const ribbonCount = React.useMemo(
    () => selectedRibbonsData.length,
    [selectedRibbonsData],
  );

  //Capacity Change Event handlers
  const onAddSegment = React.useCallback(
    date => dispatch(createCapacityLocally({ wellId: currentWellId, date })),
    [currentWellId, dispatch],
  );
  const onDayInitChange = React.useCallback(
    ({ capacityEventId, newDayInit }) => {
      dispatch(
        initUpdateCapacityDayLocally({
          wellId: currentWellId,
          capacityEventId,
          newDayInit,
        }),
      );
    },
    [currentWellId, dispatch],
  );
  const onDeclineInitChange = React.useCallback(
    ({ phase, capacityEventId, date, rate }) => {
      dispatch(
        updateCapacityEventDeclineIndirectlyLocally({
          wellId: currentWellId,
          phase,
          capacityEventId,
          date,
          rate,
        }),
      );
    },
    [currentWellId, dispatch],
  );

  const onRateInitChange = React.useCallback(
    ({ phase, newRate, capacityEventId }) => {
      dispatch(
        updateCapacityRateLocally({
          wellId: currentWellId,
          phase,
          capacityEventId,
          newRate,
        }),
      );
    },
    [currentWellId, dispatch],
  );

  const onBFactorDrag = React.useCallback(
    ({ phase, capacityEventId, date, rate }) =>
      dispatch(
        updateCapacityBFactorIndirectlyLocally({
          wellId: currentWellId,
          phase,
          capacityEventId,
          date,
          rate,
        }),
      ),
    [currentWellId, dispatch],
  );

  //Variance Event
  const onCreateVarianceEventFirstClick = React.useCallback(
    date =>
      dispatch(createVarianceEventLocally({ wellId: currentWellId, date })),
    [currentWellId, dispatch],
  );

  const onCreateVarianceEventSecondClick = React.useCallback(
    varianceEvent =>
      dispatch(createRemoveVarianceEvent(R.omit(['id'], varianceEvent))),
    [dispatch],
  );

  const onDeleteVarianceEventLocal = React.useCallback(
    id =>
      dispatch(
        deleteVarianceEventLocally({
          wellId: currentWellId,
          varianceEventId: id,
        }),
      ),
    [currentWellId, dispatch],
  );
  const onUpdateVarianceEvent = React.useCallback(
    ({ dates, varianceEventId }) => {
      dispatch(
        updateVarianceEventDatesLocally({
          wellId: currentWellId,
          varianceEventId,
          dates,
        }),
      );
    },
    [currentWellId, dispatch],
  );

  // Allocation Issue handlers
  const onAllocIssueCreate = React.useCallback(
    localAllocIssue => dispatch(initCreateAllocIssue(localAllocIssue)),
    [dispatch],
  );
  const onAllocIssueUpdate = React.useCallback(
    ({ updatedIssue, data }) => {
      dispatch(
        updateLocalAllocIssue({ updatedIssue, data, interactive: true }),
      );
    },
    [dispatch],
  );

  const onCreateLocalAllocIssue = React.useCallback(
    date => {
      dispatch(createLocalAllocIssue({ wellId: currentWellId, date }));
    },
    [currentWellId, dispatch],
  );

  const onDeleteLocalAllocIssue = React.useCallback(
    id => {
      dispatch(deleteLocalAllocIssue({ wellId: currentWellId, id }));
    },
    [currentWellId, dispatch],
  );

  const trellisesChartHeight = React.useMemo(
    () => chartHeight - ribbonCount * RIBBON_HEIGHT,
    [chartHeight, ribbonCount],
  );
  const trellisesArea = React.useMemo(() => {
    return {
      bottom: height - X_AXIS_HEIGHT,
      top: CHART_TITLE_HEIGHT,
    };
  }, [height]);

  const trellisHeight =
    trellisesChartHeight / (cavSeries.length + groupToDisplay.length);

  const drilldownPhase = drilldownTableParams.phase;

  const capacitySegmentsAreas = React.useMemo(
    () => getCapacitySegmentsAreas(capacityChangeEvents, xScale, chartPos),
    [capacityChangeEvents, xScale, chartPos],
  );
  const varianceEventsAreas = React.useMemo(
    () => getVarianceEventsAreas(varianceEvents, xScale, chartPos),
    [varianceEvents, xScale, chartPos],
  );
  const allocIssuesAreas = React.useMemo(
    () =>
      getAllocIssuesAreas(
        issuesWithoutResolved,
        xScale,
        trellisHeight,
        chartPos,
        groupToDisplay,
      ),
    [issuesWithoutResolved, xScale, trellisHeight, chartPos, groupToDisplay],
  );
  const handleChartHover = React.useCallback(
    e => {
      if (isXAxisDragging) return;
      if (e.target.classList.contains('series')) {
        onHighlightCapacityOff();
        onHighlightCapacityDividerOff();
        onHighlightVarianceOff();
        onHighlightEventDividerOff();

        return;
      }
      if (
        isDragging ||
        additionMode.isOn ||
        zoomInMode.isOn ||
        regionOfInterestMode.isOn ||
        !isCavTrellis
      )
        return;
      const { clientX, clientY } = e;
      const inTrellises =
        clientY <= trellisesArea.bottom && clientY >= trellisesArea.top;
      if (!isInside({ clientX, clientY }, chartPos)) return;
      if (
        capacitySegmentsAreas.every(area => !isBetweenX(clientX, area)) ||
        !inTrellises
      ) {
        onHighlightCapacityOff();
      } else if (inTrellises) {
        capacitySegmentsAreas.forEach((area, i) => {
          if (isBetweenX(clientX, area)) onCapacityHover(i);
        });
      }
      if (
        varianceEventsAreas.every(area => !isBetweenX(clientX, area)) ||
        !inTrellises
      ) {
        onHighlightVarianceOff();
      } else if (inTrellises) {
        varianceEventsAreas.forEach((area, i) => {
          if (isBetweenX(clientX, area)) onEventHover(i);
        });
      }
      if (appConfig.allocationIssues) {
        if (
          allocIssuesAreas.every(
            area => !isInside({ clientX, clientY }, area),
          ) ||
          !inTrellises
        ) {
          onHighlightAllocIssueOff();
        } else if (inTrellises) {
          allocIssuesAreas.forEach((area, i) => {
            if (isInside({ clientX, clientY }, area)) {
              onAllocIssueHover(area.issueId);
            }
          });
        }
      }
    },
    [
      additionMode,
      allocIssuesAreas,
      appConfig.allocationIssues,
      capacitySegmentsAreas,
      chartPos,
      isDragging,
      onCapacityHover,
      onEventHover,
      onHighlightAllocIssueOff,
      onHighlightCapacityDividerOff,
      onHighlightCapacityOff,
      onHighlightEventDividerOff,
      onHighlightVarianceOff,
      onAllocIssueHover,
      isCavTrellis,
      regionOfInterestMode.isOn,
      trellisesArea,
      varianceEventsAreas,
      zoomInMode,
      isXAxisDragging,
    ],
  );

  const overlayAddCapacityEl = React.useRef(null);
  const overlayAddVarianceEl = React.useRef(null);
  const overlayAddRibbonEl = React.useRef(null);
  const overlayAddAllocIssueEl = React.useRef(null);
  const overlayZoomInEl = React.useRef(null);
  const overlayRegionOfInterestEl = React.useRef(null);
  const overlayNotesProdDayEl = React.useRef(null);

  const showMessage =
    Object.keys(cavSeries).length + groupToDisplay.length === 0;

  const onSetTrellisTooltipData = React.useCallback(
    trellisTooltipData =>
      dispatch(setTooltipData({ data: { trellisTooltipData } })),
    [dispatch],
  );

  const onSetRibbonTooltipData = React.useCallback(
    ribbonTooltipData => {
      if (
        R.isNil(ribbonTooltipData) &&
        tooltipData &&
        !R.isNil(tooltipData.ribbonTooltipData)
      ) {
        dispatch(
          setTooltipData({ data: { ...tooltipData, ribbonTooltipData } }),
        );
      } else if (!R.isNil(ribbonTooltipData)) {
        dispatch(setTooltipData({ data: { ribbonTooltipData } }));
      }
    },
    [dispatch, tooltipData],
  );

  const onSetDataSeriesTooltipData = React.useCallback(
    dataSeriesTooltipData =>
      dispatch(setTooltipData({ data: { dataSeriesTooltipData } })),
    [dispatch],
  );

  const onPillClick = React.useCallback(() => {
    prevent.dispatchEvent(() => rightPanel.setDialog({ type: 'ChartOptions' }));
  }, [rightPanel.setDialog, prevent.dispatchEvent]);

  const handleClickOutside = React.useCallback(() => {
    if (additionMode.isOn) {
      onVarianceDialogClose();
      additionModeOff();
      deleleLatestLocalRibbonEvent();
    }
    if (zoomInMode.isOn) zoomInModeOff();
    if (regionOfInterestMode.isOn) regionOfInterestModeOff();
    if (notesMode.isOn) notesModeOff();
  }, [
    additionMode,
    zoomInMode,
    additionModeOff,
    notesMode,
    notesModeOff,
    onVarianceDialogClose,
    zoomInModeOff,
    regionOfInterestMode,
    regionOfInterestModeOff,
    deleleLatestLocalRibbonEvent,
  ]);

  useMouseDownOutside(
    [
      overlayAddAllocIssueEl,
      overlayAddCapacityEl,
      overlayAddRibbonEl,
      overlayAddVarianceEl,
      overlayNotesProdDayEl,
      overlayRegionOfInterestEl,
      overlayZoomInEl,
    ],
    handleClickOutside,
  );

  const increaseLastEvent = React.useCallback(
    (e: KeyboardEvent) => {
      const { keyCode, target } = e;
      if (!(target instanceof window.HTMLElement)) {
        return;
      }
      const { tagName } = target;
      if (tagName === 'INPUT' || tagName === 'TEXTAREA') return;
      if (keyCode === E_KEY_CODE && !R.isEmpty(varianceEvents)) {
        const lastVarianceEvent: VarianceEvent = varianceEvents[0];
        const newEndDay = utcDay.offset(appConfig.today, -1);
        onUpdateVarianceEvent({
          dates: [lastVarianceEvent.dayStart, newEndDay],
          varianceEventId: lastVarianceEvent.id,
        });
      }
    },
    [appConfig, varianceEvents, onUpdateVarianceEvent],
  );
  const showBarHoverEffect = React.useMemo(
    () =>
      !isDragging &&
      !additionMode.isOn &&
      !notesMode.isOn &&
      !regionOfInterestMode.isOn &&
      !zoomInMode.isOn,
    [isDragging, additionMode, notesMode, regionOfInterestMode, zoomInMode],
  );

  const ribbonMap = React.useMemo(
    () =>
      ribbons.reduce((acc, ribbon) => {
        acc[ribbon.id] = ribbon;
        return acc;
      }, {}),
    [ribbons],
  );
  const currentDrilldownPhase = drilldownTableParams.phase;
  const clearSelectionHandler = React.useCallback((e: any) => {
    const selection = window.getSelection();
    if (
      selection &&
      R.pathOr('', ['anchorNode', 'parentNode', 'id'], selection) ===
        'interactiveTitle' &&
      !e.target.classList.contains('chartTitle')
    ) {
      selection && selection.removeAllRanges();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('keydown', increaseLastEvent);
    document.addEventListener('click', clearSelectionHandler);

    return () => {
      document.removeEventListener('click', clearSelectionHandler);
      document.removeEventListener('keydown', increaseLastEvent);
    };
  }, [increaseLastEvent, clearSelectionHandler]);

  const handleTrellisesMouseMove = useThrottledCallback(
    e => {
      handleDragX(e);
      handleChartHover(e);
    },
    [handleChartHover],
  );

  React.useEffect(() => {
    if (!(isXAxisDragging || isYAxisDragging)) return;

    document.addEventListener('mouseup', handleDragStop);

    return () => document.removeEventListener('mouseup', handleDragStop);
  }, [handleDragStop, isXAxisDragging, isYAxisDragging]);

  return (
    <CapacityVarianceProvider
      capacityEvents={capacityChangeEvents}
      varianceEvents={varianceEvents}
      production={production}
      nri={nri}
      lastDate={endDate ?? new Date()}
      limitDate={appConfig.today}
      drilldownPhase={drilldownTableParams.phase}
      grossNet={drilldownTableParams.grossNet}
      isIndicatorChartShown={cavSeries.some(s =>
        INDICATORS_LIST.includes(s.id),
      )}
      onCapacityEventChange={(eventId, events) => {
        const event = events.find(e => e.id === eventId);
        if (!event) return;
        dispatch(
          setCapacity({
            wellId: currentWellId,
            capacityEventId: eventId,
            modifiedCapacityEvent: event,
          }),
        );
      }}
    >
      <WellProductionChart.Wrapper>
        <WellProductionChart.Container>
          <WellProductionChart.TitleWrapper>
            <WellProductionChart.Title id="chartTitle" className="chartTitle">
              <Tooltip title={title ? title : ''} placement="bottom-start">
                <WellProductionChart.InteractiveTitle
                  id="interactiveTitle"
                  className="chartTitle"
                >
                  {title}
                </WellProductionChart.InteractiveTitle>
              </Tooltip>
              {!hasEnoughData ? <LoaderIndicatorWithDelay /> : null}
            </WellProductionChart.Title>
          </WellProductionChart.TitleWrapper>
          {!hasEnoughData ? (
            <WellProductionChart.ProgressWrapper>
              <LoadingPlaceholder
                appConfig={appConfig}
                drilldownTableParams={drilldownTableParams}
                chartOptions={chartOptions}
                cavSeries={cavSeries}
                groupToDisplay={groupToDisplay}
                onPillClick={onPillClick}
                ribbonCount={ribbonCount}
                selectedRibbonsData={selectedRibbonsData}
                seriesMapping={seriesMapping}
                trellisHeight={trellisHeight}
              />
            </WellProductionChart.ProgressWrapper>
          ) : (
            <>
              <WellProductionChart.TrellisesWrapper
                height={chartHeight}
                className="trellises-wrapper"
                isTrellises={isCavTrellis}
                onMouseMove={handleTrellisesMouseMove}
                onMouseLeave={() => {
                  onHighlightCapacityOff();
                  onHighlightCapacityDividerOff();
                  onHighlightVarianceOff();
                  onHighlightEventDividerOff();
                }}
                onMouseDown={handleDragStartX}
                onMouseUp={handleDragStop}
              >
                {notesDialog.show && notesProductionDay && (
                  <NotesProductionLine
                    leftOffset={leftOffset}
                    notesDates={notesDates}
                    notesProductionDay={notesProductionDay}
                    xScale={xScale}
                    setNotesProductionDay={changeNotesDay}
                    startDrag={startDrag}
                    finishDrag={finishDrag}
                    today={appConfig.today}
                  />
                )}
                {(isCavTrellis || !!ribbonCount) && (
                  <Highlightables
                    additionMode={additionMode}
                    capacityCategories={capacityCategories}
                    capacityDialog={capacityDialog}
                    capacityEvents={capacityChangeEvents}
                    capacitySegmentsAreas={capacitySegmentsAreas}
                    chartPos={chartPos}
                    finishDrag={finishDrag}
                    varianceCategories={varianceCategories}
                    varianceEvents={varianceEvents}
                    varianceEventsAreas={varianceEventsAreas}
                    eventColors={eventColors}
                    varianceDialog={varianceDialog}
                    highlightedCapacity={highlightedCapacity}
                    highlightedCapacityDivider={highlightedCapacityDivider}
                    highlightedEvent={highlightedVariance}
                    highlightedEventDivider={highlightedVarianceDivider}
                    highlightedRibbonEvent={highlightedRibbonEvent}
                    isPossibleEditCapacity={isPossibleEditCapacity}
                    isPossibleEditVariance={isPossibleEditVariance}
                    leftOffset={leftOffset}
                    onCapacityDialogClose={onCapacityDialogClose}
                    onCapacityDialogOpen={onCapacityDialogOpen}
                    onVarianceDialogClose={onVarianceDialogClose}
                    onVarianceDialogOpen={onVarianceDialogOpen}
                    onHighlightCapacityOff={onHighlightCapacityOff}
                    onHighlightCapacityDividerOff={
                      onHighlightCapacityDividerOff
                    }
                    onHighlightVarianceOff={onHighlightVarianceOff}
                    onHighlightEventDividerOff={onHighlightEventDividerOff}
                    ribbonDialog={ribbonDialog}
                    ribbonEvents={allRibbonEvents}
                    ribbonMap={ribbonMap}
                    ribbonOptions={ribbonOptions}
                    startDrag={startDrag}
                    today={appConfig.today}
                    xScale={xScale}
                    onXAxisScaling={onXAxisScaling}
                    isYAxisDragging={isYAxisDragging || isXAxisDragging}
                  />
                )}
                {showMessage ? (
                  <NoSelectedItemsMessage message={NO_SERIES_MESSAGE} />
                ) : null}
                {cavSeries.map((series, i) => {
                  if (
                    series.id.toUpperCase() === BOE ||
                    series.id.toUpperCase() === GOR ||
                    series.id.toUpperCase() === GLR ||
                    series.id === WATERCUT ||
                    series.id === TOTAL_LIQUID
                  ) {
                    return (
                      <DivIndicatorChart
                        allWellAllocIssues={issuesWithoutResolved || []}
                        allocIssueDialog={allocIssueDialog}
                        allocIssuesVisibility={appConfig.allocationIssues}
                        capacityDialog={capacityDialog}
                        changeMinDrilldownTableDate={
                          changeMinDrilldownTableDate
                        }
                        changeMaxDrilldownTableDate={
                          changeMaxDrilldownTableDate
                        }
                        clearDrilldownTable={resetDrilldownTable}
                        chartWasDragging={chartWasDragging}
                        currentWellId={currentWellId}
                        displaysRegionOfInterest={true}
                        drilldownTableParams={drilldownTableParams}
                        eventColors={eventColors}
                        varianceDialog={varianceDialog}
                        varianceEvents={varianceEvents}
                        extremeDates={extremeDates}
                        finishDrag={finishDrag}
                        forecastData={forecastData}
                        format={CHARTS_OPTIONS[series.id].format}
                        hasCapacityChanges={hasCapacityChanges}
                        height={trellisHeight}
                        highlightedAllocIssue={highlightedAllocIssue}
                        highlightedAllocIssueDivider={
                          highlightedAllocIssueDivider
                        }
                        highlightedEvent={highlightedVariance}
                        highlightedEventDivider={highlightedVarianceDivider}
                        production={production}
                        capacityChangeEvents={capacityChangeEvents}
                        capacityData={indicatorsCapacity[series.id]}
                        isAxisDragging={isXAxisDragging || isYAxisDragging}
                        isDisplayingForecast={isDisplayingForecast}
                        isDragging={isDragging}
                        isLast={
                          i === cavSeries.length - 1 &&
                          groupToDisplay.length === 0
                        }
                        isPossibleEditAlloc={isPossibleEditAlloc}
                        key={`trellis_${series.id}`}
                        leftOffset={leftOffset}
                        onAllocIssueDialogOpen={onAllocIssueDialogOpen}
                        onAllocIssueDividerHover={onAllocIssueDividerHover}
                        onAllocIssueUpdate={onAllocIssueUpdate}
                        onCapacityDialogClose={onCapacityDialogClose}
                        onCapacityDividerHover={onCapacityDividerHover}
                        onCapacityDialogOpen={onCapacityDialogOpen}
                        onDayInitChange={onDayInitChange}
                        onEventDividerHover={onEventDividerHover}
                        onHighlightCapacityDividerOff={
                          onHighlightCapacityDividerOff
                        }
                        onHighlightEventDividerOff={onHighlightEventDividerOff}
                        onHighlightAllocIssueDividerOff={
                          onHighlightAllocIssueDividerOff
                        }
                        onLocalAllocIssueUpdate={onAllocIssueUpdate}
                        onPillClick={onPillClick}
                        onSetTooltipData={onSetTrellisTooltipData}
                        onVarianceDialogOpen={onVarianceDialogOpen}
                        onVarianceEventUpdate={onUpdateVarianceEvent}
                        permissions={permissions}
                        pillText={getPillText(
                          series.id,
                          drilldownTableParams.grossNet,
                          false,
                        )}
                        position={i}
                        regionOfInterest={
                          activePanel === DRILLDOWN_PANEL &&
                          !regionOfInterestMode.isOn
                        }
                        showBarHoverEffect={showBarHoverEffect}
                        startDrag={startDrag}
                        tooltipData={tooltipData}
                        today={appConfig.today}
                        trellisTitle={series.id}
                        varianceEventSum={varianceEventSum}
                        width={chartWidth}
                        xScale={xScale}
                        onXAxisScaling={onXAxisScaling}
                      />
                    );
                  } else if (series.id !== VARIANCE_TRELLIS) {
                    return (
                      <DivPhaseChart
                        allocIssues={issuesWithoutResolved}
                        allocIssueDialog={allocIssueDialog}
                        allocIssuesVisibility={appConfig.allocationIssues}
                        capacity={capacityChangeEvents}
                        capacityData={capacityData[series.id]}
                        capacityDialog={capacityDialog}
                        changeMinDrilldownTableDate={
                          changeMinDrilldownTableDate
                        }
                        changeMaxDrilldownTableDate={
                          changeMaxDrilldownTableDate
                        }
                        chartWasDragging={chartWasDragging}
                        clearDrilldownTable={resetDrilldownTable}
                        currentWellId={currentWellId}
                        drilldownTableParams={drilldownTableParams}
                        displaysRegionOfInterest={true}
                        varianceEvents={varianceEvents}
                        eventColors={eventColors}
                        extremeDates={extremeDates}
                        varianceDialog={varianceDialog}
                        finishDrag={finishDrag}
                        forecastData={forecastData}
                        format={CHARTS_OPTIONS[series.id].format}
                        hasCapacityChanges={hasCapacityChanges}
                        height={trellisHeight}
                        isAxisDragging={isXAxisDragging || isYAxisDragging}
                        isDisplayingForecast={isDisplayingForecast}
                        isDragging={isDragging}
                        isLast={
                          i === cavSeries.length - 1 &&
                          groupToDisplay.length === 0
                        }
                        isPossibleEditCapacity={isPossibleEditCapacity}
                        isPossibleEditVariance={isPossibleEditVariance}
                        isPossibleEditAlloc={isPossibleEditAlloc}
                        key={`trellis_${series.id}`}
                        leftOffset={leftOffset}
                        nri={nri}
                        onBFactorDrag={onBFactorDrag}
                        onDeclineInitChange={onDeclineInitChange}
                        onCapacityDividerHover={onCapacityDividerHover}
                        onAllocIssueDialogOpen={onAllocIssueDialogOpen}
                        onAllocIssueDividerHover={onAllocIssueDividerHover}
                        onAllocIssueUpdate={onAllocIssueUpdate}
                        onEventDividerHover={onEventDividerHover}
                        onCapacityDialogClose={onCapacityDialogClose}
                        onCapacityDialogOpen={onCapacityDialogOpen}
                        onDayInitChange={onDayInitChange}
                        onXAxisScaling={onXAxisScaling}
                        onHighlightAllocIssueDividerOff={
                          onHighlightAllocIssueDividerOff
                        }
                        onHighlightCapacityDividerOff={
                          onHighlightCapacityDividerOff
                        }
                        onHighlightEventDividerOff={onHighlightEventDividerOff}
                        onLocalAllocIssueUpdate={onAllocIssueUpdate}
                        onPillClick={onPillClick}
                        onRateInitChange={onRateInitChange}
                        onSetTooltipData={onSetTrellisTooltipData}
                        onVarianceDialogOpen={onVarianceDialogOpen}
                        onVarianceEventUpdate={onUpdateVarianceEvent}
                        permissions={permissions}
                        pillText={getPillText(
                          series.id,
                          drilldownTableParams.grossNet,
                          false,
                        )}
                        position={i}
                        production={production}
                        showBarHoverEffect={showBarHoverEffect}
                        startDrag={startDrag}
                        today={appConfig.today}
                        tooltipData={tooltipData}
                        trellisTitle={CHARTS_OPTIONS[series.id].title}
                        width={chartWidth}
                        xScale={xScale}
                        highlightedAllocIssue={highlightedAllocIssue}
                        highlightedAllocIssueDivider={
                          highlightedAllocIssueDivider
                        }
                        highlightedEvent={highlightedVariance}
                        highlightedEventDivider={highlightedVarianceDivider}
                        regionOfInterest={
                          activePanel === DRILLDOWN_PANEL &&
                          !regionOfInterestMode.isOn
                        }
                        varianceEventSum={varianceEventSum}
                      />
                    );
                  } else if (
                    series.id === VARIANCE_TRELLIS &&
                    devFeatures.varianceTrellis
                  ) {
                    return (
                      <DivVarianceChart
                        key={series.id}
                        allWellAllocIssues={issuesWithoutResolved || []}
                        allocIssueDialog={allocIssueDialog}
                        allocIssuesVisibility={appConfig.allocationIssues}
                        capacity={capacityChangeEvents}
                        capacityData={capacityData}
                        capacityDialog={capacityDialog}
                        changeMinDrilldownTableDate={
                          changeMinDrilldownTableDate
                        }
                        changeMaxDrilldownTableDate={
                          changeMaxDrilldownTableDate
                        }
                        chartWasDragging={chartWasDragging}
                        clearDrilldownTable={resetDrilldownTable}
                        currentWellId={currentWellId}
                        drilldownTableParams={drilldownTableParams}
                        displaysRegionOfInterest={true}
                        eventColors={eventColors}
                        extremeDates={extremeDates}
                        finishDrag={finishDrag}
                        format={CHARTS_OPTIONS[drilldownPhase].format}
                        hasCapacityChanges={hasCapacityChanges}
                        height={trellisHeight}
                        highlightedAllocIssue={highlightedAllocIssue}
                        highlightedAllocIssueDivider={
                          highlightedAllocIssueDivider
                        }
                        indicatorsCapacity={indicatorsCapacity}
                        isDragging={isDragging}
                        isLast={
                          i === groupToDisplay.length - 1 &&
                          groupToDisplay.length === 0
                        }
                        isPossibleEditAlloc={isPossibleEditAlloc}
                        isPossibleEditCapacity={isPossibleEditCapacity}
                        isPossibleEditVariance={isPossibleEditVariance}
                        isXAxisDragging={isXAxisDragging}
                        isYAxisDragging={isYAxisDragging}
                        leftOffset={leftOffset}
                        onAllocIssueDialogOpen={onAllocIssueDialogOpen}
                        onAllocIssueDividerHover={onAllocIssueDividerHover}
                        onAllocIssueUpdate={onAllocIssueUpdate}
                        onCapacityDialogClose={onCapacityDialogClose}
                        onCapacityDialogOpen={onCapacityDialogOpen}
                        onCapacityDividerHover={onCapacityDividerHover}
                        onDayInitChange={onDayInitChange}
                        onEventDividerHover={onEventDividerHover}
                        onXAxisScaling={onXAxisScaling}
                        onHighlightAllocIssueDividerOff={
                          onHighlightAllocIssueDividerOff
                        }
                        onHighlightCapacityDividerOff={
                          onHighlightCapacityDividerOff
                        }
                        onHighlightEventDividerOff={onHighlightEventDividerOff}
                        onLocalAllocIssueUpdate={onAllocIssueUpdate}
                        onPillClick={onPillClick}
                        onSetTooltipData={onSetTrellisTooltipData}
                        onStartYAxisDragging={handleDragStartY}
                        onStopDragging={handleDragStop}
                        onVarianceDialogOpen={onVarianceDialogOpen}
                        onVarianceEventUpdate={onUpdateVarianceEvent}
                        pillText={getPillText(
                          currentDrilldownPhase,
                          drilldownTableParams.grossNet,
                          true,
                        )}
                        position={2}
                        production={production}
                        showBarHoverEffect={showBarHoverEffect}
                        startDrag={startDrag}
                        regionOfInterest={
                          activePanel === DRILLDOWN_PANEL &&
                          !regionOfInterestMode.isOn
                        }
                        today={appConfig.today}
                        tooltipData={tooltipData}
                        trellisTitle={VARIANCE_TRELLIS}
                        varianceDialog={varianceDialog}
                        varianceEvents={varianceEvents}
                        width={chartWidth}
                        xScale={xScale}
                      />
                    );
                  }
                  return null;
                })}
                {groupToDisplay.map((group, i) => (
                  <SeriesChart
                    key={group.ids[0]}
                    chartOptions={chartOptions}
                    chartWasDragging={chartWasDragging}
                    groupOptions={group.ids}
                    currentWellId={currentWellId}
                    leftOffset={leftOffset}
                    seriesMapping={seriesMapping}
                    extremeDates={extremeDates}
                    height={trellisHeight}
                    isAxisDragging={isXAxisDragging || isYAxisDragging}
                    isDragging={isDragging}
                    isLast={i === groupToDisplay.length - 1}
                    onPillClick={onPillClick}
                    onSetTooltipData={onSetDataSeriesTooltipData}
                    onXAxisScaling={onXAxisScaling}
                    tooltipData={tooltipData}
                    series={seriesOfCurrentWell}
                    xScale={xScale}
                    width={chartWidth}
                  />
                ))}

                {!!ribbonCount && (
                  <WellProductionChart.RibbonWrapper>
                    {selectedRibbonsData.map((ribbon, order) => (
                      <RibbonChart
                        key={ribbon.id}
                        extremeDates={extremeDates}
                        isAxisDragging={isXAxisDragging || isYAxisDragging}
                        isLast={order === selectedRibbonsData.length - 1}
                        isTrellises={isCavTrellis}
                        onDividerHover={onRibbonEventDividerHover}
                        onHighlightRibbonEventDividerOff={
                          onHighlightRibbonEventDividerOff
                        }
                        onRibbonDetailsPanelOpen={onRibbonDetailsPanelOpen}
                        onRibbonEventDialogOpen={onRibbonEventDialogOpen}
                        onSetRibbonTooltipData={onSetRibbonTooltipData}
                        order={order}
                        ribbon={ribbon}
                        ribbonEvents={ribbonEvents[ribbon.id]}
                        ribbonOptions={ribbonOptions}
                        today={appConfig.today}
                        onXAxisScaling={onXAxisScaling}
                        width={chartWidth}
                        xScale={xScale}
                      />
                    ))}
                  </WellProductionChart.RibbonWrapper>
                )}

                {additionMode.isOn && additionMode.subject === 'capacity' && (
                  <OverlayAddCapacity
                    additionModeOff={additionModeOff}
                    extremeDates={extremeDates}
                    height={chartHeight}
                    leftOffset={leftOffset}
                    onAddSegment={onAddSegment}
                    width={chartWidth}
                    ref={overlayAddCapacityEl}
                  />
                )}

                {additionMode.isOn && additionMode.subject === 'variance' && (
                  <OverlayAddVariance
                    additionModeOff={additionModeOff}
                    allocIssues={allocIssues}
                    deleteLocalVarianceEvent={onDeleteVarianceEventLocal}
                    extremeDates={extremeDates}
                    height={chartHeight}
                    leftOffset={leftOffset}
                    onVarianceEventUpdate={onUpdateVarianceEvent}
                    onVarianceEventCreateFirstClick={
                      onCreateVarianceEventFirstClick
                    }
                    onVarianceEventCreateSecondClick={
                      onCreateVarianceEventSecondClick
                    }
                    today={appConfig.today}
                    varianceEvents={varianceEvents}
                    width={chartWidth}
                    ref={overlayAddVarianceEl}
                  />
                )}

                {additionMode.isOn &&
                  additionMode.subject === 'ribbonEvent' && (
                    <OverlayAddRibbonEvent
                      ref={overlayAddRibbonEl}
                      additionModeOff={additionModeOff}
                      extremeDates={extremeDates}
                      height={chartHeight}
                      leftOffset={leftOffset}
                      today={appConfig.today}
                      width={chartWidth}
                    />
                  )}

                {additionMode.isOn && additionMode.subject === 'allocIssue' && (
                  <OverlayAddAllocIssue
                    additionModeOff={additionModeOff}
                    allocIssues={issuesWithoutResolved || []}
                    createLocalAllocIssue={onCreateLocalAllocIssue}
                    deleteLocalAllocIssue={onDeleteLocalAllocIssue}
                    extremeDates={extremeDates}
                    height={chartHeight}
                    leftOffset={leftOffset}
                    onAllocIssueCreate={onAllocIssueCreate}
                    today={appConfig.today}
                    updateLocalAllocIssue={onAllocIssueUpdate}
                    varianceEvents={varianceEvents}
                    width={chartWidth}
                    ref={overlayAddAllocIssueEl}
                  />
                )}

                {zoomInMode.isOn && (
                  <OverlayZoomIn
                    changeExtremeDates={changeTwoExtremeDates}
                    extremeDates={extremeDates}
                    height={chartHeight}
                    leftOffset={leftOffset}
                    ref={overlayZoomInEl}
                    width={chartWidth}
                    zoomInModeOff={zoomInModeOff}
                  />
                )}
                {regionOfInterestMode.isOn && (
                  <OverlayRegionOfInterest
                    changeDrilldownTableParams={changeDrilldownTableParams}
                    compareOption={drilldownTableParams.compareOption}
                    extremeDates={extremeDates}
                    height={trellisesChartHeight}
                    leftOffset={leftOffset}
                    phase={drilldownTableParams.phase}
                    ref={overlayRegionOfInterestEl}
                    regionOfInterestModeOff={regionOfInterestModeOff}
                    today={appConfig.today}
                    coreSeries={cavSeries}
                    dataSeries={groupToDisplay}
                    width={chartWidth}
                  />
                )}

                {notesMode.isOn && (
                  <OverlayNotesProdDay
                    extremeDates={extremeDates}
                    height={chartHeight}
                    width={chartWidth}
                    leftOffset={leftOffset}
                    notesModeOff={notesModeOff}
                    setNotesProductionDay={changeNotesDay}
                    ref={overlayNotesProdDayEl}
                  />
                )}
              </WellProductionChart.TrellisesWrapper>
              {!additionMode.isOn &&
                !isDragging &&
                tooltipData &&
                (tooltipData.trellisTooltipData ||
                  tooltipData.ribbonTooltipData ||
                  tooltipData.dataSeriesTooltipData) && (
                  <InformationTooltip
                    isDisplayingForecast={isDisplayingForecast}
                    tooltipData={tooltipData}
                    varianceCategories={varianceCategories}
                    forGroupChart={false}
                  />
                )}
            </>
          )}

          {(isCavTrellis || isSeriesTrellis || !!ribbonCount) && (
            <>
              <WellProductionChart.XAxisContainer width={chartWidth}>
                <XAxis
                  extremeDates={extremeDates}
                  width={chartWidth}
                  xScale={xScale}
                  isDragging={isXAxisDragging || isYAxisDragging}
                  onXAxisScaling={onXAxisScaling}
                />
              </WellProductionChart.XAxisContainer>
            </>
          )}
        </WellProductionChart.Container>
        <TopControls />
      </WellProductionChart.Wrapper>
    </CapacityVarianceProvider>
  );
};

WellProductionChart.Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

WellProductionChart.Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

WellProductionChart.TitleWrapper = styled.div`
  width: 100%;
  height: ${CHART_TITLE_HEIGHT}px;
  position: relative;
`;

WellProductionChart.Title = styled.div`
  padding-left: 17px;
  height: ${CHART_TITLE_HEIGHT}px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props: Record<string, any>) => props.theme.colors.primaryText};
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
  user-select: none;
  position: relative;
  z-index: 74;
  cursor: text;
  user-select: text;

  &::before {
    display: block;
    content: '';
    width: 6px;
    height: 27px;
    position: absolute;
    left: 0;
    top: 13px;
  }
`;

WellProductionChart.TrellisesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.isTrellises ? 'flex-start' : 'flex-end')};
  flex-grow: 2;
  width: calc(100% - ${Y_AXIS_WIDTH}px);
  height: ${props => props.height}px;
  position: absolute;
  left: ${Y_AXIS_WIDTH}px;
  top: 48px;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
`;

WellProductionChart.ProgressWrapper = styled.div`
  width: 100%;
  height: calc(100% - ${X_AXIS_HEIGHT + CHART_TITLE_HEIGHT}px);
  position: absolute;
  top: ${CHART_TITLE_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

WellProductionChart.XAxisContainer = styled.div`
  width: ${(props: Record<string, any>) => props.width}px;
  position: absolute;
  bottom: 0;
  left: ${Y_AXIS_WIDTH}px;
  height: ${X_AXIS_HEIGHT}px;
  border-left: 1px solid black;
  overflow: hidden;
`;

WellProductionChart.InteractiveTitle = styled.span`
  user-select: text;
`;

WellProductionChart.RibbonWrapper = styled.div`
  border-top: 1px solid black;
  z-index: 75;
`;

export default React.memo(WellProductionChart);
