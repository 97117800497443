import * as R from 'ramda';
import { createSelector } from 'reselect';
import { filterActions } from 'redux-ignore';

import { getGraphqlPayload } from 'store/helpers';
import type { Action, Selector } from 'store/models';

import { buildAuthData } from './utils';
import {
  FETCH_AUTH_CONFIG,
  LOGIN,
  LOGIN_VIA_SSO,
  LOGOUT,
  namespace,
  REGISTER,
  UPDATE_TOKEN,
} from './AuthActions';
import { AuthConfig } from './models/auth';

export const STATE_KEY = 'auth';
const authRegexp = new RegExp(`${namespace}/`);

export type AuthState = {
  authConfig: AuthConfig | null;
  id: string | null;
  token: string | null;
  isAuthenticated: boolean;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  loginTime: Date | null;
  isAllowedEditCapChanges: boolean;
  isAllowedEditVarEvents: boolean;
  isAllowedEditAllocIssues: boolean;
  isAllowedEditRibbonEvents: boolean;
  tokenCreationTime: Date | null;
  userUuid: string | null;
};

export const initialState: AuthState = {
  authConfig: null,
  id: null,
  token: null,
  isAuthenticated: false,
  email: null,
  firstName: null,
  lastName: null,
  loginTime: null,
  isAllowedEditCapChanges: false,
  isAllowedEditVarEvents: false,
  isAllowedEditAllocIssues: false,
  isAllowedEditRibbonEvents: false,
  tokenCreationTime: null,
  userUuid: null,
};

const AuthReducer = (state: AuthState = initialState, action: Action) => {
  switch (action.type) {
    case `${LOGIN}_SUCCESS`:
    case `${LOGIN_VIA_SSO}_SUCCESS`:
    case `${REGISTER}_SUCCESS`: {
      const user = getGraphqlPayload(action);
      const authData = buildAuthData(user);
      if (authData.inactive) return initialState;
      return R.mergeDeepRight(state, authData);
    }
    case `${FETCH_AUTH_CONFIG}_SUCCESS`:
      const data = getGraphqlPayload(action);
      return R.assoc('authConfig', data, state);
    case LOGOUT: {
      return { ...initialState, email: state.email };
    }
    case `${UPDATE_TOKEN}_SUCCESS`: {
      return R.assoc<Date, AuthState>('tokenCreationTime', new Date(), state);
    }
    default: {
      return state;
    }
  }
};

export const getAllInfo = (state: Record<string, any>) => state[STATE_KEY];
export const getAuthConfig = (state: Record<string, any>) =>
  state[STATE_KEY].authConfig;
export const getId = R.path<string>([STATE_KEY, 'id']);
export const getFullName = (_: Record<string, any>) => {
  const firstName = R.path([STATE_KEY, 'firstName'], _);
  const lastName = R.path([STATE_KEY, 'lastName'], _);

  return firstName + ' ' + lastName;
};
export const getToken = R.path<string>([STATE_KEY, 'token']);
export const getIsAuthenticated = R.path<boolean>([
  STATE_KEY,
  'isAuthenticated',
]);
export const getEmail = R.path<string>([STATE_KEY, 'email']);
export const getIsRemember = R.path<boolean>([STATE_KEY, 'isRemember']);
export const getLoginTime = R.path<string>([STATE_KEY, 'loginTime']);
export const getIsAdmin = R.path<boolean>([STATE_KEY, 'isAdmin']);
export const getIsUserManager = R.path<boolean>([STATE_KEY, 'isUserManager']);
export const getInfoForHelpRequest: Selector<Record<string, any>> =
  createSelector(getAllInfo, info => ({
    fullname: `${info.firstName} ${info.lastName}`,
    email: info.email,
  }));

export const getPermissions: Selector<Record<string, any>> = createSelector(
  getAllInfo,
  info => ({
    isAllowedEditCapChanges: info.isAllowedEditCapChanges,
    isAllowedEditVarEvents: info.isAllowedEditVarEvents,
    isAllowedEditAllocIssues: info.isAllowedEditAllocIssues,
    isAllowedEditRibbonEvents: info.isAllowedEditRibbonEvents,
    isAdmin: info.isAdmin,
  }),
);
export const getTokenCreationTime = R.path<Date>([
  STATE_KEY,
  'tokenCreationTime',
]);

export default filterActions(AuthReducer as any, action =>
  action.type.match(authRegexp),
);
