import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from 'components/Logo';
import Button from 'components/Button';
import Portal from 'components/Portal';

import { getAppConfig, getFeatures } from 'modules/appConfig/AppConfigReducer';
import { setAppConfigLocally } from 'modules/appConfig/AppConfigActions';
import { logout, changeMyPassword } from 'modules/auth/AuthActions';
import {
  getIsAuthenticated,
  getFullName,
  getIsAdmin,
  getIsUserManager,
} from 'modules/auth/AuthReducer';

import ChangePasswordModal from 'modules/user/components/ChangePassWindow';
import AccountModal from 'modules/user/components/AccountWindow';

import useMenu from 'hooks/useMenu';
import DevSubMenu from 'components/DevSubMenu';

const LogoMenu = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAdmin = useSelector(getIsAdmin);
  const isUserManager = useSelector(getIsUserManager);
  const currentUserName = useSelector(getFullName);
  const devFeatures = useSelector(getFeatures);
  const appConfig = useSelector(getAppConfig);
  const onLogout = React.useCallback(() => dispatch(logout()), [dispatch]);

  const [openDevFeatures, setOpenDevFeatures] = React.useState<boolean>(false);

  const openDevMenu = React.useCallback(() => {
    setOpenDevFeatures(true);
  }, [setOpenDevFeatures]);

  const menuElRef = React.useRef(null);
  const [isMenuOpen, onMenuHover, onMenuLeave, onTargetClick] =
    useMenu(menuElRef);
  const devItemElem = React.useRef<HTMLLIElement>(null);

  const changeDevFeatures = React.useCallback(
    (feature: any[]): void => {
      dispatch(
        setAppConfigLocally({ ...devFeatures, [feature[0]]: !feature[1] }),
      );
    },
    [dispatch, devFeatures],
  );

  const [isAccountOpen, setAccountOpen] = React.useState(false);
  const [isChangePasswordOpen, setChangePasswordOpen] = React.useState(false);

  const changePasswordRequest = React.useCallback(
    values => dispatch(changeMyPassword(values)),
    [dispatch],
  );

  React.useEffect(() => {
    if (isMenuOpen) return;

    setOpenDevFeatures(false);
  }, [isMenuOpen]);
  return (
    <LogoMenu.Container>
      <Button transparent width={53} height={34} onClick={onTargetClick}>
        <Logo noText width={53} />
      </Button>

      <Portal>
        {isMenuOpen && (
          <LogoMenu.Menu
            ref={menuElRef}
            onMouseLeave={onMenuLeave}
            onMouseEnter={onMenuHover}
          >
            <Paper>
              {currentUserName && isAuthenticated && (
                <LogoMenu.CurrentUser>{currentUserName}</LogoMenu.CurrentUser>
              )}
              <List>
                {isAuthenticated && appConfig.allocationIssues && (
                  <Link to="/backlog">
                    <MenuItem>Allocation Issue Backlog</MenuItem>
                  </Link>
                )}
                {(isAdmin || isUserManager) && (
                  <Link to="/user-management">
                    <MenuItem>User Management</MenuItem>
                  </Link>
                )}
                {(isAdmin || isUserManager) && appConfig.spotfireDashboards && (
                  <Link to="/spotfire/dashboards">
                    <MenuItem>Dashboards</MenuItem>
                  </Link>
                )}
                {isAdmin && (
                  <MenuItem onClick={openDevMenu} ref={devItemElem}>
                    Dev Features
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem onClick={() => setAccountOpen(true)}>
                    My Account
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem onClick={() => setChangePasswordOpen(true)}>
                    Change Password
                  </MenuItem>
                )}
                <MenuItem disabled={!isAuthenticated} onClick={onLogout}>
                  Logout
                </MenuItem>
              </List>
            </Paper>
            {openDevFeatures && devItemElem.current && (
              <DevSubMenu
                anchor={(
                  devItemElem.current as HTMLLIElement
                ).getBoundingClientRect()}
                devFeatures={devFeatures}
                onChange={changeDevFeatures}
                onClose={() => setOpenDevFeatures(false)}
              />
            )}
          </LogoMenu.Menu>
        )}
      </Portal>

      <ChangePasswordModal
        open={isChangePasswordOpen}
        handleClose={() => setChangePasswordOpen(false)}
        changePasswordRequest={changePasswordRequest}
      />
      <AccountModal
        open={isAccountOpen}
        handleClose={() => setAccountOpen(false)}
      />
    </LogoMenu.Container>
  );
};

LogoMenu.Container = styled.div`
  width: 53px;
  height: 34px;
  margin: 0;
  display: flex;
  align-items: center;
`;

LogoMenu.Menu = styled.div`
  width: 200px;
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 200;
  color: ${(props: Record<string, any>) => props.theme.colors.primaryText};
`;

LogoMenu.CurrentUser = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: bold;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 11px 16px;
  margin: 0;
`;
LogoMenu.SubMenu = styled(Paper)`
  position: absolute;
  top: ${props => props.anchor.top - 56}px;
  left: ${props => props.anchor.width}px;
`;

export default React.memo<Record<string, any>>(LogoMenu);
