import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import FilterTable from './FilterTable';
import { countFilterValues, filterWells } from '../../utils/filter';

const FilterView = ({
  filterColumns,
  filters,
  onAddFilter,
  onAddSeveralFilters,
  onClearFilter,
  activeTable,
  onRemoveFilter,
  searchWord,
  onSetFilter,
  filteredWells,
  wells,
}) => {
  return (
    <>
      {filterColumns.map((column, i) => {
        const sortedColumn = column.sort(
          (a, b) => a.filterColumnOrder - b.filterColumnOrder,
        );
        return (
          <FilterView.Column
            columnQuant={filterColumns.length}
            key={`column_${column[0].id}`}
          >
            {sortedColumn.map(columnMappingItem => {
              const filterName =
                columnMappingItem.wiserockBinding ||
                columnMappingItem.sourceName;
              if (filters[filterName]) {
                return (
                  <FilterTable
                    key={columnMappingItem.id}
                    addFilter={onAddFilter}
                    addSeveralFilters={onAddSeveralFilters}
                    clearFilter={onClearFilter}
                    firstColDataKey="value"
                    filterName={filterName}
                    filters={filters[filterName]}
                    isActive={activeTable === filterName}
                    removeFilter={onRemoveFilter}
                    searchWord={searchWord}
                    secondColDataKey="quantity"
                    setFilter={onSetFilter}
                    countedValues={
                      R.isEmpty(filters[columnMappingItem.sourceName])
                        ? countFilterValues(filteredWells, filterName)
                        : countFilterValues(
                            filterWells(wells, R.omit([filterName], filters)),
                            filterName,
                          )
                    }
                    size={{
                      height: columnMappingItem.filterColumnHeight,
                    }}
                    title={columnMappingItem.displayName}
                  />
                );
              }
              return null;
            })}
          </FilterView.Column>
        );
      })}
    </>
  );
};

FilterView.Column = styled.div`
  &&& {
    width: ${(props: Record<string, any>) =>
      `calc((100% - ${(props.columnQuant - 1) * 5}px) / ${props.columnQuant})`};
    display: flex;
    flex-direction: column;
  }
`;

export default FilterView;
