import * as R from 'ramda';
import {
  VarianceDrilldownTableItem,
  ASC,
  VAR_SORTING_ORDER,
  COMPARE_OPTION,
} from 'modules/drilldownTable/models/drilldownTable';

import naturalSorting from './naturalSorting';

const sortForecastTable = (
  sortCriteria: string,
  sortDirection: string,
  sortVarDirectionIndex: number,
  phaseKey: string,
  table: VarianceDrilldownTableItem[],
  compareOpt: string,
): VarianceDrilldownTableItem[] => {
  if (sortCriteria === 'variance') {
    const variancePhaseKey = phaseKey + 'Variance';
    const firstColKey = phaseKey + 'FirstColKey';
    const sortParams = VAR_SORTING_ORDER[sortVarDirectionIndex];
    const getSortableValue = listItem =>
      sortParams.abs
        ? Math.abs(listItem[variancePhaseKey])
        : listItem[variancePhaseKey];
    if (sortParams.direction === ASC) {
      return table.sort((a, b) => {
        if (a[variancePhaseKey] === null && b[variancePhaseKey] !== null) {
          return 1;
        }
        if (b[variancePhaseKey] === null && a[variancePhaseKey] !== null) {
          return -1;
        }
        if (getSortableValue(a) > getSortableValue(b)) {
          return 1;
        }
        if (getSortableValue(a) < getSortableValue(b)) {
          return -1;
        }

        return 0;
      });
    }
    return table.sort((a, b) => {
      const isAGroupNull = a[firstColKey] === 0 && a[variancePhaseKey] === 0;
      const isBGroupNull = b[firstColKey] === 0 && b[variancePhaseKey] === 0;
      if (
        (a[firstColKey] === null && b[firstColKey] !== null) ||
        (!isBGroupNull && isAGroupNull)
      ) {
        return -1;
      }
      if (
        (b[firstColKey] === null && a[firstColKey] !== null) ||
        (isBGroupNull && !isAGroupNull)
      ) {
        return 1;
      }
      if (getSortableValue(a) > getSortableValue(b)) {
        return -1;
      }
      if (getSortableValue(a) < getSortableValue(b)) {
        return 1;
      }
      return 0;
    });
  }

  const key =
    sortCriteria === 'well'
      ? sortCriteria
      : phaseKey + sortCriteria[0].toUpperCase() + sortCriteria.slice(1);
  //sort by name
  if (sortCriteria !== 'secondCol' && sortCriteria !== 'thirdCol') {
    if (sortDirection === ASC) {
      return table.sort((a, b) =>
        naturalSorting(
          R.pathOr('', [sortCriteria], a).toLowerCase(),
          R.pathOr('', [sortCriteria], b).toLowerCase(),
        ),
      );
    }
    return table.sort(
      (a, b) =>
        0 -
        naturalSorting(
          R.pathOr('', [sortCriteria], a).toLowerCase(),
          R.pathOr('', [sortCriteria], b).toLowerCase(),
        ),
    );
  }

  if (sortCriteria === 'secondCol' && compareOpt === COMPARE_OPTION.extVsCap) {
    const variancePhaseKey = phaseKey + 'SecondCol';
    if (sortDirection === ASC) {
      return table.sort((a, b) => {
        const isAGroupNull = a[key] === 0 && a[variancePhaseKey] === 0;
        const isBGroupNull = b[key] === 0 && b[variancePhaseKey] === 0;
        if (
          (a[key] === null && b[key] !== null) ||
          (isAGroupNull && !isBGroupNull)
        ) {
          return 1;
        }
        if (
          (b[key] === null && a[key] !== null) ||
          (isBGroupNull && !isAGroupNull)
        ) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
    }
    return table.sort((a, b) => {
      const isAGroupNull = a[key] === 0 && a[variancePhaseKey] === 0;
      const isBGroupNull = b[key] === 0 && b[variancePhaseKey] === 0;
      if (
        (a[key] === null && b[key] !== null) ||
        (isAGroupNull && !isBGroupNull)
      ) {
        return -1;
      }
      if (
        (b[key] === null && a[key] !== null) ||
        (isBGroupNull && !isAGroupNull)
      ) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
  }

  if (sortCriteria === 'secondCol' && compareOpt === COMPARE_OPTION.actVsExt) {
    if (sortDirection === ASC) {
      return table.sort((a, b) => {
        if (a[key] === null && b[key] !== null) {
          return 1;
        }
        if (b[key] === null && a[key] !== null) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
    }
    return table.sort((a, b) => {
      if (a[key] === null && b[key] !== null) {
        return -1;
      }
      if (b[key] === null && a[key] !== null) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
  }

  if (sortDirection === ASC) {
    return table.sort((a, b) => {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  }

  return table.sort((a, b) => {
    if (a[key] > b[key]) return -1;
    if (a[key] < b[key]) return 1;
    return 0;
  });
};

export default sortForecastTable;
