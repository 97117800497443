import * as R from 'ramda';

import {
  DrilldownTableParams,
  VarianceDrilldownTableItem,
  WellDrilldownTableItem,
} from '../models/drilldownTable';

const filterDrilldownTable = (
  table: VarianceDrilldownTableItem[] | WellDrilldownTableItem[],
  params: DrilldownTableParams,
  numberOfRoiDays: number,
  filters,
  wells,
): any => {
  const isNotEmpty = (val, key) => !R.isEmpty(val);
  const filtersWithoutEmpty = R.pickBy(isNotEmpty, filters);

  const filteredWellIdIndexedList = R.values(wells).reduce((acc, well) => {
    const isIncluded = R.keys(filtersWithoutEmpty).every(
      filterName =>
        filters[filterName].includes(well[filterName]) ||
        (R.path([filterName, 0], filters) === '' && well[filterName] === null),
    );
    if (isIncluded) acc[well.id] = well;

    return acc;
  }, {});
  const filteredTable = table.filter(tableItem =>
    Boolean(filteredWellIdIndexedList[tableItem.wellId]),
  );

  const grossNetNormalized = filteredTable.map(tableItem => {
    if (params.grossNet === 'Gross') return tableItem;
    const nriNumber = wells[tableItem.wellId].NRI
      ? parseFloat(wells[tableItem.wellId].NRI)
      : 1;
    const nriNormalized = nriNumber <= 1 && nriNumber >= 0 ? nriNumber : 1;

    return {
      ...tableItem,
      oilVariance: tableItem.oilVariance * nriNormalized,
      waterVariance: tableItem.waterVariance * nriNormalized,
      gasVariance: tableItem.gasVariance * nriNormalized,
      boeVariance: tableItem.boeVariance * nriNormalized,
      oilCapacity: tableItem.oilCapacity * nriNormalized,
      waterCapacity: tableItem.waterCapacity * nriNormalized,
      gasCapacity: tableItem.gasCapacity * nriNormalized,
      boeCapacity: tableItem.boeCapacity * nriNormalized,
      oilActual: tableItem.oilActual * nriNormalized,
      waterActual: tableItem.waterActual * nriNormalized,
      gasActual: tableItem.gasActual * nriNormalized,
      boeActual: tableItem.boeActual * nriNormalized,
    };
  });
  const rateVolumeNormalized = grossNetNormalized.map(tableItem => {
    if (params.rateVolume === 'Rate') return tableItem;

    return {
      ...tableItem,
      oilVariance: tableItem.oilVariance * numberOfRoiDays,
      oilCapacity: tableItem.oilCapacity * numberOfRoiDays,
      oilActual: tableItem.oilActual * numberOfRoiDays,
      gasVariance: tableItem.gasVariance * numberOfRoiDays,
      gasCapacity: tableItem.gasCapacity * numberOfRoiDays,
      gasActual: tableItem.gasActual * numberOfRoiDays,
      waterVariance: tableItem.waterVariance * numberOfRoiDays,
      waterCapacity: tableItem.waterCapacity * numberOfRoiDays,
      waterActual: tableItem.waterActual * numberOfRoiDays,
      boeVariance: tableItem.boeVariance * numberOfRoiDays,
      boeCapacity: tableItem.boeCapacity * numberOfRoiDays,
      boeActual: tableItem.boeActual * numberOfRoiDays,
    };
  });
  return rateVolumeNormalized;
};

export default filterDrilldownTable;
