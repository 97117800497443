import { XYCoord } from 'dnd-core';
import * as R from 'ramda';
import React from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import styled from 'styled-components';

import { ChartOptionsMenuIcon, CheckIcon, CloseIcon } from 'components/Icons';
import { ItemTypes, LayoutOption } from '../models';
import { WellColumnMappingItem } from 'modules/well/models/well';
import { CustomAttribute } from './CustomAttribute';

interface DragItem {
  index: number;
  fixedIndex: number;
  id: string;
  type: string;
}

interface DragFilterOptionProps {
  checkIfAttributeNameAlreadyExists: (name: string) => boolean;
  changeFiltersOptionsOrder: (dragId: string, hoverId: string) => void;
  changeFiltersOptionsOrderGlobally: () => void;
  columnMapingData: WellColumnMappingItem | null;
  fixedIndex: number;
  option: LayoutOption;
  index: number;
  onChangeOptionVisible: (optionId: string) => void;
  onRemoveOption: (optionId: string, filterName: string) => void;
  onSetDraggableColumn: (columnName: string) => void;
  setIndicatorIndex: (id: number | null) => void;
  setIsAnyOptionDraging: (data: boolean) => void;
  setTooltipPosition: (data: { x: number; y: number }) => void;
  setTooltipText: (data: string) => void;
}

const DragFilterOption = ({
  checkIfAttributeNameAlreadyExists,
  changeFiltersOptionsOrder,
  changeFiltersOptionsOrderGlobally,
  columnMapingData,
  option,
  index,
  fixedIndex,
  onChangeOptionVisible,
  onRemoveOption,
  onSetDraggableColumn,
  setIndicatorIndex,
  setIsAnyOptionDraging,
  setTooltipText,
  setTooltipPosition,
}: DragFilterOptionProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isCustomDragging, setIsCustomDragging] = React.useState(false);
  const [canDrag, setCanDrag] = React.useState(true);
  // const [isHovered, setIsHovered] = React.useState(false); disable edit/delete due to drag

  const onStartDragging = React.useCallback(() => {
    if (!canDrag) return;
    setIsCustomDragging(true);
    onSetDraggableColumn(option.id);
  }, [canDrag, option]);

  const onEndDragging = React.useCallback(() => {
    setIsCustomDragging(false);
    onSetDraggableColumn('');
  }, []);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.OPTION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      if (item.fixedIndex >= index) setIndicatorIndex(index);
      else setIndicatorIndex(index + 1);

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY + 18 < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY - 18 > hoverMiddleY) {
        return;
      }

      changeFiltersOptionsOrder(option.id, item.id);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    canDrag,
    type: ItemTypes.OPTION,
    item: () => {
      onStartDragging();
      return { id: option.id, index, fixedIndex };
    },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end: () => {
      changeFiltersOptionsOrderGlobally();
      setIndicatorIndex(null);
      onEndDragging();
      onSetDraggableColumn('');
      setIsAnyOptionDraging(false);
    },
  });

  preview(drop(ref));
  const description = React.useMemo(
    () => R.propOr('', 'description', columnMapingData),
    [columnMapingData],
  );

  const onMouseMoveHandler = React.useCallback(
    e => {
      setTooltipText(description);
      setTooltipPosition({ x: e.clientX, y: e.clientY });
    },
    [setTooltipPosition],
  );

  const onMouseEnterHandler = React.useCallback(() => {
    //setIsHovered(true); disable edit/delete due to drag
    if (isDragging) {
      setTooltipText('');
    } else {
      setTooltipText(description);
    }
  }, [setTooltipText, description, isDragging]);
  const onMouseLeaveHandler = React.useCallback(() => {
    //setIsHovered(false); disable edit/delete due to drag
    setTooltipText('');
  }, [setTooltipText]);

  return (
    <DragFilterOption.OptionWrapper
      ref={ref}
      isDragging={isDragging}
      isFirst={index === 0}
      data-handler-id={handlerId}
    >
      <DragFilterOption.ItemWrapper
        isDragging={isDragging}
        isCustomDragging={isCustomDragging}
      >
        <DragFilterOption.DragArea
          ref={drag}
          onMouseMove={onMouseMoveHandler}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          onMouseDown={onStartDragging}
          onMouseUp={onEndDragging}
        >
          <DragFilterOption.DragButton isCustomDragging={isCustomDragging}>
            <DragFilterOption.ChartOptionsMenuIcon />
          </DragFilterOption.DragButton>

          <DragFilterOption.OptionName
            isDragging={isCustomDragging}
            isCustom={option.id.includes('custom')}
          >
            {option.id.includes('custom') ? (
              <CustomAttribute
                attribute={option}
                checkIfAttributeNameAlreadyExists={
                  checkIfAttributeNameAlreadyExists
                }
                onSetEditMode={isEditMode => setCanDrag(!isEditMode)}
              />
            ) : (
              <>
                <span>{option.displayName}</span>
                <DragFilterOption.Source>
                  {R.propOr('', 'source', columnMapingData)}
                </DragFilterOption.Source>
              </>
            )}
          </DragFilterOption.OptionName>
        </DragFilterOption.DragArea>

        <DragFilterOption.Button
          isCustomDragging={isCustomDragging}
          onClick={() => {
            onChangeOptionVisible(option.id);
          }}
        >
          {option.isShown ? <CheckIcon /> : null}
        </DragFilterOption.Button>
        <DragFilterOption.Button
          isCustomDragging={isCustomDragging}
          onClick={() => {
            onRemoveOption(option.id, option.filterName);
          }}
        >
          <CloseIcon />
        </DragFilterOption.Button>
      </DragFilterOption.ItemWrapper>
    </DragFilterOption.OptionWrapper>
  );
};

DragFilterOption.ChartOptionsMenuIcon = styled(ChartOptionsMenuIcon)`
  font-size: 8px;
`;

DragFilterOption.OptionWrapper = styled.div`
  border: ${props => props.theme.borders.thingray};
  background-color: ${props => (props.isDragging ? '#fff' : '#efefef')};
  border-top: ${props =>
    !props.isFirst ? 'none' : props.theme.borders.thingray};
`;

DragFilterOption.ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 25px 25px;
  align-items: center;
  opacity: ${props => (props.isDragging ? '0' : '1')};
  background-color: ${props =>
    props.isCustomDragging ? '#B9B9B9' : '#efefef'};
`;

DragFilterOption.Button = styled.button`
  padding: 0;
  min-height: 20px;
  height: 100%;
  border: none;
  border-left: ${props => props.theme.borders.thingray};
  outline: none;
  background-color: ${props =>
    props.isCustomDragging ? '#B9B9B9' : '#efefef'};
  > svg {
    width: 15px;
  }
`;

DragFilterOption.DragButton = styled.button`
  padding: 0;
  min-height: 20px;
  height: 100%;
  border: none;
  border-right: ${props => props.theme.borders.thingray};
  outline: none;
  background-color: ${props =>
    props.isCustomDragging ? '#B9B9B9' : '#efefef'};
  > svg {
    width: 8px;
  }
  :hover {
    cursor: ns-resize;
  }
`;

DragFilterOption.DragArea = styled.div`
  display: grid;
  grid-template-columns: 15px auto;
  align-items: center;
`;

DragFilterOption.OptionName = styled.span`
  margin: 0 ${({ isCustom }) => (isCustom ? '0' : '5px')};
  display: flex;
  justify-content: space-between;
  height: 20px;
  align-items: center;

  .source {
    color: #a9a9a9;
  }
`;

DragFilterOption.Source = styled.span`
  color: #a9a9a9;
`;

export default DragFilterOption;
