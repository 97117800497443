import * as React from 'react';
import * as R from 'ramda';

import useComponentSize from 'hooks/useComponentSize';
import type { AllocIssue } from 'modules/allocIssue/models/allocIssue';
import MainBarsGroup from 'modules/chart/components/MainBarsGroup';
import SelectedBar from 'modules/chart/components/SelectedBar';
import {
  createVarianceYScale,
  getTrellisGeneralClipPathId,
  getTrellisId,
} from 'modules/chart/utils';
import type { VarianceEvent } from 'modules/varianceEvent/models/varianceEvent';
import type {
  IdDialog,
  IdIndexDialog,
  TooltipData,
  TrellisTooltipData,
} from 'modules/ui/models/ui';

import AllocIssueArea from './allocIssue/AllocIssueArea';
import VarianceAllocArea from './allocIssue/VarianceAllocArea';
import EventVarianceArea from './varianceEvent/EventVarianceArea';
import { useCapacityData, useVarianceData } from '../context/capacityVariance';
interface SVGVarianceTrellisProps {
  allocIssues: AllocIssue[];
  allocIssueDialog?: IdIndexDialog;
  allocIssuesVisibility: boolean;
  eventColors: { [key: string]: string };
  height: number;
  highlightedAllocIssue?: IdDialog;
  highlightedAllocIssueDivider?: IdDialog;
  isAxisDragging: boolean;
  isPossibleEditAlloc: boolean;
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  maxDataPoint: number;
  minDataPoint: number;
  showBarHoverEffect: boolean;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  varianceData: { date: Date; variance: number }[];
  varianceEvents: VarianceEvent[];
  xScale: any;
  yAxisLinePos: number | null;
}

const SVGVarianceTrellis = ({
  allocIssues,
  allocIssueDialog,
  allocIssuesVisibility,
  eventColors,
  height,
  highlightedAllocIssue,
  highlightedAllocIssueDivider,
  isAxisDragging,
  isPossibleEditAlloc,
  maxDataPoint,
  minDataPoint,
  onSetTooltipData,
  showBarHoverEffect,
  tooltipData,
  trellisTitle,
  varianceData: das,
  varianceEvents,
  xScale,
  yAxisLinePos,
}: SVGVarianceTrellisProps) => {
  const svgEl: React.RefObject<HTMLElement | null> = React.useRef(null);
  const svgBoundingRect = useComponentSize(svgEl);

  const yScale = React.useMemo(
    () => createVarianceYScale(height, minDataPoint, maxDataPoint),
    [maxDataPoint, minDataPoint, height],
  );

  const capacityData = useCapacityData();
  const varianceData = useVarianceData();
  const varianceAreasWithCapacity = React.useMemo(
    () => capacityData.getVarianceAreas(),
    [capacityData.getVarianceAreas],
  );
  const varianceAreas = React.useMemo(
    () => varianceData.getVarianceFromVarianceAreas(varianceAreasWithCapacity),
    [varianceData.getVarianceFromVarianceAreas],
  );
  const variance = React.useMemo(
    () => varianceData.getAll(),
    [varianceData.getAll],
  );

  return (
    <svg
      //@ts-expect-error
      ref={svgEl}
      className="varianceTrellis_chart"
      height="100%"
      id={getTrellisId(trellisTitle)}
      preserveAspectRatio="none"
      viewBox={`0 0 ${xScale.range()[1]} ${height}`}
      width={xScale.range()[1] - 2}
      shapeRendering="geometricPrecision"
      style={{ cursor: isAxisDragging ? 'grabbing' : 'auto' }}
    >
      <defs>
        <clipPath id={getTrellisGeneralClipPathId(trellisTitle)}>
          <rect height={height} width={xScale.range()[1] - 1} />
        </clipPath>
      </defs>
      {allocIssues &&
        allocIssuesVisibility &&
        !R.isEmpty(allocIssues) &&
        allocIssues.map((allocIssue, i) => (
          <AllocIssueArea
            key={`allocIssueArea_${allocIssue.id}`}
            allocIssue={allocIssue}
            height={height}
            xScale={xScale}
            isHighlighted={
              (!!highlightedAllocIssue &&
                highlightedAllocIssue.show &&
                highlightedAllocIssue.id === allocIssue.id) ||
              (!!highlightedAllocIssueDivider &&
                highlightedAllocIssueDivider.show &&
                highlightedAllocIssueDivider.id === allocIssue.id) ||
              (!!allocIssueDialog &&
                allocIssueDialog.show &&
                allocIssueDialog.id === allocIssue.id)
            }
            isEditable={
              isPossibleEditAlloc &&
              !!allocIssueDialog &&
              allocIssueDialog.show &&
              allocIssueDialog.id === allocIssue.id
            }
          />
        ))}
      <MainBarsGroup
        data={variance}
        xScale={xScale}
        yScale={yScale}
        purpose="variance"
        type="well"
      />

      {allocIssues &&
        allocIssuesVisibility &&
        !R.isEmpty(allocIssues) &&
        allocIssues.map((allocIssue, i) => (
          <VarianceAllocArea
            key={`varianceIssueArea_${allocIssue.id}`}
            allocIssue={allocIssue}
            height={height}
            varianceData={variance}
            xScale={xScale}
            yScale={yScale}
          />
        ))}

      {!R.isEmpty(varianceAreas) &&
        varianceEvents.map((varianceEvent, idx) => (
          <EventVarianceArea
            color={eventColors[varianceEvent.varianceOptionId]}
            key={varianceEvent.id}
            varianceArea={varianceAreas[idx]}
            yScale={yScale}
            xScale={xScale}
          />
        ))}

      {svgEl.current && showBarHoverEffect && (
        <SelectedBar
          allocIssues={allocIssues}
          onSetTooltipData={onSetTooltipData}
          varianceData={variance}
          svgBoundingRect={svgBoundingRect}
          tooltipData={tooltipData}
          trellisTitle={trellisTitle}
          varianceEvents={varianceEvents}
          xScale={xScale}
          yScale={yScale}
        />
      )}
      <line
        stroke="#000000"
        strokeWidth={1}
        x1={0}
        x2={xScale.range()[1]}
        y1={yScale(0)}
        y2={yScale(0)}
      />
      {yAxisLinePos && (
        <line
          stroke="#c42424"
          strokeWidth={1}
          x1={0}
          x2={xScale.range()[1]}
          y1={yAxisLinePos}
          y2={yAxisLinePos}
          vectorEffect="non-scaling-stroke"
        />
      )}
    </svg>
  );
};
export default React.memo<SVGVarianceTrellisProps>(SVGVarianceTrellis);
