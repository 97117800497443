import * as React from 'react';
import styled from 'styled-components';
import { SortDirection } from 'react-virtualized';

import { SortIndicator, SortIndicatorABS } from 'components/Icons';
import { MaxColumnWidth } from '../models/drilldownTable';

const getFirstColHeader = (groups, firstColDataKey) =>
  groups[firstColDataKey] ? groups[firstColDataKey].title : '';

interface WellHeaderRowProps {
  className: string;
  firstColDataKey: string;
  fourthColDataKey: string;
  groups: { [groupKey: string]: Record<string, any> };
  maxColumnWidth: MaxColumnWidth;
  onClick: (data: { sortBy: string; sortDirection?: string }) => void;
  secondColDataKey: string;
  secondColTitle: string;
  sortBy: string;
  sortDirection: string;
  style: { [prop: string]: string };
  thirdColDataKey: string;
  thirdColTitle: string;
  varianceSortingParams: { abs: boolean; direction: string };
}

const WellHeaderRow = ({
  className,
  firstColDataKey,
  fourthColDataKey,
  groups,
  maxColumnWidth,
  onClick,
  secondColDataKey,
  secondColTitle,
  sortBy,
  sortDirection,
  style,
  thirdColDataKey,
  thirdColTitle,
  varianceSortingParams,
}: WellHeaderRowProps) => (
  <div className={className} role="row" style={style}>
    <WellHeaderRow.FirstCol
      onClick={() =>
        onClick({
          sortBy: firstColDataKey,
          sortDirection:
            sortDirection === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        })
      }
    >
      {getFirstColHeader(groups, firstColDataKey)}
      {sortBy === firstColDataKey && (
        <SortIndicator sortDirection={sortDirection} />
      )}
    </WellHeaderRow.FirstCol>

    <WellHeaderRow.SecondCol
      withIcon={sortBy === secondColDataKey}
      onClick={() =>
        onClick({
          sortBy: secondColDataKey,
          sortDirection:
            sortDirection === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        })
      }
      width={maxColumnWidth.secondColWidth}
    >
      <WellHeaderRow.ColTextContainer>
        {secondColTitle}
        {sortBy === secondColDataKey && (
          <WellHeaderRow.SortIndicatorWrapper>
            <SortIndicator sortDirection={sortDirection} />
          </WellHeaderRow.SortIndicatorWrapper>
        )}
      </WellHeaderRow.ColTextContainer>
    </WellHeaderRow.SecondCol>

    <WellHeaderRow.SecondCol
      withIcon={sortBy === thirdColDataKey}
      onClick={() =>
        onClick({
          sortBy: thirdColDataKey,
          sortDirection:
            sortDirection === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC,
        })
      }
      width={maxColumnWidth.thirdColWidth}
    >
      <WellHeaderRow.ColTextContainer>
        {thirdColTitle}
        {sortBy === thirdColDataKey && (
          <WellHeaderRow.SortIndicatorWrapper>
            <SortIndicator sortDirection={sortDirection} />
          </WellHeaderRow.SortIndicatorWrapper>
        )}
      </WellHeaderRow.ColTextContainer>
    </WellHeaderRow.SecondCol>

    <WellHeaderRow.SecondCol
      withIcon={sortBy === fourthColDataKey}
      onClick={() => onClick({ sortBy: fourthColDataKey })}
      width={maxColumnWidth.fourthColWidth}
    >
      <WellHeaderRow.ColTextContainer>
        Var.
        {sortBy === fourthColDataKey && !varianceSortingParams.abs && (
          <WellHeaderRow.SortIndicatorWrapper>
            <SortIndicator
              sortDirection={varianceSortingParams.direction}
              style={{ marginLeft: '1px' }}
            />
          </WellHeaderRow.SortIndicatorWrapper>
        )}
        {sortBy === fourthColDataKey && varianceSortingParams.abs && (
          <WellHeaderRow.SortIndicatorWrapper>
            <SortIndicatorABS
              sortDirection={varianceSortingParams.direction}
              style={{ marginLeft: '1px' }}
            />
          </WellHeaderRow.SortIndicatorWrapper>
        )}
      </WellHeaderRow.ColTextContainer>
    </WellHeaderRow.SecondCol>
  </div>
);

WellHeaderRow.Col = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid #c1c1c1;
  padding-right: 2px;
  cursor: pointer;
  line-height: 1.3em;
`;

WellHeaderRow.SecondCol = styled(WellHeaderRow.Col)`
  flex: 0 0 ${(props: Record<string, any>) => props.width + 1}px;
  box-sizing: content-box;
  position: relative;
`;

WellHeaderRow.FirstCol = styled(WellHeaderRow.Col)`
  padding-left: 5px;
  justify-content: flex-start;
  border-left: none;
  flex: 1 1 200px;
`;

WellHeaderRow.ColTextContainer = styled.div`
  width: 40px;
  display: grid;
  grid-template-columns: 30px 10px;
  justify-items: end;
`;

WellHeaderRow.SortIndicatorWrapper = styled.div`
  position: relative;
  left: 2px;
  height: 5px;
`;

export default WellHeaderRow;
