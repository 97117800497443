type DataValue = string | number | boolean;
type Data = Record<string, DataValue | Record<string, string | number>>;

const createSharedStorage = <T = Data>(key: string) => {
  const STORAGE_KEY = key;
  let listeners: ((v: Partial<T>) => void)[] = [];

  const getData = () => {
    const dataStringified = localStorage.getItem(STORAGE_KEY) || '{}';
    const data = JSON.parse(dataStringified);
    return data;
  };

  const setData = (data: Partial<T>) => {
    const currentData = getData();
    const newData = { ...currentData, ...data };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newData));
    listeners.forEach(l => l(newData));
  };

  const resetData = () => {
    localStorage.removeItem(STORAGE_KEY);
  };

  const onStorageChange = (evt: any) => {
    if (evt?.key !== STORAGE_KEY) return;
    if (!evt?.newValue) return;

    const newData = JSON.parse(evt?.newValue);
    if (newData) listeners.forEach(l => l(newData));
  };

  const subscribe = (listenerFunc: any) => {
    window.addEventListener('storage', onStorageChange);
    listeners.push(listenerFunc);

    return () => {
      window.removeEventListener('storage', onStorageChange);
      listeners = listeners.filter(l => l !== listenerFunc);
    };
  };

  return {
    get: getData,
    set: setData,
    reset: resetData,
    subscribe,
  };
};

export default createSharedStorage;
