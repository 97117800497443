import * as R from 'ramda';
import { all, delay, put, takeLatest, select } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { isIdNew } from 'helpers';

import { AUTHENTICATED } from 'modules/app/AppActions';
import { getToday } from 'modules/appConfig/AppConfigReducer';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import { Action } from 'store/models';

import {
  fetchWellRibbonEvents,
  fetchRibbonOptions,
  fetchRibbons,
  updateRemoteRibbonEvent,
  deleteRemoteRibbonEvent,
  populateRibbonEvents,
  CREATE_RIBBON_EVENT_LOCALLY,
  DELETE_RIBBON_EVENT_LOCALLY,
  FETCH_WELL_RIBBON_EVENTS,
  UPDATE_RIBBON_EVENT_DATES_LOCALLY,
  UPDATE_RIBBON_EVENT_EXTRA_INPUTS_DATA_LOCALLY,
  UPDATE_RIBBON_EVENT_NO_END_DATE_LOCALLY,
  UPDATE_RIBBON_EVENT_NOTES_LOCALLY,
  UPDATE_RIBBON_EVENT_OPTION_LOCALLY,
  CREATE_REMOTE_RIBBON_EVENT,
  POPULATE_RIBBON_EVENTS,
} from './RibbonActions';
import { getRibbonEvents, getDefaultEventOptionId } from './RibbonReducer';
import {
  SET_CURRENT_WELL_ID,
  unsetRightPanelDialog,
  setRightPanelDialog,
  CLOSE_RIBBON_EVENT_DIALOG,
} from 'modules/ui/UIActions';
import { getRightPanelDialogs } from 'modules/ui/UIReducer';
import { normalizeRibbonEventsData } from './utils';
import { utcDay } from 'd3-time';
import { parseSearchParams } from 'modules/router/utils/router';
import { EventPanel } from 'modules/router/models/router';
import { OPEN_RIBBON_CONVERSATION } from 'modules/inboxConversation/InboxConversationActions';

function* initialFetchRibbonSaga(): Generator<any, any, any> {
  yield put(fetchRibbonOptions());
  yield put(fetchRibbons());
}

function* fetchWellRibbonEventsSaga(action): Generator<any, any, any> {
  yield put(fetchWellRibbonEvents(action.payload));
}

function* initSyncRibbonWithApiSaga(action: Action): Generator<any, any, any> {
  const { ribbonEventId } = action.payload;
  if (isIdNew(ribbonEventId)) return;
  yield delay(700);
  const ribbonEvents = yield select(getRibbonEvents);
  const event = R.clone(ribbonEvents[ribbonEventId]);
  if (event.noEndDate) {
    event.dayEnd = null;
  }
  if (event) {
    yield put(updateRemoteRibbonEvent(event));
  }
}

function* deleteRemoteEventSaga(action: Action): Generator<any, any, any> {
  const { ribbonEventId } = action.payload;
  yield put(unsetRightPanelDialog());
  if (!isIdNew(ribbonEventId)) {
    yield put(deleteRemoteRibbonEvent(ribbonEventId));
  }
}

function* normalizeRibbonEventsSaga(action): Generator<any, any, any> {
  const { wellId } = getGraphqlPrevActionVariables(action);
  const listRibbonEvents = getGraphqlPayload(action);
  const today = yield select(getToday);
  const normalizedData = normalizeRibbonEventsData(
    listRibbonEvents,
    utcDay.offset(today, -1),
  );
  yield put(populateRibbonEvents({ wellId, ribbonEvents: normalizedData }));
}

function* calculateNewRibbonEventSaga(
  action: Action,
): Generator<any, any, any> {
  const { wellId, date } = action.payload;
  const { RibbonDetails } = yield select(getRightPanelDialogs);
  const oldRibbonEvents = yield select(getRibbonEvents);
  const ribbonId = RibbonDetails.index;
  const defaultRibbonOptionId = yield select(getDefaultEventOptionId, ribbonId);
  const newRibbonEventId = uuid();
  const newEvent = {
    id: newRibbonEventId,
    wellId,
    ribbonId,
    ribbonOptionId: defaultRibbonOptionId,
    dayStart: date,
    dayEnd: date,
    notes: '',
    extraInputsData: null,
    noEndDate: false,
    locallyCreatedAt: new Date(),
  };
  const newRibbonEvents = {
    wellId,
    ribbonEvents: {
      ...oldRibbonEvents,
      [newRibbonEventId]: newEvent,
    },
  };
  yield put(populateRibbonEvents(newRibbonEvents));
}

function* normalizeCreatedEventSaga(action): Generator<any, any, any> {
  const newEvent = getGraphqlPayload(action);
  const {
    payload: { wellId },
  } = getGraphqlPrevActionVariables(action);
  const oldRibbonEvents = yield select(getRibbonEvents);
  const oldEvent = R.values(oldRibbonEvents).find(
    event =>
      isIdNew(event.id) &&
      event.ribbonId === newEvent.ribbonId &&
      event.dayStart.getTime() === new Date(newEvent.dayStart).getTime(),
  );
  if (oldEvent) {
    const clearedEvents = R.omit([oldEvent.id], oldRibbonEvents);
    const today = yield select(getToday);
    const normalizedNewEvent = normalizeRibbonEventsData(
      [newEvent],
      utcDay.offset(today, -1),
    );
    const newEvents = R.merge(clearedEvents, normalizedNewEvent);
    yield put(populateRibbonEvents({ wellId, ribbonEvents: newEvents }));
    yield put(
      setRightPanelDialog({
        type: 'RibbonEvent',
        data: { index: newEvent.id, id: newEvent.id },
      }),
    );
  }
}

function* openRibbonEventPanelFromRouteSaga(action): Generator<any, any, any> {
  const { wellId, ribbonEvents: events } = action.payload;
  const searchParams = parseSearchParams(window.location.search);

  if (
    !searchParams.eventPanel ||
    searchParams.eventPanel.type !== EventPanel.ribbon ||
    R.isEmpty(events)
  )
    return;

  const { id } = searchParams.eventPanel;
  const eventList = R.values(events);
  const index = eventList.findIndex(e => e.id === id && e.wellId === wellId);
  if (index < 0) return yield put({ type: CLOSE_RIBBON_EVENT_DIALOG });

  const data = { id };
  yield put(setRightPanelDialog({ type: 'RibbonEvent', data }));
}

function* openRibbonConversationSaga(action): Generator<any, any, any> {
  const { id } = action.payload;
  const data = { id };
  yield put(setRightPanelDialog({ type: 'RibbonEvent', data }));
}

function* ribbonSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(SET_CURRENT_WELL_ID, fetchWellRibbonEventsSaga),
    takeLatest(AUTHENTICATED, initialFetchRibbonSaga),
    takeLatest(
      [
        UPDATE_RIBBON_EVENT_DATES_LOCALLY,
        UPDATE_RIBBON_EVENT_EXTRA_INPUTS_DATA_LOCALLY,
        UPDATE_RIBBON_EVENT_NOTES_LOCALLY,
        UPDATE_RIBBON_EVENT_OPTION_LOCALLY,
        UPDATE_RIBBON_EVENT_NO_END_DATE_LOCALLY,
      ],
      initSyncRibbonWithApiSaga,
    ),
    takeLatest(DELETE_RIBBON_EVENT_LOCALLY, deleteRemoteEventSaga),
    takeLatest(
      `${FETCH_WELL_RIBBON_EVENTS}_SUCCESS`,
      normalizeRibbonEventsSaga,
    ),
    takeLatest(CREATE_RIBBON_EVENT_LOCALLY, calculateNewRibbonEventSaga),
    takeLatest(
      `${CREATE_REMOTE_RIBBON_EVENT}_SUCCESS`,
      normalizeCreatedEventSaga,
    ),
    takeLatest(POPULATE_RIBBON_EVENTS, openRibbonEventPanelFromRouteSaga),
    takeLatest(OPEN_RIBBON_CONVERSATION, openRibbonConversationSaga),
  ]);
}

export default ribbonSagas;
