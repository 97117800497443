import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ExtendedTabInfo } from 'components/Tabs/Model';
import { FilterIcon } from 'components/Icons';
import { Tab } from './Tab';
import { FilterBadge } from './Badge';

export const Wells = ({
  appliedFilters,
  tab,
}: {
  appliedFilters: number;
  tab?: ExtendedTabInfo;
}) => {
  return tab ? (
    <Wells.Tab isActive={tab.isActive}>
      <Link to="/filter">
        <Wells.Name>Wells</Wells.Name>

        <Wells.FilterButton width={24} height={24}>
          <FilterIcon width={18} height={18} color="#484848" />
          {appliedFilters > 0 && (
            <FilterBadge top={-7} right={-4}>
              {appliedFilters}
            </FilterBadge>
          )}
        </Wells.FilterButton>
      </Link>
    </Wells.Tab>
  ) : null;
};

Wells.Tab = styled(Tab)`
  padding: 0;

  > a {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
`;

Wells.Name = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

Wells.FilterButton = styled.div`
  position: relative;
  margin: 0 3px;
`;
