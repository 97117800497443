import { all, put, select, takeLatest } from 'redux-saga/effects';

import { claerGroupForecasts } from 'modules/externalForecast/ExternalForecastActions';
import { clearAllGroupChartsWithExcept } from 'modules/groupChart/GroupChartActions';
import { clearAllGroupSeries } from 'modules/series//SeriesActions';
import { resetCurrentGroup } from 'modules/ui/UIActions';
import { getCurrentGroup, getGroupMode } from 'modules/ui/UIReducer';
import { FETCH_WELL_COLUMN_MAPPING } from 'modules/well/WellActions';
import { getColumnMapping } from 'modules/well/WellReducer';

import {
  ADD_FILTER,
  ADD_SEVERAL_FILTERS,
  REMOVE_FILTER,
  SET_FILTER,
  CLEAR_FILTER,
  CLEAR_ALL_FILTERS,
  setInitialFilter,
  clearFilter,
} from './FilterActions';
import { getFilters } from './FilterReducer';
import { FETCH_WELL_CUSTOM_ATTRIBUTES } from 'modules/filterLayouts/FilterLayoutsActions';
import { getGraphqlPayload } from 'store/helpers';

/**
 * Populates filters state basing on column mapping
 */
function* populateEmptyFiltersSaga(): Generator<any, any, any> {
  const columns = yield select(getColumnMapping);
  const filters = yield select(getFilters);

  const columnsToSetFilters = columns.filter(
    column => !(filters[column.sourceName] || filters[column.wiserockBinding]),
  );
  yield all(
    columnsToSetFilters.map(column =>
      put(
        setInitialFilter({
          filterName: column.wiserockBinding || column.sourceName,
        }),
      ),
    ),
  );

  yield put(setInitialFilter({ filterName: 'id' }));
}

function* populateCustomEmptyFiltersSaga(action): Generator<any, any, any> {
  const wellCustomAttributes: { id: string; name: string }[] =
    getGraphqlPayload(action);

  yield all(
    Object.values(wellCustomAttributes).map(attr =>
      put(
        setInitialFilter({
          filterName: `custom ${attr.name}`,
          filterValue: '',
        }),
      ),
    ),
  );

  yield all(
    Object.values(wellCustomAttributes).map(attr =>
      put(clearFilter(`custom ${attr.name}`)),
    ),
  );
}

function* clearDataSaga(action): Generator<any, any, any> {
  const { type, payload } = action;
  if (type === SET_FILTER) {
    const groupMode = yield select(getGroupMode);
    if (groupMode.isOn && groupMode.subject === payload.filterName) {
      const currentGroup = yield select(getCurrentGroup);
      yield put(clearAllGroupChartsWithExcept(currentGroup));
      yield put(claerGroupForecasts(currentGroup));
      yield put(clearAllGroupSeries(currentGroup));

      return;
    }
  }
  yield put(clearAllGroupChartsWithExcept());
  yield put(clearAllGroupSeries());
  yield put(claerGroupForecasts());

  const currentPageIsFilter = window.location.pathname.includes('/filter');
  if (currentPageIsFilter) {
    yield put(resetCurrentGroup());
  }
}

function* filterSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      `${FETCH_WELL_COLUMN_MAPPING}_SUCCESS`,
      populateEmptyFiltersSaga,
    ),
    takeLatest(
      `${FETCH_WELL_CUSTOM_ATTRIBUTES}_SUCCESS`,
      populateCustomEmptyFiltersSaga,
    ),
    takeLatest(
      [
        ADD_FILTER,
        ADD_SEVERAL_FILTERS,
        REMOVE_FILTER,
        SET_FILTER,
        CLEAR_FILTER,
        CLEAR_ALL_FILTERS,
      ],
      clearDataSaga,
    ),
  ]);
}

export default filterSagas;
