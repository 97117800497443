import { format } from 'd3-format';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as R from 'ramda';
import * as React from 'react';
import { Column, Table, AutoSizer, SortDirection } from 'react-virtualized';
import styled from 'styled-components';

import { findClosest } from 'helpers';
import useMenu from 'hooks/useMenu';

import { CELL_HEIGHT } from '../models/drilldownTable';
import ContextMenu from './ContextMenu';
import VarianceTableRow from './VarianceTableRow';
import VarianceHeaderRow from './VarianceHeaderRow';

const numberRateFormat = format(',.1f');
const numberVolumeFormat = format(',d');

const UP_KEYCODE = 38;
const DOWN_KEYCODE = 40;

const getListOfOptions = ({
  lastHighlighted,
  index,
  highlightedIndecies,
  searchedTable,
}): string[] => {
  const selectionFrom =
    lastHighlighted || findClosest(index, highlightedIndecies) || 0;

  return searchedTable
    .slice(Math.min(index, selectionFrom), Math.max(index, selectionFrom) + 1)
    .map(data => data.varianceOptionId);
};

interface VarianceDrilldownTableColumnsProps {
  addSeveralOptions: (optionIds: string[]) => void;
  addVarianceOption: (optioinId: string) => void;
  currentTable: string | null;
  currentVarianceOptionIds: string[];
  drilldownTable: { [key: string]: string | number }[];
  isLoading: boolean;
  onChoose: (rowData: Record<string, any>) => void;
  removeVarianceOption: (optioinId: string) => void;
  resetCurrentVarianceOption: () => void;
  searchWord: string;
  tableHeight: number;
  volumeType: boolean;
}

const VarianceDrilldownTableColumns = ({
  addSeveralOptions,
  addVarianceOption,
  currentTable,
  currentVarianceOptionIds,
  drilldownTable,
  isLoading,
  onChoose,
  removeVarianceOption,
  resetCurrentVarianceOption,
  searchWord,
  tableHeight,
  volumeType,
}: VarianceDrilldownTableColumnsProps) => {
  const numberFormat = volumeType ? numberVolumeFormat : numberRateFormat;

  const firstColDataKey = 'subCause';
  const secondColDataKey = 'variance';

  const [lastHighlighted, setLastHighlighted] = React.useState<number | null>(
    null,
  );

  const searchedTable: any[] = React.useMemo(() => {
    const searchWordInLowerCase = searchWord.toLowerCase();
    const searchResult = drilldownTable.filter(drilldownTableRow =>
      (drilldownTableRow[firstColDataKey] as string)
        .toLowerCase()
        .includes(searchWordInLowerCase),
    );
    const normMatchedItems = searchWord
      ? searchResult.map(drilldownItem => ({
          ...drilldownItem,
          matched: [
            (drilldownItem[firstColDataKey] as string)
              .toLowerCase()
              .indexOf(searchWordInLowerCase),
            (drilldownItem[firstColDataKey] as string)
              .toLowerCase()
              .indexOf(searchWordInLowerCase) + searchWord.length,
          ],
        }))
      : searchResult;

    return normMatchedItems;
  }, [firstColDataKey, searchWord, drilldownTable]);

  const [sortBy, setSortBy] = React.useState(secondColDataKey);
  const [sortDirection, setSortDirection] = React.useState(SortDirection.ASC);
  const sort = sortTarget => {
    if (sortTarget === sortBy) {
      setSortDirection(
        sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC,
      );
    } else {
      setSortBy(sortTarget);
      setSortDirection(SortDirection.ASC);
    }
  };
  const sortedList = React.useMemo(() => {
    if (sortDirection === SortDirection.ASC) {
      const sortResult = searchedTable.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return -1;
        if (a[sortBy] > b[sortBy]) return 1;
        return 0;
      });
      return sortResult;
    }
    const sortResult = searchedTable.sort((a, b) => {
      if (a[sortBy] > b[sortBy]) return -1;
      if (a[sortBy] < b[sortBy]) return 1;
      return 0;
    });

    return sortResult;
  }, [sortBy, sortDirection, searchedTable]);

  const totalRow = React.useMemo(
    () =>
      sortedList.reduce(
        (acc, tableItem) => {
          acc[secondColDataKey] =
            acc[secondColDataKey] + tableItem[secondColDataKey];
          return acc;
        },
        {
          [firstColDataKey]: 'TOTAL',
          [secondColDataKey]: 0,
          color: '#ffffff',
          varianceOptionId: 'none',
        },
      ),
    [firstColDataKey, secondColDataKey, sortedList],
  );
  const showBottomTotalRow =
    tableHeight <= CELL_HEIGHT * searchedTable.length + 68;
  const resultList = showBottomTotalRow
    ? searchedTable
    : searchedTable.concat(totalRow);

  const rowRenderer = (props: {
    index: number;
    onRowClick: (row: {
      event: MouseEvent;
      index: number;
      rowData: Record<string, any>;
    }) => void;
    rowData: Record<string, any>;
    style: { [prop: string]: string };
    className: string;
  }) => {
    const { className, rowData } = props;
    const isHighlighted = R.isEmpty(currentVarianceOptionIds)
      ? true
      : currentVarianceOptionIds.includes(rowData.varianceOptionId);
    const newClassName = isHighlighted
      ? className + ' row--highlighted'
      : className + ' row--not-highlighted';

    return (
      <VarianceTableRow
        {...props}
        className={newClassName}
        firstColDataKey={firstColDataKey}
        secondColDataKey={secondColDataKey}
        hasColorEffects
        isHighlighted={isHighlighted}
        bgColor={rowData.color}
        format={numberFormat}
        wide={volumeType}
      />
    );
  };

  const headerRowRenderer = (props: {
    className: string;
    style: { [prop: string]: string };
    sort: (sortTarget: string) => void;
    sortBy: string;
    sortDirection: string;
  }) => {
    return (
      <VarianceHeaderRow
        {...props}
        firstColDataKey={firstColDataKey}
        secondColDataKey={secondColDataKey}
        wide={volumeType}
      />
    );
  };

  const chooseRowByKeyboard = React.useCallback(
    (e: KeyboardEvent) => {
      const willCrash = R.isEmpty(searchedTable);
      if (willCrash || currentTable !== 'VarianceDrilldownTable') return;
      const { target } = e;
      if (target instanceof HTMLElement) {
        const { tagName } = target;

        if (
          tagName === 'INPUT' ||
          tagName === 'TEXTAREA' ||
          tagName === 'SELECT'
        )
          return;
        const { keyCode } = e;
        if (!R.isEmpty(currentVarianceOptionIds)) {
          const highlightedIndecies = searchedTable.reduce((acc, item, i) => {
            if (currentVarianceOptionIds.includes(item.varianceOptionId))
              acc.push(i);
            return acc;
          }, []);

          if (keyCode === UP_KEYCODE) {
            e.preventDefault();
            const newHighlightedIndex = lastHighlighted
              ? Math.max(lastHighlighted - 1, 0)
              : Math.max(highlightedIndecies[0] - 1, 0);
            const newItem = searchedTable[newHighlightedIndex];
            onChoose(newItem);
            setLastHighlighted(newHighlightedIndex);
          } else if (keyCode === DOWN_KEYCODE) {
            e.preventDefault();
            const lastItemIndex = searchedTable.length - 1;
            const lastHighlightedIndexByOrder = highlightedIndecies.length - 1;
            const newHighlightedIndex = lastHighlighted
              ? Math.min(lastHighlighted + 1, lastItemIndex)
              : Math.min(
                  highlightedIndecies[lastHighlightedIndexByOrder] + 1,
                  lastItemIndex,
                );
            const newItem = searchedTable[newHighlightedIndex];
            onChoose(newItem);
            setLastHighlighted(newHighlightedIndex);
          }
        }
      }
    },
    [
      currentVarianceOptionIds,
      currentTable,
      lastHighlighted,
      onChoose,
      searchedTable,
    ],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', chooseRowByKeyboard);
    return () => {
      document.removeEventListener('keydown', chooseRowByKeyboard);
    };
  }, [chooseRowByKeyboard]);

  const rowClickHandler = React.useCallback(
    ({ event, index, rowData }) => {
      const highlightedIndecies = searchedTable.reduce((acc, item, i) => {
        if (currentVarianceOptionIds.includes(item.varianceOptionId))
          acc.push(i);
        return acc;
      }, []);
      if (rowData[firstColDataKey] === 'TOTAL') return;
      const { shiftKey, ctrlKey, metaKey } = event;
      const clickedVarianceOptionId = rowData.varianceOptionId;
      const isHighlighted = currentVarianceOptionIds.includes(
        clickedVarianceOptionId,
      );

      if (ctrlKey || metaKey) {
        if (isHighlighted) {
          removeVarianceOption(clickedVarianceOptionId);

          return;
        }
        addVarianceOption(clickedVarianceOptionId);
        setLastHighlighted(index);
      } else if (!ctrlKey && !metaKey && !shiftKey) {
        onChoose(rowData);
        setLastHighlighted(index);
      } else if (shiftKey) {
        if (highlightedIndecies.length > 0) {
          const itemsToSelect = getListOfOptions({
            lastHighlighted,
            index,
            highlightedIndecies,
            searchedTable,
          });
          addSeveralOptions(itemsToSelect);
          setLastHighlighted(index);
        } else if (highlightedIndecies.length === 0) {
          addVarianceOption(clickedVarianceOptionId);
          setLastHighlighted(index);
        }
      }
    },
    [
      addVarianceOption,
      addSeveralOptions,
      currentVarianceOptionIds,
      firstColDataKey,
      lastHighlighted,
      onChoose,
      removeVarianceOption,
      searchedTable,
    ],
  );

  const contextMenuElRef = React.useRef(null);
  const [
    isMenuOpen,
    onMenuHover,
    onMenuLeave,
    onTargetClick,
    closeMenu,
    contextPosition,
  ] = useMenu(contextMenuElRef);

  return (
    <VarianceDrilldownTableColumns.Container
      onClick={e => {
        if (
          Boolean(e.target.className.includes) &&
          e.target.className.includes('ReactVirtualized__Table__Grid')
        )
          resetCurrentVarianceOption();
      }}
      onContextMenu={e => {
        e.preventDefault();
        onTargetClick(e);
      }}
    >
      {!isLoading ? (
        <>
          <AutoSizer>
            {({ height, width }) => (
              <VarianceDrilldownTableColumns.StyledTable
                rowStyle={{ alignItems: 'stretch' }}
                rowHeight={CELL_HEIGHT}
                headerHeight={CELL_HEIGHT}
                headerRowRenderer={(props: {
                  className: string;
                  style: { [prop: string]: string };
                }) =>
                  headerRowRenderer({
                    ...props,
                    sortBy,
                    sortDirection,
                    sort,
                  })
                }
                onRowClick={rowClickHandler}
                rowCount={resultList.length}
                rowGetter={({ index }) => resultList[index]}
                height={showBottomTotalRow ? height - CELL_HEIGHT : height}
                width={width}
                rowRenderer={rowRenderer}
                scrollToAlignment="auto"
                showBottomTotalRow={showBottomTotalRow}
              >
                <Column
                  label={
                    firstColDataKey[0].toUpperCase() +
                    firstColDataKey.substring(1)
                  }
                  dataKey={firstColDataKey}
                  width={140}
                  flexGrow={1}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
                <Column
                  width={55}
                  label="Var"
                  dataKey={secondColDataKey}
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </VarianceDrilldownTableColumns.StyledTable>
            )}
          </AutoSizer>
          {showBottomTotalRow ? (
            <VarianceDrilldownTableColumns.TotalRow wide={volumeType}>
              <div>TOTAL</div>
              <div title={totalRow[secondColDataKey]}>
                {numberFormat(totalRow[secondColDataKey])}
              </div>
            </VarianceDrilldownTableColumns.TotalRow>
          ) : null}
        </>
      ) : (
        <VarianceDrilldownTableColumns.CircularProgressWrapper>
          <CircularProgress color="inherit" />
        </VarianceDrilldownTableColumns.CircularProgressWrapper>
      )}
      {isMenuOpen && (
        <ContextMenu
          closeMenu={closeMenu}
          contextPosition={contextPosition}
          data={searchedTable.concat(totalRow)}
          dataKeys={[firstColDataKey, secondColDataKey]}
          header={['Variance Category', 'Variance']}
          ref={contextMenuElRef}
          onMenuHover={onMenuHover}
          onMenuLeave={onMenuLeave}
        />
      )}
    </VarianceDrilldownTableColumns.Container>
  );
};

VarianceDrilldownTableColumns.Container = styled.div`
  height: calc(100% - 43px);
  overflow: hidden;
  font-family: 'Lato', sans-serif;
  border-top: 1px solid #c1c1c1;

  & > div:first-child {
    z-index: 20;
    position: relative;
  }
`;

VarianceDrilldownTableColumns.StyledTable = styled(Table)`
  user-select: none;
  z-index: 20;

  & * {
    outline: none;
  }

  & .ReactVirtualized__Table__headerRow {
    background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
    font-size: 12px;
    height: 18px;
  }

  & .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: flex-end;

    &:nth-child(2) {
      justify-content: flex-start;
      flex-direction: row-reverse;
      border-left: 1px solid #c1c1c1;
      margin-right: 5px;
    }
  }

  & .ReactVirtualized__Table__row {
    box-shadow: 1px 0 0 0 #c1c1c1, 1px 1px 0 0 #c1c1c1, 0 1px 0 0 #c1c1c1 inset;

    &:first-child {
      box-shadow: 1px 0 0 0 #c1c1c1, 1px 1px 0 0 #c1c1c1;
    }

    &:last-child {
      border-bottom: 1px solid #c1c1c1;

      > div {
        font-weight: ${props => (props.showBottomTotalRow ? '500' : '700')};
        color: ${props => (props.showBottomTotalRow ? '' : 'rgba(0,0,0,0.8)')};
      }
    }

    &:hover {
      box-shadow: 1px 0 0 0 black, 0 1px 0 0 black;
      z-index: 20;
      border-top: 1px solid black;
    }

    &:last-child:hover {
      border-bottom: 1px solid black;
    }
  }

  & .ReactVirtualized__Table__rowColumn[aria-colindex='1'] {
    margin: 0 !important;
    padding: 0 5px !important;
    border-top: 1px solid #c1c1c1;
  }

  & .ReactVirtualized__Table__rowColumn[aria-colindex='2'] {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-left: 1px solid #c1c1c1;
    margin-right: 5px;
  }

  & span {
    text-transform: none;
    overflow: visible;
  }

  & .row--highlighted {
    opacity: 1;
  }

  & .row--not-highlighted {
    opacity: 0.4;
  }
`;

VarianceDrilldownTableColumns.CircularProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

VarianceDrilldownTableColumns.TotalRow = styled.div`
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #c1c1c1;
  width: 100%;
  line-height: 1em;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  padding-right: 7px;
  padding-bottom: 2px;
  height: ${CELL_HEIGHT - 1}px;
  z-index: 10;
  & > div {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-right: 2px;
    cursor: default;
  }
  & > div:first-child {
    flex: 1 1 200px;
    padding-left: 5px;
  }

  & > div:not(:first-child) {
    flex: 0 0 ${(props: Record<string, any>) => (props.wide ? '74' : '59')}px;
    border-left: 1px solid #c1c1c1;
    justify-content: flex-end;
    margin-right: 1px;
  }
`;

export default React.memo<VarianceDrilldownTableColumnsProps>(
  VarianceDrilldownTableColumns,
);
