import React from 'react';
import spotfireStorage from '../utils/spotfireStorage';
import { SpotfireStorageData } from '../models';

const useSpotfireStorage = (
  onChange?: (data: Partial<SpotfireStorageData>) => void,
) => {
  const [currentData, setCurrentData] = React.useState(spotfireStorage.get());

  React.useEffect(() => {
    return spotfireStorage.subscribe(data => {
      setCurrentData(data);
      onChange?.(data);
    });
  }, [onChange]);

  return {
    data: currentData,
    setData: spotfireStorage.set,
  };
};

export default useSpotfireStorage;
