export type LatestEventNote = {
  authors: string[];
  eventType: string;
  eventId: string;
  noteTimestamp: Date;
  noteText: string;
  hasAttachedPhotos: boolean;
  hasAttachedFiles: boolean;
  inbox: boolean;
  markedAsInbox: boolean;
  read: boolean;
  archived: boolean;
  wellId: string;
  wellName: string;
  dateStart: Date;
  dateEnd: Date | null;
  urlSearch: string;
};

export enum NoteFilter {
  all = 'all',
  sent = 'sent',
  incoming = 'incoming',
}

export type ActionButton<T> = {
  type: T;
  action: () => void;
};

export type NoteActionButtons = {
  main: ActionButton<'archive' | 'inbox'>;
  read: ActionButton<'read' | 'unread'>;
};

export type PageConfiguration = {
  page: number;
  count: number;
};

export const NOTES_PER_INITIAL_LOAD = 5000;
export const NOTES_PER_PAGE = 50;
export const PAGE_ANCHOR_OFFSET = 4;
export const PAGE_LATERAL_OFFSET = 2;

export const EMPTY_INBOX_MESSAGE = 'There are no messages to display.';
