import Button from 'components/Button';
import SearchForm from 'components/SearchForm';
import * as React from 'react';
import styled from 'styled-components';

interface FilterTableControlPanelProps {
  clearAllFilters: () => void;
  isTableOpened: boolean;
  onSearchWordChange: (word: string) => void;
  redirectToChart: () => void;
  searchWord: string;
  toggleFilterTable: () => void;
  wellsFound: number;
  wellsTotal: number;
  isFiltering: boolean;
}

const FilterTableControlPanel = ({
  clearAllFilters,
  isTableOpened,
  onSearchWordChange,
  redirectToChart,
  searchWord,
  toggleFilterTable,
  wellsFound,
  wellsTotal,
  isFiltering,
}: FilterTableControlPanelProps) => {
  const formatNumber = React.useCallback(
    n => n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    [],
  );

  const wellsFoundFormatted = React.useMemo(
    () => formatNumber(wellsFound),
    [wellsFound],
  );

  const wellsTotalFormatted = React.useMemo(
    () => formatNumber(wellsTotal),
    [wellsTotal],
  );

  return (
    <FilterTableControlPanel.Container>
      <FilterTableControlPanel.SearchFormContainer>
        <SearchForm
          placeholder="Search"
          inputHandler={onSearchWordChange}
          value={searchWord}
        />
      </FilterTableControlPanel.SearchFormContainer>

      <FilterTableControlPanel.ToggleButton onClick={toggleFilterTable}>
        <Button width={104} height={30}>
          {isTableOpened ? 'Hide' : 'Show'} Filters
        </Button>
      </FilterTableControlPanel.ToggleButton>

      <FilterTableControlPanel.WellsCount>
        <FilterTableControlPanel.WellsRemaining isFiltering={isFiltering}>
          <span>Remaining:</span>
          <span>{formatNumber(wellsFoundFormatted)} / </span>
        </FilterTableControlPanel.WellsRemaining>

        <span>{wellsTotalFormatted} Wells</span>
      </FilterTableControlPanel.WellsCount>

      {isFiltering ? (
        <Button
          width={82}
          height={30}
          variant="critical"
          onClick={clearAllFilters}
        >
          Clear All
        </Button>
      ) : null}

      <Button width={70} height={30} onClick={redirectToChart}>
        Done
      </Button>
    </FilterTableControlPanel.Container>
  );
};

FilterTableControlPanel.Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  padding: 5px 8px;
  gap: 8px;
  background: #f0f0f0;
`;

FilterTableControlPanel.ToggleButton = styled.span`
  cursor: pointer;
`;

FilterTableControlPanel.WellsCount = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

FilterTableControlPanel.WellsRemaining = styled.div`
  display: ${({ isFiltering }) => (isFiltering ? 'flex' : 'none')};
  gap: 10px;

  span {
    white-space: pre-wrap;
  }
`;

FilterTableControlPanel.SearchFormContainer = styled.div`
  > div {
    height: 100%;

    > div {
      top: 55%;
      transform: translateY(-50%) rotateY(180deg);
    }

    input {
      height: 100%;

      &::placeholder {
        font-style: normal;
      }
    }
  }
`;

export default FilterTableControlPanel;
