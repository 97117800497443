import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { isIdNew } from 'helpers';
import { useNonInputKeydown } from 'hooks/useKeydown';
import { getFeatures } from 'modules/appConfig/AppConfigReducer';
import { getPermissions } from 'modules/auth/AuthReducer';
import CategoryForm from 'modules/category/forms/CategoryForm';
import NotesForm from 'modules/category/forms/NotesForm';
import RightPanel from 'modules/dashboard/components/RightPanel';
import {
  getCapacityCategories,
  getDefaultCapacityCategoryId,
} from 'modules/category/CategoryReducer';
import EventNoteThread from 'modules/eventNotes/containers/EventNoteThread';
import {
  closeCapacityDeleteAlertWindow,
  closeCapacityCancelEditsAlertWindow,
  openCapacityDeleteAlertWindow,
  openCapacityCancelEditsAlertWindow,
} from 'modules/ui/UIActions';
import {
  getCapacityDeleteAlertWindow,
  getCapacityEditsAlertWindow,
  getCurrentWellId,
  getWasDragging,
  getNoteImageDetailsOverlay,
  getIsEventConversationOpen,
} from 'modules/ui/UIReducer';
import useRightPanel from 'modules/ui/hooks/useRightPanel';

import AlertWindow from 'components/AlertWindow';
import Button from 'components/Button';
import InputCheckbox from 'components/InputCheckbox';
import CloseOnMouseClickOutside from 'components/CloseOnMouseClickOutside';
import ResizableGrid from 'components/ResizableGrid';
import { getLastWellEventsNotes } from 'modules/eventNotes/EventNotesReducer';
import { usePreventContext } from 'context/PreventContext';

import {
  createRemoteCapacity,
  deleteCapacityLocally,
  updateCapacityExtraInputsLocally,
  updateCapacityCategoryLocally,
  initUpdateCapacityDayLocally,
  updateCapacityAnyFieldLocally,
  restoreWellCapacityFromBuffer,
} from '../CapacityChangeEventActions';
import {
  getWellCapacityEventsSorted,
  getHasChanges,
} from '../CapacityChangeEventReducer';
import CapacityDateForm from '../forms/CapacityDateForm';
import DeclineParametersForm from '../forms/DeclineParametersForm';
import ModalWindow from 'components/ModalWindow';

const EditCapacityPanel = () => {
  const dispatch = useDispatch();

  const [hasUnsavedNotes, setHasUnsavedNotes] = React.useState(false);
  const capacityDeleteAlertWindow = useSelector(getCapacityDeleteAlertWindow);
  const capacityCancelEditsAlertWindow = useSelector(
    getCapacityEditsAlertWindow,
  );
  const capacityCategories = useSelector(getCapacityCategories);
  const defaultCapacityCategoryId = useSelector(getDefaultCapacityCategoryId);
  const currentWellId = useSelector(getCurrentWellId);
  const capacityEventsSortedByDate = useSelector(state =>
    getWellCapacityEventsSorted(state, { wellId: currentWellId }),
  );
  const hasChanges = useSelector(state => getHasChanges(state, currentWellId));
  const permissions = useSelector(getPermissions);
  const { isAllowedEditCapChanges } = permissions;
  const devFeatures = useSelector(getFeatures);
  const imageDetailsOverlay = useSelector(getNoteImageDetailsOverlay);
  const chartWasDragging = useSelector(getWasDragging);
  const isEventConversationOpen = useSelector(getIsEventConversationOpen);
  const prevent = usePreventContext();

  const rightPanel = useRightPanel();

  const currentEvent = React.useMemo(
    () => capacityEventsSortedByDate[rightPanel.dialogIndex] || {},
    [capacityEventsSortedByDate, rightPanel.dialogIndex],
  );

  const isShownLeftArrow = React.useMemo(
    () =>
      rightPanel.dialogIndex > -1 &&
      capacityEventsSortedByDate.length - 1 > rightPanel.dialogIndex,
    [capacityEventsSortedByDate, rightPanel.dialogIndex],
  );

  const isShownRightArrow = React.useMemo(
    () => rightPanel.dialogIndex > 0,
    [rightPanel.dialogIndex],
  );

  const eventNotes = useSelector(store =>
    getLastWellEventsNotes(store, {
      wellId: currentWellId,
      eventType: 'capacity',
    }),
  );

  const currentCategory = React.useMemo(
    () =>
      currentEvent.capacityChangeOptionId
        ? capacityCategories[currentEvent.capacityChangeOptionId]
        : capacityCategories[defaultCapacityCategoryId],
    [currentEvent, capacityCategories, defaultCapacityCategoryId],
  );

  const notesCount = React.useMemo(() => {
    if (
      R.isEmpty(currentEvent) ||
      eventNotes.length === 0 ||
      !eventNotes[currentEvent.id]
    )
      return 0;

    return R.values(eventNotes[currentEvent.id]).length;
  }, [currentEvent, eventNotes]);

  const notesSubtitle = React.useMemo(() => {
    if (notesCount === 0) return '';
    return `${notesCount} Note${notesCount === 1 ? '' : 's'}`;
  }, [notesCount]);

  const shouldDisplayExtraInputs = React.useMemo(
    () =>
      devFeatures?.customInputs &&
      currentCategory?.extraInputsType &&
      currentCategory?.extraInputsType.length !== 0,
    [devFeatures?.customInputs, currentCategory?.extraInputsType],
  );

  const onLeftArrowClick = React.useCallback(() => {
    if (isShownLeftArrow) {
      const index = rightPanel.dialogIndex + 1;
      const id = capacityEventsSortedByDate[index].id;
      const data = { id, index };
      rightPanel.setDialog({ type: 'CapacityChangeEvent', data });
    }
  }, [isShownLeftArrow, rightPanel.dialogIndex, rightPanel.setDialog]);

  const onRightArrowClick = React.useCallback(() => {
    if (isShownRightArrow) {
      const index = rightPanel.dialogIndex - 1;
      const id = capacityEventsSortedByDate[index].id;
      const data = { id, index };
      rightPanel.setDialog({ type: 'CapacityChangeEvent', data });
    }
  }, [isShownRightArrow, rightPanel.dialogIndex, capacityEventsSortedByDate]);

  const onCapacityUpdate = React.useCallback(
    values => {
      if (!isAllowedEditCapChanges) return;

      dispatch(
        updateCapacityAnyFieldLocally({
          wellId: currentWellId,
          capacityEventId: currentEvent.id,
          values,
        }),
      );
    },
    [currentEvent.id, currentWellId, dispatch, isAllowedEditCapChanges],
  );
  const onCapacityDayUpdate = React.useCallback(
    newDay => {
      if (!isAllowedEditCapChanges) return;

      dispatch(
        initUpdateCapacityDayLocally({
          wellId: currentWellId,
          capacityEventId: currentEvent.id,
          newDayInit: newDay,
        }),
      );
    },
    [currentWellId, dispatch, isAllowedEditCapChanges, currentEvent.id],
  );

  const onCapacityCategoryChoose = React.useCallback(
    ({ planType, cause, subCause }) => {
      if (!isAllowedEditCapChanges) return;
      const categories = R.values(capacityCategories);
      const category = categories.find(
        cat =>
          cat.planType === planType &&
          cat.cause === cause &&
          cat.subCause === subCause,
      );
      const categoryId = category ? category.id : null;
      dispatch(
        updateCapacityCategoryLocally({
          wellId: currentWellId,
          categoryId,
          capacityEventId: currentEvent.id,
        }),
      );
    },
    [
      capacityCategories,
      currentEvent.id,
      currentWellId,
      dispatch,
      isAllowedEditCapChanges,
    ],
  );
  const onDeleteCapacitySegment = React.useCallback(() => {
    rightPanel.unsetDialog();
    dispatch(
      deleteCapacityLocally({
        wellId: currentWellId,
        capacityEventId:
          capacityEventsSortedByDate[capacityDeleteAlertWindow.index].id,
      }),
    );
  }, [
    currentWellId,
    capacityEventsSortedByDate,
    capacityDeleteAlertWindow,
    dispatch,
    rightPanel.unsetDialog,
  ]);

  const handleDelete = React.useCallback(() => {
    if (!isAllowedEditCapChanges) return;
    const index = rightPanel.dialogIndex;
    dispatch(openCapacityDeleteAlertWindow(index));
  }, [dispatch, rightPanel.dialogIndex]);

  const onCapacityDeleteAlertWindowClose = React.useCallback(
    () => dispatch(closeCapacityDeleteAlertWindow()),
    [dispatch],
  );
  const onChangesCancel = React.useCallback(
    () => dispatch(openCapacityCancelEditsAlertWindow(rightPanel.dialogIndex)),
    [dispatch, rightPanel.dialogIndex],
  );
  const onCapacityEditsAlertWindowClose = React.useCallback(
    () => dispatch(closeCapacityCancelEditsAlertWindow()),
    [dispatch],
  );
  const onCapacityDialogClose = React.useCallback(() => {
    if (!currentEvent.id || isIdNew(currentEvent.id)) return;
    prevent.dispatchEvent(rightPanel.unsetDialog);
  }, [rightPanel.unsetDialog, currentEvent.id, prevent.dispatchEvent]);

  const onExtraInputsChange = React.useCallback(
    extraInputsData => {
      if (!currentEvent.id) return;
      dispatch(
        updateCapacityExtraInputsLocally({
          wellId: currentWellId,
          capacityEventId: currentEvent.id,
          newExtraInputsData: extraInputsData,
        }),
      );
    },
    [currentEvent.id, dispatch, currentWellId],
  );
  const onSaveClick = React.useCallback(() => {
    if (isIdNew(currentEvent.id)) {
      dispatch(createRemoteCapacity(currentEvent));
    } else {
      onCapacityDialogClose();
    }
  }, [currentEvent, dispatch, onCapacityDialogClose]);

  const onNotesChange = React.useCallback(
    notes => onCapacityUpdate({ notes }),
    [onCapacityUpdate],
  );

  const onCancelConfirm = React.useCallback(() => {
    dispatch(
      restoreWellCapacityFromBuffer({
        wellId: currentWellId,
        capacityEventId: currentEvent.id,
      }),
    );
    onCapacityEditsAlertWindowClose();
    onCapacityDialogClose();
  }, [
    dispatch,
    currentEvent.id,
    currentWellId,
    onCapacityDialogClose,
    onCapacityEditsAlertWindowClose,
  ]);

  useNonInputKeydown(
    ({ keyName }) => {
      if (!rightPanel.isDialogOfType('CapacityChangeEvent')) return;
      if (imageDetailsOverlay.show) return;
      if (keyName === 'ESCAPE') return onCapacityDialogClose();
      if (keyName === 'DELETE') return handleDelete();
      if (keyName === 'RIGHT') return onRightArrowClick();
      if (keyName === 'LEFT') return onLeftArrowClick();
    },
    [
      imageDetailsOverlay.show,
      rightPanel.isDialogOfType,
      onCapacityDialogClose,
      onLeftArrowClick,
      onRightArrowClick,
    ],
  );

  const onisLockSlopeCapacityLine = React.useCallback(
    e => {
      onCapacityUpdate({ isLockSlopeCapacityLine: e.target.checked });
    },
    [onCapacityUpdate],
  );

  const handleClickOutside = React.useCallback(() => {
    if (document.activeElement?.tagName !== 'TEXTAREA' && !chartWasDragging) {
      onCapacityDialogClose();
    }
  }, [chartWasDragging, onCapacityDialogClose]);

  const onNoteSaveStateChange = React.useCallback(
    (state: boolean) => {
      setHasUnsavedNotes(state);
    },
    [setHasUnsavedNotes],
  );

  React.useEffect(() => {
    if (
      !prevent.event ||
      hasUnsavedNotes ||
      !rightPanel.isDialogOfType('CapacityChangeEvent')
    )
      return;

    prevent.event.proceed();
  }, [prevent.event, rightPanel.isDialogOfType]);

  if (!rightPanel.isDialogOfType('CapacityChangeEvent')) return null;

  return (
    <RightPanel
      onDialogClose={onSaveClick}
      title="Capacity Change Details"
      isShown={Boolean(rightPanel.dialog)}
      isShownLeftArrow={isShownLeftArrow}
      isShownRightArrow={isShownRightArrow}
      onLeftArrowClick={onLeftArrowClick}
      onRightArrowClick={onRightArrowClick}
    >
      {!R.isEmpty(currentEvent) && (
        <CloseOnMouseClickOutside
          exceptForClassName="capacity-interactive"
          closeHandler={handleClickOutside}
          event="mouseup"
        >
          <ResizableGrid
            initialTemplate={
              isEventConversationOpen
                ? ['32px', 'calc(100% - 32px)']
                : shouldDisplayExtraInputs
                ? ['472px', 'calc(100% - 472px)']
                : ['400px', 'calc(100% - 400px)']
            }
          >
            <ResizableGrid.Cell title="Event Inputs" headerBorders="bottom">
              <EditCapacityPanel.EventInputsContainer>
                <EditCapacityPanel.EventInputSection>
                  <EditCapacityPanel.EventInputContent>
                    <CategoryForm
                      disabled={!isAllowedEditCapChanges}
                      categories={capacityCategories}
                      currentCategory={currentCategory}
                      onCategoryChoose={category =>
                        onCapacityCategoryChoose(category)
                      }
                    />

                    <CapacityDateForm
                      capacityEventsSorted={capacityEventsSortedByDate}
                      disabled={!isAllowedEditCapChanges}
                      pointIndex={rightPanel.dialogIndex}
                      capacityEvent={currentEvent}
                      onCapacityDayUpdate={onCapacityDayUpdate}
                    />
                  </EditCapacityPanel.EventInputContent>
                </EditCapacityPanel.EventInputSection>

                <EditCapacityPanel.EventInputSection style={{ marginTop: 25 }}>
                  <EditCapacityPanel.EventInputTitle>
                    Decline Parameters
                  </EditCapacityPanel.EventInputTitle>

                  <DeclineParametersForm
                    disabled={!isAllowedEditCapChanges}
                    capacityEvent={currentEvent}
                    onCapacityUpdate={onCapacityUpdate}
                  />

                  <EditCapacityPanel.ActionsWrapper>
                    <InputCheckbox
                      marginTop={10}
                      marginBottom={10}
                      label="Lock Decline and B Factor"
                      checked={currentEvent.isLockSlopeCapacityLine}
                      onChange={e => onisLockSlopeCapacityLine(e)}
                    />

                    <EditCapacityPanel.BtnWrapper>
                      {hasChanges && (
                        <Button
                          width={80}
                          height={30}
                          onClick={onChangesCancel}
                          className="capacity-interactive interactive"
                        >
                          Cancel
                        </Button>
                      )}

                      <Button
                        width={80}
                        height={30}
                        variant="critical"
                        onClick={handleDelete}
                        disabled={!isAllowedEditCapChanges}
                        className="capacity-interactive interactive"
                      >
                        Delete
                      </Button>
                    </EditCapacityPanel.BtnWrapper>
                  </EditCapacityPanel.ActionsWrapper>
                </EditCapacityPanel.EventInputSection>

                {shouldDisplayExtraInputs && (
                  <EditCapacityPanel.EventInputSection
                    style={{ marginTop: 17 }}
                  >
                    <EditCapacityPanel.EventInputTitle>
                      Event Attributes
                    </EditCapacityPanel.EventInputTitle>

                    <NotesForm
                      currentCategory={currentCategory}
                      currentItem={currentEvent}
                      devFeatures={devFeatures}
                      disabled={!isAllowedEditCapChanges}
                      onExtraInputsChange={onExtraInputsChange}
                      onNotesChange={onNotesChange}
                    />
                  </EditCapacityPanel.EventInputSection>
                )}
              </EditCapacityPanel.EventInputsContainer>
            </ResizableGrid.Cell>

            <ResizableGrid.Cell title="Conversation" subtitle={notesSubtitle}>
              <EventNoteThread
                wellId={currentEvent.wellId}
                eventId={currentEvent.id}
                eventType="capacity"
                noAnyMessages={notesCount === 0}
                onNoteSaveStateChange={onNoteSaveStateChange}
              />
            </ResizableGrid.Cell>
          </ResizableGrid>
        </CloseOnMouseClickOutside>
      )}

      {capacityDeleteAlertWindow.show && (
        <AlertWindow
          handleClose={onCapacityDeleteAlertWindowClose}
          onDelete={() => {
            onDeleteCapacitySegment();
            onCapacityDeleteAlertWindowClose();
          }}
          subject="capacity change"
        />
      )}
      {capacityCancelEditsAlertWindow.show && (
        <AlertWindow
          handleClose={onCapacityEditsAlertWindowClose}
          onDelete={onCancelConfirm}
          text="Are you sure you want to discard your edits to this capacity change?"
          actionButton="Discard"
        />
      )}

      {prevent.event && hasUnsavedNotes && (
        <ModalWindow
          title="Are you sure you want to leave without saving your note?"
          close={prevent.event.cancel}
          style={{
            width: window.innerWidth - rightPanel.width - 10,
          }}
          backdropStyle={{
            width: window.innerWidth - rightPanel.width - 10,
          }}
          controls={[
            {
              text: 'Yes, discard the note',
              action: prevent.event.proceed,
              style: {
                width: 150,
              },
              type: 'danger',
            },
            {
              text: 'No, continue editing',
              action: prevent.event.cancel,
              style: {
                width: 150,
              },
            },
          ]}
        />
      )}
    </RightPanel>
  );
};

EditCapacityPanel.EventInputsContainer = styled.div`
  padding: 10px 14px 0 14px;
`;

EditCapacityPanel.EventInputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

EditCapacityPanel.EventInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

EditCapacityPanel.EventInputSection = styled.div``;

EditCapacityPanel.EventInputTitle = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
`;

EditCapacityPanel.ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

EditCapacityPanel.BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export default EditCapacityPanel;
