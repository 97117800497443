import * as R from 'ramda';
import {
  VarianceDrilldownTableItem,
  ASC,
  VAR_SORTING_ORDER,
} from 'modules/drilldownTable/models/drilldownTable';

import naturalSorting from './naturalSorting';

const sortTable = (
  sortCriteria: string,
  sortDirection: string,
  sortVarDirectionIndex: number,
  phaseKey: string,
  table: VarianceDrilldownTableItem[],
): VarianceDrilldownTableItem[] => {
  if (sortCriteria === 'variance') {
    const variancePhaseKey = phaseKey + 'Variance';
    const sortParams = VAR_SORTING_ORDER[sortVarDirectionIndex];
    const getSortableValue = listItem =>
      sortParams.abs
        ? Math.abs(listItem[variancePhaseKey])
        : listItem[variancePhaseKey];
    if (sortParams.direction === ASC) {
      return table.sort((a, b) => {
        if (getSortableValue(a) > getSortableValue(b)) {
          return 1;
        }
        if (getSortableValue(a) < getSortableValue(b)) {
          return -1;
        }
        return 0;
      });
    }
    return table.sort((a, b) => {
      if (getSortableValue(a) > getSortableValue(b)) return -1;
      if (getSortableValue(a) < getSortableValue(b)) return 1;
      return 0;
    });
  }

  const key =
    sortCriteria === 'capacity' || sortCriteria === 'actual'
      ? phaseKey + sortCriteria[0].toUpperCase() + sortCriteria.slice(1)
      : sortCriteria;
  if (key === 'well') {
    if (sortDirection === ASC) {
      return table.sort((a, b) =>
        naturalSorting(
          R.pathOr('', [key], a).toLowerCase(),
          R.pathOr('', [key], b).toLowerCase(),
        ),
      );
    }
    return table.sort(
      (a, b) =>
        0 -
        naturalSorting(
          R.pathOr('', [key], a).toLowerCase(),
          R.pathOr('', [key], b).toLowerCase(),
        ),
    );
  }
  if (sortDirection === ASC) {
    return table.sort((a, b) => {
      if (a[key] > b[key]) return 1;
      if (a[key] < b[key]) return -1;
      return 0;
    });
  }

  return table.sort((a, b) => {
    if (a[key] > b[key]) return -1;
    if (a[key] < b[key]) return 1;
    return 0;
  });
};

export default sortTable;
