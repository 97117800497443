import { area, curveStepAfter } from 'd3-shape';
import { utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import { Series, SeriesMapping } from '../models';

interface SeriesBarsProps {
  data: Series[];
  currentSeriesMapping: SeriesMapping;
  customColor: string | undefined;
  xScale: any;
  yScale: any;
}

const SeriesBars = ({
  data,
  currentSeriesMapping,
  customColor,
  xScale,
  yScale,
}: SeriesBarsProps) => {
  const targetDataKey = `series${currentSeriesMapping.seriesIndex}`;
  const lastRealDate = R.pathOr(new Date(), [data.length - 1, 'day'], data);
  const lastDayData = R.pathOr({}, [data.length - 1], data);
  const newDate = utcDay.offset(lastRealDate, 1);
  const dataWithEmptyLastDay = [...data, { ...lastDayData, day: newDate }];
  const createArea: (data: any) => any = React.useMemo(
    () =>
      area()
        .x((d: any) => xScale(utcDay(new Date(d.day)) || 0))
        .y1(d => yScale(d[targetDataKey] || 0))
        .y0(yScale(0))
        .curve(curveStepAfter),
    [xScale, yScale, targetDataKey],
  );
  return (
    <path
      className="panInteraction"
      fill={customColor || currentSeriesMapping.color}
      d={createArea(dataWithEmptyLastDay)}
    />
  );
};

export default React.memo<SeriesBarsProps>(SeriesBars);
