import * as React from 'react';
import styled from 'styled-components';

import { SortIndicator } from 'components/Icons';

interface VarianceHeaderRowProps {
  className: string;
  firstColDataKey: string;
  style: { [prop: string]: string };
  secondColDataKey: string;
  sort: (sortTarget: string) => void;
  sortBy: string;
  sortDirection: string;
  wide: boolean;
}

const VarianceHeaderRow = ({
  className,
  firstColDataKey,
  style,
  secondColDataKey,
  sort,
  sortBy,
  sortDirection,
  wide,
}: VarianceHeaderRowProps) => (
  <div className={className} role="row" style={style}>
    <VarianceHeaderRow.FirstCol onClick={() => sort(firstColDataKey)}>
      Variance Category
      {sortBy === firstColDataKey && (
        <SortIndicator sortDirection={sortDirection} />
      )}
    </VarianceHeaderRow.FirstCol>
    <VarianceHeaderRow.SecondCol
      onClick={() => sort(secondColDataKey)}
      wide={wide}
    >
      Var.
      {sortBy === secondColDataKey && (
        <VarianceHeaderRow.SortIndicatorWrapper leftOffset={wide ? '45' : '40'}>
          <SortIndicator sortDirection={sortDirection} />
        </VarianceHeaderRow.SortIndicatorWrapper>
      )}
    </VarianceHeaderRow.SecondCol>
  </div>
);

VarianceHeaderRow.FirstCol = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  width: 189px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 5px;
  padding-right: 2px;
  cursor: pointer;
  flex: 1 1 200px;
  line-height: 1.3em;
`;

VarianceHeaderRow.SecondCol = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-transform: none;
  flex: 0 0 ${(props: Record<string, any>) => (props.wide ? '70' : '55')}px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid #c1c1c1;
  padding-right: 2px;
  margin-right: 5px;
  padding-left: 5px;
  cursor: pointer;
  line-height: 1.3em;
  position: relative;
`;

VarianceHeaderRow.SortIndicatorWrapper = styled.div`
  position: absolute;
  left: ${(props: Record<string, any>) =>
    props.leftOffset ? props.leftOffset + 'px' : '40px'};
  top: 2px;
`;

export default VarianceHeaderRow;
