import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { all, put, takeLatest, select, take } from 'redux-saga/effects';
import { getGraphqlPayload } from 'store/helpers';

import { SeriesMapping } from 'modules/series/models';
import { FETCH_SERIES_MAPPING } from 'modules/series/SeriesActions';
import { getSelectedRibbons } from 'modules/ui/UIReducer';

import {
  ADD_OPTIONS,
  SWITCH_CAV_OPTION_IN_TOP_CONTROLS,
  ADD_OPTIONS_GROUP,
  ADD_OPTION_TO_GROUP,
  addAvailableOptions,
  addOptions,
  REMOVE_OPTIONS_GROUP,
  removeAvailableOption,
  setInitialState,
  setAllCoreSeriesOption,
  setAvailableCoreSeries,
  setCoreSeriesOption,
  SET_DATA_SERIES_FROM_ROUTE,
  setNewGroupOrder,
  setAvailableOptions,
  switchOptionsVisible,
  setCustomColor,
  switchChartType,
  setCoreSeriesFromRoute,
  SET_CORESERIES_FROM_ROUTE,
  setCoreSeries,
  resetDataSeries,
} from './ChartOptionsActions';
import {
  getAllDataSeriesOptions,
  getCoreSeriesChartOption,
} from './ChartOptionsReducer';
import {
  AvailableDataSeries,
  coreSeries,
  ListChartOptions,
  STEP_LINE_CHART,
} from './models';
import {
  setCurrentSeriesConfiguration,
  SET_CURRENT_SERIES_LAYOUT,
} from 'modules/seriesLayouts/SeriesLayoutsActions';
import {
  decodeCoreSeries,
  decodeDataSeries,
  parseRibbons,
} from 'modules/router/utils/router';
import { setRibbons, SET_RIBBONS, SWITCH_RIBBONS } from 'modules/ui/UIActions';
import { getCurrentLayout } from 'modules/seriesLayouts/SeriesLayoutsReducer';

export interface ChartOption {
  id: string;
  title: string;
  isShow: boolean;
  chartType?: string;
}

function* addSereisToOptionsSaga(action): Generator<any, any, any> {
  const coreSeriesChartOption = yield select(getCoreSeriesChartOption);
  if (coreSeriesChartOption.length !== R.values(coreSeries).length) {
    yield put(setInitialState());
  }

  const seriesMapping: SeriesMapping[] = getGraphqlPayload(action);
  const existingOptions: ListChartOptions = yield select(
    getAllDataSeriesOptions,
    null,
  );
  const existingSeriesOptions = Object.keys(existingOptions).reduce(
    (acc, key) => {
      acc[key] = existingOptions[key];
      return acc;
    },
    {} as ListChartOptions,
  );
  const options = seriesMapping.reduce((acc, series) => {
    acc[series.id] = {
      id: series.id,
      title: series.displayName + ', ' + series.units,
      isShow: false,
      chartType: STEP_LINE_CHART,
      units: series.units,
      color: series.color,
    };

    return acc;
  }, {} as ListChartOptions);
  const availableOptions = seriesMapping.map(
    (series: SeriesMapping): AvailableDataSeries => ({
      id: series.id,
      title: series.displayName + ', ' + series.units,
    }),
  );
  const optionsKeys = Object.keys(existingSeriesOptions);
  if (
    optionsKeys.length !== seriesMapping.length ||
    (!R.path([optionsKeys[0], 'units'], existingSeriesOptions) &&
      !R.isEmpty(existingSeriesOptions))
  ) {
    yield put(resetDataSeries());
    yield put(addOptions(options));
    yield put(addAvailableOptions(availableOptions));

    return;
  }
  const isNotSame = seriesMapping.reduce((acc, series) => {
    const existingSeries = existingSeriesOptions[series.id];
    if (
      !existingSeries ||
      existingSeries.title !== `${series.displayName}, ${series.units}`
    ) {
      return true;
    }

    return acc;
  }, false);

  if (isNotSame) {
    yield put(resetDataSeries());
    yield put(addOptions(options));
    yield put(addAvailableOptions(availableOptions));
  }
}

function* removeAvailableoptionSaga(action): Generator<any, any, any> {
  const {
    payload: { optionId },
  } = action;
  yield put(removeAvailableOption({ optionId }));
}
function* removeGroupSaga(action): Generator<any, any, any> {
  const { payload } = action;
  const options: ListChartOptions = yield select(getAllDataSeriesOptions, null);
  const newAvailabelOptons = payload.options.map(id => ({
    id,
    title: options[id].title,
  }));
  yield put(addAvailableOptions(newAvailabelOptons));
}

function* addGroupThroughTopControlsSaga(action): Generator<any, any, any> {
  const {
    payload: { optionId, checked },
  } = action;
  if (optionId === 'all') {
    yield put(setAllCoreSeriesOption({ isShow: checked }));
    return;
  }
  yield put(
    setAvailableCoreSeries({ optionId: optionId, isAvailable: checked }),
  );
  yield put(setCoreSeriesOption({ optionId: optionId, isShow: !checked }));
}

function* setCustomColorAndChartTypeForDataSeries(groups) {
  yield all(
    groups.reduce((acc, group) => {
      group.forEach(({ id, customColor, chartType }) => {
        acc.push(
          put(
            setCustomColor({
              color: customColor ? '#' + customColor : '',
              id,
            }),
          ),
        );
        acc.push(put(switchChartType({ optionId: id, type: chartType })));
      });
      return acc;
    }, []),
  );
}

function* setDataSeriesFromRouteSeries(action): Generator<any, any, any> {
  const {
    payload: { dataSeries },
  } = action;
  let existingOptions: ListChartOptions = yield select(getAllDataSeriesOptions);
  if (R.isEmpty(existingOptions)) {
    yield take(ADD_OPTIONS);
    existingOptions = yield select(getAllDataSeriesOptions);
  }

  if (R.isEmpty(existingOptions)) {
    return;
  }
  const existingGroups: any[] = [];
  const existingDataSeries = dataSeries.reduce((acc, group) => {
    const existingGroupOptions = group.dataSeriesGroup.filter(
      option => existingOptions[option.id],
    );
    if (existingGroupOptions.length) {
      existingGroups.push(existingGroupOptions);
      return [
        ...acc,
        {
          groupId: uuid(),
          options: existingGroupOptions.map(option => option.id),
        },
      ];
    }
    return acc;
  }, []);

  const availableOptions = R.values(existingOptions).map(
    (series: ChartOption): AvailableDataSeries => ({
      id: series.id,
      title: series.title,
    }),
  );

  const unavailableOptions = existingDataSeries.reduce(
    (acc, group) => [...acc, ...group.options],
    [],
  );

  const actualAvailableOptions = availableOptions.filter(
    option => !unavailableOptions.includes(option.id),
  );
  yield put(switchOptionsVisible({ options: unavailableOptions, show: true }));
  yield put(setAvailableOptions(actualAvailableOptions));
  yield put(setNewGroupOrder(existingDataSeries));
  yield* setCustomColorAndChartTypeForDataSeries(existingGroups);
}

function* setCoreSeriesFromRouteSeries(action): Generator<any, any, any> {
  const {
    payload: { series },
  } = action;
  let existingOptions: ChartOption[] = yield select(getCoreSeriesChartOption);
  if (R.isEmpty(existingOptions)) {
    yield take(ADD_OPTIONS);
    existingOptions = yield select(getCoreSeriesChartOption);
  }

  if (R.isEmpty(existingOptions)) {
    return;
  }

  const cavSeries = R.clone(coreSeries);
  Object.keys(cavSeries).forEach(key => {
    cavSeries[key] = {
      ...cavSeries[key],
      isShow: series.includes(key),
      isAvailable: !series.includes(key),
    };
  });

  yield put(setCoreSeries({ series: cavSeries }));
}

function* setSeriesFromSeriesLayout(action): Generator<any, any, any> {
  const { configuration } = action.payload;
  const [, coreSeriesString, dataSeriesString, ribbonsString] =
    configuration.split('&');

  const coreSeries = decodeCoreSeries(coreSeriesString.split('=')[1]);
  const dataSeries = decodeDataSeries(dataSeriesString.split('=')[1]);
  const ribbons = parseRibbons((ribbonsString ?? '=').split('=')[1]) ?? [];

  yield put(setCoreSeriesFromRoute({ series: coreSeries }));
  yield* setDataSeriesFromRouteSeries({ payload: { dataSeries } });
  yield put(setRibbons(ribbons));
}

function* switchRibbonInLayoutConfiguration(action): Generator<any, any, any> {
  const selectedRibbons = yield select(getSelectedRibbons);
  const layout = yield select(getCurrentLayout);
  if (R.isEmpty(layout)) return;
  const coreSeries = layout.configuration.match(/(?<=coreseries=)[^&]+&??/);
  const dataSeries = layout.configuration.match(/(?<=dataseries=)[^&]+&??/);
  const newConfiguration: string[] = [];
  if (coreSeries) newConfiguration.push(`&coreseries=${coreSeries.join('-')}`);
  if (dataSeries) newConfiguration.push(`&dataseries=${dataSeries.join('-')}`);
  if (Object.keys(selectedRibbons).length > 0)
    newConfiguration.push(`&ribbons=${Object.keys(selectedRibbons).join('-')}`);

  yield put(
    setCurrentSeriesConfiguration({ configuration: newConfiguration.join('') }),
  );
}

function* chartOptionsSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(`${FETCH_SERIES_MAPPING}_SUCCESS`, addSereisToOptionsSaga),
    takeLatest(
      [ADD_OPTION_TO_GROUP, ADD_OPTIONS_GROUP],
      removeAvailableoptionSaga,
    ),
    takeLatest(SET_DATA_SERIES_FROM_ROUTE, setDataSeriesFromRouteSeries),
    takeLatest(SET_CORESERIES_FROM_ROUTE, setCoreSeriesFromRouteSeries),
    takeLatest(REMOVE_OPTIONS_GROUP, removeGroupSaga),
    takeLatest(
      SWITCH_CAV_OPTION_IN_TOP_CONTROLS,
      addGroupThroughTopControlsSaga,
    ),
    takeLatest(SET_CURRENT_SERIES_LAYOUT, setSeriesFromSeriesLayout),
    takeLatest(
      [SWITCH_RIBBONS, SET_RIBBONS],
      switchRibbonInLayoutConfiguration,
    ),
  ]);
}

export default chartOptionsSagas;
