import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import type { Category } from 'modules/category/models/category';
import type { TooltipData } from 'modules/ui/models/ui';

import DataSeriesInformation from './DataSeriesInformation';
import TrellisInformation from './TrellisInformation';
import RibbonInformation from './RibbonInformation';

interface InformatioinTooltipProps {
  isDisplayingForecast: boolean;
  tooltipData: TooltipData;
  varianceCategories?: { [id: string]: Category };
  forGroupChart: boolean;
}

const InformationTooltip = ({
  isDisplayingForecast,
  tooltipData,
  varianceCategories,
  forGroupChart,
}: InformatioinTooltipProps) => {
  const clientY =
    R.pathOr(false, ['trellisTooltipData', 'clientY'], tooltipData) ||
    R.pathOr(0, ['ribbonTooltipData', 'clientY'], tooltipData) ||
    R.pathOr(0, ['dataSeriesTooltipData', 'clientY'], tooltipData);

  const clientX =
    R.pathOr(false, ['trellisTooltipData', 'clientX'], tooltipData) ||
    R.pathOr(0, ['ribbonTooltipData', 'clientX'], tooltipData) ||
    R.pathOr(0, ['dataSeriesTooltipData', 'clientX'], tooltipData);

  const yPos = clientY - 5;
  const transform = `translate(${clientX + 15}px, calc(${yPos}px - 100%))`;

  return (
    <InformationTooltip.Tooltip style={{ transform }}>
      {tooltipData.trellisTooltipData && (
        <TrellisInformation
          isDisplayingForecast={isDisplayingForecast}
          tooltipData={tooltipData.trellisTooltipData}
          varianceCategories={varianceCategories}
          forGroupChart={forGroupChart}
        />
      )}
      {tooltipData.ribbonTooltipData && (
        <RibbonInformation tooltipData={tooltipData.ribbonTooltipData} />
      )}
      {tooltipData.dataSeriesTooltipData && (
        <DataSeriesInformation
          tooltipData={tooltipData.dataSeriesTooltipData}
        />
      )}
    </InformationTooltip.Tooltip>
  );
};

InformationTooltip.Tooltip = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  min-width: 90px;
  background: white;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  justify-content: start | end;
  gap: 0 7px;
  z-index: 700;
`;

export default InformationTooltip;
