import { utcHour } from 'd3-time';
import * as R from 'ramda';
import {
  all,
  delay,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';

import { isIdNew } from 'helpers';
import { getAppConfig } from 'modules/appConfig/AppConfigReducer';
import { getDefaultCapacityCategoryId } from 'modules/category/CategoryReducer';
import { getProduction } from 'modules/production/ProductionReducer';
import {
  SET_CURRENT_WELL_ID,
  setRightPanelDialog,
  CLOSE_CAPACITY_DIALOG,
} from 'modules/ui/UIActions';
import { getCurrentWellId, getRightPanelDialogs } from 'modules/ui/UIReducer';
import {
  CREATE_CAPACITY_LOCALLY,
  DELETE_CAPACITY_LOCALLY,
  INIT_UPDATE_CAPACITY_DAY_LOCALLY,
  RESTORE_WELL_CAPACITY_FROM_BUFFER,
  UPDATE_CAPACITY_DAY_LOCALLY,
  UPDATE_CAPACITY_DECLINE_DIRECTLY_LOCALLY,
  UPDATE_CAPACITY_DECLINE_INDIRECTLY_LOCALLY,
  UPDATE_CAPACITY_RATE_LOCALLY,
  UPDATE_CAPACITY_BFACTOR_DIRECTLY_LOCALLY,
  UPDATE_CAPACITY_BFACTOR_INDIRECTLY_LOCALLY,
  UPDATE_CAPACITY_CATEGORY_LOCALLY,
  UPDATE_CAPACITY_EXTRA_INPUTS_LOCALLY,
  UPDATE_CAPACITY_ANY_FIELD_LOCALLY,
  updateCapacityDayLocally,
  updateRemoteCapacity,
  deleteRemoteCapacity,
  populateCapacityAfterCreating,
  fetchWellCapacity,
  createRemoteCapacity,
  CREATE_REMOTE_CAPACITY,
  FETCH_WELL_CAPACITY,
  SET_CAPACITY,
} from './CapacityChangeEventActions';
import {
  getWellCapacityEventsSorted,
  getWellCapacityEventsIndexedById,
  getCapacityChangeEvent,
  getIdHistory,
} from './CapacityChangeEventReducer';
import { generateNewCapacityEvent, countCapRateDecline } from './utils';
import { getGraphqlPayload } from 'store/helpers';
import { updateRouteAfterCreatingEvent } from 'modules/router/RouterActions';
import { parseSearchParams } from 'modules/router/utils/router';
import { EventPanel } from 'modules/router/models/router';
import { OPEN_CAPACITY_CONVERSATION } from 'modules/inboxConversation/InboxConversationActions';

function* calculateNewCapacityEventSaga(action): Generator<any, any, any> {
  const { wellId, date } = action.payload;
  const oldCapacity = yield select(getWellCapacityEventsSorted, { wellId });
  const config = yield select(getAppConfig);
  const { today } = config;

  const capacityWithSameDateExists =
    oldCapacity.findIndex(
      capacityEvent => capacityEvent.dayInit.getTime() === date.getTime(),
    ) !== -1;
  if (capacityWithSameDateExists) return;

  const production = yield select(getProduction);
  const defaultCategoryId = yield select(getDefaultCapacityCategoryId, null);

  const newCapacityEvent = generateNewCapacityEvent(
    wellId,
    oldCapacity,
    date,
    production,
    defaultCategoryId,
    today,
  );

  yield put(populateCapacityAfterCreating({ wellId, newCapacityEvent }));

  const newCapEvents = yield select(getWellCapacityEventsSorted, { wellId });
  const newEventIndex = newCapEvents.findIndex(capEvent =>
    isIdNew(capEvent.id),
  );

  yield put(
    setRightPanelDialog({
      type: 'CapacityChangeEvent',
      data: { id: newCapEvents[newEventIndex].id, index: newEventIndex },
    }),
  );
  yield put(createRemoteCapacity(R.omit(['id', 'syncing'], newCapacityEvent)));
}

function* fetchCapacityForCurrentWellSaga(): Generator<any, any, any> {
  const currentWellId = yield select(getCurrentWellId);

  yield put(fetchWellCapacity(currentWellId));
}

function* deleteCapacityEventRemotelySaga(action): Generator<any, any, any> {
  const { capacityEventId } = action.payload;
  if (!isIdNew(capacityEventId)) {
    yield put(deleteRemoteCapacity(capacityEventId));
  }
}

function* modifyCapacityEventDaySaga(action): Generator<any, any, any> {
  const { wellId, capacityEventId, newDayInit } = action.payload;
  const wellCapacity = yield select(getWellCapacityEventsIndexedById, {
    wellId,
  });
  const idHistory = yield select(getIdHistory);
  const newId = idHistory[capacityEventId];

  const sourcePoint = R.pathOr(
    R.pathOr({}, [newId], idHistory),
    [capacityEventId],
    wellCapacity,
  );
  if (R.isEmpty(sourcePoint)) {
    return;
  }
  if (sourcePoint.isLockSlopeCapacityLine) {
    yield put(
      updateCapacityDayLocally({
        wellId,
        capacityEventId: sourcePoint.id,
        modifiedCapacityEvent: {
          ...sourcePoint,
          dayInit: newDayInit,
        },
      }),
    );
    return;
  }

  const daysElapsed = utcHour.count(sourcePoint.dayInit, newDayInit) / 24;

  const oilRateDecline = countCapRateDecline({
    daysElapsed,
    rateInit: sourcePoint.oilRateInit,
    declineInitDailyNom: sourcePoint.oilDeclineInitDailyNom,
    bFactor: sourcePoint.oilBFactor,
    defaultRate: 0,
  });
  const gasRateDecline = countCapRateDecline({
    daysElapsed,
    rateInit: sourcePoint.gasRateInit,
    declineInitDailyNom: sourcePoint.gasDeclineInitDailyNom,
    bFactor: sourcePoint.gasBFactor,
    defaultRate: 0,
  });
  const waterRateDecline = countCapRateDecline({
    daysElapsed,
    rateInit: sourcePoint.waterRateInit,
    declineInitDailyNom: sourcePoint.waterDeclineInitDailyNom,
    bFactor: sourcePoint.waterBFactor,
    defaultRate: 0,
  });

  yield put(
    updateCapacityDayLocally({
      wellId,
      capacityEventId: sourcePoint.id,
      modifiedCapacityEvent: {
        ...sourcePoint,
        dayInit: newDayInit,
        oilRateInit: oilRateDecline.rateInit,
        oilDeclineInitDailyNom: oilRateDecline.declineInitDailyNom,
        gasRateInit: gasRateDecline.rateInit,
        gasDeclineInitDailyNom: gasRateDecline.declineInitDailyNom,
        waterRateInit: waterRateDecline.rateInit,
        waterDeclineInitDailyNom: waterRateDecline.declineInitDailyNom,
      },
    }),
  );
}

function* initSyncCapacityWithApiSaga(action): Generator<any, any, any> {
  const { capacityEventId, wellId, modifiedCapacityEvent } = action.payload;
  if (
    isIdNew(capacityEventId) ||
    (!capacityEventId && !modifiedCapacityEvent.id)
  ) {
    return;
  }

  yield delay(700);

  const capacityEvent = yield select(getCapacityChangeEvent, {
    wellId,
    capacityEventId: capacityEventId || modifiedCapacityEvent.id,
  });

  yield put(updateRemoteCapacity(capacityEvent));
}

function* updateRouteWithEventPanelAfterCreatingSaga(
  action,
): Generator<any, any, any> {
  const { CapacityChangeEvent } = yield select(getRightPanelDialogs);
  const oldId = CapacityChangeEvent?.id;

  const newCapacityEvent = getGraphqlPayload(action);
  const newId = newCapacityEvent.id;

  if (!isIdNew(oldId)) return;
  yield put(updateRouteAfterCreatingEvent({ oldId, newId }));
}

function* openCapacityPanelFromRouteSaga(action): Generator<any, any, any> {
  const events = getGraphqlPayload(action);
  const searchParams = parseSearchParams(window.location.search);
  const currentWellId = yield select(getCurrentWellId);

  if (
    !searchParams.eventPanel ||
    searchParams.eventPanel.type !== EventPanel.capacity ||
    R.isEmpty(events)
  )
    return;

  const { id } = searchParams.eventPanel;
  const eventIndex = events
    .map(e => ({ date: Date.parse(e.dayInit), id: e.id, wellId: e.wellId }))
    .sort((a, b) => b.date - a.date)
    .findIndex(e => e.id === id && e.wellId === currentWellId);

  if (eventIndex < 0) {
    return yield put({ type: CLOSE_CAPACITY_DIALOG });
  }

  yield put(
    setRightPanelDialog({
      type: 'CapacityChangeEvent',
      data: { index: eventIndex, id },
    }),
  );
}

function* openCapacityConversationSaga(action): Generator<any, any, any> {
  const { id, wellId } = action.payload;
  const events = yield select(getWellCapacityEventsSorted, {
    wellId,
  });
  const index = events.findIndex(e => e.id === id);

  yield put(
    setRightPanelDialog({
      type: 'CapacityChangeEvent',
      data: { id, index: Math.max(0, index) },
    }),
  );
}

function* capacityChangeEventSaga(): Generator<any, any, any> {
  yield all([
    takeLatest(CREATE_CAPACITY_LOCALLY, calculateNewCapacityEventSaga),
    takeLatest(SET_CURRENT_WELL_ID, fetchCapacityForCurrentWellSaga),
    takeLatest(DELETE_CAPACITY_LOCALLY, deleteCapacityEventRemotelySaga),
    takeEvery(INIT_UPDATE_CAPACITY_DAY_LOCALLY, modifyCapacityEventDaySaga),
    takeLatest(
      `${CREATE_REMOTE_CAPACITY}_SUCCESS`,
      updateRouteWithEventPanelAfterCreatingSaga,
    ),
    takeLatest(
      `${FETCH_WELL_CAPACITY}_SUCCESS`,
      openCapacityPanelFromRouteSaga,
    ),
    takeLatest(OPEN_CAPACITY_CONVERSATION, openCapacityConversationSaga),
    takeLatest(
      [
        SET_CAPACITY,
        UPDATE_CAPACITY_DAY_LOCALLY,
        UPDATE_CAPACITY_DECLINE_DIRECTLY_LOCALLY,
        UPDATE_CAPACITY_DECLINE_INDIRECTLY_LOCALLY,
        UPDATE_CAPACITY_RATE_LOCALLY,
        UPDATE_CAPACITY_BFACTOR_DIRECTLY_LOCALLY,
        UPDATE_CAPACITY_BFACTOR_INDIRECTLY_LOCALLY,
        UPDATE_CAPACITY_CATEGORY_LOCALLY,
        UPDATE_CAPACITY_EXTRA_INPUTS_LOCALLY,
        UPDATE_CAPACITY_ANY_FIELD_LOCALLY,
        RESTORE_WELL_CAPACITY_FROM_BUFFER,
      ],
      initSyncCapacityWithApiSaga,
    ),
  ]);
}

export default capacityChangeEventSaga;
