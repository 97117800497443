import * as React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import useStorage from 'hooks/useStorage';
import { useNonInputKeydown } from 'hooks/useKeydown';
import CloseOnMouseClickOutside from 'components/CloseOnMouseClickOutside';
import SeriesLayoutsSelect from 'modules/seriesLayouts/containers/SeriesLayoutsSelect';
import RightPanel from 'modules/dashboard/components/RightPanel';
import { getRibbons } from 'modules/ribbon/RibbonReducer';
import { getSeriesMapping } from 'modules/series/SeriesReducer';
import { switchRibbons } from 'modules/ui/UIActions';
import { getSelectedRibbons, getWasDragging } from 'modules/ui/UIReducer';
import useRightPanel from 'modules/ui/hooks/useRightPanel';

import {
  addAvailableOptions,
  addOptionsGroup,
  addOptionToGroup,
  removeOptionFromGroup,
  removeOptionsGroup,
  setAvailableCoreSeries,
  setCustomColor,
  setNewGroupOrder,
  switchChartType,
  switchOptionVisible,
  setNewOptionsOrder,
  setCoreSeriesOption,
} from '../ChartOptionsActions';
import {
  getAvailableCoreSeriesOption,
  getAvailableDataSeriesOptions,
  getAllDataSeriesOptions,
  getDataSeriesOptionsGroups,
  getSelectedCoreSeriesOptions,
} from '../ChartOptionsReducer';
import RibbonMenu from '../components/RibbonMenu';
import DragArea from '../components/DragAreea';
import { ChartOptionsGroup, PANEL_SIZES } from '../models';
import CavSeriesMenu from '../components/CavSeriesMenu';
import AvailableOtherSeriesMenu from '../components/AvailableOtherSeriesMenu';
import { CommonScrollbar } from 'components/CommonScrollbar';

const ChartOptionsPanel = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const chartWasDragging = useSelector(getWasDragging);
  const ribbons = useSelector(getRibbons);
  const selectedRibbons = useSelector(getSelectedRibbons);
  const availableDataSeries = useSelector(getAvailableDataSeriesOptions);
  const availableCoreSeries = useSelector(getAvailableCoreSeriesOption);
  const chartOptions = useSelector(getAllDataSeriesOptions);
  const chartOptionsGroups = useSelector(getDataSeriesOptionsGroups);
  const seriesMapping = useSelector(getSeriesMapping);

  const coreSeriesOptionsGroup = useSelector(getSelectedCoreSeriesOptions);
  const onCreateNewGroup = React.useCallback(
    (data: { optionId: string; newGroupIndex?: number; groupId?: string }) => {
      const { optionId, newGroupIndex, groupId } = data;
      if (groupId) {
        dispatch(removeOptionFromGroup({ groupId, optionId: optionId }));
      }
      dispatch(addOptionsGroup({ optionId, newGroupIndex }));
    },
    [dispatch],
  );
  const [availableSeriesHeight, setAvailableSeriesHeight] = useStorage(
    'availableSeriesHeight',
    availableDataSeries.length
      ? availableDataSeries.length * PANEL_SIZES.optionHeight +
          PANEL_SIZES.labelHeight
      : PANEL_SIZES.minAvailableAreaHeight,
    'session',
  );

  const [viewportHeight, setViewportHeight] = React.useState(
    window.innerHeight,
  );

  const ribbonsContainerRef = React.useRef<HTMLElement>(null);
  const ribbonsContainerHeight = ribbonsContainerRef.current
    ? ribbonsContainerRef.current.getBoundingClientRect().height
    : PANEL_SIZES.minRibbonsAreaHeight;

  const selectedSeriesAreaHeight = React.useMemo(() => {
    const height =
      viewportHeight -
      //padding
      5 * 2 -
      ribbonsContainerHeight -
      availableSeriesHeight -
      PANEL_SIZES.titleBlock -
      50 -
      PANEL_SIZES.dragArea;
    return Math.max(PANEL_SIZES.minSelectedAreaHeight, height);
  }, [viewportHeight, availableSeriesHeight, ribbonsContainerHeight]);

  const getNewAvailableSeriesAreaHeight = React.useCallback(
    (mouseYPos: number): number =>
      viewportHeight - mouseYPos - ribbonsContainerHeight - 25,
    [viewportHeight, ribbonsContainerHeight],
  );

  const onSetNewOptionsOrder = React.useCallback(
    (option: ChartOptionsGroup) => {
      dispatch(setNewOptionsOrder(option));
    },
    [dispatch],
  );

  const onSetNewGroupOrder = React.useCallback(
    (groups: ChartOptionsGroup[]) => {
      dispatch(setNewGroupOrder(groups));
    },
    [dispatch],
  );

  const onSwitchChartType = React.useCallback(
    (optionId: string, type: string) => {
      dispatch(switchChartType({ optionId, type }));
    },
    [dispatch],
  );
  const onSwitchOptionVisibility = React.useCallback(
    (optionId: string) => {
      dispatch(switchOptionVisible({ optionId }));
    },
    [dispatch],
  );

  const onAddOptionToGroup = React.useCallback(
    (data: {
      groupId: string;
      optionId: string;
      newOptionIndex: number;
      previousGroupId?: string;
    }) => {
      const { groupId, optionId, newOptionIndex, previousGroupId } = data;
      if (previousGroupId) {
        dispatch(removeOptionFromGroup({ groupId: previousGroupId, optionId }));
      }
      dispatch(addOptionToGroup({ groupId, optionId, newOptionIndex }));
    },
    [dispatch],
  );

  const onRibbonCheck = React.useCallback(
    data => {
      dispatch(switchRibbons(data));
      rightPanel.unsetDialogOfType('RibbonEvent');
    },
    [dispatch, rightPanel.unsetDialogOfType],
  );
  const onRemoveGroup = React.useCallback(
    group => {
      dispatch(removeOptionsGroup(group));
    },
    [dispatch],
  );

  const onAddOptionToAvailable = React.useCallback(
    (groupId, option) => {
      dispatch(removeOptionFromGroup({ groupId, optionId: option.id }));
      dispatch(addAvailableOptions([{ id: option.id, title: option.title }]));
    },
    [dispatch],
  );
  const onSetCustomColor = React.useCallback(
    (id: string, color: string) => {
      if (!id) return;
      dispatch(setCustomColor({ id, color }));
    },
    [dispatch],
  );

  const onPanelClose = React.useCallback(() => {
    rightPanel.unsetDialogOfType('ChartOptions');
  }, [rightPanel.unsetDialogOfType]);

  const onToggleCoreSeriesSeries = React.useCallback(
    data => dispatch(setCoreSeriesOption(data)),
    [dispatch],
  );

  const onRemoveCoreeries = React.useCallback(
    data => {
      dispatch(setCoreSeriesOption(data));
      dispatch(setAvailableCoreSeries(data));
    },
    [dispatch],
  );

  useNonInputKeydown(
    ({ keyName }) => {
      if (keyName === 'ESCAPE') return onPanelClose();
    },
    [onPanelClose],
  );

  React.useEffect(() => {
    const resizeHandler = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <RightPanel
      onDialogClose={onPanelClose}
      title="Layout Configuration"
      isShown
    >
      {rightPanel.isDialogOfType('ChartOptions') && (
        <CloseOnMouseClickOutside
          exceptForClassName="chart-options-interactive"
          closeHandler={() => {
            !chartWasDragging && onPanelClose();
          }}
          event="mouseup"
        >
          <ChartOptionsPanel.Content className="chart-options-interactive interactive">
            <ChartOptionsPanel.MainContent style={{ height: '100%' }}>
              <ChartOptionsPanel.SelectWrapper>
                <SeriesLayoutsSelect />
              </ChartOptionsPanel.SelectWrapper>

              <ChartOptionsPanel.SelectedAreaWrapper>
                <CommonScrollbar
                  style={{
                    height: selectedSeriesAreaHeight,
                  }}
                >
                  <CavSeriesMenu
                    series={coreSeriesOptionsGroup}
                    onToggleCavSeries={onToggleCoreSeriesSeries}
                    onRemoveCoreeries={onRemoveCoreeries}
                  />

                  {Boolean(chartOptionsGroups.length) && (
                    <DragArea
                      chartOptions={chartOptions}
                      chartOptionsGroups={chartOptionsGroups}
                      onAddOptionToAvailable={onAddOptionToAvailable}
                      onAddOptionToGroup={onAddOptionToGroup}
                      onCreateNewGroup={onCreateNewGroup}
                      onRemoveGroup={onRemoveGroup}
                      onSetCustomColor={onSetCustomColor}
                      onSetNewGroupOrder={onSetNewGroupOrder}
                      onSwitchChartType={onSwitchChartType}
                      onSwitchOptionVisibility={onSwitchOptionVisibility}
                      seriesMapping={seriesMapping}
                      onSetNewOptionsOrder={onSetNewOptionsOrder}
                    />
                  )}
                </CommonScrollbar>
              </ChartOptionsPanel.SelectedAreaWrapper>
            </ChartOptionsPanel.MainContent>

            {R.isEmpty(availableDataSeries) &&
            R.isEmpty(availableCoreSeries) ? (
              <div></div>
            ) : (
              <AvailableOtherSeriesMenu
                dataSeries={availableDataSeries}
                coreSeries={availableCoreSeries}
                getNewAvailableSeriesAreaHeight={
                  getNewAvailableSeriesAreaHeight
                }
                height={availableSeriesHeight}
                onRemoveAvaliableCoreSeries={onRemoveCoreeries}
                onRemoveAvaliableDataSeries={onCreateNewGroup}
                setAvailableSeriesHeight={setAvailableSeriesHeight}
              />
            )}

            <ChartOptionsPanel.RibbonsWrapper ref={ribbonsContainerRef}>
              <RibbonMenu
                ribbons={ribbons}
                selectedRibbons={selectedRibbons}
                onRibbonCheck={onRibbonCheck}
              />
            </ChartOptionsPanel.RibbonsWrapper>
          </ChartOptionsPanel.Content>
        </CloseOnMouseClickOutside>
      )}
    </RightPanel>
  );
};

ChartOptionsPanel.SelectWrapper = styled.div`
  border-bottom: 1px solid #c1c1c1;
  box-shadow: 0 3px 6px -2px rgba(8, 3, 3, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.05);
`;

ChartOptionsPanel.MarginBtnWrapper = styled.div`
  margin-left: 10px;
`;

ChartOptionsPanel.RibbonsWrapper = styled.div``;

ChartOptionsPanel.MainContent = styled.div`
  overflow: auto;

  display: flex;
  flex-direction: column;
`;

ChartOptionsPanel.SelectedAreaWrapper = styled.div`
  overflow: hidden;
`;

ChartOptionsPanel.BorromContent = styled.div`
  display: grid;
  align-content: end;
  margin-bottom: 9px;
`;

ChartOptionsPanel.Content = styled.div`
  display: grid;
  grid-template-rows:
    minmax(${PANEL_SIZES.minSelectedAreaHeight}px, auto) minmax(
      ${PANEL_SIZES.minAvailableAreaHeight}px,
      max-content
    )
    minmax(${PANEL_SIZES.minRibbonsAreaHeight}px, max-content);
  padding-bottom: 10px;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
`;

export default ChartOptionsPanel;
