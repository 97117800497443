import { utcFormat } from 'd3-time-format';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getWellCapacityEventsSorted } from 'modules/capacityChangeEvent/CapacityChangeEventReducer';
import {
  getVarianceCategories,
  getCapacityCategories,
} from 'modules/category/CategoryReducer';
import { getWellVarianceEventsSortedByDate } from 'modules/varianceEvent/VarianceEventReducer';
import {
  highlightCapacity,
  highlightCapacityOff,
  highlightVariance,
  highlightVarianceOff,
} from 'modules/ui/UIActions';
import {
  getGroupMode,
  getCurrentWellId,
  getHighlightedCapacity,
  getHighlightedCapacityDivider,
  getHighlightedVariance,
  getHighlightedVarianceDivider,
  getDataFetchingStatus,
} from 'modules/ui/UIReducer';
import { getRibbonFetchingStatus } from 'modules/ribbon/RibbonReducer';
import { DataFetchingStatus } from 'modules/ui/models/ui';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import { getForecastFetchingStatus } from 'modules/externalForecast/ExternalForecastReducer';
import { getIsProductionAvailible } from 'modules/production/ProductionReducer';
import EventNoteTooltip from 'modules/eventNotes/components/EventNoteTooltip';

import Card from 'components/Card';
import { CloseLegendPanelIcon } from 'components/Icons';

const LegendPanel = ({ show }: { show: boolean }) => {
  const dispatch = useDispatch();
  const currentWellId = useSelector(getCurrentWellId);
  const capacity = useSelector(store =>
    getWellCapacityEventsSorted(store, { wellId: currentWellId }),
  );
  const capacityCategories = useSelector(getCapacityCategories);
  const varianceEvents = useSelector(store =>
    getWellVarianceEventsSortedByDate(store, { wellId: currentWellId }),
  );

  const varianceCategories = useSelector(getVarianceCategories);
  const highlightedCapacity = useSelector(getHighlightedCapacity);
  const highlightedCapacityDivider = useSelector(getHighlightedCapacityDivider);
  const highlightedEvent = useSelector(getHighlightedVariance);
  const highlightedEventDivider = useSelector(getHighlightedVarianceDivider);
  const eventFetchingStatus: DataFetchingStatus = useSelector(
    getDataFetchingStatus,
  );
  const ribbonEventFetchingStatus = useSelector(getRibbonFetchingStatus);

  const forecastLoadingStatus = useSelector(getForecastFetchingStatus);

  const hasProductionData = useSelector(getIsProductionAvailible);

  const hasEnoughData =
    hasProductionData &&
    eventFetchingStatus.isCapacityEventsFetched &&
    eventFetchingStatus.isVarianceEventsFetched &&
    forecastLoadingStatus &&
    ribbonEventFetchingStatus;

  const groupMode = useSelector(getGroupMode);
  const rightPanel = useRightPanel();

  return (
    <LegendPanel.Container show={show}>
      <LegendPanel.Card>
        <LegendPanel.Header>
          Legend
          <LegendPanel.IconButton onClick={() => rightPanel.collapse()}>
            <CloseLegendPanelIcon />
          </LegendPanel.IconButton>
        </LegendPanel.Header>
        {!groupMode.isOn && hasEnoughData && (
          <LegendPanel.Content>
            {!R.isEmpty(varianceEvents) && !R.isEmpty(varianceCategories) && (
              <LegendPanel.Section>
                <LegendPanel.SectionHeader>
                  Variance Events
                </LegendPanel.SectionHeader>
                {varianceEvents.map((event, i) => (
                  <LegendPanel.Item
                    key={`event_legend_${event.id}`}
                    onClick={() =>
                      rightPanel.setDialog({
                        type: 'VarianceChangeEvent',
                        data: { id: event.id, index: i },
                      })
                    }
                    onMouseEnter={() => dispatch(highlightVariance(i))}
                    onMouseLeave={() => dispatch(highlightVarianceOff())}
                    isHighlighted={
                      (highlightedEvent.show && highlightedEvent.index === i) ||
                      (highlightedEventDivider.show &&
                        highlightedEventDivider.index === i)
                    }
                  >
                    {varianceCategories[event.varianceOptionId] &&
                      varianceCategories[event.varianceOptionId].color && (
                        <LegendPanel.ColorMark
                          color={
                            varianceCategories[event.varianceOptionId].color
                          }
                        />
                      )}
                    <LegendPanel.EventLabel>
                      {`${utcFormat('%m/%d/%y')(event.dayStart)}: ${R.pathOr(
                        'Unknown event type',
                        [event.varianceOptionId, 'subCause'],
                        varianceCategories,
                      )}`}
                      <LegendPanel.CommentContainer>
                        <EventNoteTooltip
                          eventType="variance"
                          eventId={event.id}
                          wellId={currentWellId}
                        />
                      </LegendPanel.CommentContainer>
                    </LegendPanel.EventLabel>
                  </LegendPanel.Item>
                ))}
              </LegendPanel.Section>
            )}
            {!R.isEmpty(capacity) && !R.isEmpty(capacityCategories) && (
              <LegendPanel.Section>
                <LegendPanel.SectionHeader>
                  Capacity Changes
                </LegendPanel.SectionHeader>
                {capacity.map((capacityPoint, i) => (
                  <LegendPanel.Item
                    key={`capacity_legend_${capacityPoint.id}`}
                    onClick={() =>
                      rightPanel.setDialog({
                        type: 'CapacityChangeEvent',
                        data: { id: capacityPoint.id, index: i },
                      })
                    }
                    onMouseEnter={() => dispatch(highlightCapacity(i))}
                    onMouseLeave={() => dispatch(highlightCapacityOff())}
                    isHighlighted={
                      (highlightedCapacity.show &&
                        highlightedCapacity.index === i) ||
                      (highlightedCapacityDivider.show &&
                        highlightedCapacityDivider.index === i)
                    }
                  >
                    <LegendPanel.LineMark />
                    <LegendPanel.EventLabel>
                      {capacityCategories[
                        capacityPoint.capacityChangeOptionId || '10'
                      ] &&
                        `${utcFormat('%m/%d/%y')(capacityPoint.dayInit)}: ${
                          capacityCategories[
                            capacityPoint.capacityChangeOptionId || '10'
                          ].subCause
                        }`}
                      <LegendPanel.CommentContainer>
                        <EventNoteTooltip
                          eventType="capacity"
                          eventId={capacityPoint.id}
                          wellId={currentWellId}
                        />
                      </LegendPanel.CommentContainer>
                    </LegendPanel.EventLabel>
                  </LegendPanel.Item>
                ))}
              </LegendPanel.Section>
            )}
          </LegendPanel.Content>
        )}
      </LegendPanel.Card>
    </LegendPanel.Container>
  );
};

LegendPanel.Container = styled.div`
  height: 100%;
`;

LegendPanel.Card = styled(Card)`
  padding-bottom: 10px;
  border: none;
`;

LegendPanel.Header = styled.div`
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px 15px 20px;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #000;
`;

LegendPanel.Content = styled.div`
  height: calc(100% - 59px);
  overflow-y: auto;
`;

LegendPanel.Section = styled.div``;

LegendPanel.SectionHeader = styled.div`
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0 20px;
  font-size: 14px;
  font-weight: bold;
`;

LegendPanel.Item = styled.div`
  display: flex;
  padding: 10px 0 10px 20px;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props: Record<string, any>) =>
    props.isHighlighted ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

LegendPanel.EventLabel = styled.div``;

LegendPanel.CommentIconWrapper = styled.div`
  margin-left: 3px;
`;

LegendPanel.Tooltip = styled.div`
  margin-left: 3px;
  border: 1px;
`;

LegendPanel.ColorMark = styled.div`
  min-width: 20px;
  min-height: 20px;
  background-color: ${(props: Record<string, any>) =>
    props.color ? props.color : 'black'};
  margin-right: 8px;
`;

LegendPanel.LineMark = styled.div`
  height: 1px;
  width: 20px;
  background-color: black;
  margin-right: 8px;
`;

LegendPanel.CommentContainer = styled.span`
  display: inline-block;
`;

LegendPanel.IconButton = styled.div`
  height: 31px;
  width: 30px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

LegendPanel.TooltipContent = styled.div`
  white-space: pre-line;
  max-width: 290px;
`;

LegendPanel.TooltipContentHeader = styled.h1``;

export default LegendPanel;
