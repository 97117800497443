import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

import { setAppConfigLocally } from 'modules/appConfig/AppConfigActions';
import { getAppConfig, getFeatures } from 'modules/appConfig/AppConfigReducer';
import { changeMyPassword, logout } from 'modules/auth/AuthActions';
import {
  getFullName,
  getIsAdmin,
  getIsAuthenticated,
  getIsUserManager,
} from 'modules/auth/AuthReducer';

import DevSubMenu from 'components/DevSubMenu';
import ChangePasswordModal from 'modules/user/components/ChangePassWindow';
import AccountModal from 'modules/user/components/AccountWindow';
import useMouseDownOutside from 'hooks/useMouseDownOutside';

export const Menu = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAdmin = useSelector(getIsAdmin);
  const isUserManager = useSelector(getIsUserManager);
  const currentUserName = useSelector(getFullName);
  const devFeatures = useSelector(getFeatures);
  const appConfig = useSelector(getAppConfig);
  const onLogout = React.useCallback(() => dispatch(logout()), [dispatch]);

  const [isOpenDevFeatures, setIsOpenDevFeatures] = React.useState(false);

  const openDevMenu = React.useCallback(() => {
    setIsOpenDevFeatures(true);
  }, [setIsOpenDevFeatures]);

  const closeDevMenu = React.useCallback(() => {
    setIsOpenDevFeatures(false);
  }, [setIsOpenDevFeatures]);

  const devItemElem = React.useRef<HTMLLIElement>(null);
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  const changeDevFeatures = React.useCallback(
    (feature: any[]): void => {
      dispatch(
        setAppConfigLocally({ ...devFeatures, [feature[0]]: !feature[1] }),
      );
    },
    [dispatch, devFeatures],
  );

  const [isAccountOpen, setAccountOpen] = React.useState(false);
  const [isChangePasswordOpen, setChangePasswordOpen] = React.useState(false);

  const changePasswordRequest = React.useCallback(
    values => dispatch(changeMyPassword(values)),
    [dispatch],
  );

  useMouseDownOutside([menuRef], closeDevMenu);

  return isAuthenticated ? (
    <Menu.Container ref={menuRef}>
      {currentUserName && <Menu.Username>{currentUserName}</Menu.Username>}

      {appConfig.allocationIssues && (
        <>
          <Menu.Section>
            <Menu.Item>
              <Link to="/backlog">Allocation Issue Backlog</Link>
            </Menu.Item>
          </Menu.Section>

          <Menu.Divider />
        </>
      )}

      <Menu.Section>
        <Menu.Item onClick={() => setAccountOpen(true)}>My Account</Menu.Item>

        <Menu.Item onClick={() => setChangePasswordOpen(true)}>
          Change Password
        </Menu.Item>

        <Menu.Item onClick={onLogout}>Log Out</Menu.Item>
      </Menu.Section>

      {(isAdmin || isUserManager) && (
        <>
          <Menu.Divider />

          <Menu.Section>
            <Menu.Item>
              <Link to="/user-management">User Management</Link>
            </Menu.Item>

            {appConfig.spotfireDashboards && (
              <Menu.Item>
                <Link to="/spotfire/dashboards">Dashboards</Link>
              </Menu.Item>
            )}

            {isAdmin && (
              <Menu.Item onClick={openDevMenu} ref={devItemElem}>
                Feature Flags
              </Menu.Item>
            )}
          </Menu.Section>
        </>
      )}

      {isOpenDevFeatures && devItemElem.current && (
        <DevSubMenu
          anchor={(
            devItemElem.current as HTMLLIElement
          ).getBoundingClientRect()}
          devFeatures={devFeatures}
          onChange={changeDevFeatures}
          onClose={closeDevMenu}
        />
      )}

      <ChangePasswordModal
        open={isChangePasswordOpen}
        handleClose={() => setChangePasswordOpen(false)}
        changePasswordRequest={changePasswordRequest}
      />

      <AccountModal
        open={isAccountOpen}
        handleClose={() => setAccountOpen(false)}
      />
    </Menu.Container>
  ) : null;
};

Menu.Container = styled.div``;

Menu.Username = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin: 21px 0 21px 16px;
`;

Menu.Section = styled.div``;

Menu.Item = styled(MenuItem)`
  font-size: 14px;
  padding: 0 16px;
  height: 32px;
  > * {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  a {
    display: flex;
    align-items: center;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

Menu.Divider = styled.div`
  height: 1px;
  background-color: #e7e7e7;
  margin: 8px 16px;
`;
