import * as R from 'ramda';

import { countBoeValue } from 'modules/production/utils';

import type { VarianceDrilldownTableItem } from 'modules/drilldownTable/models/drilldownTable';
import sum from './sum';

const createGroupedTable = (
  table: VarianceDrilldownTableItem[],
  subject = 'well',
  compareOption = 'Actual',
) => {
  if (!subject) return [];
  const groupedTable = R.groupBy(R.prop(subject), table);

  return R.keys(groupedTable).reduce((acc, subjectItemName) => {
    const oilVariance = sum(groupedTable[subjectItemName], 'oilVariance');
    const gasVariance = sum(groupedTable[subjectItemName], 'gasVariance');
    const waterVariance = sum(groupedTable[subjectItemName], 'waterVariance');
    const boeVariance = countBoeValue(oilVariance, gasVariance);

    const oilCapacity = sum(groupedTable[subjectItemName], 'oilCapacity');
    const gasCapacity = sum(groupedTable[subjectItemName], 'gasCapacity');
    const waterCapacity = sum(groupedTable[subjectItemName], 'waterCapacity');
    const boeCapacity = countBoeValue(oilCapacity, gasCapacity);

    const oilSource = sum(groupedTable[subjectItemName], `oil${compareOption}`);
    const gasSource = sum(groupedTable[subjectItemName], `gas${compareOption}`);
    const waterSource = sum(
      groupedTable[subjectItemName],
      `water${compareOption}`,
    );
    const boeSource = countBoeValue(oilSource, gasSource);
    const displaySubjectItemName =
      subjectItemName === 'null' ? '' : subjectItemName;
    const tableRowData = {
      [subject || 'well']: subject === 'all' ? 'all' : displaySubjectItemName,
      oilVariance,
      gasVariance,
      waterVariance,
      boeVariance,
      oilCapacity,
      gasCapacity,
      waterCapacity,
      boeCapacity,
      [`oil${compareOption}`]: oilSource,
      [`gas${compareOption}`]: gasSource,
      [`water${compareOption}`]: waterSource,
      [`boe${compareOption}`]: boeSource,
    };

    acc.push(tableRowData);
    return acc;
  }, []);
};

export default createGroupedTable;
