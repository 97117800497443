import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import useComponentSize from 'hooks/useComponentSize';
import { ChartRectContext } from 'modules/chart/hooks/useChartRect';
import GroupProductionChart from 'modules/groupChart/containers/GroupProductionChart';
import WellProductionChart from 'modules/wellChart/containers/WellProductionChart';
import { getGroupMode } from 'modules/ui/UIReducer';
import useRightPanel from 'modules/ui/hooks/useRightPanel';
import Card from 'components/Card';

import { LEFT_PANEL_WIDTH } from '../models/dashboard';

type ProductionChartProps = {
  hideDrilldown: boolean;
  dashboardWidth: number;
};

const ProductionChart = (props: ProductionChartProps) => {
  const { hideDrilldown, dashboardWidth } = props;
  const rightPanel = useRightPanel();
  const groupMode = useSelector(getGroupMode);
  const chartWrapperEl = React.useRef(null);

  const rightPanelWidth = React.useMemo(() => {
    if (rightPanel.isExpanded) return rightPanel.width;
    return 0;
  }, [rightPanel.isExpanded, rightPanel.width]);

  const chartHeight = useComponentSize(chartWrapperEl).height || 0;
  const chartWidth = React.useMemo(() => {
    const offset = !rightPanel.isExpanded ? 15 : 20;
    const leftPanelWidth = hideDrilldown ? -5 : LEFT_PANEL_WIDTH;
    return dashboardWidth - rightPanelWidth - leftPanelWidth - offset;
  }, [hideDrilldown, rightPanel.isExpanded, rightPanelWidth, dashboardWidth]);

  const chartRectValue = React.useMemo(() => {
    return {
      width: chartWidth - 2,
      height: chartHeight,
      leftOffset: hideDrilldown ? -2 : LEFT_PANEL_WIDTH,
      rightOffset: rightPanelWidth,
    };
  }, [chartHeight, chartWidth, hideDrilldown, rightPanelWidth]);

  const isChartVisible = chartHeight > 0 && chartWidth > 0;

  return (
    <ProductionChart.ChartWrapper
      ref={chartWrapperEl}
      rightPanelWidth={rightPanelWidth}
      hideDrilldown={hideDrilldown}
      isExpanded={rightPanel.isExpanded}
    >
      <ProductionChart.ChartCard>
        <ProductionChart.CardInnerWrapper>
          {!isChartVisible && <CircularProgress color="inherit" />}

          {isChartVisible && (
            <ChartRectContext.Provider value={chartRectValue}>
              {groupMode.isOn && <GroupProductionChart />}
              {!groupMode.isOn && <WellProductionChart />}
            </ChartRectContext.Provider>
          )}
        </ProductionChart.CardInnerWrapper>
      </ProductionChart.ChartCard>
    </ProductionChart.ChartWrapper>
  );
};

ProductionChart.ChartCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

ProductionChart.ChartWrapper = styled.div`
  width: ${(props: Record<string, any>) =>
    `calc(100vw - ${props.hideDrilldown ? 0 : LEFT_PANEL_WIDTH}px - ${
      props.isExpanded ? props.rightPanelWidth : 0
    }px - ${props.hideDrilldown ? 15 : 20}px + ${
      props.isExpanded ? 0 : 10
    }px)`};
  margin-right: ${props => (props.isLegendPanelOpen ? '5px' : 0)};
`;

ProductionChart.CardInnerWrapper = styled.div`
  height: calc(100vh - 10px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ProductionChart;
