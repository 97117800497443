import React from 'react';
import styled from 'styled-components';

interface OptionTitleProps {
  title: string;
  setTooltipPosition: (data: { x: number; y: number }) => void;
  setTooltipText: (data: string) => void;
}

const OptionTitle = ({
  title,
  setTooltipPosition,
  setTooltipText,
}: OptionTitleProps) => {
  const onMouseMoveHandler = React.useCallback(
    (e: MouseEvent) => {
      setTooltipText(title);

      setTooltipPosition({ x: e.clientX, y: e.clientY });
    },
    [setTooltipPosition],
  );

  const onMouseLeaveHandler = React.useCallback(() => {
    setTooltipText('');
  }, [setTooltipText]);

  const onMouseEnterHandler = React.useCallback(() => {
    setTooltipText(title);
  }, [setTooltipText, title]);

  return (
    <OptionTitle.Title
      onMouseMove={onMouseMoveHandler}
      onMouseLeave={() => onMouseLeaveHandler()}
      onMouseEnter={onMouseEnterHandler}
    >
      {title}
    </OptionTitle.Title>
  );
};

OptionTitle.Title = styled.span`
  margin-left: 5px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default OptionTitle;
