import * as R from 'ramda';
import { filterActions } from 'redux-ignore';
import { createSelector } from 'reselect';

import type { Action, Selector } from 'store/models';
import {
  getGraphqlPayload,
  getGraphqlPrevActionVariables,
} from 'store/helpers';
import { getMinDate, getMaxDate, isIdNew } from 'helpers';

import type { VarianceEvent } from './models/varianceEvent';
import { normalizeVarianceEvents } from './utils';
import {
  CREATE_REMOTE_VARIANCE_EVENT,
  DELETE_VARIANCE_EVENT_LOCALLY,
  FETCH_VARIANCE_EVENTS,
  namespace,
  POPULATE_VARIANCE_EVENTS_AFTER_CREATING,
  UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY,
  UPDATE_VARIANCE_EVENT_DATES_LOCALLY,
  UPDATE_VARIANCE_EVENT_NOTES_LOCALLY,
  UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY,
} from './VarianceEventActions';

const filterRegExp = new RegExp(`${namespace}/`);
export const STATE_KEY = 'varianceEvent';

type VarianceEventState = {
  [wellId: string]: {
    [id: string]: VarianceEvent;
  };
};

const initialState = {};

const VarianceEventReducer = (
  state: VarianceEventState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case `${FETCH_VARIANCE_EVENTS}_SUCCESS`: {
      const rawVariance = getGraphqlPayload(action);
      const { wellId } = getGraphqlPrevActionVariables(action);

      const normalizedVarianceEvents: {
        [id: string]: VarianceEvent;
      } = normalizeVarianceEvents(rawVariance);

      return R.assoc<{ [id: string]: VarianceEvent }, VarianceEventState>(
        wellId,
        normalizedVarianceEvents,
        state,
      );
    }
    case UPDATE_VARIANCE_EVENT_CATEGORY_LOCALLY: {
      const { wellId, varianceEventId, varianceOptionId } = action.payload;

      return R.assocPath<string, VarianceEventState, string>(
        [wellId, varianceEventId, 'varianceOptionId'],
        varianceOptionId,
        state,
      );
    }
    case UPDATE_VARIANCE_EVENT_DATES_LOCALLY: {
      const { wellId, varianceEventId, dates } = action.payload;
      const dayStart = getMinDate(...(dates as [Date, Date]));
      const dayEnd = getMaxDate(...(dates as [Date, Date]));

      return R.compose(
        R.assocPath<string, VarianceEventState, Date>(
          [wellId, varianceEventId, 'dayStart'],
          dayStart,
        ),
        R.assocPath([wellId, varianceEventId, 'dayEnd'], dayEnd),
      )(state);
    }
    case UPDATE_VARIANCE_EXTRA_INPUTS_DATA_LOCALLY: {
      const { wellId, varianceEventId, extraInputsData } = action.payload;

      return R.assocPath<string, VarianceEventState, Record<string, any>>(
        [wellId, varianceEventId, 'extraInputsData'],
        extraInputsData,
        state,
      );
    }
    case UPDATE_VARIANCE_EVENT_NOTES_LOCALLY: {
      const { wellId, varianceEventId, notes } = action.payload;

      return R.assocPath<string, VarianceEventState, Record<string, any>>(
        [wellId, varianceEventId, 'notes'],
        notes,
        state,
      );
    }
    case POPULATE_VARIANCE_EVENTS_AFTER_CREATING: {
      const { wellId, newVarianceEvents } = action.payload;

      return R.assoc<{ [id: string]: VarianceEvent }, VarianceEventState>(
        wellId,
        newVarianceEvents,
        state,
      );
    }
    case DELETE_VARIANCE_EVENT_LOCALLY: {
      const { wellId, varianceEventId } = action.payload;

      return R.dissocPath<string, Record<string, any>>(
        [wellId, varianceEventId],
        state,
      );
    }
    case CREATE_REMOTE_VARIANCE_EVENT: {
      const { wellId, dayStart, dayEnd } =
        action.payload.graphql.variables.payload;
      const wellVarEvents = state[wellId] || {};

      const newWellVarEvents = Object.keys(wellVarEvents).reduce(
        (acc, capId) => {
          if (
            isIdNew(capId) &&
            wellVarEvents[capId].dayStart.getTime() === dayStart.getTime() &&
            wellVarEvents[capId].dayEnd.getTime() === dayEnd.getTime()
          ) {
            acc[capId] = { ...wellVarEvents[capId], syncing: true };
          } else {
            acc[capId] = wellVarEvents[capId];
          }

          return acc;
        },
        {},
      );

      return R.assoc(wellId, newWellVarEvents, state);
    }
    case `${CREATE_REMOTE_VARIANCE_EVENT}_SUCCESS`: {
      const newVarianceEvent = getGraphqlPayload(action);
      const normalizedEvent = normalizeVarianceEvents([newVarianceEvent])[
        newVarianceEvent.id
      ];
      const wellVarEvents = state[newVarianceEvent.wellId] || {};

      const newWellVarEvents = Object.keys(wellVarEvents).reduce(
        (acc, capId) => {
          if (
            isIdNew(capId) &&
            wellVarEvents[capId].dayStart.getTime() ===
              normalizedEvent.dayStart.getTime() &&
            wellVarEvents[capId].dayEnd.getTime() ===
              normalizedEvent.dayEnd.getTime()
          ) {
            acc[normalizedEvent.id] = normalizedEvent;
          } else {
            acc[capId] = wellVarEvents[capId];
          }

          return acc;
        },
        {},
      );

      return R.assoc<{ [id: string]: VarianceEvent }, VarianceEventState>(
        newVarianceEvent.wellId,
        newWellVarEvents,
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getVarianceEvents: Selector<Record<string, any>> = (
  state: Record<string, any>,
) => state[STATE_KEY] || {};

export const getWellVarianceEventsIndexedById: Selector<{
  [id: string]: VarianceEvent;
}> = createSelector(
  getVarianceEvents,
  (_, { wellId }) => wellId,
  (variance, wellId) => {
    if (!R.isNil(wellId)) return variance[wellId] || {};
    return {};
  },
);

export const getWellVarianceEventsSortedByDate: Selector<VarianceEvent[]> =
  createSelector(getWellVarianceEventsIndexedById, wellVariance =>
    R.values(wellVariance)
      .sort((a, b) => a.dayStart - b.dayStart)
      .reverse(),
  );

export const getVarianceEvent: Selector<VarianceEvent> = createSelector(
  getWellVarianceEventsIndexedById,
  (_, { varianceEventId }) => varianceEventId,
  (wellVarianceEventsById, varianceEventId) =>
    wellVarianceEventsById[varianceEventId] || {},
);

export default filterActions(VarianceEventReducer, action =>
  action.type.match(filterRegExp),
);
