import * as R from 'ramda';
import * as React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getFeatures, getAppConfig } from 'modules/appConfig/AppConfigReducer';
import {
  getCurrentVarianceOptions,
  getDrilldownLoadingStatus,
  getDrilldownTableParams,
  getDrilldownTableTotalRow,
  getSortCriteria,
  getSortDirection,
  getSortedForecastGroupedTable,
  getSortedGroupedTable,
  getSortedVarianceDrilldownTable,
  getSortedWellDrilldownTable,
  getSortedWellForecastDrilldownTable,
  getSortVarDirectionIndex,
} from 'modules/drilldownTable/DrilldownTableReducer';
import {
  addCurrentVarianceOption,
  addSeveralVarianceOptions,
  configureSorting,
  initClearDrilldownTable,
  removeOneVarianceOption,
  resetCurrentVarianceOption,
  setCurrentVarianceOption,
  setMaxDrilldownTableDate,
  setMinDrilldownTableDate,
  setDrilldownTablePhase,
  setDrilldownTableParams,
} from 'modules/drilldownTable/DrilldownTableActions';
import { changeVisibilityStatus } from 'modules/externalForecast/ExternalForecastActions';
import { getForecastStatus } from 'modules/externalForecast/ExternalForecastReducer';
import { setFilter } from 'modules/filter/FilterActions';
import { getFilters } from 'modules/filter/FilterReducer';

import useRightPanel from 'modules/ui/hooks/useRightPanel';
import {
  changeGroupModeSubject,
  disableGroupMode,
  enableGroupMode,
  enableRegionOfInterestMode,
  resetActivePanel,
  setCurrentWellId,
  setCurrentGroup,
} from 'modules/ui/UIActions';
import {
  getActivePanel,
  getCurrentWellId,
  getCurrentGroup,
  getGroupMode,
} from 'modules/ui/UIReducer';
import { DRILLDOWN_PANEL } from 'modules/ui/models/ui';
import {
  getAllWells,
  getColumnMapping,
  getGroups,
  getGroupsByKey,
} from 'modules/well/WellReducer';
import useStorage from 'hooks/useStorage';

import Card from 'components/Card';

import DrilldownTableForm from './DrilldownTableForm';
import WellDrilldownTable from './WellDrilldownTable';
import VarianceDrilldownTable from 'modules/drilldownTable/components/VarianceDrilldownTable';
import { COMPARE_OPTION } from 'modules/drilldownTable/models/drilldownTable';
import useSpotfireStorage from 'modules/spotfire/hooks/useSpotfireStorage';

const DrilldownPanelV2 = () => {
  const dispatch = useDispatch();
  const rightPanel = useRightPanel();
  const allWells = useSelector(getAllWells);
  const currentWellId = useSelector(getCurrentWellId);
  const currentGroup = useSelector(getCurrentGroup);
  const currentDrilldownTableState = useSelector(getDrilldownTableParams);
  const currentVarianceOptionIds = useSelector(getCurrentVarianceOptions);
  const wellDrilldownTable = useSelector(getSortedWellDrilldownTable);
  const varianceDrilldownTable = useSelector(getSortedVarianceDrilldownTable);
  const groupMode = useSelector(getGroupMode);
  const groupedTable = useSelector(state =>
    getSortedGroupedTable(state, {
      subject: groupMode.subject,
    }),
  );
  const columnMapping = useSelector(getColumnMapping);
  const hasNri = columnMapping.some(item => item.wiserockBinding === 'NRI');
  const filters = useSelector(getFilters);
  const activePanel = useSelector(getActivePanel);
  const sortCriteria = useSelector(getSortCriteria);
  const sortDirection = useSelector(getSortDirection);
  const sortVarDirectionIndex = useSelector(getSortVarDirectionIndex);
  const groups = useSelector(getGroups);
  const groupsByKey = useSelector(getGroupsByKey);
  const drilldownLoadingStatus = useSelector(getDrilldownLoadingStatus);
  const devFeatures = useSelector(getFeatures);
  const appConfig = useSelector(getAppConfig);
  const isDisplayedForecast = useSelector(getForecastStatus);
  const filtersApplied = R.pickBy(filter => !R.isEmpty(filter), filters);
  const filterQuant = R.keys(filtersApplied).length;
  const totalRow = useSelector(getDrilldownTableTotalRow);
  const wellForecastTabale = useSelector(getSortedWellForecastDrilldownTable);
  const groupForecastTable = useSelector(state =>
    getSortedForecastGroupedTable(state, {
      subject: groupMode.subject,
    }),
  );

  const onChangeForecastStatus = React.useCallback(
    status => dispatch(changeVisibilityStatus({ status })),
    [dispatch],
  );

  const resetVarianceOption = React.useCallback(() => {
    setCurrentTable('WellDrilldownTable');
    dispatch(resetCurrentVarianceOption());
  }, [dispatch]);

  const regionOfInterestModeOn = React.useCallback(() => {
    dispatch(resetActivePanel());
    dispatch(enableRegionOfInterestMode());
  }, [dispatch]);

  const keyMap: { regionOfInterestModeOn: string } = {
    regionOfInterestModeOn: 'r',
  };

  const onSetCompareOption = React.useCallback(
    compareOption => dispatch(setDrilldownTableParams({ compareOption })),
    [dispatch],
  );

  const handlers = {
    regionOfInterestModeOn,
  };

  const turnOnGrouping = React.useCallback(
    subject => dispatch(enableGroupMode(subject)),
    [dispatch],
  );
  const turnOffGrouping = React.useCallback(
    () => dispatch(disableGroupMode()),
    [dispatch],
  );
  const changeGrouping = React.useCallback(
    subject => dispatch(changeGroupModeSubject(subject)),
    [dispatch],
  );
  const isActive = activePanel === DRILLDOWN_PANEL;

  const [searchWellWord, setSearchWellWord] = React.useState('');
  const [searchVarianceWord, setSearchVarianceWord] = React.useState('');
  const [varianceTableHeight, setVarianceTableHeight] = useStorage(
    'varianceTableHeight',
    0,
    'session',
  );
  const [viewportHeight, setViewportHeight] = React.useState(
    window.innerHeight,
  );
  const [currentTable, setCurrentTable] = React.useState('WellDrilldownTable');

  const wellTableHeight = React.useMemo(() => {
    const withoutStaticElements = viewportHeight - 5 * 2 - (41 + 10) - 30;
    return currentDrilldownTableState.compareOption !== COMPARE_OPTION.actual
      ? withoutStaticElements + 20
      : withoutStaticElements - varianceTableHeight;
  }, [varianceTableHeight, viewportHeight, currentDrilldownTableState]);

  const onWellTableRowChoose = React.useCallback(
    (rowData: { [key: string]: string | number }) => {
      rightPanel.unsetDialogOfType('CapacityChangeEvent');
      if (!groupMode.isOn) {
        dispatch(setCurrentWellId(rowData.wellId as string));
      } else {
        dispatch(
          setCurrentGroup({
            subject: groupMode.subject,
            item: rowData[groupMode.subject] as string,
          }),
        );
      }
    },
    [dispatch, groupMode],
  );

  const onVarianceTableRowChoose = React.useCallback(
    rowData => {
      dispatch(setCurrentVarianceOption(rowData.varianceOptionId));
      setCurrentTable('VarianceDrilldownTable');
    },
    [dispatch],
  );

  const addVarianceOption = React.useCallback(
    optionId => {
      dispatch(addCurrentVarianceOption(optionId));
      setCurrentTable('VarianceDrilldownTable');
    },
    [dispatch],
  );

  const addSeveralVarianceOptionIds = React.useCallback(
    optionIds => {
      dispatch(addSeveralVarianceOptions(optionIds));
      setCurrentTable('VarianceDrilldownTable');
    },
    [dispatch],
  );

  const removeVarianceOption = React.useCallback(
    optionId => {
      dispatch(removeOneVarianceOption(optionId));
      setCurrentTable('VarianceDrilldownTable');
    },
    [dispatch],
  );

  const beforeSubmit = React.useCallback(() => {
    dispatch(initClearDrilldownTable());
  }, [dispatch]);

  const setMaxDate = React.useCallback(
    date => dispatch(setMaxDrilldownTableDate(date)),
    [dispatch],
  );
  const setMinDate = React.useCallback(
    date => dispatch(setMinDrilldownTableDate(date)),
    [dispatch],
  );
  const setPhase = React.useCallback(
    phase => dispatch(setDrilldownTablePhase(phase)),
    [dispatch],
  );
  const setGrossNet = React.useCallback(
    grossNet => dispatch(setDrilldownTableParams({ grossNet })),
    [dispatch],
  );
  const setRateVolume = React.useCallback(
    rateVolume => dispatch(setDrilldownTableParams({ rateVolume })),
    [dispatch],
  );
  const setSorting = React.useCallback(
    ({ sortBy, sortDirection }) => {
      dispatch(
        configureSorting({
          sortBy,
          sortDirection,
        }),
      );
    },
    [dispatch],
  );

  const closeVarianceDrilldownTable = React.useCallback(() => {
    setVarianceTableHeight(0);
  }, [setVarianceTableHeight]);

  const filterToCurrentItem = React.useCallback(() => {
    const currentGroupingCriteria = currentGroup.subject
      ? currentGroup.subject
      : 'well';
    if (currentGroupingCriteria === 'all') {
      return;
    }
    if (currentGroupingCriteria === 'well') {
      dispatch(
        setFilter({
          filterName: 'LEASE',
          filterValue: allWells[currentWellId].LEASE,
        }),
      );
      return;
    }
    dispatch(
      setFilter({
        filterName: currentGroup.subject,
        filterValue: currentGroup.item,
      }),
    );
  }, [allWells, currentGroup, currentWellId, dispatch]);

  const handleGroupSelection = React.useCallback(
    e => {
      if (e.currentTarget.id) {
        const groupingSubject = e.currentTarget.id;
        if (groupingSubject === 'well') {
          turnOffGrouping();
        } else if (!groupMode.isOn) {
          turnOnGrouping(groupingSubject);
        } else {
          changeGrouping(groupingSubject);
        }
      }
    },
    [groupMode, changeGrouping, turnOffGrouping, turnOnGrouping],
  );

  const handleFilterGroupSelection = React.useCallback(
    e => {
      filterToCurrentItem();
      handleGroupSelection(e);
    },
    [handleGroupSelection, filterToCurrentItem],
  );

  React.useEffect(() => {
    if (
      currentDrilldownTableState.compareOption !== COMPARE_OPTION.actual &&
      varianceTableHeight > 0
    ) {
      closeVarianceDrilldownTable();
    }
  }, [
    currentDrilldownTableState,
    varianceTableHeight,
    closeVarianceDrilldownTable,
  ]);

  const onKeyDownHandler = React.useCallback(
    (e: KeyboardEvent) => {
      const { code, target } = e;
      if (target instanceof HTMLElement) {
        const { tagName } = target;
        if (
          tagName === 'INPUT' ||
          tagName === 'TEXTAREA' ||
          tagName === 'SELECT'
        )
          return;
      }
      if (
        code === 'KeyF' &&
        currentDrilldownTableState.compareOption === COMPARE_OPTION.actual
      ) {
        if (!appConfig.showExternalForecast) {
          onChangeForecastStatus(false);
        }
        onChangeForecastStatus(!isDisplayedForecast);
      }
    },
    [
      appConfig,
      currentDrilldownTableState,
      isDisplayedForecast,
      onChangeForecastStatus,
    ],
  );

  useSpotfireStorage(data => {
    if (!data.wellId) return;
    dispatch(setCurrentWellId(data.wellId as string));
  });

  React.useEffect(() => {
    const resizeHandler = e => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', resizeHandler);
    document.addEventListener('keydown', onKeyDownHandler);

    return () => {
      document.removeEventListener('keydown', onKeyDownHandler);
      window.removeEventListener('resize', resizeHandler);
    };
  }, [onKeyDownHandler]);

  return (
    <DrilldownPanelV2.Container>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <DrilldownTableForm
        appConfig={appConfig}
        beforeSubmit={beforeSubmit}
        currentDrilldownTableState={currentDrilldownTableState}
        hasNri={hasNri}
        isActive={isActive}
        isDisplayedForecast={isDisplayedForecast}
        onChangeForecastStatus={onChangeForecastStatus}
        onSetCompareOption={onSetCompareOption}
        regionOfInterestModeOn={regionOfInterestModeOn}
        setMaxDrilldownTableDate={setMaxDate}
        setMinDrilldownTableDate={setMinDate}
        setDrilldownTableOption={setPhase}
        setGrossNet={setGrossNet}
        setRateVolume={setRateVolume}
      />
      <DrilldownPanelV2.InternalContainer
        varianceTableHeight={varianceTableHeight}
      >
        <WellDrilldownTable
          compareOption={currentDrilldownTableState.compareOption}
          currentGroup={currentGroup}
          currentTable={currentTable}
          currentWellId={currentWellId}
          devFeatures={devFeatures}
          groupForecastTable={groupForecastTable}
          groupMode={groupMode}
          groupsByKey={groupsByKey}
          groupsByOrder={groups}
          groupedTable={groupedTable}
          filterQuant={filterQuant}
          handleFilterGroupSelection={handleFilterGroupSelection}
          handleGroupSelection={handleGroupSelection}
          height={wellTableHeight}
          isTableLoading={
            drilldownLoadingStatus.isWellTableFetching || R.isEmpty(groupsByKey)
          }
          filterToCurrentItem={filterToCurrentItem}
          onWellTableRowChoose={onWellTableRowChoose}
          searchWord={searchWellWord}
          setCurrentTable={setCurrentTable}
          setSearchWord={setSearchWellWord}
          setSorting={setSorting}
          sortCriteria={sortCriteria}
          sortDirection={sortDirection}
          sortVarDirectionIndex={sortVarDirectionIndex}
          totalRow={totalRow}
          volumeType={currentDrilldownTableState.rateVolume === 'Volume'}
          wellDrilldownTable={wellDrilldownTable}
          wellForecastTable={wellForecastTabale}
        />
        <VarianceDrilldownTable
          addSeveralOptions={addSeveralVarianceOptionIds}
          addVarianceOption={addVarianceOption}
          currentTable={currentTable}
          currentVarianceOptionIds={currentVarianceOptionIds}
          height={varianceTableHeight}
          isTableLoading={
            drilldownLoadingStatus.isVarianceTableFetching ||
            R.isEmpty(groupsByKey) ||
            R.isEmpty(varianceDrilldownTable)
          }
          isDisplayed={
            currentDrilldownTableState.compareOption === COMPARE_OPTION.actual
          }
          onVarianceTableRowChoose={onVarianceTableRowChoose}
          removeVarianceOption={removeVarianceOption}
          resetVarianceOption={resetVarianceOption}
          searchWord={searchVarianceWord}
          setSearchWord={setSearchVarianceWord}
          setVarianceTableHeight={setVarianceTableHeight}
          varianceDrilldownTable={varianceDrilldownTable}
          volumeType={currentDrilldownTableState.rateVolume === 'Volume'}
        />
      </DrilldownPanelV2.InternalContainer>
    </DrilldownPanelV2.Container>
  );
};

DrilldownPanelV2.Container = styled(Card)`
  width: 100%;
  height: 100%;
  padding: 5px 10px 10px 10px;
  position: relative;
`;

DrilldownPanelV2.InternalContainer = styled.div`
  overflow: hidden;
  border-bottom: ${(props: Record<string, any>) =>
    props.varianceTableHeight <= 32 ? '1px solid #c1c1c1' : ''};
`;

export default React.memo<Record<string, any>>(DrilldownPanelV2);
