import { utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';
import styled from 'styled-components';

import { Y_AXIS_WIDTH } from 'modules/chart/models/chart';
import { createVarianceYScale, isInside } from 'modules/chart/utils';
import SeriesPill from 'modules/chart/components/SeriesPill';
import YAxis from 'modules/chart/components/YAxis';
import { ListChartOptions, BAR_CHART } from 'modules/chartOptions/models';
import { DataSeriesTooltipData, TooltipData } from 'modules/ui/models/ui';

import usePrevious from 'hooks/usePrevious';

import SVGSeriesTrellis from '../components/SVGSeriesTrellis';
import SVGSeriesTrellisInteraction from '../components/SVGSeriesTrellisInteraction';
import { Series, NormalizedSeriesMapping } from '../models';
import SecondaryInformationTooltip from 'modules/chart/components/SecondaryInformationTooltip';

const getDataForSeriesTooltip = ({
  dataMap,
  chartOptions,
  groupOptions,
  seriesMapping,
  tooltipData,
}) => {
  const tooltipDate =
    R.pathOr(false, ['trellisTooltipData', 'day'], tooltipData) ||
    R.pathOr(false, ['dataSeriesTooltipData', 'day'], tooltipData) ||
    null;
  if (tooltipDate && !R.isEmpty(seriesMapping)) {
    const dataForTooltip = groupOptions.reduce((acc, seriesId) => {
      const name = seriesMapping[seriesId]?.displayName;
      const color = (chartOptions[seriesId].customColor ||
        chartOptions[seriesId].color) as string;
      const value = R.pathOr(
        0,
        [
          tooltipDate.toISOString(),
          `series${seriesMapping[seriesId].seriesIndex}`,
        ],
        dataMap,
      );
      acc.push({
        name,
        color,
        value,
      });
      return acc;
    }, [] as any);

    return dataForTooltip;
  }
  return [];
};

const getFormat = (sourceName: string) =>
  sourceName.toLowerCase() === 'Watercut' ? '.0%' : ',d';

const getMaxDataPoint = (
  seriesData: Series[],
  seriesMapping: NormalizedSeriesMapping,
  options: string[],
  extremeDates: { min: Date; max: Date },
) => {
  if (!seriesData || R.isEmpty(seriesMapping)) {
    return 0;
  }
  const maxGlobalValue = options.reduce((acc: number, optionId: string) => {
    const maxSeriesValue = seriesData.reduce((acc: number, series: Series) => {
      const value = series[`series${seriesMapping[optionId].seriesIndex}`];
      const day = utcDay.round(new Date(series.day));
      if (
        day.getTime() <= extremeDates.max.getTime() &&
        day.getTime() >= extremeDates.min.getTime() &&
        value > acc
      ) {
        acc = value;
      }

      return acc;
    }, 0);

    if (acc < maxSeriesValue) {
      acc = maxSeriesValue;
    }
    return acc;
  }, 0);

  return maxGlobalValue * 1.05 || 10;
};

interface SeriesChartProps {
  groupOptions: string[];
  chartWasDragging: boolean;
  seriesMapping: NormalizedSeriesMapping;

  currentWellId: string;
  extremeDates: { min: Date; max: Date };
  height: number;
  isAxisDragging: boolean;
  isDragging: boolean;
  isLast: boolean;
  leftOffset: number;
  onPillClick: () => void;
  onSetTooltipData: (tooltipData: DataSeriesTooltipData | null) => void;
  onXAxisScaling: (
    e: MouseEvent,
    svgEl: { current: Element | null } | null,
  ) => void;
  series: Series[];
  tooltipData: TooltipData;
  xScale: any;
  width: number;
  chartOptions: ListChartOptions;
}

const SeriesChart = ({
  groupOptions,
  chartOptions,
  chartWasDragging,
  seriesMapping,
  currentWellId,
  extremeDates,
  height,
  isAxisDragging,
  isDragging,
  isLast,
  leftOffset,
  onPillClick,
  onSetTooltipData,
  onXAxisScaling,
  tooltipData,
  series,
  xScale,
  width,
}: SeriesChartProps) => {
  const containerElem = React.useRef(null);
  const rect =
    containerElem && containerElem.current
      ? //@ts-expect-error
        containerElem.current.getBoundingClientRect()
      : {};

  const getContainerAreaCoords = React.useCallback(
    elementBoundingRect => ({
      x1: elementBoundingRect.x,
      x2: elementBoundingRect.x + elementBoundingRect.width,
      y1: elementBoundingRect.y,
      y2: elementBoundingRect.y + elementBoundingRect.height,
    }),
    [],
  );
  const noteContainerCoords = getContainerAreaCoords(rect);

  const currentPointerIsInsideChart =
    tooltipData && tooltipData.dataSeriesTooltipData
      ? isInside(
          R.pick(['clientY', 'clientX'], tooltipData.dataSeriesTooltipData),
          noteContainerCoords,
        )
      : false;

  const initialMaxDataPoint = React.useMemo(
    () => getMaxDataPoint(series, seriesMapping, groupOptions, extremeDates),
    [series, seriesMapping, extremeDates, groupOptions],
  );
  const [displayMinDataPoint, setDisplayMinDataPoint] = React.useState(0);
  const [displayMaxDataPoint, setDisplayMaxDataPoint] =
    React.useState(initialMaxDataPoint);
  const [yAxisLinePos, setYAxisLinePos] = React.useState<null | number>(null);

  const [isAdjusted, setIsAdjusted] = React.useState(false);
  const resetMax = React.useCallback(() => {
    setIsAdjusted(false);
    setDisplayMinDataPoint(0);
    setDisplayMaxDataPoint(initialMaxDataPoint);
  }, [setIsAdjusted, setDisplayMaxDataPoint, initialMaxDataPoint]);

  const prevMaxDataPoint = usePrevious(initialMaxDataPoint);
  const prevWellId = usePrevious(currentWellId);

  const [isYAxisDragging, setIsStartYAxisDragging] = React.useState(false);

  const yScale = React.useMemo(
    () =>
      createVarianceYScale(height, displayMinDataPoint, displayMaxDataPoint),
    [height, displayMinDataPoint, displayMaxDataPoint],
  );

  const showLine = React.useCallback(
    (rate: number) => {
      const linePosition = yScale(rate);
      setYAxisLinePos(linePosition);
    },
    [setYAxisLinePos, yScale],
  );
  const hideLine = React.useCallback(
    () => setYAxisLinePos(null),
    [setYAxisLinePos],
  );

  React.useEffect(() => {
    if (
      !R.isNil(prevMaxDataPoint) &&
      prevMaxDataPoint !== initialMaxDataPoint &&
      prevMaxDataPoint === displayMaxDataPoint
    ) {
      setDisplayMaxDataPoint(initialMaxDataPoint);
    }
  }, [
    prevMaxDataPoint,
    initialMaxDataPoint,
    displayMaxDataPoint,
    setDisplayMaxDataPoint,
  ]);

  const orderdGroupOptions = React.useMemo(() => {
    if (groupOptions.length >= 1) {
      return [...groupOptions].reverse().sort((a, b) => {
        const typeA = chartOptions[a].chartType;
        const typeB = chartOptions[b].chartType;
        if (typeA !== typeB && typeA === BAR_CHART) {
          return -1;
        }
        if (typeA !== typeB && typeB === BAR_CHART) {
          return 1;
        }
        return 0;
      });
    }
    return groupOptions;
  }, [groupOptions, chartOptions]);

  const color = React.useMemo(() => {
    if (groupOptions.length > 1 || R.isEmpty(seriesMapping)) {
      return '#000';
    }
    return (
      chartOptions[groupOptions[0]].customColor ||
      seriesMapping[groupOptions[0]].color
    );
  }, [groupOptions, chartOptions, seriesMapping]);

  React.useEffect(() => {
    if (prevWellId !== currentWellId) resetMax();
  }, [currentWellId, prevWellId, resetMax]);

  const dataMap = React.useMemo(() => {
    return series.reduce((acc, series) => {
      acc[series.day.toISOString()] = series;
      return acc;
    }, {});
  }, [series]);

  const dataForSeriesTooltip = getDataForSeriesTooltip({
    dataMap,
    chartOptions,
    groupOptions,
    seriesMapping,
    tooltipData,
  });
  return (
    <>
      {!R.isEmpty(seriesMapping) && (
        <SeriesChart.Container
          height={height}
          isLast={isLast}
          ref={containerElem}
        >
          <SeriesChart.SVGWrapper className="trellis-chart-wrapper">
            <SVGSeriesTrellis
              chartOptions={chartOptions}
              groupOptions={orderdGroupOptions}
              height={height}
              isAxisDragging={isAxisDragging}
              onSetTooltipData={onSetTooltipData}
              series={series}
              seriesMapping={seriesMapping}
              showLine={displayMinDataPoint < 0}
              dataMap={dataMap}
              tooltipData={tooltipData}
              xScale={xScale}
              yAxisLinePos={yAxisLinePos}
              yScale={yScale}
            />
            {!(isAxisDragging && chartWasDragging) && (
              <SVGSeriesTrellisInteraction
                height={height}
                isDragging={isDragging}
                maxDataPoint={displayMaxDataPoint}
                onXAxisScaling={onXAxisScaling}
                width={width}
              />
            )}
          </SeriesChart.SVGWrapper>

          <SeriesChart.YAxisContainer>
            <YAxis
              format={getFormat(seriesMapping[groupOptions[0]].sourceName)}
              height={height}
              color={color}
              disableZero={displayMinDataPoint >= 0}
              maxDataPoint={displayMaxDataPoint}
              isAdjusted={isAdjusted}
              hideLine={hideLine}
              minDataPoint={displayMinDataPoint}
              onStartYAxisDragging={() => setIsStartYAxisDragging(true)}
              onStopDragging={() => setIsStartYAxisDragging(false)}
              setDisplayMaxDataPoint={setDisplayMaxDataPoint}
              setDisplayMinDataPoint={setDisplayMinDataPoint}
              isDragging={isYAxisDragging}
              resetMax={resetMax}
              showLine={showLine}
              setIsAdjusted={setIsAdjusted}
              yScale={yScale}
              varianceTrellis
            />
          </SeriesChart.YAxisContainer>
          <SeriesChart.PillsContainer>
            {groupOptions.map(id => (
              <SeriesPill
                key={id}
                color={chartOptions[id].customColor || seriesMapping[id].color}
                onPillClick={onPillClick}
                text={`${seriesMapping[id].displayName}, ${seriesMapping[id].units}`}
              />
            ))}
          </SeriesChart.PillsContainer>
          {tooltipData &&
            (tooltipData.trellisTooltipData ||
              tooltipData.dataSeriesTooltipData) &&
            !currentPointerIsInsideChart && (
              <SecondaryInformationTooltip
                containerHeight={height}
                leftOffset={leftOffset}
                tooltipData={tooltipData}
                secondaryCavTooltipData={{}}
                seriesTooltipData={dataForSeriesTooltip}
                trellisTitle={''}
                yScale={yScale}
              />
            )}
        </SeriesChart.Container>
      )}
    </>
  );
};

SeriesChart.Container = styled.div`
  width: 100%;
  height: ${(props: Record<string, any>) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: ${(props: Record<string, any>) =>
    props.isLast ? 'none' : '1px solid grey'};
`;

SeriesChart.SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & > svg,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

SeriesChart.PillsContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 5px;
  display: flex;

  > div {
    position: initial;
    margin-left: 10px;
  }
`;

SeriesChart.YAxisContainer = styled.div`
  position: absolute;
  height: 100%;
  width: ${Y_AXIS_WIDTH}px;
  margin-left: -${Y_AXIS_WIDTH}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1px 0 0 black;
`;

export default React.memo<SeriesChartProps>(SeriesChart);
