import { utcMonth, utcDay, utcYear, TimeInterval } from 'd3-time';

import {
  OIL,
  GAS,
  WATER,
  BOE,
  GOR,
  WATERCUT,
  GLR,
  TOTAL_LIQUID,
} from 'modules/phase/models/phase';

export interface ResourceProductionChartOptions {
  title: string;
  format: string;
  units: string;
}

export interface Zoom {
  rangeMin: number;
  primary: {
    interval: TimeInterval;
    format: string;
    labelWidth: number;
  };
  secondary: {
    interval: TimeInterval;
    format: string;
    labelWidth: number;
  };
}

export const TITLE_PARAMS = { width: 55, padding: 10 };
export const SCALE_EXTENT = 15;
export const Y_AXIS_WIDTH = 50;
export const X_AXIS_HEIGHT = 30;
export const CHART_TITLE_HEIGHT = 48;
export const RIBBON_HEIGHT = 11;
export const VARIANCE_TRELLIS = 'VARIANCE_TRELLIS';

export const NO_SERIES_MESSAGE =
  'No series are currently selected in the Layout Configuration panel';

export const COLORS = {
  productionBar: '#595959',
  uncategorizedVariance: '#d8d8d8',
};

export const VARIANCE_UNITS = {
  [OIL]: 'BBL',
  [GAS]: 'MCF',
  [WATER]: 'BBL',
  [BOE]: 'BOE',
};

export const CHARTS_OPTIONS = {
  [OIL]: {
    title: OIL,
    format: ',d',
    units: 'BOPD',
  },
  [GAS]: {
    title: GAS,
    format: ',d',
    units: 'MCFD',
  },
  [WATER]: {
    title: WATER,
    format: ',d',
    units: 'BWPD',
  },
  [BOE]: {
    title: 'BOE',
    format: ',d',
    units: 'BOEPD',
  },
  [GOR]: {
    title: 'GOR',
    format: ',d',
    units: 'SCF/STB',
  },
  [WATERCUT]: {
    title: 'Watercut',
    format: '.0%',
    units: '%',
  },
  [TOTAL_LIQUID]: { title: 'Total Liquid', format: ',d', units: 'BPD' },
  [GLR]: {
    title: 'GLR',
    format: ',d',
    units: 'SCF/BBL',
  },
  [VARIANCE_TRELLIS]: { title: 'Variance', format: ',d', units: 'Other' },
};

export const CHARTS_ORDER = {
  [BOE]: 0,
  [OIL]: 1,
  [GAS]: 2,
  [GOR]: 3,
  [WATER]: 4,
  [WATERCUT]: 5,
  [GLR]: 6,
  [VARIANCE_TRELLIS]: 7,
};

export const ZOOM_LEVELS: Zoom[] = [
  {
    rangeMin: 2.5 * 365,
    primary: {
      interval: utcMonth.every(1) as TimeInterval,
      format: '',
      labelWidth: 0,
    },
    secondary: {
      interval: utcYear.every(1) as TimeInterval,
      format: '%Y',
      labelWidth: 30,
    },
  },
  {
    rangeMin: 365,
    primary: {
      interval: utcMonth.every(1) as TimeInterval,
      format: '%b',
      labelWidth: 30,
    },
    secondary: {
      interval: utcYear.every(1) as TimeInterval,
      format: '%Y',
      labelWidth: 30,
    },
  },
  {
    rangeMin: 6 * 30,
    primary: {
      interval: utcDay.every(3) as TimeInterval,
      format: '',
      labelWidth: 0,
    },
    secondary: {
      interval: utcMonth.every(1) as TimeInterval,
      format: '%b %Y',
      labelWidth: 50,
    },
  },
  {
    rangeMin: 2 * 30,
    primary: {
      interval: utcDay.every(2) as TimeInterval,
      format: '',
      labelWidth: 0,
    },
    secondary: {
      interval: utcMonth.every(1) as TimeInterval,
      format: '%b %Y',
      labelWidth: 50,
    },
  },
  {
    rangeMin: 0,
    primary: {
      interval: utcDay.every(1) as TimeInterval,
      format: '%e',
      labelWidth: 20,
    },
    secondary: {
      interval: utcMonth.every(1) as TimeInterval,
      format: '%b %Y',
      labelWidth: 50,
    },
  },
];
