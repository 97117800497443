import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import GroupMenu from './GroupMenu';
import FilterMenu from './FilterMenu';
import type { Group } from 'modules/well/models/well';
import type { Mode } from 'modules/ui/models/ui';

import SearchForm from 'components/SearchForm';
import { FilterIcon } from 'components/Icons';
import Button from 'components/Button';

import WellDrilldownTableColumns from 'modules/drilldownTable/components/WellDrilldownTableColumns';
import { CommonTotalRow } from 'modules/drilldownTable/models/drilldownTable';

interface WellDrilldownTableProps {
  compareOption: string;
  currentGroup: { subject: string; item: string };
  currentTable: string | null;
  currentWellId: string;
  devFeatures: { [featurre: string]: boolean };
  groupsByKey: { [key: string]: Group };
  groupsByOrder: Group[];
  groupMode: Mode;
  groupedTable: { [key: string]: string | number }[];
  groupForecastTable: { [key: string]: string | number }[];
  filterQuant: number;
  filterToCurrentItem: () => void;
  handleFilterGroupSelection: (group: MouseEvent) => void;
  handleGroupSelection: (group: MouseEvent) => void;
  height: number;
  isTableLoading: boolean;
  onWellTableRowChoose: (rowData: { [key: string]: string | number }) => void;
  searchWord: string;
  setCurrentTable: (table: string) => void;
  setSearchWord: (searchWord: string) => void;
  setSorting: (sortData: { sortBy: string; sortDirection?: string }) => void;
  sortCriteria: string;
  sortDirection: string;
  sortVarDirectionIndex: number;
  totalRow: CommonTotalRow;
  volumeType: boolean;
  wellDrilldownTable: { [key: string]: string | number }[];
  wellForecastTable: { [key: string]: string | number }[];
}

const WellDrilldownTable = ({
  compareOption,
  currentGroup,
  currentTable,
  currentWellId,
  devFeatures,
  filterQuant,
  filterToCurrentItem,
  groupedTable,
  groupForecastTable,
  groupMode,
  groupsByKey,
  groupsByOrder,
  handleFilterGroupSelection,
  handleGroupSelection,
  height,
  isTableLoading,
  onWellTableRowChoose,
  searchWord,
  setCurrentTable,
  setSearchWord,
  setSorting,
  sortCriteria,
  sortDirection,
  sortVarDirectionIndex,
  totalRow,
  volumeType,
  wellDrilldownTable,
  wellForecastTable,
}: WellDrilldownTableProps) => (
  <WellDrilldownTable.Container
    style={{ height: 5 + height + 'px' }}
    onClick={() => setCurrentTable('WellDrilldownTable')}
  >
    <WellDrilldownTable.MenuWrapper>
      {devFeatures.groupBy && (
        <GroupMenu
          groupsByOrder={groupsByOrder}
          handleGroupSelection={handleGroupSelection}
        />
      )}
      <SearchForm
        placeholder="Search"
        inputHandler={setSearchWord}
        value={searchWord}
        width={devFeatures.groupBy ? 310 : 380}
      />
      {devFeatures.groupBy && (
        <FilterMenu
          disabled={currentGroup.subject === 'all'}
          groupsByOrder={groupsByOrder}
          handleFilterGroupSelection={handleFilterGroupSelection}
          filterToCurrentItem={filterToCurrentItem}
        />
      )}
      <WellDrilldownTable.FilterWrapper>
        <Link to="/filter">
          <WellDrilldownTable.FilterButton type="button" width={30} height={26}>
            <FilterIcon />
            {filterQuant > 0 && (
              <WellDrilldownTable.FilterBadge>
                {filterQuant}
              </WellDrilldownTable.FilterBadge>
            )}
          </WellDrilldownTable.FilterButton>
        </Link>
      </WellDrilldownTable.FilterWrapper>
    </WellDrilldownTable.MenuWrapper>
    <WellDrilldownTableColumns
      compareOption={compareOption}
      currentGroup={currentGroup}
      currentTable={currentTable}
      currentWellId={currentWellId}
      groupForecastTable={groupForecastTable}
      groupsByKey={groupsByKey}
      drilldownTable={wellDrilldownTable}
      groupedTable={groupedTable}
      groupMode={groupMode}
      isLoading={isTableLoading}
      onChoose={onWellTableRowChoose}
      searchWord={searchWord}
      setSorting={setSorting}
      sortCriteria={sortCriteria}
      sortDirection={sortDirection}
      sortVarDirectionIndex={sortVarDirectionIndex}
      totalRow={totalRow}
      tableHeight={height}
      volumeType={volumeType}
      wellForecastTable={wellForecastTable}
    />
  </WellDrilldownTable.Container>
);

WellDrilldownTable.Container = styled.div`
  margin-bottom: 8px;
  border-left: 1px solid #c1c1c1;
  border-right: 1px solid #c1c1c1;
`;

WellDrilldownTable.MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, #f3f3f3 0%, #e1e1e1 100%);
  height: 38px;
  border-top: 1px solid #c1c1c1;
  padding: 0 5px;
`;

WellDrilldownTable.FilterWrapper = styled.div`
  position: relative;
`;

WellDrilldownTable.FilterButton = styled(Button)`
  position: relative;
`;

WellDrilldownTable.FilterBadge = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  background-color: ${(props: Record<string, any>) =>
    props.theme.colors.criticalText};
  color: #e8e8e8;
  position: absolute;
  top: 1px;
  right: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
`;

WellDrilldownTable.MenuItemLabel = styled.div`
  width: 200px;
  margin-left: 10px;
  padding: 10px 0;
`;

export default WellDrilldownTable;
