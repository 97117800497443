import { utcDay } from 'd3-time';
import * as R from 'ramda';
import * as React from 'react';

import type { TooltipData, TrellisTooltipData } from 'modules/ui/models/ui';
import { isInside, getDataForTooltip } from 'modules/chart/utils';

interface GroupSelectedBarProps {
  dataMap: { [id: string]: any };
  onSetTooltipData: (tooltipData: TrellisTooltipData | null) => void;
  svgBoundingRect: Record<string, any>;
  today: Date;
  tooltipData: TooltipData | null;
  trellisTitle: string;
  xScale: any;
  yScale: any;
}

const GroupSelectedBar = ({
  dataMap,
  onSetTooltipData,
  svgBoundingRect,
  today,
  tooltipData,
  trellisTitle,
  xScale,
  yScale,
}: GroupSelectedBarProps) => {
  const trellisTooltipData = R.pathOr(
    null,
    ['trellisTooltipData'],
    tooltipData,
  );
  const chartAreaCoords = React.useMemo(
    () => ({
      x1: svgBoundingRect.x,
      x2: svgBoundingRect.x + svgBoundingRect.width,
      y1: 55,
      y2: window.innerHeight - 35,
    }),
    [svgBoundingRect],
  );
  const trellisAreaCoords = React.useMemo(
    () => ({
      x1: svgBoundingRect.x,
      x2: svgBoundingRect.x + svgBoundingRect.width,
      y1: svgBoundingRect.y,
      y2: svgBoundingRect.y + svgBoundingRect.height,
    }),
    [svgBoundingRect],
  );

  const tooltipDate = React.useMemo(() => {
    return trellisTooltipData ? trellisTooltipData.day : false;
  }, [trellisTooltipData]);

  const barData = React.useMemo(() => {
    return tooltipDate ? dataMap[tooltipDate.toISOString()] : false;
  }, [tooltipDate, dataMap]);

  const dataIsEmpty = !trellisTooltipData;

  const selectedBarHeight = React.useMemo(() => {
    if (barData) {
      const range = yScale.range();
      const domain = yScale.domain();
      const coef =
        (range[0] - range[1]) / (Math.abs(domain[0]) + Math.abs(domain[1]));

      return Math.max(barData.production, barData.capacity) * coef;
    }
    return 0;
  }, [yScale, barData]);
  const selectedBarX = React.useMemo(
    () => (barData ? xScale(tooltipDate) : 0),
    [xScale, tooltipDate, barData],
  );
  const selectedBarY = barData
    ? yScale(Math.max(barData.production, barData.capacity))
    : 0;

  const barWidth = React.useMemo(
    () =>
      xScale.range()[1] / utcDay.count(xScale.domain()[0], xScale.domain()[1]),
    [xScale],
  );

  const mouseMoveListener = React.useCallback(
    (e: MouseEvent) => {
      const { clientX, clientY } = e;
      const currentPointerPosition = {
        clientX,
        clientY,
      };

      const currentPointerIsInsideTrellis = isInside(
        currentPointerPosition,
        trellisAreaCoords,
      );
      if (!currentPointerIsInsideTrellis) return;

      const value = yScale.invert(
        currentPointerPosition.clientY - trellisAreaCoords.y1,
      );
      const pointerDate = utcDay.floor(
        xScale.invert(currentPointerPosition.clientX - chartAreaCoords.x1),
      );
      const pointerData = {
        ...dataMap[pointerDate.toISOString()],
        clientX,
        clientY,
        trellis: trellisTitle,
      };

      const dataForTooltip = getDataForTooltip(value, pointerData);
      onSetTooltipData(
        dataForTooltip !== null &&
          dataForTooltip.day.getTime() > utcDay.offset(today, -1).getTime()
          ? null
          : dataForTooltip,
      );
    },
    [
      chartAreaCoords,
      dataMap,
      onSetTooltipData,
      today,
      trellisAreaCoords,
      trellisTitle,
      xScale,
      yScale,
    ],
  );
  const mouseLeaveListener = React.useCallback(
    () => onSetTooltipData(null),
    [onSetTooltipData],
  );

  React.useEffect(() => {
    const trellisesWrappers = document.querySelectorAll('.trellises-wrapper');
    const trellisesWrappersArray = Array.from(trellisesWrappers);
    trellisesWrappersArray.forEach(elem => {
      elem.addEventListener('mousemove', mouseMoveListener as EventListener);
      elem.addEventListener('mouseleave', mouseLeaveListener);
    });
    return () =>
      trellisesWrappersArray.forEach(elem => {
        elem.removeEventListener(
          'mousemove',
          mouseMoveListener as EventListener,
        );
        elem.removeEventListener('mouseleave', mouseLeaveListener);
      });
  }, [mouseMoveListener, mouseLeaveListener]);

  return (
    <>
      {!dataIsEmpty && (
        <>
          <rect
            width={barWidth + 6}
            height={selectedBarHeight + 6}
            x={selectedBarX - 3}
            y={selectedBarY - 3}
            strokeWidth="1"
            stroke="black"
            fill="transparent"
            pointerEvents="none"
          />
        </>
      )}
    </>
  );
};

export default GroupSelectedBar;
